import React, { Fragment } from "react";
import AnaliseGarantia from "src/components/Basicos/AnaliseGarantia";
import CheckBox from "src/components/Basicos/Form/CheckBox";
import InputValidate from "src/components/Basicos/Form/Input/inputValidate";
import HistoricoLocatarioSecundario from "src/components/Basicos/HistoricoLocatarioSecundario";
// import { formatarValor } from "src/components/Basicos/funcoes";
import TitleValidate from "src/components/Basicos/TitleSelectedValidate";
import { formatarValor } from "src/components/Basicos/funcoes";
import { mask } from "src/helpers/functions";

export type Props = {
    locatario?: any;
    campos?: any;
    handleClick?: any;
    selectSection?: any;
    sections?: any;
    onChange?: any;
    openModal?: any;
    itsView?: boolean;
    etapaId?: any;
    locatarioId?: any;
    statusNotButton?: any;
    rgOrCnhConjuge?: any;
    rgOrCnh?: any;
    tipos_atividade_ids?: any;
    nameInterpriseOrServicePublicConjuge?: any;
    nulos?: any;
    nameInterpriseOrServicePublic?: any;
    rgOrCnhProcurador?: any;
    locatarioSecundarioSection?: any;
    fiadorSection?: any;
    addLocatario?: any;
    onClickLocatarioSecundario?: any;
    existFalse?: any;
    itsFullIsTrue?: any;
    onSubmit?: any;
    itsFull?: any;
}

const FormularioCadastroPessoaJuridicaMobile: React.FunctionComponent<Props> = ({
    locatario,
    campos,
    handleClick,
    selectSection,
    sections,
    onChange,
    openModal,
    itsView,
    etapaId,
    locatarioId,
    statusNotButton,
    rgOrCnhConjuge,
    rgOrCnh,
    tipos_atividade_ids,
    nameInterpriseOrServicePublicConjuge,
    nulos,
    nameInterpriseOrServicePublic,
    rgOrCnhProcurador,
    locatarioSecundarioSection,
    fiadorSection,
    addLocatario,
    onClickLocatarioSecundario,
    existFalse,
    itsFullIsTrue,
    onSubmit,
    itsFull
}) => {
    return (
        <Fragment>
            <div className="bg-white p-5 flex flex-col rounded-lg border-white border h-full w-full" style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>
                <div className="flex">
                    <AnaliseGarantia
                        locatarioId={locatarioId}
                    />

                    <HistoricoLocatarioSecundario
                        locatarioId={locatarioId}
                    />
                </div>
                {/* Dados da Empresa */}
                <div>
                    <TitleValidate
                        title={'Dados da Empresa'}
                        selectSection={selectSection}
                        checkSection={sections?.identificacao_pessoa_juridica}
                        section={5}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Razão Social`}
                        name={`razao_social_locatario`}
                        value={locatario?.informacoes_basicas?.razao_social ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos?.razao_social_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Nome Fantasia`}
                        name={`nome_fantasia_locatario`}
                        value={locatario?.informacoes_basicas?.nome_fantasia ?? null}

                        onClick={handleClick}
                        status={itsView ? null : campos?.nome_fantasia_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`CNPJ`}
                        name={`cnpj_locatario`}
                        value={mask(locatario?.informacoes_basicas?.cnpj, "cpf_cnpj") ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos?.cnpj_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`E-mail`}
                        name={`email_locatario`}
                        value={locatario?.informacoes_basicas?.email ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos?.email_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Telefone 1`}
                        name={`telefone_locatario`}
                        value={mask(locatario?.informacoes_basicas?.telefone1, "telefone") ?? null}
                        // required={true}
                        onClick={handleClick}
                        status={itsView ? null : campos?.telefone_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Telefone 2`}
                        name={`telefone2_locatario`}
                        value={mask(locatario?.informacoes_basicas?.telefone2, "telefone") ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos?.telefone2_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`CEP`}
                        name={`cep`}
                        value={mask(locatario?.endereco?.cep, "cep") ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos?.cep ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Logradouro`}
                        name={`logradouro`}
                        value={locatario?.endereco?.logradouro ?? null}
                        // required={true}
                        onClick={handleClick}
                        status={itsView ? null : campos?.logradouro ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Número`}
                        name={`numero`}
                        value={locatario?.endereco?.numero ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos?.numero ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Complemento`}
                        name={`complemento`}
                        value={locatario?.endereco?.complemento ?? null}
                        onClick={handleClick}
                        hidden={nulos.includes(locatario?.endereco?.complemento)}
                        status={itsView ? null : campos?.complemento ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Bairro`}
                        name={`bairro`}
                        value={locatario?.endereco?.bairro ?? null}

                        onClick={handleClick}
                        status={itsView ? null : campos?.bairro ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Cidade`}
                        name={`cidade`}
                        value={locatario?.endereco?.cidade ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos?.cidade ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Estado`}
                        name={`estado`}
                        value={locatario?.endereco?.estado ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos?.estado ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <InputValidate
                        title={`Garantia Locatícia`}
                        name={`garantia_locaticia_locatario`}
                        value={locatario?.garantia?.descricao ?? locatario?.locatario_imovel?.locatario_principal?.garantia?.descricao ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.garantia_locaticia_locatario ?? null}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    <span className="font-extrabold text-lg text-gray-system-mega-imoveis">Documentos da Empresa</span>
                    {locatario?.arquivos_empresa?.arquivo_inscricao_cnpj?.nome ?
                        <InputValidate
                            title={`Cartão Inscrição CNPJ`}
                            name={`cartao_inscricao_cnpj`}
                            value={locatario?.arquivos_empresa?.arquivo_inscricao_cnpj?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            file={locatario?.arquivos_empresa?.arquivo_inscricao_cnpj?.caminho ?? null}
                            status={itsView ? null : campos?.cartao_inscricao_cnpj ?? null}
                            itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                        /> : ""}
                    {locatario?.arquivos_empresa?.arquivo_contrato_social?.nome ?
                        <InputValidate
                            title={`Contrato Social`}
                            name={`contrato_social_locatario`}
                            value={locatario?.arquivos_empresa?.arquivo_contrato_social?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            file={locatario?.arquivos_empresa?.arquivo_contrato_social?.caminho}
                            status={itsView ? null : campos?.contrato_social_locatario ?? null}
                            itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                        /> : ""}
                    {locatario?.arquivos_empresa?.arquivo_ultimo_aditivo_contrato?.nome ?
                        <InputValidate
                            title={`Último Aditivo do Contrato*`}
                            name={`ultimo_aditivo_contrato`}
                            value={locatario?.arquivos_empresa?.arquivo_ultimo_aditivo_contrato?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            file={locatario?.arquivos_empresa?.arquivo_ultimo_aditivo_contrato?.caminho}
                            status={itsView ? null : campos?.ultimo_aditivo_contrato ?? null}
                            itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                        /> : ""}
                    {locatario?.arquivos_empresa?.imposto_renda_pj?.nome ?
                        <InputValidate
                            title={`Imposto de Renda PJ`}
                            name={`imposto_renda_pj`}
                            value={locatario?.arquivos_empresa?.imposto_renda_pj?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            file={locatario?.arquivos_empresa?.imposto_renda_pj?.caminho}
                            status={itsView ? null : campos?.imposto_renda_pj ?? null}
                            itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                        /> : ""}
                </div>


                {/* Representante Legal */}
                <div className={`mt-12 ${locatario.representantes.length === 0 ? 'hidden' : ''}`}>
                    <TitleValidate
                        title={'Representante Legal'}
                        selectSection={selectSection}
                        checkSection={sections?.representantes_legal}
                        section={6}
                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                    />
                    {/* Array representantes */}
                    {locatario?.representantes.map((item: any, index: number) => {
                        let numberRepresentante = index + 1;
                        return <div>
                            <p className="font-extrabold text-xl text-gray-system-mega-imoveis mb-2">Representante {numberRepresentante}</p>
                            <InputValidate
                                title={`Nome do Representante Legal`}
                                name={`nome_representante${numberRepresentante}`}
                                value={item?.informacao_basica?.nome_cliente ?? null}
                                // required={true}
                                onClick={handleClick}
                                status={itsView ? null : campos?.[`nome_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <InputValidate
                                title={`E-mail`}
                                name={`email_representante${numberRepresentante}`}
                                value={item?.informacao_basica?.email ?? null}
                                onClick={handleClick}
                                status={itsView ? null : campos?.[`email_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <InputValidate
                                title={`Telefone 1`}
                                name={`telefone1_representante${numberRepresentante}`}
                                value={mask(item?.informacao_basica?.telefone1, "telefone") ?? null}
                                onClick={handleClick}
                                status={itsView ? null : campos?.[`telefone1_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <InputValidate
                                title={`Telefone 2`}
                                name={`telefone2_representante${numberRepresentante}`}
                                value={mask(item?.informacao_basica?.telefone2, "telefone") ?? null}
                                onClick={handleClick}
                                status={itsView ? null : campos?.[`telefone2_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <InputValidate
                                title={`CPF`}
                                name={`cpf_representante${numberRepresentante}`}
                                value={mask(item?.informacao_basica?.cpf, "cpf_cnpj") ?? null}
                                onClick={handleClick}
                                status={itsView ? null : campos?.[`cpf_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            {item?.informacao_basica?.rg_cnh_frente?.nome ?
                                <InputValidate
                                    title={`Frente`}
                                    name={`rg_cnh_frente_representante${numberRepresentante}`}
                                    value={item?.informacao_basica?.rg_cnh_frente?.nome ?? null}
                                    onClick={handleClick}
                                    isFile={true}
                                    file={item?.informacao_basica?.rg_cnh_frente?.caminho ?? null}
                                    status={itsView ? null : campos?.[`rg_cnh_frente_representante${numberRepresentante}`] ?? null}
                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                /> : ""}
                            {item?.informacao_basica?.rg_cnh_verso?.nome ?
                                <InputValidate
                                    title={`Verso`}
                                    name={`rg_cnh_verso_representante${numberRepresentante}`}
                                    value={item?.informacao_basica?.rg_cnh_verso?.nome ?? null}
                                    onClick={handleClick}
                                    isFile={true}
                                    file={item?.informacao_basica?.rg_cnh_verso?.caminho ?? null}
                                    status={itsView ? null : campos?.[`rg_cnh_verso_representante${numberRepresentante}`] ?? null}
                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                /> : ""}
                            <InputValidate
                                title={`Naturalidade`}
                                name={`naturalidade_representante${numberRepresentante}`}
                                value={item?.informacao_basica?.naturalidade ?? null}
                                hidden={item?.informacao_basica?.naturalidade === null}
                                onClick={handleClick}
                                status={itsView ? null : campos?.[`naturalidade_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <InputValidate
                                title={`Nacionalidade`}
                                name={`nacionalidade_representante${numberRepresentante}`}
                                value={item?.informacao_basica?.nacionalidade ?? null}
                                hidden={item?.informacao_basica?.nacionalidade === null}
                                onClick={handleClick}
                                status={itsView ? null : campos?.[`nacionalidade_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <InputValidate
                                title={`Estado Civil`}
                                name={`estado_civil_representante${numberRepresentante}`}
                                value={item?.informacao_basica?.estado_civil?.descricao ?? null}
                                onClick={handleClick}
                                status={itsView ? null : campos?.[`estado_civil_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <InputValidate
                                title={`CEP`}
                                name={`cep_representante${numberRepresentante}`}
                                value={mask(item?.endereco?.cep, "cep") ?? null}
                                onClick={handleClick}
                                status={itsView ? null : campos?.[`cep_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <InputValidate
                                title={`Logradouro`}
                                name={`logradouro_representante${numberRepresentante}`}
                                value={item?.endereco?.logradouro ?? null}
                                onClick={handleClick}
                                status={itsView ? null : campos?.[`logradouro_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <InputValidate
                                title={`Número`}
                                name={`numero_representante${numberRepresentante}`}
                                value={item?.endereco?.numero ?? null}
                                onClick={handleClick}
                                status={itsView ? null : campos?.[`numero_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <InputValidate
                                title={`Complemento`}
                                name={`complemento_representante${numberRepresentante}`}
                                value={item?.endereco?.complemento ?? null}
                                onClick={handleClick}
                                status={itsView ? null : campos?.[`complemento_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <InputValidate
                                title={`Bairro`}
                                name={`bairro_representante${numberRepresentante}`}
                                value={item?.endereco?.bairro ?? null}

                                onClick={handleClick}
                                status={itsView ? null : campos?.[`bairro_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <InputValidate
                                title={`Cidade`}
                                name={`cidade_representante${numberRepresentante}`}
                                value={item?.endereco?.cidade ?? null}
                                onClick={handleClick}
                                status={itsView ? null : campos?.[`cidade_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <InputValidate
                                title={`Estado`}
                                name={`estado_representante${numberRepresentante}`}
                                value={item?.endereco?.estado ?? null}
                                onClick={handleClick}
                                status={itsView ? null : campos?.[`estado_representante${numberRepresentante}`] ?? null}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            {item?.arquivo_comprovante_residencia?.nome ?
                                <InputValidate
                                    title={`Comprovante de Residência*`}
                                    name={`comprovante_residencia_representante${numberRepresentante}`}
                                    value={item?.arquivo_comprovante_residencia?.nome ?? null}
                                    onClick={handleClick}
                                    isFile={true}
                                    hidden={item?.arquivo_comprovante_residencia === null}
                                    file={item?.arquivo_comprovante_residencia?.caminho ?? null}
                                    status={itsView ? null : campos?.[`comprovante_residencia_representante${numberRepresentante}`] ?? null}
                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                /> : ""}
                            <p className={`font-extrabold text-lg text-gray-system-mega-imoveis ml-6`}>{item?.arquivo_outros?.caminho !== undefined
                                || item?.arquivo_extrato_bancario?.caminho !== undefined
                                || item?.arquivo_extrato_bancario2?.caminho !== undefined
                                || item?.arquivo_extrato_bancario3?.caminho !== undefined
                                || item?.arquivo_ctps?.caminho !== undefined
                                || item?.arquivo_imposto_renda?.caminho !== undefined ? 'Comprovante de Renda*' : ''}</p>
                            {item?.arquivo_imposto_renda?.nome ?
                                <InputValidate
                                    title={`Imposto de Renda`}
                                    name={`imposto_renda_pj_representante${numberRepresentante}`}
                                    value={item?.arquivo_imposto_renda?.nome ?? null}
                                    onClick={handleClick}
                                    isFile={true}
                                    hidden={item?.arquivo_imposto_renda === null}
                                    file={item?.arquivo_imposto_renda?.caminho ?? null}
                                    status={itsView ? null : campos?.[`imposto_renda_pj_representante${numberRepresentante}`] ?? null}
                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                /> : ""}
                            {item?.arquivo_extrato_bancario?.nome ?
                                <InputValidate
                                    title={`Extrato Bancário`}
                                    name={`extrato_bancario_representante${numberRepresentante}`}
                                    value={item?.arquivo_extrato_bancario?.nome ?? null}
                                    onClick={handleClick}
                                    isFile={true}
                                    hidden={item?.arquivo_extrato_bancario === null}
                                    file={item?.arquivo_extrato_bancario?.caminho ?? null}
                                    status={itsView ? null : campos?.[`extrato_bancario_representante${numberRepresentante}`] ?? null}
                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                /> : ""}
                            {item?.arquivo_extrato_bancario2?.nome ?
                                <InputValidate
                                    title={`Extrato Bancário 2`}
                                    name={`extrato_bancario2_representante${numberRepresentante}`}
                                    value={item?.arquivo_extrato_bancario2?.nome ?? null}
                                    onClick={handleClick}
                                    isFile={true}
                                    hidden={item?.arquivo_extrato_bancario2 === null}
                                    file={item?.arquivo_extrato_bancario2?.caminho ?? null}
                                    status={itsView ? null : campos?.[`extrato_bancario2_representante${numberRepresentante}`] ?? null}
                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                /> : ""}
                            {item?.arquivo_extrato_bancario3?.nome ?
                                <InputValidate
                                    title={`Extrato Bancário 3`}
                                    name={`extrato_bancario3_representante${numberRepresentante}`}
                                    value={item?.arquivo_extrato_bancario3?.nome ?? null}
                                    onClick={handleClick}
                                    isFile={true}
                                    hidden={item?.arquivo_extrato_bancario3 === null}
                                    file={item?.arquivo_extrato_bancario3?.caminho ?? null}
                                    status={itsView ? null : campos?.[`extrato_bancario3_representante${numberRepresentante}`] ?? null}
                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                /> : ""}
                            {item?.arquivo_extrato_bancario?.nome ?
                                <InputValidate
                                    title={`Outros`}
                                    name={`outros_documentos_representante${numberRepresentante}`}
                                    value={item?.arquivo_outros?.nome ?? null}
                                    onClick={handleClick}
                                    isFile={true}
                                    hidden={item?.arquivo_outros === null}
                                    file={item?.arquivo_outros?.caminho ?? null}
                                    status={itsView ? null : campos?.[`outros_documentos_representante${numberRepresentante}`] ?? null}
                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                /> : ""}
                        </div>
                    })}
                </div>
                {/* Outros Dados - x campos*/}
                <div className={`mt-12 ${locatario?.fiador.filter((item: any) => { return item?.status === 1 }).length > 0 || locatario?.locatario_secundario.filter((item: any) => { return item?.principal === false }).length > 0 ? '' : 'hidden'}`}>
                    <TitleValidate
                        title={'Outros Dados'}
                        selectSection={selectSection}
                        checkSection={sections?.outros_dados ?? null}
                        section={9}
                        itsButton={true}
                    />
                    {locatario?.locatario_secundario.filter((item: any) => { return item?.principal === false }).map((lo: any) => {
                        return locatarioSecundarioSection(lo);
                    })}

                    {locatario?.fiador.map((item: any) => {
                        return fiadorSection(item);
                    })}
                </div>
                {/* Imóvel Pretendido */}
                {/* <div className={`mt-12 ${locatario.imovel_pretendido === null ? 'hidden' : ''}`}>
                            <TitleValidate
                                title={'Imóvel Pretendido'}
                                selectSection={selectSection}
                                checkSection={sections?.imovel_pretendido_juridico}
                                section={7}
                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                            />
                            <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`Código do Imóvel`}
                                        name={`codigo_imovel_pretendido_juridico`}
                                        value={locatario?.imovel_pretendido?.imovel?.codigo_imovel ?? null}
                                        onClick={handleClick}
                                        status={itsView ? null : campos?.codigo_imovel_pretendido_juridico ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Logradouro`}
                                            name={`logradouro_imovel_pretendido_juridico`}
                                            value={locatario?.imovel_pretendido?.imovel?.endereco ?? null}
                                            onClick={handleClick}
                                            status={itsView ? null : campos?.logradouro_imovel_pretendido_juridico ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                        />
                                        <InputValidate
                                            title={`Número`}
                                            name={`numero_imovel_pretendido_juridico`}
                                            value={locatario?.imovel_pretendido?.imovel?.numero ?? null}
                                            onClick={handleClick}
                                            status={itsView ? null : campos?.numero_imovel_pretendido_juridico ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                        />
                                    </div>
                                    <InputValidate
                                        title={`Complemento`}
                                        name={`complemento_imovel_pretendido_juridico`}
                                        value={locatario?.imovel_pretendido?.imovel?.complemento ?? null}
                                        onClick={handleClick}
                                        hidden={nulos.includes(locatario?.imovel_pretendido?.imovel?.complemento)}
                                        status={itsView ? null : campos?.complemento_imovel_pretendido_juridico ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                    />
                                </div>
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`Bairro`}
                                        name={`bairro_imovel_pretendido_juridico`}
                                        value={locatario?.imovel_pretendido?.imovel?.bairro ?? null}
                                        onClick={handleClick}
                                        status={itsView ? null : campos?.bairro_imovel_pretendido_juridico ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Cidade`}
                                            name={`cidade_imovel_pretendido_juridico`}
                                            value={locatario?.imovel_pretendido?.imovel?.cidade ?? null}
                                            onClick={handleClick}
                                            status={itsView ? null : campos?.cidade_imovel_pretendido_juridico ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                        />
                                        <InputValidate
                                            title={`Estado`}
                                            name={`estado_imovel_pretendido_juridico`}
                                            value={locatario?.imovel_pretendido?.imovel?.estado ?? null}
                                            onClick={handleClick}
                                            status={itsView ? null : campos?.estado_imovel_pretendido_juridico ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                <div className={`mt-12 ${locatario?.imovel_pretendido === null ? 'hidden' : ''}`}>
                    <TitleValidate
                        title={'Imóvel Pretendido'}
                        selectSection={selectSection}
                        checkSection={sections?.imovel_pretendido ?? null}
                        section={4}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Finalidade`}
                        name={`finalidade_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.finalidade?.descricao ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.finalidade_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Código do Imóvel`}
                        name={`codigo_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.imovel?.codigo_imovel ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.codigo_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Logradouro`}
                        name={`logradouro_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.imovel?.endereco ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.logradouro_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Número`}
                        name={`numero_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.imovel?.numero ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.numero_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Complemento`}
                        name={`complemento_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.imovel?.complemento ?? null}
                        onClick={handleClick}
                        hidden={locatario?.imovel_pretendido?.imovel?.complemento === null}
                        status={itsView ? null : campos.complemento_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Bairro`}
                        name={`bairro_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.imovel?.bairro ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.bairro_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Cidade`}
                        name={`cidade_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.imovel?.cidade ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.cidade_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Estado`}
                        name={`estado_imovel_pretendido`}
                        value={locatario?.imovel_pretendido?.imovel?.estado ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.estado_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Aluguel`}
                        name={`aluguel_imovel_pretendido`}
                        value={formatarValor(locatario?.imovel_pretendido?.imovel?.preco_locacao, "dinheiro") ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.aluguel_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`IPTU`}
                        name={`iptu_imovel_pretendido`}
                        value={formatarValor(locatario?.imovel_pretendido?.imovel?.iptu, "dinheiro") ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.iptu_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Condomínio`}
                        name={`condominio_imovel_pretendido`}
                        value={formatarValor(locatario?.imovel_pretendido?.imovel?.condominio, "dinheiro") ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.condominio_imovel_pretendido ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Sede Responsável pelo Atendimento`}
                        name={`sede_responsavel_atendimento`}
                        value={locatario?.sede_responsavel?.nome ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.sede_responsavel_atendimento ?? null}
                        itsButton={true}
                    />
                </div>

                {locatario?.principal ?
                    <CheckBox
                        id="novo_locatario"
                        checked={!addLocatario}
                        funcao={onClickLocatarioSecundario}
                        label={'Solicitar adição de novos Locatários Secundários?'}
                        style={'mb-2 mt-2'}
                    // id={'1'}
                    />
                    : ""
                }

                {/* comentario*/}
                <div className={`${itsView ? 'hidden' : 'instrucoes-reenvio'} mt-5`}>
                    <div className="w-1/2">
                        <p className="font-extrabold text-lg text-gray-system-mega-imoveis">Instruções para reenvio*</p>
                        <textarea
                            rows={2}
                            name={`instrucao_reenvio`}
                            value={locatario?.instrucao_reenvio}
                            style={{ maxHeight: "20rem", minHeight: "3.5rem", resize: "none" }}
                            className="block p-4 w-full h-32 mt-2 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                            placeholder={'Adicione instruções para reenvio'}
                            onChange={onChange}
                            required={existFalse}
                        />
                    </div>
                </div>
            </div>
            {!statusNotButton.includes(locatario?.ultimo_historico?.status?.id) ? <div>
                {itsFullIsTrue() ? <div className="w-full flex flex-row gap-4 justify-center items-center">
                    <div>
                        <button
                            type="button"
                            onClick={() => openModal(true)}
                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                            style={{ backgroundColor: '#FF3B3B' }}
                        >
                            reprovar cadastro
                        </button>
                    </div>
                    <div>
                        <button
                            type="submit"
                            onClick={() => onSubmit("aprovado", 3)}
                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                            style={{ backgroundColor: '#0DCB64' }}
                        >
                            aprovar cadastro
                        </button>
                    </div>
                </div> : <div className="w-full flex flex-row gap-4 justify-center items-center">
                    <div>
                        <button
                            type="submit"
                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs border-2 border-blue-mega-imoveis text-blue-dark-mega-imoveis bg-transparent cursor-pointer rounded-lg justify-center items-center`}
                        >
                            Salvar e continuar depois
                        </button>
                    </div>
                    <div>
                        <button
                            type="submit"
                            disabled={!itsFull() ?? true}
                            onClick={() => onSubmit("reenviado", 3)}
                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs ${itsFull() ? 'bg-blue-dark-mega-imoveis text-white' : 'bg-gray-300 text-gray-700'}   cursor-pointer rounded-lg justify-center items-center`}
                        >
                            enviar para usuário revisar
                        </button>
                    </div>
                </div>}
            </div> : ''}
        </Fragment>
    );
}

export default FormularioCadastroPessoaJuridicaMobile;