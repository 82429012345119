import React, { Fragment } from "react";
import { FaCheck, FaExclamation } from "react-icons/fa";


export default function TitleValidate({
    selectSection,
    checkSection,
    title,
    section,
    itsButton = false
}: any): JSX.Element {
    return (
        <Fragment>
            <div className="w-full border-b mb-4">
                <div className="flex flex-row gap-2 w-full">
                    {itsButton ? "" : <div className="flex flex-col gap-1 justify-start items-center">
                        <button type={`button`} onClick={() => selectSection(section, true)} className={`flex justify-center focus:outline-none items-center p-1 cursor-pointer rounded-full h-5 w-5 ${checkSection && checkSection !== null ? 'bg-green-500' : 'bg-white shadow-md'}`}>
                            <span className={`flex p-1`}>
                                <FaCheck style={{ color: checkSection === null ? "#0DCB64" : checkSection ? "#FFFFFF" : "#0DCB64" }} />
                            </span>
                        </button>

                        <button type={`button`} onClick={() => selectSection(section, false)} className={`flex justify-center focus:outline-none items-center p-1 cursor-pointer rounded-full h-5 w-5 ${!checkSection && checkSection !== null ? 'bg-yellow-500' : 'bg-white shadow-md'}`}>
                            <span className={`flex p-1`}>
                                <FaExclamation style={{ color: !checkSection && checkSection !== null ? "#FFFFFF" : "#FFD80E" }} />
                            </span>
                        </button>
                    </div>}
                    <div>
                        <p className="font-extrabold text-2xl text-gray-system-mega-imoveis mb-4 font-mont-bold">{title ?? null}</p>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
