import React, { useState } from 'react';
import { Title } from 'src/components';
import InputSelect from 'src/components/Basicos/Form/Input/InputSelect';
import Inputlabel from 'src/components/Basicos/Form/Input/InputLabel';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import './style.css'

function Accordion({
    tiposSedesCadastro,
    usuario,
    onChangeEventSelect,
    sedeActive,
    typeInicio,
    setTypeInicio,
    dateActive,
    onChangeDate,
    typeFim,
    setTypeFim }: any) {
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded((current) => !current);

    return (
        <div onClick={toggleExpanded} className={`item-accordion ${expanded ? "accordion-expandido-mobile" : "accordion-nao-expandido"}`}>
            <div className={`flex flex-col w-full h-auto rounded-2xl bg-white p-8 gap-2 items-center ${expanded ? "itens-expadido" : "itens-nao-expandido"}`}>
                <div className="flex w-full filtro-title-mobile">
                    <Title
                        className={`text-2xl font-bold font-mont-bold text-gray-system-mega-imoveis`}
                        content={`Filtros`} />
                    {expanded ? <IoIosArrowUp size={'1.5rem'} className='text-gray-system-mega-imoveis'/> : <IoIosArrowDown size={'1.5rem'} className='text-gray-system-mega-imoveis'/>}
                </div>
                <div className={`${expanded ? "flex w-full lg:flex-row flex-col gap-5" : "itens-accordion-nao-expandido"}`}>
                    <div className="w-full">
                        <Title
                            className={`text-base font-bold text-gray-system-mega-imoveis`}
                            content={`Sede`} />
                        <InputSelect
                            name={`sede_id`}
                            options={tiposSedesCadastro?.filter((item: any) => (usuario.sedes)?.some((elemento: any) => elemento.sede_id === item.value))}
                            className={`bg-white w-full text-gray-900 text-sm border-gray-300 border rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2`}
                            colClassname={`w-full`}
                            onChange={onChangeEventSelect}
                            placeholder={"Selecione"}
                            value={tiposSedesCadastro?.filter((option: any) => (option?.value === sedeActive))}
                        />
                    </div>
                    <Inputlabel
                        type={typeInicio}
                        className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 p-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full`}
                        colClassname={`w-full`}
                        name={"dateInicial"}
                        placeholder={`Período Início Vigência`}
                        onFocus={() => setTypeInicio("date")}
                        value={dateActive?.dateInicial ?? ""}
                        onChange={onChangeDate}
                        inputTitle={`Data Cadastro Inicial`}
                        titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
                    />
                    <Inputlabel
                        type={typeFim}
                        className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4`}
                        colClassname={`w-full`}
                        name={"dateFinal"}
                        placeholder={`Período Fim Vigência`}
                        onFocus={() => setTypeFim("date")}
                        value={dateActive?.dateFinal ?? ""}
                        onChange={onChangeDate}
                        inputTitle={`Data Cadastro Final`}
                        titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
                    />
                </div>
            </div>
        </div>
    )
}

type Props = {
    tiposSedesCadastro: any,
    usuario: any,
    onChangeEventSelect: any,
    sedeActive: any,
    typeInicio: any,
    setTypeInicio: any,
    dateActive: any,
    onChangeDate: any,
    typeFim: any,
    setTypeFim: any
}

const FiltroMobile: React.FunctionComponent<Props> = ({
    tiposSedesCadastro,
    usuario,
    onChangeEventSelect,
    sedeActive,
    typeInicio,
    setTypeInicio,
    dateActive,
    onChangeDate,
    typeFim,
    setTypeFim
}) => {

    return (
        <Accordion
            tiposSedesCadastro={tiposSedesCadastro}
            usuario={usuario}
            onChangeEventSelect={onChangeEventSelect}
            sedeActive={sedeActive}
            typeInicio={typeInicio}
            setTypeInicio={setTypeInicio}
            dateActive={dateActive}
            onChangeDate={onChangeDate}
            typeFim={typeFim}
            setTypeFim={setTypeFim}
        />
    );
}

export default FiltroMobile;