export const selectStyle = {
  control: (styles, state) => {
    return {
      ...styles,
      borderRadius: "10px",
      borderBottom: "none",
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      padding: "",
      minHeight: "0px",
      outline: 0,
      boxShadow: "none",
      height: state?.getValue().length > 1 ? undefined : "calc(1.5em + 1rem)",
      flexWrap: "unset !important",
      "&:hover": { boxShadow: "none", outline: 0 },
      "&:focus": {
        boxShadow: "0",
        outline: 0,
        border: "none",
      },
      backgroundColor: false ? "#e9ecef" : styles.backgroundColor,
      opacity: false ? 1 : styles.opacity,
      cursor: "pointer",
    };
  },
  option: (styles) => ({
    ...styles,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    fontSize: "0.8rem",
    overflow: "hidden",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "#828282",
  }),
  valueContainer: (styles) => ({
    ...styles,
    width: "120px",
    outline: 0,
    "&:hover": { boxShadow: "none", outline: 0 },
    "&:focus": { boxShadow: "0", outline: 0 },
    }),
  input: (styles) => ({
    ...styles,
    height: "",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#828282",
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 999999,
  }),
};
