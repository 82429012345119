import React, { useRef, useState } from 'react'
import { FiEye } from 'react-icons/fi';
import iconCrownFill from "src/assets/icones/icon-crown-fill.svg"
import iconCrown from "src/assets/icones/icon-crown.svg"
import { MdArrowDropDown } from 'react-icons/md';
import { mask } from 'src/helpers/functions';
import { NavLink } from 'react-router-dom';
import { BiTrash } from "react-icons/bi";
import ModalDesativarLocSec from 'src/components/Funcionalidades/ModalDesativarLocSec';

interface AccordionProps {
    children?: Array<JSX.Element> | JSX.Element;
    disable?: boolean;
    data?: any;
    type?: string;
    responsibleRegister?: string;
    isLocatarioSecundario?: boolean
}

export const AccordionTimeLine: React.FC<AccordionProps> = ({
    children,
    disable = false,
    data,
    type,
    responsibleRegister,
    isLocatarioSecundario = false
}) => {
    const [active, setActive] = useState(false)
    const [height, setHeight] = useState('0px')
    const [rotate, setRotate] = useState('transform duration-700 ease')
    const [modalDesativar, setModalDesativar] = useState<boolean>(false);

    // const titleAccordion = type === 'fiador' ? "Fiador" : type === 'imovel' ? `${data?.endereco?.logradouro}, ${data?.endereco?.numero}, ${data?.endereco?.bairro}, ${data?.endereco?.cidade}, ${data?.endereco?.estado}` : `Locatário ${isLocatarioSecundario ? "Secundário" : "Principal"}`
    const contentSpace = useRef(null)

    function toggleAccordion() {
        setActive((prevState) => !prevState)
        // @ts-ignore
        setHeight(active ? '0px' : `${contentSpace.current.scrollHeight}px`)
        setRotate(active ? 'transform duration-700 ease' : 'transform duration-700 ease rotate-180')
    }

    const ModalDesativar = () => {
        setModalDesativar(!modalDesativar)
    }

    //foi criado esse arranjo porque por algum motivo a primeira funcao nao estava funcionando para fechar o modal.
    const fecharModalDesativar = () => {
        setModalDesativar(false)
    }

    function normalizarWord(word: string | undefined) {
        switch (word) {
            case "locatario":
                return "locatario";
            case "secundario":
                return "secundário";
            default:
                return word;
        }
    }

    function getTexts(type: string | undefined, key: string) {
        switch (type) {
            case "locatario":
                const locatario = {
                    'title': `Locatário ${isLocatarioSecundario ? "Secundário" : "Principal"}`,
                    'subtitle': ` - ${data?.informacoes_basicas?.nome_cliente ?? data?.informacoes_basicas?.razao_social}`,
                    'description': `${mask(data?.informacoes_basicas?.cpf, "cpf_cnpj")} | ${mask(data?.informacoes_basicas?.telefone1, 'telefone')} | ${data?.informacoes_basicas?.email} | Cadastrado pelo ${normalizarWord(responsibleRegister)}`,
                    'mainButton': !isLocatarioSecundario,
                    'redirect': `/cadastro/${type}/detalhes/${data?.id}`
                }
                return locatario[key];
            case "fiador":
                const fiador = {
                    'title': `Fiador`,
                    'subtitle': ` - ${data?.informacoes_basicas?.nome_cliente ?? data?.informacoes_basicas?.razao_social ?? data?.informacoes_basicas?.nome_fantasia}`,
                    'description': `${mask(data?.informacoes_basicas?.cpf, "cpf_cnpj")} | ${mask(data?.informacoes_basicas?.telefone1, 'telefone')} | ${data?.informacoes_basicas?.email} | Cadastrado pelo ${normalizarWord(responsibleRegister)}`,
                    'mainButton': null,
                    'redirect': `/cadastro/${type}/detalhes/${data?.id}`
                }
                return fiador[key];
            case "imovel":
                const imovel = {
                    'title': `${data?.endereco?.logradouro}, ${data?.endereco?.numero}, ${data?.endereco?.bairro}, ${data?.endereco?.cidade}, ${data?.endereco?.estado}`,
                    'subtitle': '',
                    'description': `Para ${data?.venda ? `Venda ${data?.locacao ? `e Locação` : ''}` : `${data?.locacao ? 'Locacao' : ''}`}`,
                    'mainButton': null,
                    'redirect': `/cadastro/${type}/detalhes/${data?.id}`
                }
                return imovel[key];
            case "proprietario":
                const proprietario = {
                    'title': `Proprietário`,
                    'subtitle': ` - ${data?.informacoes_basicas?.nome_cliente ?? data?.informacoes_basicas?.nome_fantasia}`,
                    'description': `${mask(data?.informacoes_basicas?.cpf, "cpf_cnpj")} | ${mask(data?.informacoes_basicas?.telefone1, 'telefone')} | ${data?.informacoes_basicas?.email} `,
                    'mainButton': data?.imovel_proprietario_auxiliar?.principal,
                    'redirect': `/cadastro/${type}/detalhes/${data?.id}`
                }
                return proprietario[key];
            default:
                return;
        }
    }

    return (
        <>
            <ModalDesativarLocSec
                open={modalDesativar}
                locatario={data}
                isClose={fecharModalDesativar}
                fiador={data}
            />
            <div className={`${!disable ? `flex flex-col px-4 border rounded-2xl shadow-lg mb-8  ${data?.status == 2 ? 'bg-white' : ''}` : ''} `}>
                {!disable ? <button className={`py-6 box-border gap-2 appearance-none cursor-pointer focus:outline-none flex items-center justify-between z-10`}>
                    <div>
                        <div className={`flex w-full items-center justify-start p-1 focus:outline-none `} >
                            <span className="font-mont-bold" >{getTexts(type, 'title')}{getTexts(type, 'subtitle')}</span>
                        </div>
                        <div className={`flex w-full items-center justify-start p-1 focus:outline-none `} >
                            <span> {getTexts(type, 'description')} </span>
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        {type == "locatario" && isLocatarioSecundario ? <div className="justify-end items-center flex">
                            <button type={`button`} onClick={() => { ModalDesativar() }} className={`flex justify-center focus:outline-none cursor-pointer ${!getTexts(type, 'mainButton') ? 'bg-gray-ligth-system-mega-imoveis w-10 h-10 items-center rounded-lg mr-3' : ''}`}>
                                <div className={`flex ml-auto h-16 w-16 items-center justify-center p-1 focus:outline-none `} onClick={() => { }}>
                                    <BiTrash size={`1.5rem`} style={{ color: "#807E7E" }} />
                                </div>
                            </button>
                        </div> : ""}
                        {getTexts(type, 'mainButton') !== null ? <div className="justify-end items-center flex">
                            <button type={`button`} onClick={() => { }} className={`flex justify-center focus:outline-none cursor-pointer ${!getTexts(type, 'mainButton') ? 'bg-gray-ligth-system-mega-imoveis w-10 h-10 items-center rounded-lg mr-3' : ''}`}>
                                <div className={`flex ml-auto h-16 w-16 items-center justify-center p-1 focus:outline-none `} onClick={() => { }}>
                                    <img src={!getTexts(type, 'mainButton') ? iconCrown : iconCrownFill} className={`inset-0 ${!getTexts(type, 'mainButton') ? 'h-8 w-8' : 'h-12 w-12'} object-center`} alt="icon_crown" />
                                </div>
                            </button>
                        </div> : ""}
                        <div className="justify-end items-center flex mr-4">
                            <NavLink to={getTexts(type, 'redirect')} className={`flex justify-center focus:outline-none cursor-pointer bg-gray-ligth-system-mega-imoveis w-10 h-10 items-center rounded-lg`}>
                                <span className={`flex p-1`}>
                                    <FiEye size={`1.5rem`} style={{ color: "#807E7E" }} />
                                </span>
                            </NavLink>
                        </div>
                        {/* <Link
                        to={getTexts(type, 'redirect')}
                        className="flex ml-auto h-10 text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl">
                        <FiEye
                            size={'1.3rem'}
                            className={`text-gray-system-mega-imoveis`} />
                    </Link> */}
                        <div className={`flex ml-auto h-10 w-10 items-center justify-center mt-3 p-1 focus:outline-none rounded-xl`}>
                            <MdArrowDropDown className={`${rotate} inline-block text-blue-mega-imoveis`} size={`3rem`} onClick={toggleAccordion} />
                        </div>
                    </div>
                </button> : ""}
                <div
                    ref={contentSpace}
                    style={{ maxHeight: `${!disable ? height : 'auto'}` }}
                    className="overflow-hidden transition-max-height duration-700 ease-in-out"
                >
                    <React.Fragment>
                        {children}
                    </React.Fragment>
                </div>
            </div>
        </>
    )
}