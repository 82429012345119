import React, { useState } from 'react';
// import { axiosApi } from 'src/services/axiosInstance';
import Modal from '../ModalNoticia';
import ModalEnviado from './ModalEnviado';
import './style.css';
import { axiosApi } from 'src/services/axiosInstance';

type Props = {
    open: boolean;
    titulo: string | undefined;
    modalId: string | number | undefined;
    nameRouter?: string;
    isClose?: any;
    requestData?: any;
    onChangeStatus?: any;
    status?: any;
}

const ModalRedefinirSenha: React.FC<Props> = ({
    isClose,
    open,
    modalId,
    titulo = "Tem Certeza que Deseja Excluir?",
    nameRouter = "",
    requestData,
    onChangeStatus = "",
    status = ""
}: Props): JSX.Element => {
    const [modalEnviado, setModalEnviado] = useState<boolean>(false);

    const onSubmitReprovacao = async () => {
        try {
            await axiosApi.put(`${nameRouter}/${modalId}`)
        } catch (error) {
            console.log(error);
        } finally {
            setModalEnviado(true)
        }
    }

    const closeModal = () => {
        setModalEnviado(false)
        isClose();
    }

    return (
        <Modal
            isOpen={open}
            isClose={() => isClose()}
            headerStyle={''}
            closeOnOutsideClick={false}
            widthModal={`35%`}
            modalStyle={`modal-style bg-white rounded-lg py-5 px-10 modal-resetar-senha`}
        >
            <div>
                <ModalEnviado
                    modalId={modalId}
                    titulo='Senha Enviada'
                    open={modalEnviado}
                    isClose={closeModal}
                />
                {/* <form onClick={onSubmitReprovacao} className={`flex flex-col items-center h-32 justify-between`}> */}
                <div className="flex title-modal-redefinir">
                    <p className="font-extrabold text-center text-2xl mb-4 font-mont-bold" style={{ color: "#081E52" }}>{titulo}</p>
                </div>

                <div className='frase-modal-senha'>
                    <h1 className='mt-4 mb-4'>Deseja realmente resetar senha do colaborador?</h1>
                </div>

                <div className="flex flex-row w-full justify-center mt-4 botoes-reset-senha">

                    <button
                        type={`button`}
                        className={`flex w-full h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs bg-blue-mega-imoveis text-white cursor-pointer rounded-lg justify-center items-center tamanho-botao-reset`}
                        onClick={() => isClose()}
                    >
                        Não
                    </button>
                    <button
                        type={`button`}
                        className={`flex w-full h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs bg-white text-blue-mega-imoveis cursor-pointer rounded-lg justify-center items-center botao-reset-senha tamanho-botao-reset`}
                        onClick={() => onSubmitReprovacao()}
                    >
                        Sim
                    </button>
                </div>
                {/* </form> */}
            </div>
        </Modal>
    );
}

export default ModalRedefinirSenha;