import React, { Fragment, useEffect, useState } from 'react';
import './style.css';
import Popup from 'reactjs-popup';
import { Title } from 'src/components';
import InputSelect from 'src/components/Basicos/Form/Input/InputSelect';
import Inputlabel from 'src/components/Basicos/Form/Input/InputLabel';
import { useControlContextCadastro } from 'src/contexts/CadastroControlContext';

type DateType = {
    dateInicial: String | null,
    dateFinal: String | null,
}

type Props = {
    isOpen: any
    isClose: any
    closeOnOutsideClick: any
    lockScroll: any
    openCloseModal: any
    changeFiltro: any
}

const ModalFiltroMobile: React.FunctionComponent<Props> = ({
    isOpen,
    isClose,
    closeOnOutsideClick,
    lockScroll,
    openCloseModal,
    changeFiltro
}) => {
    const { dateActive, sedeActive, tiposSedesCadastro, tiposEtapas, setEtapa, etapaActive, setStatusEtapa, statusEtapaActive, setSede, setDateFilter } = useControlContextCadastro();
    const [typeInicio, setTypeInicio] = useState("text");
    const [typeFim, setTypeFim] = useState("text");
    const [dataForm, setDataForm] = useState<DateType>({
        dateInicial: null,
        dateFinal: null
    });

    const campos = {
        '3': [
            { 'value': 1, 'label': 'Pendente' },
            { 'value': 2, 'label': 'Aguardando Reenvio' },
            { 'value': 3, 'label': 'Reenviado' },
            { 'value': 4, 'label': 'Aprovado' },
            { 'value': 5, 'label': 'Reprovado' }
        ],
        '4': [
            { 'value': 1, 'label': 'Pendente' },
            { 'value': 2, 'label': 'Aguardando Reenvio' },
            { 'value': 3, 'label': 'Reenviado' },
            { 'value': 4, 'label': 'Aprovado' },
            { 'value': 5, 'label': 'Reprovado' }
        ],
        '5': [
            { 'value': 1, 'label': 'Pendente' },
            { 'value': 10, 'label': 'Realizado' },
            { 'value': 11, 'label': 'Não Realizado' }
        ],
        '6': [
            { 'value': 1, 'label': 'Pendente' },
            { 'value': 10, 'label': 'Realizado' },
            { 'value': 11, 'label': 'Não Realizado' }
        ]
    };

    const onChangeDate = (event: any) => {
        const { name, value } = event.target;
        setDataForm({
            ...dataForm,
            [name]: value
        })
    };

    const onChangeEtapa = (etapa: any) => {
        setEtapa(etapa.value);
    }

    const onChangeStatusEtapa = (status: any) => {
        setStatusEtapa(status.value);
    }

    const onChangeSede = (sede: any) => {
        setSede(sede.value);
    }

    useEffect(() => {
        setDateFilter(dataForm.dateInicial, dataForm.dateFinal);
    }, [dataForm]);

    return (
        <Popup
            modal
            open={isOpen}
            closeOnDocumentClick={openCloseModal}
            lockScroll={lockScroll}
            onClose={openCloseModal}
        >
            <Fragment>
                <div className='modal-filtro-mobile'>
                    <div className="w-full">
                        <Title
                            className={`text-base font-bold text-gray-system-mega-imoveis`}
                            content={`Sede`} />
                        <InputSelect
                            name={`sede_id`}
                            options={tiposSedesCadastro}
                            className={`bg-white w-full text-gray-900 text-sm border-gray-300 border rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2`}
                            colClassname={`w-full`}
                            onChange={onChangeSede}
                            placeholder={"Selecione"}
                            value={tiposSedesCadastro?.filter((option: any) => (option?.value === sedeActive))}
                        />
                        <Inputlabel
                            type={typeInicio}
                            className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 p-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full`}
                            colClassname={`w-full`}
                            name={"dateInicial"}
                            placeholder={`Período Início Vigência`}
                            onFocus={() => setTypeInicio("date")}
                            value={dateActive?.dateInicial ?? ""}
                            onChange={onChangeDate}
                            inputTitle={`Data Cadastro Inicial`}
                            titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
                        />
                        <Inputlabel
                            type={typeFim}
                            className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4`}
                            colClassname={`w-full`}
                            name={"dateFinal"}
                            placeholder={`Período Fim Vigência`}
                            onFocus={() => setTypeFim("date")}
                            value={dateActive?.dateFinal ?? ""}
                            onChange={onChangeDate}
                            inputTitle={`Data Cadastro Final`}
                            titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
                        />

                        <Title
                            className={`text-base font-bold text-gray-system-mega-imoveis`}
                            content={`Etapa`} />
                        <InputSelect
                            name={`etapa_id`}
                            options={tiposEtapas}
                            className={`bg-white w-full text-gray-900 text-sm border-gray-300 border rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2`}
                            colClassname={`w-full`}
                            onChange={onChangeEtapa}
                            placeholder={"Selecione"}
                            value={tiposEtapas?.filter((option: any) => (option?.value === etapaActive))}
                        />
                        <Title
                            className={`text-base font-bold text-gray-system-mega-imoveis`}
                            content={`Status da Etapa`} />
                        <InputSelect
                            name={`status_etapa_id`}
                            options={campos[etapaActive]}
                            className={`bg-white w-full text-gray-900 text-sm border-gray-300 border rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2`}
                            colClassname={`w-full`}
                            onChange={onChangeStatusEtapa}
                            placeholder={"Selecione"}
                            value={campos[etapaActive]?.filter((option: any) => (option?.value === statusEtapaActive))}
                        />
                    </div>
                </div>
            </Fragment>
        </Popup>
    );
}

export default ModalFiltroMobile;