import React from "react";
import dateFnsParse from "date-fns/parse";
import dateFnsFormat from "date-fns/format";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "src/components/Toast";
import { AiOutlineHome } from "react-icons/ai";
import { FaBullhorn, FaPencilAlt, FaRegNewspaper } from "react-icons/fa";
import { BsGear, BsListCheck } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { MdOutlineDisabledByDefault } from "react-icons/md";

const cpfCnpj = (e: any) => {
  if (e?.length > 11) {
    e = e
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, "$1.$2.$3/$4-$5");
  } else if (e?.length > 5) {
    e = e
      .replace(/\D/g, "")
      .replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, "$1.$2.$3-$4");
  }
  return e;
};

const cep = (e: any) => {
  return e?.replace(/\D/g, "").replace(/^(\d{5})(\d{3}).*/, "$1-$2");
};

export const data = (e: any) => {
  var n = e.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
  return !n[2] ? n[1] : n[1] + "/" + n[2] + "/" + n[3];
};

export const newMaskWpp = (tel: any) => {
  var n = tel?.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})(\d{0,1})(\d{0,4})(\d{0,4})/);
  if (tel?.length === 13)
    tel = "+" + n?.[1] + " (" + n?.[2] + ") " + n?.[3] + " " + n?.[4] + " " + n?.[5];
  return tel;
}

const telefone = (tel: any) => {
  if (tel?.length === 13) {
    tel = tel?.replace(/\D/g, "")
    tel = tel?.replace(/^(\d)/, "+$1")
    tel = tel?.replace(/(.{3})(\d)/, "$1 ($2")
    tel = tel?.replace(/(.{7})(\d)/, "$1) $2 ")
  } else if (tel?.length === 11) {
    tel = tel?.replace(/(.{0})(\d)/, "$1 ($2")
    tel = tel?.replace(/(.{4})(\d)/, "$1) $2 ")
  } else if (tel?.length === 12) {
    tel = tel?.replace(/\D/g, "")
    tel = tel?.replace(/^(\d)/, "+$1")
    tel = tel?.replace(/(.{3})(\d)/, "$1 ($2")
    tel = tel?.replace(/(.{7})(\d)/, "$1) $2")
  }

  if (tel?.length === 12) {
    tel = tel?.replace(/(.{1})$/, "-$1")
  } else if (tel?.length === 13) {
    tel = tel?.replace(/(.{2})$/, "-$1")
  } else if (tel?.length === 14) {
    tel = tel?.replace(/(.{3})$/, "-$1")
  } else if (tel?.length === 15) {
    tel = tel?.replace(/(.{4})$/, "-$1")
  } else if (tel?.length > 15) {
    tel = tel?.replace(/(.{4})$/, "-$1")
  } else if (tel?.length <= 8) {
    var parte1 = tel.slice(0, 4);
    var parte2 = tel.slice(4, 9);
    tel = `${parte1}-${parte2}`
  }
  return tel;
};

export const maskInput = (e: any) => {
  const { id, value } = e.target;
  if (id === "cpf_cnpj") {
    cpfCnpj(value);
  } else if (id === "telefone") {
    telefone(value);
  } else if (id === "hora") {
    e.target.value = hora(e.target.value);
  }
};

export const hora = (e: any) => {
  var n = e.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})/);
  return !n[2] ? n[1] : !n[3] ? n[1] + ":" + n[2] : n[1] + ":" + n[2] + " - " + n[3] + ":" + n[4];
};

export const unMask = (e: any) => {
  return e?.replace(/\D/g, "");
};

export const normalizarLocatario = (name: string) => {
  switch (name) {
    case "principal":
      return "Locatário Principal";
    default:
      return name;
  }
};

export const mask = (valor: any, tipo: any) => {
  switch (tipo) {
    case "cpf_cnpj":
      return cpfCnpj(valor);
    case "telefone":
      return telefone(valor);
    case "cep":
      return cep(valor);
    default:
      return valor;
  }
};

export const dateToPtBr = (date: any) => {
  return date?.split("-")?.reverse()?.join("/");
};

export const dateToPtBrWithHour = (date: any) => {
  var data = date?.split(" ");
  return dateToPtBr(data?.[0]) + " " + data?.[1]
}

export const dateLaravelToPtBr = (date: any) => {
  var d = date?.split("T");

  return d?.[0]?.split("-")?.reverse()?.join("/");
};

export const dateAmerican = (date: any) => {
  return date?.split("/")?.reverse()?.join("-");
};

export const onlyNumberInput = (e: any) => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault();
  }
};

export const numbersWithDot = (e: any) => {
  var rgx = /^[0-9]*\.?[0-9]*$/;
  return e?.match(rgx);
};

function buildFormData(formData: FormData, data: any, parentKey: string = "") {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data: object, metodo: string) {
  const formData = new FormData();
  formData.append("_method", metodo);
  buildFormData(formData, data);
  return formData;
}

export const parseDate = (
  str: string,
  format = formatoData,
  locale: string
) => {
  const parsed = dateFnsParse(str, format, new Date());
  return parsed instanceof Date ? parsed : undefined;
};

export const formatDate = (
  date: Date,
  format = formatoData,
  locale: string
) => {
  return date ? dateFnsFormat(date, format) : date;
};

export const formatoData = "dd/MM/yyyy";
export const formatLaravelTimestamp = "yyyy-MM-dd HH:mm:ss";
export const formatLaravelDate = "yyyy-MM-dd";
export const pt_br = {
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  labels: { nextMonth: "Próximo mês", previousMonth: "Mês anterior" },
  firstDayOfWeek: 1,
  weekdaysShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
  weekdaysLong: [
    "Domingo",
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
    "Sábado",
  ],
};

export const isObject = (val: string | object) => {
  return val instanceof Object;
};

// const transformarValidacaoLaravel = (msg: string | object) => {
//   if (isObject(msg)) {
//     return Object.keys(msg).flatMap((x: any) => msg[x].flatMap((y: any) => y));
//   }
//   return msg;
// };

export const error = (mensagens: string | object) => {
  // mensagens = transformarValidacaoLaravel(mensagens);

  const render = Array.isArray(mensagens) ? (
    <div>
      Error:
      <ul>
        {mensagens.map((element, key) => (
          <li key={key}>{element}</li>
        ))}
      </ul>
    </div>
  ) : (
    `${mensagens}!`
  );

  toast.error(render, {
    position: "bottom-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const alertMsg = (tipo: string, msg: string) => {
  switch (tipo) {
    case "success":
      toast.success(<Toast toastType={"success"} toastContent={msg} />);
      break;
    case "warning":
      toast.warning(<Toast toastType={"warning"} toastContent={msg} />);
      break;
    case "error":
      toast.error(<Toast toastType={"error"} toastContent={msg} />);
      break;
  }
};

export const tratamentoInput = (value: any) => value === ',' ? '' : value;

export const adaptTitle = (title: string) => {
  let fontSize = '0';
  if (title.length > 0 && title.length <= 30) {
    fontSize = '0.9rem';
  } else if (title.length > 31 && title.length <= 35) {
    fontSize = '0.75rem';
  } else if (title.length > 36 && title.length <= 40) {
    fontSize = '0.65em';
  } else {
    fontSize = '0.6rem';
  }
  return fontSize;
}

export const compararDatas = (initial_date: string, final_date: string, data: any) => {
  let nulos = [null, '', ' ', undefined];

  if (!nulos.includes(initial_date) || !nulos.includes(final_date)) {
    if (nulos.includes(initial_date) && !nulos.includes(final_date)) {
      return data.filter((item: any) => { return new Date(item?.created_at?.split('T')[0]) <= new Date(final_date) });
    } else if (nulos.includes(final_date) && !nulos.includes(initial_date)) {
      return data.filter((item: any) => { return new Date(item?.created_at?.split('T')[0]) >= new Date(initial_date) });
    } else {
      return data.filter((item: any) => { return new Date(item?.created_at?.split('T')[0]) >= new Date(initial_date) && new Date(item?.created_at?.split('T')[0]) <= new Date(final_date) });
    }
  }
  return data;
}

export const searchFilter = (searching: string, data: any) => {
  let nulos = [null, '', ' ', undefined];

  if (!nulos.includes(searching)) {
    return data.filter((item: any) => { return item?.titulo.toLowerCase().includes(searching.toLowerCase()) });
  }
  return data;
}

export const searchFilterCargo = (searching: string, data: any) => {
  let nulos = [null, '', ' ', undefined];

  if (!nulos.includes(searching)) {
    return data.filter((item: any) => { return item?.descricao.toLowerCase().includes(searching.toLowerCase()) });
  }
  return data;
}

export const tipoFilter = (tipo_id: number | undefined, data: any) => {
  let nulos = [null, undefined, 0];

  if (!nulos.includes(tipo_id)) {
    return data.filter((item: any) => { return item?.tipo_noticia_id === tipo_id });
  }
  return data;
}


export const compararDataVigente = (dataInicioVigente: string, dataFimVigente: string, dataInicio: string, dataFim: string) => (

  (new Date(dataInicio) <= new Date(dataFimVigente) || new Date(dataFim) <= new Date(dataFimVigente)) && (new Date(dataInicio) >= new Date(dataInicioVigente) || new Date(dataFim) >= new Date(dataInicioVigente)) || (new Date(dataInicio) <= new Date(dataInicioVigente) && new Date(dataFim) >= new Date(dataFimVigente))
);

//não remover o item
export const adicionarValor = (array: any, valor: number) => {
  var objeto = array;
  objeto.map((item: any, index: number) => {
    return objeto[index].id_rateio_energia = valor;
  });
  return objeto
};

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export const statusColor = (status_id: number) => {
  switch (status_id) {
    case 1:
      return {
        title: 'Pendente',
        color: '#FFD80E'
      }
    case 2:
      return {
        title: 'Aguardando reenvio',
        color: '#FFC189'
      }
    case 3:
      return {
        title: 'Reenviado',
        color: '#FF820E'
      }
    case 4:
      return {
        title: 'Aprovado',
        color: '#0CB75A'
      }
    case 5:
      return {
        title: 'Reprovado',
        color: '#FF3B3B'
      }

    case 6:
      return {
        title: 'API Não Responde',
        color: '#FFD80E'
      }
    case 7:
      return {
        title: 'Cadastro pré-aprovado',
        color: '#0CB75A'
      }
    case 8:
      return {
        title: 'Cadastro pré-reprovado',
        color: '#FF3B3B'
      }
    case 9:
      return {
        title: 'Em análise',
        color: '#0A21FF'
      }
    case 10:
      return {
        title: 'Realizado',
        color: '#0CB75A'
      }
    case 11:
      return {
        title: 'Não Realizado',
        color: '#FF3B3B'
      }
    case 12:
      return {
        title: 'Cancelado',
        color: '#a6a2a2'
      }
    default:
      return {
        title: 'Pendente',
        color: '#E6E6E6'
      }
  }
}

export const icon = (iconName: string, classname: string) => {
  switch (iconName) {
    case "homeIcon":
      return <AiOutlineHome size={'1.3rem'} className={`${classname}`} />
    case "cadastroIcon":
      return <FaPencilAlt size={'1.3rem'} className={`${classname}`} />
    case "solicitacoesIcon":
      return <FaBullhorn size={'1.3rem'} className={`${classname}`} />
    case "noticiasIcon":
      return <FaRegNewspaper size={'1.3rem'} className={`${classname}`} />
    case "auxiliaresIcon":
      return <BsGear size={'1.3rem'} className={`${classname}`} />
    case "consultasIcon":
      return <BsListCheck size={'1.3rem'} className={`${classname}`} />
    case "logoutIcon":
      return <FiLogOut size={'1.3rem'} className={` transform rotate-180 ${classname}`} />
    default:
      return <MdOutlineDisabledByDefault size={'1.3rem'} />
  }
};


export const dadosConsulta = () => {
  var url = window.location.href.split("?")[1];
  var header = url.split("&");
  var objFinal = new Array
  for (var i = 0; i < header.length; i++) {
    var atributo = header[i]?.split("=");
    objFinal[atributo?.[0]] = atributo?.[1]
  }
  return objFinal;
}