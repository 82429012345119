import React, { useEffect, useState } from 'react';
import Loader from 'src/components/Basicos/Loader';
import { axiosApi } from 'src/services/axiosInstance';
import './style.css';
import { colunasCadastroLocacao, colunasVisitasImoveis, colunasLocatariosInteressados, colunasIndicacaoCorretor, colunasImoveisAlugados } from "./data-columns.json"
import TableConsulta from './TableConsulta';

interface HeaderProps {
    tipo: String;
    sedes: String;
    data_ini: String;
    data_fim: String;
    garantia: String;
    status: String;
    tipo_solicitacao_manutencao: String;
}

const Consulta: React.FunctionComponent = () => {
    const [dados, setDados] = useState<any>();
    const [header, setHeader] = useState<HeaderProps>();
    const [loader, setLoader] = useState<boolean>(true);

    const loadInfos = async (url: String, url2: String) => {
        try {
            setLoader(true);
            const { data } = await axiosApi.get(`consultas/${url}?${url2}`);
            setDados(data.dados);
            setHeader(data.header);
            setLoader(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        var url = window.location.href.split("?")[1]?.split("&")?.[0]?.split("=");
        var url2 = window.location.href.split("?")[1];
        loadInfos(url[1], url2);
    }, []);

    return (
        <Loader isActive={loader}>
            <div className='p-10'>
                <div className={`colapse-consulta-infos`}>
                    <div>
                        <h1 className='text-white estilo-fonte-negrito ml-6 text-2xl'>{header?.tipo}</h1>
                    </div>
                    <div className='infos-header'>
                        <h1 className='text-white ml-6 text-lg'><span className='estilo-fonte-negrito text-xl'>Sede: </span>{header?.sedes}</h1>
                        <h1 className='text-white ml-10 text-lg'><span className='estilo-fonte-negrito text-xl'>Data Início: </span>{header?.data_ini}</h1>
                        <h1 className='text-white ml-10 text-lg'><span className='estilo-fonte-negrito text-xl'>Data Fim: </span>{header?.data_fim}</h1>
                        {
                            header?.tipo == "Locatários Interessados" ||
                                header?.tipo == "Proprietários Interessados" ||
                                header?.tipo == "Solicitações de Proprietários" ||
                                header?.tipo == "Solicitações de Imóveis Alugados" ||
                                header?.tipo == "Indicação do Corretor" ||
                                header?.tipo == "Ouvidoria" ?
                                <h1 className='text-white ml-10 text-lg'><span className='estilo-fonte-negrito text-xl'>Tipos: </span>{header?.tipo_solicitacao_manutencao}</h1>
                                : header?.tipo == "Cadastros de Locação" ? <h1 className='text-white ml-10 text-lg'><span className='estilo-fonte-negrito text-xl'>Tipo de Garantia: </span>{header?.garantia}</h1> : ''
                        }
                        <h1 className='text-white ml-10 text-lg'><span className='estilo-fonte-negrito text-xl'>Status: </span>{header?.status}</h1>
                    </div>
                </div>
                <div className='mt-10'>
                    {
                        dados != undefined ?
                            <TableConsulta
                                columns={header?.tipo == 'Visitas a Imóveis' ? colunasVisitasImoveis :
                                    header?.tipo == 'Locatários Interessados' ? colunasLocatariosInteressados :
                                        header?.tipo == 'Proprietários Interessados' ? colunasLocatariosInteressados :
                                            header?.tipo == 'Solicitações de Proprietários' ? colunasImoveisAlugados :
                                                header?.tipo == 'Ouvidoria' ? colunasLocatariosInteressados :
                                                    header?.tipo == 'Solicitações de Imóveis Alugados' ? colunasImoveisAlugados :
                                                        header?.tipo == 'Indicação do Corretor' ? colunasIndicacaoCorretor : colunasCadastroLocacao}
                                data={dados}
                            /> : ''
                    }
                </div>
            </div>


        </Loader>
    );
}

export default Consulta;