import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardTable from "src/components/Basicos/Cards/CardTable";
// import { converterDataBr } from "src/components/Basicos/funcoes";
import Loader from "src/components/Basicos/Loader";
import TableDetails from "src/components/Basicos/TableDetails";
import TimelimeCadastroImovel from "src/components/Basicos/Timeline/TimelineCadastroImovel";
import TimelimeCadastroProprietario from "src/components/Basicos/Timeline/TimelineCadastroProprietario";
import { ajustUserAgent, converterDataBr } from "src/components/Basicos/funcoes";
import { axiosApi } from "src/services/axiosInstance";

const DetalhesCadastroImovel: React.FunctionComponent = () => {
    const [imovelData, setimovelData] = useState<any>();
    const [historico, setHistorico] = useState<any>([]);
    const [timeline, setTimeline] = useState<any>([]);
    const [proprietarios, setProprietarios] = useState<any>([]);
    const [loader, setLoader] = useState(false);
    const [etapaAtual, setEtapaAtual] = useState(0);
    const [linkImovel, setLinkImovel] = useState<any>();



    let { imovelProprietarioId }: any = useParams();

    const onTop = () => {
        window.scrollTo(0, 0);
    }

    const getImovel = async (imovelProprietarioId: number) => {
        try {
            if (imovelProprietarioId !== null) {
                setLoader(true)
                const { data } = await axiosApi.get(`/imovel-proprietario/${imovelProprietarioId}`);
                setimovelData(data?.data);

                setHistorico(data?.data?.historico);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const getLinhaTempo = async (imovelProprietarioId: number) => {
        try {
            if (imovelProprietarioId !== null && imovelProprietarioId !== undefined) {
                setLoader(true)
                const { data } = await axiosApi.get(`adm/linha-tempo/imovel-proprietario/${imovelProprietarioId}`);
                // setTimeline(data?.data?.timeLine);
                setTimeline(data?.data?.imovel?.timeline);
                setProprietarios(data?.data?.proprietarios);

            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const statusTimeLine = async (status_id: number, imovelStatusId: number) => {
        try {
            if (imovelStatusId !== null && etapaAtual !== 0) {
                setLoader(true)

                const dataForm = {
                    imovel_proprietario_id: Number(imovelStatusId),
                    etapa_id: etapaAtual,
                    comentario: linkImovel ?? null
                }
                const pathName = { '10': 'realizado', '11': 'nao-realizado' }
                await axiosApi.post(`/imovel-proprietario/status/${pathName[status_id]}`, dataForm);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
            setEtapaAtual(0);
            getLinhaTempo(imovelProprietarioId);
            getImovel(imovelProprietarioId);
        }
    }

    const imovel = [
        { header: "Endereço", value: imovelData?.endereco?.logradouro },
        { header: "Tipo", value: imovelData?.tipo_imovel?.descricao },
        { header: "Finalidade", value: imovelData?.finalidade?.descricao },
        { header: "Para Locação?", value: imovelData?.locacao ? 'Sim' : 'Não' },
        { header: "Para Venda?", value: imovelData?.venda ? 'Sim' : 'Não' }
    ];

    const origemCadastro = [
        { header: "Data e Hora", value: converterDataBr(imovelData?.informacoes_dispositivo?.created_at) },
        { header: "Dispositivo", value: imovelData?.informacoes_dispositivo?.device_type },
        { header: "Versão", value: ajustUserAgent(imovelData?.informacoes_dispositivo?.user_agent) },
        { header: "Navegador", value: imovelData?.informacoes_dispositivo?.browser_name },
        { header: "Versão do Navegador", value: imovelData?.informacoes_dispositivo?.browser_version },
        { header: "Sistema Operacional", value: imovelData?.informacoes_dispositivo?.os_name }
    ];


    useEffect(() => {
        onTop();
        getImovel(imovelProprietarioId);
        getLinhaTempo(imovelProprietarioId);
    }, [imovelProprietarioId]);

    useEffect(() => {
        onTop();
    }, []);
    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
                <div className="p-6">
                    <div className="bg-white p-10 flex flex-col space-y-20 rounded-lg border-gray-100 border h-full w-full" style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>
                        <CardTable
                            data={imovel}
                            title={`Informações do Imóvel`}
                            key={1}
                        />
                        <CardTable
                            data={origemCadastro}
                            title={`Origem do Cadastro`}
                            key={2}
                        />

                        {timeline !== undefined ? <TimelimeCadastroImovel
                            imovelId={imovelProprietarioId}
                            timeline={timeline}
                            setStatusTimeLine={statusTimeLine}
                            setEtapaAtual={setEtapaAtual}
                            setStateLinkImovel={setLinkImovel}
                            etapaAtual={etapaAtual}
                            isPrincipal={true}
                            comentario={linkImovel}
                        /> : ''}

                        {/* <div className="mt-5">
                            <p className="font-light text-lg text-gray-system-mega-imoveis">Comentário</p>
                            <textarea
                                rows={2}
                                name={`comentario_locatario`}
                                style={{ maxHeight: "20rem", minHeight: "3.5rem" }}
                                className="block p-4 w-full h-32 mt-2 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                                placeholder={'Adicione um comentário'}
                            />
                        </div> */}

                        <div className="flex flex-col">
                            <div className="text-left font-extrabold text-xl mb-4 font-mont-bold">
                                <span>{proprietarios?.length > 0 ? 'Proprietários' : ''}</span>
                            </div>
                            {proprietarios?.map((item: any) => {
                                return <TimelimeCadastroProprietario
                                    proprietarioId={item?.id}
                                    proprietario={item}
                                    timeline={item?.timeline}
                                />
                            })}
                        </div>

                        <TableDetails
                            title="Histórico de Alterações"
                            historico={historico}
                        />
                    </div>
                </div>
            </Loader>
        </Fragment>
    );
}

export default DetalhesCadastroImovel;