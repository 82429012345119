import React, { Fragment, useEffect, useState } from "react";
import { axiosApi } from "src/services/axiosInstance";

export type Props = {
    locatarioId: number
}

export type hitoricoData = {
    id: number
    locatario_id: number
    quantidade: number
    updated_at: string
    created_at: string
}

function HistoricoLocatarioSecundario({
    locatarioId
}: Props): JSX.Element {
    const [historico, setHistorico] = useState<hitoricoData[]>([]);

    const getHistorico = async (locatarioId: number) => {
        try {
            if (locatarioId !== null) {
                const { data } = await axiosApi.get(`/historico-locatario-secundario/${locatarioId}`);
                setHistorico(data?.dados);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getHistorico(locatarioId);
    }, [locatarioId])

    return (
        <Fragment>
            <div className={`w-full h-full  ${historico?.length > 0 ? 'mb-8 mt-3' : 'hidden'}`}>
                <p className="font-extrabold text-2xl mb-3 font-mont-bold"> Histórico Solicitação Novos Locatários </p>
                <div className="flex flex-row">
                    <table className="border-collapse">
                        {historico?.map((item: hitoricoData) => {
                            return (
                                <tr className="h-10" key={item.id}>
                                    <td className="font-extrabold text-lg font-mont-bold text-left"> {item?.created_at} </td>
                                    <td>
                                        <label htmlFor="inline-radio" className="ml-2 mr-2 text-base font-semibold">Solicitado</label>
                                    </td>
                                    <td className="ml-2 text-base font-semibold"> +{item?.quantidade} </td>
                                </tr>
                            );
                        })}

                    </table>
                </div>
            </div>
        </Fragment>
    );
}

export default HistoricoLocatarioSecundario;