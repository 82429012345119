import React, { Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import InputValidate from "src/components/Basicos/Form/Input/inputValidate";
import { formatarValor } from "src/components/Basicos/funcoes";
import TitleValidate from "src/components/Basicos/TitleSelectedValidate";
import { mask } from "src/helpers/functions";
import { axiosApi } from "src/services/axiosInstance";

export type Props = {
    imovel?: any;
    campos?: any;
    handleClick?: any;
    selectSection?: any;
    sections?: any;
    onChange?: any;
    openModal?: any;
    itsView?: boolean
}

const FormularioCadastroImovel: React.FunctionComponent<Props> = ({
    imovel,
    campos,
    handleClick,
    selectSection,
    sections,
    onChange,
    openModal,
    itsView
}) => {
    const { imovelProprietarioId }: any = useParams();
    const history = useHistory();

    const existFalse = Object.values(campos).filter(function (item: any) { return item === false }).length > 0;

    const cpfOrCnpj = (proprietarioItem: any) => {
        const cpf_cnpj = proprietarioItem?.informacoes_basicas?.cpf ?? proprietarioItem?.informacoes_basicas?.cnpj;
        return cpf_cnpj.length > 11 ? 'CNPJ' : 'CPF';
    }

    let nulos = [null, undefined, ""];

    const valorVenda = parseFloat(String(imovel?.valor_venda).replace('.', '').replace(',', '.'));
    const valorLocacao = parseFloat(String(imovel?.valor_locacao).replace('.', '').replace(',', '.'));

    const handleStatusCadastro = async (status: string, etapa_id: number) => {
        try {
            if (!nulos.includes(imovel?.instrucao_reenvio) || status === "aprovado") {
                const data = {
                    imovel_proprietario_id: imovelProprietarioId,
                    etapa_id: etapa_id,
                    comentario: null
                }
                await axiosApi.post(`imovel-proprietario/status/${status}`, data);
                if (status === 'reprovado') {
                    history.goBack();
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onSubmit = async (e: any) => {
        e.preventDefault();
        try {
            if (imovelProprietarioId !== null) {
                await axiosApi.post(`validacao/imovel-proprietario/${imovelProprietarioId}`, campos).then(async (response) => {
                    const data = {
                        instrucao_reenvio: imovel?.instrucao_reenvio
                    }
                    await axiosApi.put(`imovel-instrucao/${imovelProprietarioId}`, data);
                    history.goBack();
                });
            }

        } catch (error) {
            console.log(error);
        }
    }


    const itsFull = () => {
        const size = Object.keys(campos ?? null).length;

        // quantidades de campos necessario para ser validado
        let qtdCamposGeral = 23;


        //Documento que são opcionais
        if (nulos.includes(imovel?.arquivo_agua?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(imovel?.arquivo_gas?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(imovel?.arquivo_condominio?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(imovel?.arquivo_iptu?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(imovel?.arquivo_arquivo_energia?.nome)) {
            qtdCamposGeral -= 1;
        }

        return size >= qtdCamposGeral;
    }

    const itsFullIsTrue = () => {
        const value = Object.values(campos ?? null);
        return !value.includes(false) && itsFull() && value?.length;
    }

    const proprietarioSection = ((proprietario: any, index: number) => {
        return <div className="flex flex-col gap-4">
            <p className="font-mont-bold text-xl text-gray-system-mega-imoveis mb-2">Proprietário Secundário {index + 1}</p>
            <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2`}>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                    <InputValidate
                        title={`O Proprietário é*`}
                        name={`tipo_pessoa_proprietario`}
                        value={proprietario?.tipo_pessoa?.descricao ?? null}
                        onClick={handleClick}
                        status={null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={cpfOrCnpj(proprietario)}
                        name={`cpf_proprietario`}
                        value={mask(proprietario?.informacoes_basicas?.cpf, 'cpf_cnpj') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.telefone2_proprietario ?? null}
                        itsButton={true}
                    />
                </div>
                <InputValidate
                    title={`E-mail do Proprietário Secundário*`}
                    name={`email_proprietario`}
                    value={proprietario?.informacoes_basicas?.email ?? null}
                    onClick={handleClick}
                    status={itsView ? null : campos.email_proprietario ?? null}
                    itsButton={true}
                />
            </div>
            <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2`}>
                <InputValidate
                    title={`Nome do Proprietário Secundário`}
                    name={`nome_proprietario`}
                    value={proprietario?.informacoes_basicas.nome_cliente ?? proprietario?.informacoes_basicas.nome_fantasia ?? null}
                    onClick={handleClick}
                    status={itsView ? null : campos.nome_proprietario ?? null}
                    itsButton={true}
                />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                    <InputValidate
                        title={`Telefone 1`}
                        name={`telefone_proprietario`}
                        value={mask(proprietario?.informacoes_basicas?.telefone1, 'telefone') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.telefone_proprietario ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Telefone 2`}
                        name={`telefone2_proprietario`}
                        value={mask(proprietario?.informacoes_basicas?.telefone2, 'telefone') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.telefone2_proprietario ?? null}
                        itsButton={true}
                    />
                </div>
            </div>
            <div className={`flex flex-col mb-8 my-2 gap-2`}>
                <div className="flex">
                    <div className="flex items-center mr-4">
                        <input id="inline-radio" type="radio" value="" name={`inline-radio-group1-${proprietario?.id}`} className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2" checked={proprietario?.responsavel_ficha === "principal"} />
                        <label htmlFor="inline-radio" className="ml-2 text-base font-semibold text-gray-system-mega-imoveis">Proprietário Principal irá responder</label>
                    </div>
                    <div className="flex items-center mr-4">
                        <input id="inline-2-radio" type="radio" value="" name={`inline-radio-group2-${proprietario?.id}`} className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2" checked={proprietario?.responsavel_ficha === "secundario"} />
                        <label htmlFor="inline-2-radio" className="ml-2 text-base font-semibold text-gray-system-mega-imoveis">Proprietário Secundário irá responder</label>
                    </div>
                </div>
            </div>
        </div>
    });

    return (
        <Fragment>
            <div className="p-6">
                <form onSubmit={onSubmit}>
                    <div className="bg-white p-10 flex flex-col rounded-lg border-white border h-full w-full" style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>

                        {/* Dados do Imóvel */}
                        <div className="border-b border-blue-500">
                            <TitleValidate
                                title={'Dados do Imóvel'}
                                selectSection={selectSection}
                                checkSection={sections?.dados_imovel}
                                section={0}
                                itsButton={itsView}
                            />
                            <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`Tipo`}
                                        name={`tipo_imovel`}
                                        value={imovel?.tipo_imovel?.descricao ?? null}
                                        // hidden={nulos.includes(imovel?.tipo_imovel?.descricao)}
                                        onClick={handleClick}
                                        status={campos.tipo_imovel ?? null}
                                        itsButton={itsView}
                                    />
                                    <InputValidate
                                        title={`Finalidade`}
                                        name={`finalidade_imovel`}
                                        value={imovel?.finalidade?.descricao ?? null}
                                        // hidden={nulos.includes(imovel?.finalidade?.descricao)}
                                        onClick={handleClick}
                                        status={campos.finalidade_imovel ?? null}
                                        itsButton={itsView}
                                    />
                                    <InputValidate
                                        title={`Matrícula do Imóvel`}
                                        name={`matricula_imovel`}
                                        value={imovel?.matricula_imovel ?? null}
                                        // hidden={nulos.includes(imovel?.matricula_imovel)}
                                        onClick={handleClick}
                                        status={campos.matricula_imovel ?? null}
                                        itsButton={itsView}
                                    />

                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Para Locação`}
                                            name={`locacao_imovel`}
                                            value={imovel?.locacao ? 'Sim' : 'Não' ?? null}
                                            // hidden={nulos.includes(imovel?.locacao)}
                                            onClick={handleClick}
                                            status={campos.locacao_imovel ?? null}
                                            itsButton={itsView}
                                        />
                                        <InputValidate
                                            title={`Para Venda`}
                                            name={`venda_imovel`}
                                            value={imovel?.venda ? 'Sim' : 'Não' ?? null}
                                            // hidden={nulos.includes(imovel?.venda)}
                                            onClick={handleClick}
                                            status={campos.venda_imovel ?? null}
                                            itsButton={itsView}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Valor da Locação`}
                                            name={`valor_locacao`}
                                            value={formatarValor(valorLocacao, "dinheiro") ?? null}
                                            onClick={handleClick}
                                            status={campos.valor_locacao ?? null}
                                            itsButton={itsView}
                                        />
                                        <InputValidate
                                            title={`Valor da Venda`}
                                            name={`valor_venda`}
                                            value={formatarValor(valorVenda, "dinheiro") ?? null}
                                            onClick={handleClick}
                                            status={campos.valor_venda ?? null}
                                            itsButton={itsView}
                                        />
                                    </div>
                                    <InputValidate
                                        title={`Será Representado por Procurador?`}
                                        name={`principal_por_procuracao`}
                                        value={imovel?.principal_por_procuracao ? 'Sim' : 'Não' ?? null}
                                        // hidden={nulos.includes(imovel?.principal_por_procuracao)}
                                        onClick={handleClick}
                                        status={campos.principal_por_procuracao ?? null}
                                        itsButton={itsView}
                                    />
                                    <InputValidate
                                        title={`Cópia da Matrícula, Escritura
                                        ou Contrato de Compra e Venda do Imóvel`}
                                        name={`matricula_escritura_contrato`}
                                        value={imovel?.arquivo_matricula_escritura?.nome ?? null}
                                        // hidden={nulos.includes(imovel?.arquivo_matricula_escritura?.nome)}
                                        isFile={true}
                                        file={imovel?.arquivo_matricula_escritura?.caminho ?? null}
                                        onClick={handleClick}
                                        status={campos.matricula_escritura_contrato ?? null}
                                        itsButton={itsView}
                                    />


                                </div>
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`CEP`}
                                        name={`cep_imovel`}
                                        value={mask(imovel?.endereco?.cep, "cep") ?? null}
                                        // hidden={nulos.includes(imovel?.endereco?.cep)}
                                        onClick={handleClick}
                                        status={campos.cep_imovel ?? null}
                                        itsButton={itsView}
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Logradouro`}
                                            name={`logradouro_imovel`}
                                            value={imovel?.endereco?.logradouro ?? null}
                                            // hidden={nulos.includes(imovel?.endereco?.logradouro)}
                                            onClick={handleClick}
                                            status={campos.logradouro_imovel ?? null}
                                            itsButton={itsView}
                                        />
                                        <InputValidate
                                            title={`Número`}
                                            name={`numero_imovel`}
                                            value={imovel?.endereco?.numero ?? null}
                                            // hidden={nulos.includes(imovel?.endereco?.numero)}

                                            onClick={handleClick}
                                            status={campos.numero_imovel ?? null}
                                            itsButton={itsView}
                                        />
                                    </div>
                                    <InputValidate
                                        title={`Complemento`}
                                        name={`complemento_imovel`}
                                        value={imovel?.endereco?.complemento ?? null}
                                        // hidden={nulos.includes(imovel?.endereco?.complemento)}
                                        onClick={handleClick}
                                        status={campos.complemento_imovel ?? null}
                                        itsButton={itsView}
                                    />
                                    <InputValidate
                                        title={`Bairro`}
                                        name={`bairro_imovel`}
                                        value={imovel?.endereco?.bairro ?? null}
                                        // hidden={nulos.includes(imovel?.endereco?.bairro)}
                                        onClick={handleClick}
                                        status={campos.bairro_imovel ?? null}
                                        itsButton={itsView}
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Cidade`}
                                            name={`cidade_imovel`}
                                            value={imovel?.endereco?.cidade ?? null}
                                            // hidden={nulos.includes(imovel?.endereco?.cidade)}

                                            onClick={handleClick}
                                            status={campos.cidade_imovel ?? null}
                                            itsButton={itsView}
                                        />
                                        <InputValidate
                                            title={`Estado`}
                                            name={`estado_imovel`}
                                            value={imovel?.endereco?.estado ?? null}
                                            // hidden={nulos.includes(imovel?.endereco?.estado)}

                                            onClick={handleClick}
                                            status={campos.estado_imovel ?? null}
                                            itsButton={itsView}
                                        />
                                    </div>
                                    <InputValidate
                                        title={`IPTU`}
                                        name={`iptu_imovel`}
                                        value={imovel?.iptu ?? null}
                                        // hidden={nulos.includes(imovel?.iptu)}
                                        onClick={handleClick}
                                        status={campos.iptu_imovel ?? null}
                                        itsButton={itsView}
                                    />
                                </div>
                            </div>
                        </div>


                        {/* Características do Imóvel */}
                        <div className={`border-b border-blue-500 mt-12 `}>
                            <TitleValidate
                                title={'Características do Imóvel'}
                                selectSection={selectSection}
                                checkSection={sections?.caracteristica_imovel}
                                section={1}
                                itsButton={itsView}
                            />
                            <div className="flex flex-col w-full mb-8">
                                <div className="flex flex-col gap-2">
                                    <span className="font-extrabold text-lg text-gray-system-mega-imoveis">Documentos do Imóvel</span>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Água`}
                                            name={`arquivo_agua_imovel`}
                                            value={imovel?.arquivo_agua?.nome ?? null}
                                            hidden={nulos.includes(imovel?.arquivo_agua?.nome)}
                                            isFile={true}
                                            file={imovel?.arquivo_agua?.caminho ?? null}
                                            onClick={handleClick}
                                            status={campos.arquivo_agua_imovel ?? null}
                                            itsButton={itsView}
                                        />
                                        <InputValidate
                                            title={`Gás`}
                                            name={`arquivo_gas_imovel`}
                                            value={imovel?.arquivo_gas?.nome ?? null}
                                            hidden={nulos.includes(imovel?.arquivo_gas?.nome)}
                                            isFile={true}
                                            file={imovel?.arquivo_gas?.caminho ?? null}
                                            onClick={handleClick}
                                            status={campos.arquivo_gas_imovel ?? null}
                                            itsButton={itsView}
                                        />
                                        <InputValidate
                                            title={`Condomínio`}
                                            name={`arquivo_condominio_imovel`}
                                            value={imovel?.arquivo_condominio?.nome ?? null}
                                            hidden={nulos.includes(imovel?.arquivo_condominio?.nome)}
                                            isFile={true}
                                            file={imovel?.arquivo_condominio?.caminho ?? null}
                                            onClick={handleClick}
                                            status={campos.arquivo_condominio_imovel ?? null}
                                            itsButton={itsView}
                                        />
                                        <InputValidate
                                            title={`IPTU`}
                                            name={`arquivo_iptu_imovel`}
                                            value={imovel?.arquivo_iptu?.nome ?? null}
                                            hidden={nulos.includes(imovel?.arquivo_iptu?.nome)}
                                            isFile={true}
                                            file={imovel?.arquivo_iptu?.caminho ?? null}
                                            onClick={handleClick}
                                            status={campos.arquivo_iptu_imovel ?? null}
                                            itsButton={itsView}
                                        />
                                        <InputValidate
                                            title={`Energia Elétrica`}
                                            name={`arquivo_energia_imovel`}
                                            value={imovel?.arquivo_arquivo_energia?.nome ?? null}
                                            hidden={nulos.includes(imovel?.arquivo_arquivo_energia?.nome)}
                                            isFile={true}
                                            file={imovel?.arquivo_arquivo_energia?.caminho ?? null}
                                            onClick={handleClick}
                                            status={campos.arquivo_energia_imovel ?? null}
                                            itsButton={itsView}
                                        />
                                    </div>
                                    <InputValidate
                                        title={`Quantos proprietários o imóvel possui?`}
                                        name={`qtd_proprietarios`}
                                        value={imovel?.proprietarios_count ?? null}
                                        hidden={nulos.includes(imovel?.proprietarios_count)}
                                        onClick={handleClick}
                                        status={campos.qtd_proprietarios ?? null}
                                        itsButton={itsView}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={`border-b border-blue-500 mt-12 ${imovel?.proprietarios.length > 1 ? '' : 'hidden'}`}>
                            <TitleValidate
                                title={'Outros Dados'}
                                selectSection={selectSection}
                                checkSection={sections?.outros_dados ?? null}
                                section={2}
                                itsButton={true}
                            />
                            {imovel?.proprietarios.filter((item: any) => item.id !== imovel.proprietario_principal[0].id).map((item: any, index: number) => {
                                return proprietarioSection(item, index);
                            })}
                        </div>

                        {/* comentario*/}
                        <div className={`${itsView ? 'hidden' : ''} mt-5`}>
                            <p className="font-extrabold text-lg text-gray-system-mega-imoveis">Instruções para reenvio*</p>
                            <textarea
                                rows={2}
                                name={`instrucao_reenvio`}
                                value={imovel?.instrucao_reenvio}
                                style={{ maxHeight: "20rem", minHeight: "3.5rem" }}
                                className="block p-4 w-full h-32 mt-2 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                                placeholder={'Adicione instruções para reenvio'}
                                onChange={onChange}
                                required={existFalse}
                            />
                        </div>

                    </div>
                    {itsFullIsTrue() ? <div className="w-full flex flex-row gap-4 justify-center items-center">
                        <div>
                            <button
                                type="button"
                                onClick={() => openModal(true)}
                                className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                                style={{ backgroundColor: '#FF3B3B' }}
                            >
                                reprovar cadastro
                            </button>
                        </div>
                        <div>
                            <button
                                type="submit"
                                onClick={() => handleStatusCadastro("aprovado", 3)}
                                className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                                style={{ backgroundColor: '#0DCB64' }}
                            >
                                aprovar cadastro
                            </button>
                        </div>
                    </div> : <div className="w-full flex flex-row gap-4 justify-center items-center">
                        <div>
                            <button
                                type="submit"
                                className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs border-2 border-blue-mega-imoveis text-blue-dark-mega-imoveis bg-transparent cursor-pointer rounded-lg justify-center items-center`}
                            >
                                Salvar e continuar depois
                            </button>
                        </div>
                        <div>
                            <button
                                type="submit"
                                disabled={!itsFull() ?? true}
                                onClick={() => handleStatusCadastro("reenviado", 3)}
                                className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs ${itsFull() ? 'bg-blue-dark-mega-imoveis text-white' : 'bg-gray-300 text-gray-700'}   cursor-pointer rounded-lg justify-center items-center`}
                            >
                                enviar para usuário revisar
                            </button>
                        </div>
                    </div>
                    }

                </form>
            </div>
        </Fragment>
    );
}

export default FormularioCadastroImovel;