import React, { Fragment, useEffect, useState } from "react";
import { Title } from "src/components";
import Loader from "src/components/Basicos/Loader";
import BarChartHorizontal from "src/components/Graficos/BarChart/BarChartHorizontal";
import BarChartHorizontal2 from "src/components/Graficos/BarChart/BarChartHorizontal2";
import PieChartMegaImoveis from "src/components/Graficos/PieChart";
import { axiosApi } from "src/services/axiosInstance";


type Props = {
    dateInicial: any,
    dateFinal: any,
    sedeId: any
};

const DashboardUser: React.FunctionComponent<Props> = ({
    dateInicial,
    dateFinal,
    sedeId
}) => {
    const [chartsData, setChartsData] = useState<any>();
    const [loader, setLoader] = useState<boolean>(true);

    const colorsTypeTwo = [
        '#FF7E62',
        '#6271FF'
    ];

    const getLocatariosGeral = async (sedeId: number | null, dateInicial: string | null, dateFinal: string | null) => {
        try {
            setLoader(true)

            const dataForm = {
                sede_id: sedeId ?? null,
                date_inicial: dateInicial ?? null,
                date_final: dateFinal ?? null
            }
            const { data } = await axiosApi.post(`graficos-usuarios`, dataForm);
            setChartsData(data?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getLocatariosGeral(sedeId, dateInicial, dateFinal)
    }, [sedeId, dateInicial, dateFinal]);

    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
                <div className="flex flex-col w-full pb-12 px-8 gap-5">
                    <div className="flex flex-col lg:flex-row gap-5 w-full " style={{ height: '30rem' }}>
                        <div className="flex flex-col p-8 w-full lg:w-1/2 rounded-xl bg-white h-full">
                            <div className="flex w-full">
                                <Title
                                    className={`text-2xl font-bold font-mont-bold text-gray-system-mega-imoveis`}
                                    content={`Cadastros de Locação - Gerais`} />
                            </div>
                            <PieChartMegaImoveis
                                legenda={true}
                                data={chartsData?.locatariosGeral}
                            />
                        </div>
                        <div className="flex flex-col p-8 w-full lg:w-1/2 rounded-xl bg-white h-full">
                            <div className="flex w-full">
                                <Title
                                    className={`text-2xl font-bold font-mont-bold text-gray-system-mega-imoveis`}
                                    content={`Cadastros de Imóveis`} />
                            </div>
                            <PieChartMegaImoveis
                                legenda={true}
                                data={chartsData?.ImoveisGeral} />
                        </div>
                    </div>
                    <div className="flex flex-col p-8 rounded-xl bg-white w-full" style={{ height: '30rem' }}>
                        <BarChartHorizontal
                            title={'Cadastros de Locação por Sede'}
                            buttons={true}
                            data={chartsData?.locacaoPorSede}
                        />
                    </div>
                    <div className="flex flex-col p-8 rounded-xl bg-white w-full" style={{ height: '30rem' }}>
                        <div className="flex w-full">
                            <Title
                                className={`text-2xl font-bold font-mont-bold text-gray-system-mega-imoveis`}
                                content={`Leads de Proprietário`} />
                        </div>
                        <div className="flex w-full h-auto" style={{ height: '30rem' }}>
                            <div className="flex flex-col w-full h-auto items-center ">
                                <h1>Anuncie seu Imóvel</h1>
                                <PieChartMegaImoveis
                                    legenda={false}
                                    data={chartsData?.leadsProprietarioAnuncieImovel}
                                    colorsType={colorsTypeTwo}
                                />
                            </div>
                            <div className="flex flex-col w-full h-auto items-center">
                                <h1 className="mr-48">Avaliação de Imóvel</h1>
                                <PieChartMegaImoveis
                                    legenda={true}
                                    data={chartsData?.leadsProprietarioAvaliacaoImovel}
                                    colorsType={colorsTypeTwo}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="flex flex-col p-8 rounded-xl bg-white w-full" style={{ height: '30rem' }}>
                        <div className="flex w-full">
                            <Title
                                className={`text-2xl font-bold font-mont-bold text-gray-system-mega-imoveis`}
                                content={`Leads de Locatário`} />
                        </div>
                        <div className="flex w-full h-auto" style={{ height: '30rem' }}>
                            <div className="flex flex-col w-full h-auto items-center ">
                                <h1>Solicitações de Imóveis</h1>
                                <PieChartMegaImoveis
                                    legenda={false}
                                    data={chartsData?.solicitacoesImovel}
                                    colorsType={colorsTypeTwo}
                                />
                            </div>
                            <div className="flex flex-col w-full h-auto items-center">
                                <h1 className="mr-48">Mais Informações de Imóvel</h1>
                                <PieChartMegaImoveis
                                    legenda={true}
                                    data={chartsData?.maisInformacoesImovel}
                                    colorsType={colorsTypeTwo}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="flex flex-col p-8 rounded-xl bg-white w-full" style={{ height: '30rem' }}>
                        <BarChartHorizontal2
                            title={'Solicitações do Setor de Rescisão'}
                            buttons={false}
                            data={chartsData?.solicitacoesSetorRescisao}
                        />
                    </div>
                </div>
            </Loader>
        </Fragment>
    );
}

export default DashboardUser;