import React from 'react';
import { ICheck } from 'src/@types/components';
import './style.css';

const CheckBox: React.FC<ICheck> = ({ checked, funcao, label, style, id }) => {

    return (
        <div className={`flex items-center ${style} check-cancelar`}>
            {console.log(checked)}
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={funcao}
                className='checkbox-permissao'
            />
            <label className={`ml-2 text-sm font-medium cor-cinza ${label == "Revisado pela Diretoria" ? "revisado-direcao" : ""}`}>{label}</label>
        </div>
    );
}

export default CheckBox;