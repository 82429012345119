import React, { useMemo } from 'react';
import { mask } from 'src/helpers/functions';
import BlocoFiador from '../Blocos/BlocoFiador';
import Header from '../../Universal/HeaderPDF';
import Linha from '../../Universal/Linha';
import BlocoLocSecundario from '../Blocos/BlocoLocSecundario';
import BlocoImovelPretendido from '../Blocos/BlocoImovelPretendido';
import Footer from '../../Universal/FooterPDF';
import { setArraySecundarios, setHistoricoImovel } from './functions';
import TableDetails from 'src/components/Basicos/TableDetails';

interface ModeloProps {
    locatario?: any
}

const useSepararSecundarios = (locatario: any) => {
    return useMemo(() => {
        const secundarios: Array<any> = setArraySecundarios(locatario).filter((item: any) => !item?.principal);
        const setSecundarios = (array: any[], length: number) => array.slice(0, length);
        const setHistorico = (array: any[], length: number) => array?.slice(0, length);

        const secundarios1 = setSecundarios(secundarios, 3);
        const secundarios2 = setSecundarios(secundarios.slice(3, 7), 4);
        const secundarios3 = setSecundarios(secundarios.slice(7, 10), 4);

        const imovel = secundarios.length >= 7 ? 3 : secundarios.length >= 3 ? 2 : 1;

        const historico = setHistoricoImovel(locatario?.historico, setHistorico, secundarios, 2);
        const [historico1, historico2, historico3] = historico;

        return { secundarios1, secundarios2, secundarios3, imovel, secundarios, historico1, historico2, historico3 };
    }, [locatario]);
};

const ModeloOutros2: React.FC<ModeloProps> = ({ locatario }) => {
    const { secundarios1, secundarios2, secundarios3, imovel, secundarios, historico1, historico2, historico3 } = useSepararSecundarios(locatario);

    return (
        <>
            <div className='pagina-pdf'>
                {/* Página 3 */}
                <Header
                    tipo='Locatário'
                    nome={locatario?.informacoes_basicas?.nome_cliente}
                    tipo_garantia={locatario?.garantia?.descricao}
                    status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                />
                <div className='body-pdf'>
                    <Linha campo10='Nome da Empresa' valor10={locatario?.atividade_profissional?.nome_empresa} campo20='Complemento' valor20={locatario?.atividade_profissional?.endereco?.complemento ?? null} />
                    <Linha campo10='Tempo de Empresa' valor10={locatario?.atividade_profissional?.tempo_empresa?.descricao ?? null} campo11='Cargo Atual' valor11={locatario?.atividade_profissional?.cargo_atual ?? null} campo20='Bairro' valor20={locatario?.atividade_profissional?.endereco?.bairro ?? null} />
                    <Linha campo10='CEP' valor10={mask(locatario?.atividade_profissional?.endereco?.cep, "cep") ?? null} campo20='Cidade' valor20={locatario?.atividade_profissional?.endereco?.cidade ?? null} campo21='Estado' valor21={locatario?.atividade_profissional?.endereco?.estado ?? null} />
                    <Linha campo10='Último Extrato do Cartão de Crédito*' valor10={locatario?.atividade_profissional?.arquivo_extrato_bancario?.nome} />

                    <div className='borda-principal-pdf mt-4'></div>

                    {/* Renderizar os componentes BlocoLocSecundario */}
                    {secundarios1.length !== 0 && (
                        <>
                            <h1 className='sub-title estilo-fonte-negrito'>
                                Outros Dados
                            </h1>
                            {secundarios1.map((outros: any, index: number) => (
                                secundarios[index]?.tipo_locatario_id != undefined ?
                                    <BlocoLocSecundario
                                        key={index}
                                        locatarioE={secundarios[index]?.tipo_locatario?.descricao}
                                        nome={secundarios[index]?.informacoes_basicas?.nome_cliente}
                                        cpf={mask(secundarios[index]?.informacoes_basicas?.cpf, "cpf_cnpj")}
                                        email={secundarios[index]?.informacoes_basicas?.email}
                                        telefone1={mask(secundarios[index]?.informacoes_basicas?.telefone1, "telefone")}
                                        telefone2={mask(secundarios[index]?.informacoes_basicas?.telefone2, "telefone")}
                                        responsavel={secundarios[index]?.locatario_imovel?.responsavel_ficha_locatario}
                                    />
                                    : <BlocoFiador
                                        key={index}
                                        nome={secundarios[index]?.informacoes_basicas?.nome_cliente}
                                        cpf={mask(secundarios[index]?.informacoes_basicas?.cpf, "cpf_cnpj")}
                                        email={secundarios[index]?.informacoes_basicas?.email}
                                        telefone1={mask(secundarios[index]?.informacoes_basicas?.telefone1, "telefone")}
                                        telefone2={mask(secundarios[index]?.informacoes_basicas?.telefone2, "telefone")}
                                        responsavel={secundarios[index]?.responsavel_ficha}
                                    />
                            ))}
                        </>
                    )}

                    {imovel === 1 && <BlocoImovelPretendido
                        codigo_imovel={locatario?.imovel_pretendido?.imovel?.codigo_imovel}
                        bairro={locatario?.imovel_pretendido?.imovel?.bairro}
                        logradouro={locatario?.imovel_pretendido?.imovel?.endereco}
                        numero={locatario?.imovel_pretendido?.imovel?.numero}
                        cidade={locatario?.imovel_pretendido?.imovel?.cidade}
                        estado={locatario?.imovel_pretendido?.imovel?.estado}
                        complemento={locatario?.imovel_pretendido?.imovel?.complemento}
                    />}

                    {secundarios1.length < 2 && <TableDetails
                        isPDF={true}
                        title="Histórico de Alterações"
                        historico={historico1}
                    />}


                </div>

                <div className='footer-pdf'>
                    <Footer />
                </div>
            </div>

            {secundarios1?.length >= 2 && imovel == 1 && historico1?.length != 0 && (
                <div className="pagina-pdf">
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <div className='body-pdf'>
                        <TableDetails
                            isPDF={true}
                            title="Histórico de Alterações"
                            historico={historico1}
                        />
                    </div>

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {/* Página 4 */}
            {secundarios2.length !== 0 && (
                <div className='pagina-pdf'>
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <div className='body-pdf'>
                        {/* Renderizar os componentes BlocoLocSecundario */}
                        {secundarios2.map((outros: any, index: number) => (
                            secundarios2[index + 3]?.tipo_locatario_id != undefined ?
                                <BlocoLocSecundario
                                    key={index + 3}
                                    locatarioE={secundarios[index + 3]?.tipo_locatario?.descricao}
                                    nome={secundarios[index + 3]?.informacoes_basicas?.nome_cliente}
                                    cpf={mask(secundarios[index + 3]?.informacoes_basicas?.cpf, "cpf_cnpj")}
                                    email={secundarios[index + 3]?.informacoes_basicas?.email}
                                    telefone1={mask(secundarios[index + 3]?.informacoes_basicas?.telefone1, "telefone")}
                                    telefone2={mask(secundarios[index + 3]?.informacoes_basicas?.telefone2, "telefone")}
                                />
                                : <BlocoFiador
                                    key={index + 3}
                                    nome={secundarios[index + 3]?.informacoes_basicas?.nome_cliente}
                                    cpf={mask(secundarios[index + 3]?.informacoes_basicas?.cpf, "cpf_cnpj")}
                                    email={secundarios[index + 3]?.informacoes_basicas?.email}
                                    telefone1={mask(secundarios[index + 3]?.informacoes_basicas?.telefone1, "telefone")}
                                    telefone2={mask(secundarios[index + 3]?.informacoes_basicas?.telefone2, "telefone")}
                                />
                        ))}

                        {imovel === 2 && <BlocoImovelPretendido
                            codigo_imovel={locatario?.imovel_pretendido?.imovel?.codigo_imovel}
                            bairro={locatario?.imovel_pretendido?.imovel?.bairro}
                            logradouro={locatario?.imovel_pretendido?.imovel?.endereco}
                            numero={locatario?.imovel_pretendido?.imovel?.numero}
                            cidade={locatario?.imovel_pretendido?.imovel?.cidade}
                            estado={locatario?.imovel_pretendido?.imovel?.estado}
                            complemento={locatario?.imovel_pretendido?.imovel?.complemento}
                        />}

                        {secundarios2.length < 3 && <TableDetails
                            isPDF={true}
                            title="Histórico de Alterações"
                            historico={historico1}
                        />}

                    </div>

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {secundarios2.length == 0 && imovel == 2 && (
                <div className="pagina-pdf">
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <div className='body-pdf'>
                        <BlocoImovelPretendido
                            codigo_imovel={locatario?.imovel_pretendido?.imovel?.codigo_imovel}
                            bairro={locatario?.imovel_pretendido?.imovel?.bairro}
                            logradouro={locatario?.imovel_pretendido?.imovel?.endereco}
                            numero={locatario?.imovel_pretendido?.imovel?.numero}
                            cidade={locatario?.imovel_pretendido?.imovel?.cidade}
                            estado={locatario?.imovel_pretendido?.imovel?.estado}
                            complemento={locatario?.imovel_pretendido?.imovel?.complemento}
                        />
                    </div>

                    <TableDetails
                        isPDF={true}
                        title="Histórico de Alterações"
                        historico={historico1}
                    />

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {secundarios2.length >= 3 && imovel == 2 && (
                <div className="pagina-pdf">
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <TableDetails
                        isPDF={true}
                        title="Histórico de Alterações"
                        historico={historico1}
                    />

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {/* Página 5 */}
            {secundarios3.length !== 0 && (
                <div className="pagina-pdf">
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <div className='body-pdf'>
                        {/* Renderizar os componentes BlocoLocSecundario */}
                        {secundarios3.map((outros: any, index: number) => (
                            secundarios3[index + 7]?.tipo_locatario_id != undefined ?
                                <BlocoLocSecundario
                                    key={index + 7}
                                    locatarioE={secundarios[index + 7]?.tipo_locatario?.descricao}
                                    nome={secundarios[index + 7]?.informacoes_basicas?.nome_cliente}
                                    cpf={mask(secundarios[index + 7]?.informacoes_basicas?.cpf, "cpf_cnpj")}
                                    email={secundarios[index + 7]?.informacoes_basicas?.email}
                                    telefone1={mask(secundarios[index + 7]?.informacoes_basicas?.telefone1, "telefone")}
                                    telefone2={mask(secundarios[index + 7]?.informacoes_basicas?.telefone2, "telefone")}
                                />
                                : <BlocoFiador
                                    key={index + 7}
                                    nome={secundarios[index + 7]?.informacoes_basicas?.nome_cliente}
                                    cpf={mask(secundarios[index + 7]?.informacoes_basicas?.cpf, "cpf_cnpj")}
                                    email={secundarios[index + 7]?.informacoes_basicas?.email}
                                    telefone1={mask(secundarios[index + 7]?.informacoes_basicas?.telefone1, "telefone")}
                                    telefone2={mask(secundarios[index + 7]?.informacoes_basicas?.telefone2, "telefone")}
                                />
                        ))}

                        {imovel === 3 && <BlocoImovelPretendido
                            codigo_imovel={locatario?.imovel_pretendido?.imovel?.codigo_imovel}
                            bairro={locatario?.imovel_pretendido?.imovel?.bairro}
                            logradouro={locatario?.imovel_pretendido?.imovel?.endereco}
                            numero={locatario?.imovel_pretendido?.imovel?.numero}
                            cidade={locatario?.imovel_pretendido?.imovel?.cidade}
                            estado={locatario?.imovel_pretendido?.imovel?.estado}
                            complemento={locatario?.imovel_pretendido?.imovel?.complemento}
                        />}

                        {secundarios3.length < 3 && <TableDetails
                            isPDF={true}
                            title="Histórico de Alterações"
                            historico={historico1}
                        />}

                    </div>

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {secundarios3.length == 0 && imovel == 3 && (
                <div className="pagina-pdf">
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <div className='body-pdf'>
                        <BlocoImovelPretendido
                            codigo_imovel={locatario?.imovel_pretendido?.imovel?.codigo_imovel}
                            bairro={locatario?.imovel_pretendido?.imovel?.bairro}
                            logradouro={locatario?.imovel_pretendido?.imovel?.endereco}
                            numero={locatario?.imovel_pretendido?.imovel?.numero}
                            cidade={locatario?.imovel_pretendido?.imovel?.cidade}
                            estado={locatario?.imovel_pretendido?.imovel?.estado}
                            complemento={locatario?.imovel_pretendido?.imovel?.complemento}
                        />
                    </div>
                    <TableDetails
                        isPDF={true}
                        title="Histórico de Alterações"
                        historico={historico1}
                    />

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {secundarios3.length >= 3 && imovel == 3 && (
                <div className="pagina-pdf">
                    <Header
                        tipo='Locatário'
                        nome={locatario?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={locatario?.garantia?.descricao}
                        status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <TableDetails
                        isPDF={true}
                        title="Histórico de Alterações"
                        historico={historico1}
                    />

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
            )}

            {historico2?.length != 0 && (
                <>
                    <div className="pagina-pdf">
                        <Header
                            tipo='Locatário'
                            nome={locatario?.informacoes_basicas?.nome_cliente}
                            tipo_garantia={locatario?.garantia?.descricao}
                            status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                        />
                        <div className='body-pdf'>
                            <TableDetails
                                isPDF={true}
                                title="Histórico de Alterações"
                                historico={historico2}
                            />
                        </div>
                        <div className='footer-pdf'>
                            <Footer />
                        </div>
                    </div>
                    {historico3?.length != 0 && (
                        <div className="pagina-pdf">
                            <Header
                                tipo='Locatário'
                                nome={locatario?.informacoes_basicas?.nome_cliente}
                                tipo_garantia={locatario?.garantia?.descricao}
                                status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                            />
                            <div className='body-pdf'>
                                <TableDetails
                                    isPDF={true}
                                    title="Histórico de Alterações"
                                    historico={historico3}
                                />
                            </div>
                            <div className='footer-pdf'>
                                <Footer />
                            </div>
                        </div>
                    )}
                </>
            )}

            {/* {locatario?.informacoes_basicas?.rg_cnh_frente && (
                <BlocoArquivo locatario={locatario} titulo='RG Frente' caminho={"https://megaimoveis.softbuilder.com.br/storage/images/" + locatario?.informacoes_basicas?.rg_cnh_frente?.nome} />
            )} */}
        </>
    );
};

export default ModeloOutros2;