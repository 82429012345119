import React, { Fragment } from 'react';
import { useControlContextSolicitacao } from 'src/contexts/SolicitacaoControlContext';
import SelectForm from '../../Form/SelectForm';
import { converterDataBr, statusSolicitacao } from '../../funcoes';

interface SolicitacaoProps {
    id?: number;
    usuario_id?: number;
    imovel_id?: number;
    nome?: string;
    created_at?: string;
    updated_at?: string;
    data?: string;
    tempo_atendimento?: string;
    telefone1?: string;
    hora?: string;
    telefone2?: string;
    email?: string;
    codigo_imovel?: string;
    status_solicitacao_id?: number;
    sede_id?: number;
    tipo_manutencao_id?: number;
    seguro_id?: number;
    tipo_solicitacao_id?: number;
    tipo_anuncio_imovel_id?: number;
    mensagem?: string;
    arquivo_id?: number;
    comentario?: string;
    seguro?: SeguroProps;
    tipo_anuncio?: TipoAnuncio;
    imovel?: ImovelProps;
    venda?: boolean;
    locacao?: boolean;
    arquivo: ArquivoProps;
    tipo_solicitacao_locatario?: TipoSolicitacaoLocatarioProps;
    tipo_solicitacao?: TipoSolicitacaoProps;
    tipo_indicacao?: TipoIndicacaoProps;
    pessoa_indicada?: PessoaIndicadaProps;
    // st_ativo: boolean;
}

type PessoaIndicadaProps = {
    id?: string,
    nome_cliente: string,
    razao_social: string,
    nome_fantasia: string,
    cpf: string,
    cnpj: string,
    telefone1: string,
    telefone2: string,
    email: string,
    profissao: string,
    naturalidade: string,
    nacionalidade: string,
    qtd_dependentes: string,
    estado_civil_id: string,
    rg_cnh_arquivo_id: string,
}

type TipoIndicacaoProps = {
    id?: string,
    descricao?: string,
}

interface ArquivoProps {
    id?: number;
    nome?: string;
    caminho?: string;
    tipo_mime?: string;
}

interface ImovelProps {
    id?: number;
    codigo_imovel?: string;
}
interface TipoAnuncio {
    id?: number;
    descricao?: string;
}
interface TipoSolicitacaoLocatarioProps {
    id?: number;
    descricao?: string;
}

interface TipoSolicitacaoProps {
    id?: number;
    descricao?: string;
}

interface SeguroProps {
    id?: number;
    nome?: string;
}

type Props = {
    tipoSolicitacaoId?: number;
    data?: SolicitacaoProps;
    onChangeEventSelect?: any;
    opcoesStatus: Array<number>;
}

const PrimeiraLinha: React.FunctionComponent<Props> = ({
    tipoSolicitacaoId,
    data,
    onChangeEventSelect,
    opcoesStatus
}) => {
    const {
        tiposSedes
    } = useControlContextSolicitacao();

    switch (tipoSolicitacaoId) {
        case 1:
        case 4:
            return (
                <Fragment>
                    <FormaLabelInput
                        titulo="ID Usuário"
                        nome_do_campo="id"
                        valor_campo={data?.usuario_id ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                    <FormaLabelInput
                        titulo="Data Solicitação"
                        nome_do_campo="nome_do_campo_da_model"
                        valor_campo={converterDataBr(data?.created_at) ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                    <div className='form-input-solicitacao'>
                        <SelectForm
                            name={'sede_id'}
                            options={tiposSedes}
                            label={'Sede Responsável*'}
                            value={tiposSedes?.find((element: any) => element.value === data?.sede_id)}
                            onChange={onChangeEventSelect}
                            placeholder={"Selecione"}
                        // className={"modal-input mb-3 p-1"}
                        />
                    </div>
                </Fragment>
            )
        case 2:
        case 3:
        case 7:
        case 8:
            return (
                <Fragment>
                    <FormaLabelInput
                        titulo="ID Usuário"
                        nome_do_campo="id"
                        valor_campo={data?.usuario_id ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                    <FormaLabelInput
                        titulo="Telefone"
                        nome_do_campo="telefone"
                        valor_campo={data?.telefone1 ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                    <div className='form-input-solicitacao'>
                        <SelectForm
                            name={'sede_id'}
                            options={tiposSedes}
                            label={'Sede Responsável*'}
                            value={tiposSedes?.find((element: any) => element.value === data?.sede_id)}
                            onChange={onChangeEventSelect}
                            placeholder={"Selecione"}
                        // className={"modal-input mb-3 p-1"}
                        />
                    </div>
                </Fragment>
            )
        case 6:
        case 10: return (
            <Fragment>
                <FormaLabelInput
                    titulo="ID Usuário"
                    nome_do_campo="id"
                    valor_campo={data?.usuario_id ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo="Telefone"
                    nome_do_campo="telefone"
                    valor_campo={data?.telefone1 ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo="Tipo"
                    nome_do_campo="venda"
                    valor_campo={tipoSolicitacaoId == 6 ? "Declaração de IR" : "Antecipação do Aluguel"}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
            </Fragment>
        )
        case 5: return (
            <Fragment>
                <FormaLabelInput
                    titulo="ID Usuário"
                    nome_do_campo="id"
                    valor_campo={data?.usuario_id ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo="Telefone"
                    nome_do_campo="telefone"
                    valor_campo={data?.telefone1 ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo="Seguro"
                    nome_do_campo="imovel_id"
                    valor_campo={data?.seguro?.nome ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
            </Fragment>
        )
        case 9:
            switch (data?.tipo_manutencao_id) {
                case 1: return (
                    <Fragment>
                        <FormaLabelInput
                            titulo="ID Usuário"
                            nome_do_campo="id"
                            valor_campo={data?.usuario_id ?? ""}
                            tipo_campo="text"
                            marginRigth="1.5rem"
                        />
                        <FormaLabelInput
                            titulo="Data Solicitação"
                            nome_do_campo="nome_do_campo_da_model"
                            valor_campo={converterDataBr(data?.created_at) ?? ""}
                            tipo_campo="text"
                            marginRigth="1.5rem"
                        />
                        <div className='form-input-solicitacao'>
                            <SelectForm
                                name={'sede_id'}
                                options={tiposSedes}
                                label={'Sede Responsável*'}
                                value={tiposSedes?.find((element: any) => element.value === data?.sede_id)}
                                onChange={onChangeEventSelect}
                                placeholder={"Selecione"}
                            />
                        </div>
                    </Fragment>
                )
                default:
                    return (
                        <Fragment>
                            <FormaLabelInput
                                titulo="ID Usuário"
                                nome_do_campo="id"
                                valor_campo={data?.usuario_id ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                            <FormaLabelInput
                                titulo="Data Solicitação"
                                nome_do_campo="nome_do_campo_da_model"
                                valor_campo={converterDataBr(data?.created_at) ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                            <div className='form-input-solicitacao'>
                                <SelectForm
                                    name={'status_solicitacao_id'}
                                    options={statusSolicitacao(opcoesStatus)}
                                    label={'Status'}
                                    value={statusSolicitacao(opcoesStatus).find(element => element.value === data?.status_solicitacao_id)}
                                    onChange={onChangeEventSelect}
                                    placeholder={"Status"}
                                />
                            </div>
                        </Fragment>
                    )
            }
        default:
            return (
                <Fragment>
                    <FormaLabelInput
                        titulo="ID Usuário"
                        nome_do_campo="id"
                        valor_campo={data?.id ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                    <FormaLabelInput
                        titulo="Telefone"
                        nome_do_campo="telefone"
                        valor_campo={data?.pessoa_indicada?.telefone1 ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                    <FormaLabelInput
                        titulo="Tipo de Indicação"
                        nome_do_campo="tipo_indicacao"
                        valor_campo={data?.tipo_indicacao?.descricao ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                </Fragment>
            )
    }

}

interface CamposForm {
    titulo?: string,
    nome_do_campo?: string,
    valor_campo?: number | string,
    tipo_campo?: string,
    required?: boolean,
    funcao?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    width?: string,
    height?: string,
    marginLeft?: string,
    marginRigth?: string,
    displayBlock?: string,
}

const FormaLabelInput: React.FC<CamposForm> = (props) => {
    return (
        <Fragment>
            <div className='form-input-solicitacao' style={{ width: props.width }}>
                <label className="form-label-titulo my-3" style={{ display: props.displayBlock }}>{props.titulo}</label>
                <input
                    required={props.required ? true : false}
                    type={props.tipo_campo}
                    name={props.nome_do_campo}
                    onChange={props.funcao}
                    value={props.valor_campo ?? ""}
                    className="modal-input mb-3 p-1"
                    style={{ width: props.width, height: props.height, marginLeft: props.marginLeft, marginRight: props.marginRigth }}
                />
            </div>
        </Fragment>
    );
};

export default PrimeiraLinha;