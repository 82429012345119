import React, { Fragment, useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";x
import iconAnalise from "src/assets/icones/icon-analise.svg"
import CardTimeLapse from "../../Cards/CardTimeLapse";
import { AccordionTimeLine } from "../../AccordionTimeLine";
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import iconCrownFill from "src/assets/icones/icon-crown-fill.svg"
import iconCrown from "src/assets/icones/icon-crown.svg"
import { BiTrash } from "react-icons/bi";
import { FiEye } from "react-icons/fi";
import './style.css'
import { mask } from "src/helpers/functions";
import { Link } from "react-router-dom";
import ModalDesativarLocSecMobile from "src/components/Funcionalidades/ModalDesativarLocSec/indexMobile";

export type Props = {
    locatarioId?: number;
    timeline?: any;
    setStatusTimeLine?: any,
    setEtapaAtual?: any,
    etapaAtual?: number,
    isPrincipal?: boolean,
    locatario?: any,
    itsButtons?: boolean,
    isSecundario?: boolean,
    isFiador?: boolean,
    atual?: boolean
}

export default function TimelimeCadastroLocatarioMobile({
    locatarioId,
    timeline,
    setStatusTimeLine,
    setEtapaAtual,
    isPrincipal = false,
    locatario,
    itsButtons = false,
    isSecundario = false,
    isFiador,
    atual
}: Props): JSX.Element {
    const [dataOption, setDataOption] = useState();
    const [estiloCard, setEstiloCard] = useState<any>([]);
    const [estiloCardConjuge, setEstiloCardConjuge] = useState<any>([]);
    const [cardExpandido, setCardExpandido] = useState<boolean>(false);
    const [modalDesativar, setModalDesativar] = useState<boolean>(false);

    const optionsAvaliacaoImovel: Array<any> = [
        { value: 10, label: "Realizado" },
        { value: 11, label: "Não Realizado" },
    ];

    const ModalDesativar = () => {
        setModalDesativar(!modalDesativar)
    }

    const fecharModalDesativar = () => {
        setModalDesativar(false)
    }

    const statusWithButton = [1, 2, 3, 5];

    const loadEstiloAnalise = (locatario: any) => {
        var estilo1 = locatario.analise_financeira != null ? true : false;
        var estilo2 = 2;
        if (estilo1 == true) {
            var estilo2 = timeline?.[2]?.aprovado_renda == true &&
                timeline?.[2]?.aprovado_score == true &&
                timeline?.[2]?.aprovado_restricao == true ? 1 : 2;
        }
        setEstiloCard({
            estilo1: estilo1,
            estilo2: estilo2,
            titulo: "Locatário"
        })
        if (locatario.conjuge != undefined) {
            var estilo1 = locatario.conjuge?.analise_financeira != null ? true : false;
            var estilo2 = 2;
            if (estilo1 == true) {
                var estilo2 = locatario.conjuge?.analise_financeira.restricao == false ? 1 : 2;
            }
            setEstiloCardConjuge({
                estilo1: estilo1,
                estilo2: estilo2,
                titulo: "Locatário"
            })
        }
    }

    const expandirCard = () => {
        setCardExpandido(!cardExpandido);
    }

    useEffect(() => {
        if (atual)
            setCardExpandido(true);
        if (dataOption !== undefined)
            setStatusTimeLine(dataOption, locatarioId);
    }, [dataOption]);

    useEffect(() => {
        if (locatario !== undefined && timeline.lenght !== 0)
            loadEstiloAnalise(locatario);
    }, [locatario, timeline])

    return (
        <Fragment>
            <ModalDesativarLocSecMobile
                open={modalDesativar}
                locatario={locatario}
                isClose={fecharModalDesativar}
                fiador={locatario?.fiador}
                lockScroll={false}
            />
            <div className={`${atual ? 'bg-gray-ligth-system-mega-imoveis' : 'bg-white3-mega-imoveis card-content'} card-${atual ? 'principal' : 'secundario'}-mobile${cardExpandido ? '-expandido' : ''}${window.innerWidth >= 1024 ? '-tablet' : window.innerWidth <= 350 ? '-pequeno' : ''} pt-5 pb-5 pl-5 pr-5`} style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>
                {
                    atual ?
                        <div className={`title-card-mobile${cardExpandido ? '-expandido' : ''} flex w-full`}>
                            <h1 className={`font-mont-bold text-xl ${cardExpandido ? 'expandido' : ''}`}>{isFiador ? "Fiador" : isPrincipal ? "Locatário Principal" : "Locatário Secundário"} - {locatario?.informacoes_basicas?.nome_cliente}</h1>
                            <img src={!locatario?.principal ? iconCrown : iconCrownFill} className={`inset-0 ${!locatario?.principal ? 'h-8 w-8' : 'h-12 w-12'} object-center`} alt="icon_crown" />
                            {cardExpandido
                                ? <MdArrowDropUp className={`inline-block text-blue-mega-imoveis`} size={`4rem`} />
                                : <MdArrowDropDown className={`inline-block text-blue-mega-imoveis`} size={`4rem`} />
                            }
                        </div>
                        :
                        <div className={`title-card-mobile-secundario${cardExpandido ? '-expandido' : ''} w-full`}>
                            <h1 className={`font-mont-bold text-xl`}>Locatário {isPrincipal ? "Principal" : "Secundário"} - {cardExpandido ? locatario?.informacoes_basicas?.nome_cliente : locatario?.informacoes_basicas?.nome_cliente.split(" ")[0] + " " + locatario?.informacoes_basicas?.nome_cliente.split(" ")[1]}</h1>
                            {
                                window.innerWidth <= 390
                                    ? <>
                                        <h1 className="text-gray-system-mega-imoveis fonte-mobile-menor">{mask(locatario?.informacoes_basicas?.cpf ?? locatario?.informacoes_basicas?.cnpj, "cpf_cnpj")}</h1>
                                        <h1 className="text-gray-system-mega-imoveis fonte-mobile-menor">{mask(locatario?.informacoes_basicas?.telefone1, "telefone")}</h1>
                                    </>
                                    :
                                    <div className="infos-inline">
                                        <h1 className="text-gray-system-mega-imoveis">{mask(locatario?.informacoes_basicas?.cpf ?? locatario?.informacoes_basicas?.cnpj, "cpf_cnpj")}</h1>
                                        <h1 className="text-gray-system-mega-imoveis">{mask(locatario?.informacoes_basicas?.telefone1, "telefone")}</h1>
                                    </div>
                            }

                            <h1 className="text-gray-system-mega-imoveis">{locatario?.informacoes_basicas?.email}</h1>
                            <div className="icons-secundario">
                                {
                                    locatario?.principal ? '' : <BiTrash size={`2rem`} onClick={() => { ModalDesativar() }} style={{ color: "#807E7E" }} />
                                }
                                <img src={!locatario?.principal ? iconCrown : iconCrownFill} className={`inset-0 ${!locatario?.principal ? 'h-8 w-8' : 'h-12 w-12'} object-center`} alt="icon_crown" />
                                <Link
                                    to={`/cadastro/locatario/detalhes/${locatario?.id}`}
                                    onClick={() => undefined}
                                    className="flex text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl items-center justify-center">
                                    <FiEye
                                        size={'1.2rem'}
                                        className={`text-gray-system-mega-imoveis`} />
                                </Link>
                                {cardExpandido
                                    ? <MdArrowDropUp className={`inline-block text-blue-mega-imoveis`} size={`2rem`} onClick={() => expandirCard()} />
                                    : <MdArrowDropDown className={`inline-block text-blue-mega-imoveis`} size={`2rem`} onClick={() => expandirCard()} />
                                }
                            </div>
                        </div>
                }

                <div className={`${cardExpandido ? "historico-expandido" : "historico-nao-expandido"} flex w-full scale-150`}>
                    <AccordionTimeLine
                        disable={true}
                        data={locatario}
                        type={'locatario'}
                        responsibleRegister={locatario?.locatario_imovel?.responsavel_ficha_locatario}
                        isLocatarioSecundario={isSecundario}
                    >
                        <div className={`flex p-4 ${atual ? 'bg-gray-ligth-system-mega-imoveis' : 'bg-white'} rounded-xl gap-2 ${locatario?.timeLine?.[3]?.motivo != null || locatario?.timeLine?.[4]?.motivo != null ? 'time-line-expandido' : ''}`}>
                            <div className={`flex justify-center items-center md:flex-row gap-4 w-full md:justify-between`}>
                                <CardTimeLapse
                                    etapa={1}
                                    image={iconAnalise}
                                    itsButton={itsButtons ?? true}
                                    isIcon={timeline?.[1]?.status_id == 1 ? true : false}
                                    cadastroId={locatarioId}
                                    titleCard={'Cadastro Recebido'}
                                    nextPathName={'view'}
                                    status={timeline[1]?.status_id}
                                    subTitleCard={timeline[1]?.updated_at ?? null}
                                    width={'w-40'}
                                    active={timeline[1]?.status !== null}
                                    buttonTitle={'visualizar cadastro'}
                                    locatario={locatario}
                                    timeline={timeline}
                                    cancelado={locatario?.status}
                                />
                                <CardTimeLapse
                                    etapa={2}
                                    colorText={'text-yellow-500'}
                                    isIcon={false}
                                    message={true}
                                    status={timeline[2]?.status_id}
                                    messageWarning={'Cadastro pré-aprovado'}
                                    image={iconAnalise}
                                    nextPathName={'#'}
                                    cadastroId={locatarioId}
                                    itsButton={itsButtons ?? (timeline[2]?.status_id === 7 || timeline[2]?.status_id === 8)}
                                    titleCard={'Pré-análise Financeira'}
                                    subTitleCard={timeline[2]?.updated_at ?? null}
                                    active={timeline[2]?.status !== null}
                                    buttonTitle={'acessar spc/serasa'}
                                    conjugeId={locatario?.conjuge_id}
                                    locatario={locatario}
                                    estiloCard={estiloCard}
                                    estiloCardConjuge={estiloCardConjuge}
                                    analiseFinanceira={locatario?.analise_financeira}
                                    timeline={timeline}
                                    cancelado={locatario?.status}
                                />
                                <CardTimeLapse
                                    etapa={3}
                                    locatario={locatario}
                                    color={'bg-orange-500'}
                                    colorText={'text-orange-500'}
                                    status={timeline[3]?.status_id}
                                    nextPathName={'analise-cadastro'}
                                    image={iconAnalise}
                                    itsButton={itsButtons && statusWithButton.includes(timeline[3]?.status_id)}
                                    cadastroId={locatarioId}
                                    motivo={timeline[3]?.motivo ?? null}
                                    buttonTitle={'Analisar cadastro'}
                                    titleCard={'Análise do Cadastro'}
                                    subTitleCard={timeline[3]?.updated_at ?? null}
                                    active={timeline[3]?.status !== null}
                                    disponivelAlterar={timeline[3]?.alterar}
                                    cancelado={locatario?.status}
                                    timeline={timeline}
                                />
                                <CardTimeLapse
                                    etapa={4}
                                    status={timeline[4]?.status_id}
                                    locatario={locatario}
                                    image={iconAnalise}
                                    itsButton={itsButtons && statusWithButton.includes(timeline[4]?.status_id) && timeline[4]?.alterar}
                                    cadastroId={locatarioId}
                                    titleCard={'Análise pela Diretoria'}
                                    subTitleCard={timeline[4]?.updated_at ?? null}
                                    active={timeline[4]?.status !== null}
                                    color={'bg-orange-500'}
                                    colorText={'text-orange-500'}
                                    nextPathName={'analise-diretoria'}
                                    motivo={timeline[4]?.motivo ?? null}
                                    buttonTitle={'Analisar cadastro'}
                                    disponivelAlterar={timeline[4]?.alterar}
                                    cancelado={locatario?.status}
                                />
                                <CardTimeLapse
                                    etapa={5}
                                    status={timeline[5]?.status_id}
                                    locatario={locatario}
                                    image={iconAnalise}
                                    cadastroId={locatarioId}
                                    itsButton={false}
                                    motivo={timeline[5]?.motivo ?? null}
                                    titleCard={'Vistoria de Entrada'}
                                    itsButtonDropDown={timeline[5]?.status_id !== 10}
                                    options={optionsAvaliacaoImovel}
                                    subTitleCard={timeline[5]?.updated_at ?? null}
                                    active={timeline[5]?.status !== null}
                                    setStateOption={setDataOption}
                                    setStateEtapa={setEtapaAtual}
                                    currentValue={timeline[5]?.status_id ?? null}
                                    disponivelAlterar={timeline[5]?.alterar}
                                    cancelado={locatario?.status}
                                />
                                <CardTimeLapse
                                    etapa={6}
                                    status={timeline[6]?.status_id}
                                    locatario={locatario}
                                    image={iconAnalise}
                                    cadastroId={locatarioId}
                                    itsButton={false}
                                    motivo={timeline[6]?.motivo ?? null}
                                    titleCard={'Assinatura do Contrato'}
                                    itsButtonDropDown={timeline[6]?.status_id !== 10}
                                    options={optionsAvaliacaoImovel}
                                    subTitleCard={timeline[6]?.updated_at ?? null}
                                    active={timeline[6]?.status !== null}
                                    setStateOption={setDataOption}
                                    setStateEtapa={setEtapaAtual}
                                    currentValue={timeline[6]?.status_id ?? null}
                                    disponivelAlterar={timeline[6]?.alterar}
                                    cancelado={locatario?.status}
                                />
                            </div>
                        </div>
                    </AccordionTimeLine>
                </div>
            </div>
        </Fragment>
    );
}