import React, { Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import InputValidate from "src/components/Basicos/Form/Input/inputValidate";
import { formatarValor } from "src/components/Basicos/funcoes";
import TitleValidate from "src/components/Basicos/TitleSelectedValidate";
import { mask } from "src/helpers/functions";
import { axiosApi } from "src/services/axiosInstance";

export type Props = {
    proprietario?: any;
    campos?: any;
    handleClick?: any;
    selectSection?: any;
    sections?: any;
    onChange?: any;
    openModal?: any;
    itsView?: boolean
}

const FormularioCadastroProprietarioPessoaFisica: React.FunctionComponent<Props> = ({
    proprietario,
    campos,
    handleClick,
    selectSection,
    sections,
    onChange,
    openModal,
    itsView
}) => {
    const { proprietarioId }: any = useParams();
    const history = useHistory();

    const existFalse = Object.values(campos).filter(function (item: any) { return item === false }).length > 0;

    let nulos = [null, undefined, ""];

    const rgOrCnhProcurador = proprietario?.procurador?.informacao_basica?.nacionalidade === null && proprietario?.procurador?.informacao_basica?.naturalidade === null ? 'RG' : 'CNH';

    const rgOrCnh = proprietario?.informacoes_basicas?.nacionalidade === null && proprietario?.informacoes_basicas?.naturalidade === null ? 'RG' : 'CNH';

    const rgOrCnhConjuge = proprietario?.conjuge?.informacoes_basicas?.nacionalidade === null && proprietario?.conjuge?.informacoes_basicas?.naturalidade === null ? 'RG' : 'CNH';

    const statusNotButton = [2, 5];

    const handleStatusCadastro = async (status: string, etapa_id: number) => {
        try {
            if (!nulos.includes(proprietario?.instrucao_reenvio) || status === "aprovado") {
                const data = {
                    proprietario_id: proprietarioId,
                    etapa_id: etapa_id,
                    comentario: null,
                    imovel_proprietario_id: proprietario?.imovel_proprietario?.id ?? null,
                }
                await axiosApi.post(`proprietario/status/${status}`, data);
                if (status === 'reprovado') {
                    history.goBack();
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onSubmit = async (e: any) => {
        e.preventDefault();
        try {
            if (proprietarioId !== null) {
                await axiosApi.post(`validacao/proprietario/${proprietarioId}`, campos).then(async (response) => {
                    const data = {
                        instrucao_reenvio: proprietario?.instrucao_reenvio
                    }
                    await axiosApi.put(`proprietario-instrucao/${proprietarioId}`, data);
                    history.goBack();
                });
            }

        } catch (error) {
            console.log(error);
        }
    }

    //No total são 61 campos para validar

    // identificacao 10 campos    
    // Conjuge 9 campos
    // Residencia atual 10 campos
    // Dados do Imóvel 14 campos
    // Características do Imóvel 12 campos
    // Forma de Pagamento 7 campos

    const itsFull = () => {
        const size = Object.keys(campos ?? null).length;

        let qtdCamposGeral = 60;

        if (nulos.includes(proprietario?.informacoes_basicas?.cpf)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.nome_cliente)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.rg_cnh_frente?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.rg_cnh_verso?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.telefone1)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.telefone2)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.email)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.estado_civil?.descricao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.qtd_dependentes)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.nacionalidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.naturalidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.profissao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.conjuge?.informacoes_basicas?.nome_cliente)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.conjuge?.informacoes_basicas?.email)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.conjuge?.informacoes_basicas?.telefone1)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.conjuge?.informacoes_basicas?.telefone2)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.conjuge?.informacoes_basicas?.rg_cnh_frente?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.conjuge?.informacoes_basicas?.rg_cnh_verso?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.conjuge?.informacoes_basicas?.cpf)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.conjuge?.informacoes_basicas?.naturalidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.conjuge?.informacoes_basicas?.nacionalidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.conjuge?.informacoes_basicas?.profissao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.residencia_atual?.arquivo?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.endereco?.cep)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.endereco?.logradouro)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.endereco?.numero)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.endereco?.complemento)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.endereco?.bairro)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.endereco?.cidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.endereco?.estado)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.residencia_atual?.valor_aluguel)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.residencia_atual?.tempo_locacao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.forma_recebimento?.descricao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.tipo_conta?.descricao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.favorecido)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.banco)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.favorecido)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.cpf_favorecido)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.agencia)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.operacao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.conta)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.informacao_basica?.nome_cliente)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.informacao_basica?.telefone1)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.informacao_basica?.telefone2)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.informacao_basica?.rg_cnh_frente?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.informacao_basica?.rg_cnh_verso?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.informacao_basica?.naturalidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.informacao_basica?.nacionalidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.informacao_basica?.profissao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.informacao_basica?.cpf)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.informacao_basica?.email)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.informacao_basica?.estado_civil?.descricao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.documento_procuracao?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.endereco?.cep)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.endereco?.logradouro)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.endereco?.numero)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.endereco?.complemento)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.endereco?.bairro)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.endereco?.cidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.procurador?.endereco?.estado)) {
            qtdCamposGeral -= 1;
        }

        return size >= qtdCamposGeral;
    }

    const itsFullIsTrue = () => {
        const value = Object.values(campos ?? null);
        return !value.includes(false) && itsFull() && value?.length > 10; 
    }

    return (
        <Fragment>
            <div className="p-6">
                <form onSubmit={onSubmit}>
                    <div className="bg-white p-10 flex flex-col rounded-lg border-white border h-full w-full" style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>

                        {/* Identificação */}
                        <div className="border-b border-blue-500">
                            <TitleValidate
                                title={'Identificação'}
                                selectSection={selectSection}
                                checkSection={sections?.identificacao}
                                section={0}
                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                            />
                            <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`CPF`}
                                        name={`cpf_proprietario`}
                                        value={mask(proprietario?.informacoes_basicas?.cpf, 'cpf_cnpj') ?? null}
                                        hidden={nulos.includes(proprietario?.informacoes_basicas?.cpf)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.cpf_proprietario ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <InputValidate
                                        title={`Nome`}
                                        name={`nome_proprietario`}
                                        value={proprietario?.informacoes_basicas?.nome_cliente ?? null}
                                        hidden={nulos.includes(proprietario?.informacoes_basicas?.nome_cliente)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.nome_proprietario ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <span className={`font-extrabold text-lg ${nulos.includes(proprietario?.informacoes_basicas?.rg_cnh_frente?.nome) ? 'hidden' : ''}`}>Documento de Identificação Oficial com Foto*</span>
                                    <div className={`grid grid-cols-1 lg:grid-cols-1 gap-2 ${nulos.includes(proprietario?.informacoes_basicas?.rg_cnh_frente?.nome) ? 'hidden' : ''}`}>
                                        <InputValidate
                                            title={`${rgOrCnh} - Frente`}
                                            name={`rg_cnh_frente_proprietario`}
                                            value={proprietario?.informacoes_basicas?.rg_cnh_frente?.nome ?? null}
                                            onClick={handleClick}
                                            isFile={true}
                                            file={proprietario?.informacoes_basicas?.rg_cnh_frente?.caminho ?? null}
                                            status={itsView ? null : itsView ? null : campos.rg_cnh_frente_proprietario ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`${rgOrCnh} - Verso`}
                                            name={`rg_cnh_verso_proprietario`}
                                            value={proprietario?.informacoes_basicas?.rg_cnh_verso?.nome ?? null}
                                            onClick={handleClick}
                                            isFile={true}
                                            file={proprietario?.informacoes_basicas?.rg_cnh_verso?.caminho ?? null}
                                            status={itsView ? null : itsView ? null : campos.rg_cnh_verso_proprietario ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4">
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Telefone 1`}
                                            name={`telefone_proprietario`}
                                            value={mask(proprietario?.informacoes_basicas?.telefone1, 'telefone') ?? null}
                                            hidden={nulos.includes(proprietario?.informacoes_basicas?.telefone1)}
                                            onClick={handleClick}
                                            status={itsView ? null : campos.telefone_proprietario ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Telefone 2`}
                                            name={`telefone2_proprietario`}
                                            value={mask(proprietario?.informacoes_basicas?.telefone2, 'telefone') ?? null}
                                            // hidden={nulos.includes(proprietario?.informacoes_basicas?.telefone2)}
                                            onClick={handleClick}
                                            status={itsView ? null : campos.telefone2_proprietario ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>
                                    <InputValidate
                                        title={`E-mail`}
                                        name={`email_proprietario`}
                                        value={proprietario?.informacoes_basicas?.email ?? null}
                                        hidden={nulos.includes(proprietario?.informacoes_basicas?.email)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.email_proprietario ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Estado Civil`}
                                            name={`estado_civil_proprietario`}
                                            value={proprietario?.informacoes_basicas?.estado_civil?.descricao ?? null}
                                            hidden={nulos.includes(proprietario?.informacoes_basicas?.estado_civil?.descricao)}

                                            onClick={handleClick}
                                            status={itsView ? null : campos.estado_civil_proprietario ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Nº de Dependentes`}
                                            name={`numb_dependentes_proprietario`}
                                            value={proprietario?.informacoes_basicas?.qtd_dependentes ?? null}
                                            onClick={handleClick}
                                            hidden={nulos.includes(proprietario?.informacoes_basicas?.qtd_dependentes)}
                                            status={itsView ? null : campos.numb_dependentes_proprietario ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Naturalidade`}
                                            name={`nacionalidade_proprietario`}
                                            value={proprietario?.informacoes_basicas?.nacionalidade ?? null}
                                            hidden={nulos.includes(proprietario?.informacoes_basicas?.nacionalidade)}
                                            onClick={handleClick}
                                            status={itsView ? null : campos.nacionalidade_proprietario ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Nacionalidade`}
                                            name={`naturalidade_proprietario`}
                                            value={proprietario?.informacoes_basicas?.naturalidade ?? null}
                                            hidden={nulos.includes(proprietario?.informacoes_basicas?.naturalidade)}
                                            onClick={handleClick}
                                            status={itsView ? null : campos.naturalidade_proprietario ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>
                                    <InputValidate
                                        title={`Profissão`}
                                        name={`profissao_proprietario`}
                                        value={proprietario?.informacoes_basicas?.profissao ?? null}
                                        hidden={nulos.includes(proprietario?.informacoes_basicas?.profissao)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.profissao_proprietario ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                </div>
                            </div>
                        </div>


                        {/* Cônjuge */}
                        <div className={`border-b border-blue-500 mt-12 ${proprietario?.conjuge === null ? 'hidden' : ''}`}>
                            <TitleValidate
                                title={'Cônjuge'}
                                selectSection={selectSection}
                                checkSection={sections?.conjuge ?? null}
                                section={1}
                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                            />
                            <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`Nome`}
                                        name={`nome_conjuge`}
                                        value={proprietario?.conjuge?.informacoes_basicas?.nome_cliente ?? null}
                                        hidden={nulos.includes(proprietario?.conjuge?.informacoes_basicas?.nome_cliente)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.nome_conjuge ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <InputValidate
                                        title={`E-mail`}
                                        name={`email_conjuge`}
                                        value={proprietario?.conjuge?.informacoes_basicas?.email ?? null}
                                        hidden={nulos.includes(proprietario?.conjuge?.informacoes_basicas?.email)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.email_conjuge ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Telefone 1`}
                                            name={`telefone_conjuge`}
                                            value={mask(proprietario?.conjuge?.informacoes_basicas?.telefone1, 'telefone') ?? null}
                                            hidden={nulos.includes(proprietario?.conjuge?.informacoes_basicas?.telefone1)}

                                            onClick={handleClick}
                                            status={itsView ? null : campos.telefone_conjuge ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Telefone 2`}
                                            name={`telefone2_conjuge`}
                                            value={mask(proprietario?.conjuge?.informacoes_basicas?.telefone2, 'telefone') ?? null}
                                            hidden={nulos.includes(proprietario?.conjuge?.informacoes_basicas?.telefone2)}
                                            onClick={handleClick}
                                            status={itsView ? null : campos.telefone2_conjuge ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>
                                    <span className="font-extrabold text-lg ">Documento de Identificação Oficial com Foto*</span>
                                    <span className="font-extrabold text-base mt-3">Documento: {rgOrCnhConjuge ?? 'RG ou CNH'}</span>
                                    <div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
                                        <InputValidate
                                            title={`Frente`}
                                            name={`rg_cnh_frente_conjuge`}
                                            value={proprietario?.conjuge?.informacoes_basicas?.rg_cnh_frente?.nome ?? null}
                                            hidden={nulos.includes(proprietario?.conjuge?.informacoes_basicas?.rg_cnh_frente?.nome)}
                                            onClick={handleClick}
                                            isFile={true}
                                            file={proprietario?.conjuge?.informacoes_basicas?.rg_cnh_frente?.caminho ?? null}
                                            status={itsView ? null : campos.rg_cnh_frente_conjuge ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Verso`}
                                            name={`rg_cnh_verso_conjuge`}
                                            value={proprietario?.conjuge?.informacoes_basicas?.rg_cnh_verso?.nome ?? null}
                                            hidden={nulos.includes(proprietario?.conjuge?.informacoes_basicas?.rg_cnh_verso?.nome)}
                                            onClick={handleClick}
                                            isFile={true}
                                            file={proprietario?.conjuge?.informacoes_basicas?.rg_cnh_verso?.caminho ?? null}
                                            status={itsView ? null : campos.rg_cnh_verso_conjuge ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`CPF`}
                                        name={`cpf_conjuge`}
                                        value={mask(proprietario?.conjuge?.informacoes_basicas?.cpf, 'cpf_cnpj') ?? null}
                                        hidden={nulos.includes(proprietario?.conjuge?.informacoes_basicas?.cpf)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.cpf_conjuge ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Naturalidade`}
                                            name={`naturalidade_conjuge`}
                                            value={proprietario?.conjuge?.informacoes_basicas?.naturalidade ?? null}
                                            hidden={nulos.includes(proprietario?.conjuge?.informacoes_basicas?.naturalidade)}

                                            onClick={handleClick}
                                            status={itsView ? null : campos.naturalidade_conjuge ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Nacionalidade`}
                                            name={`nacionalidade_conjuge`}
                                            value={proprietario?.conjuge?.informacoes_basicas?.nacionalidade ?? null}
                                            hidden={nulos.includes(proprietario?.conjuge?.informacoes_basicas?.nacionalidade)}

                                            onClick={handleClick}
                                            status={itsView ? null : campos.nacionalidade_conjuge ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>
                                    <InputValidate
                                        title={`Profissão`}
                                        name={`profissao_conjuge`}
                                        value={proprietario?.conjuge?.informacoes_basicas?.profissao ?? null}
                                        hidden={nulos.includes(proprietario?.conjuge?.informacoes_basicas?.profissao)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.profissao_conjuge ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                </div>
                            </div>
                        </div>


                        {/* Residência Atual */}
                        <div className={`border-b border-blue-500 mt-12 ${proprietario?.residencia_atual === null ? 'hidden' : ''}`}>
                            <TitleValidate
                                title={'Residência Atual'}
                                selectSection={selectSection}
                                checkSection={sections?.residencia_atual}
                                section={2}
                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                            />
                            <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`Comprovante de Endereço`}
                                        name={`comprovante_endereco_residencia_atual`}
                                        value={proprietario?.residencia_atual?.arquivo?.nome ?? null}
                                        hidden={nulos.includes(proprietario?.residencia_atual?.arquivo?.nome)}
                                        isFile={true}
                                        file={proprietario?.residencia_atual?.arquivo?.caminho ?? null}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.comprovante_endereco_residencia_atual ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <InputValidate
                                        title={`CEP`}
                                        name={`cep_residencia_atual`}
                                        value={mask(proprietario?.endereco?.cep, "cep") ?? null}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.cep_residencia_atual ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Logradouro`}
                                            name={`logradouro_residencia_atual`}
                                            value={proprietario?.endereco?.logradouro ?? null}
                                            hidden={nulos.includes(proprietario?.endereco?.logradouro)}

                                            onClick={handleClick}
                                            status={itsView ? null : campos.logradouro_residencia_atual ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Número`}
                                            name={`numero_residencia_atual`}
                                            value={proprietario?.endereco?.numero ?? null}
                                            hidden={nulos.includes(proprietario?.endereco?.numero)}

                                            onClick={handleClick}
                                            status={itsView ? null : campos.numero_residencia_atual ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>

                                </div>
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`Complemento`}
                                        name={`complemento_residencia_atual`}
                                        value={proprietario?.endereco?.complemento ?? null}
                                        hidden={nulos.includes(proprietario?.endereco?.complemento)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.complemento_residencia_atual ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <InputValidate
                                        title={`Bairro`}
                                        name={`bairro_residencia_atual`}
                                        value={proprietario?.endereco?.bairro ?? null}
                                        hidden={nulos.includes(proprietario?.endereco?.bairro)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.bairro_residencia_atual ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Cidade`}
                                            name={`cidade_residencia_atual`}
                                            value={proprietario?.endereco?.cidade ?? null}
                                            hidden={nulos.includes(proprietario?.endereco?.cidade)}

                                            onClick={handleClick}
                                            status={itsView ? null : campos.cidade_residencia_atual ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Estado`}
                                            name={`estado_residencia_atual`}
                                            value={proprietario?.endereco?.estado ?? null}
                                            hidden={nulos.includes(proprietario?.endereco?.estado)}
                                            onClick={handleClick}
                                            status={itsView ? null : campos.estado_residencia_atual ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Valor do Aluguel`}
                                            name={`valor_aluguel_residencia_atual`}
                                            value={formatarValor(proprietario?.residencia_atual?.valor_aluguel, "dinheiro") ?? null}
                                            hidden={nulos.includes(proprietario?.residencia_atual?.valor_aluguel)}
                                            onClick={handleClick}
                                            status={itsView ? null : campos.valor_aluguel_residencia_atual ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Tempo de Locação`}
                                            name={`tempo_locacao_residencia_atual`}
                                            value={proprietario?.residencia_atual?.tempo_locacao ?? null}
                                            hidden={nulos.includes(proprietario?.residencia_atual?.tempo_locacao)}
                                            onClick={handleClick}
                                            status={itsView ? null : campos.tempo_locacao_residencia_atual ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Forma de Recebimento */}
                        <div className={`border-b border-blue-500 mt-12 ${nulos.includes(proprietario?.forma_recebimento) ? 'hidden' : ''}`}>
                            <TitleValidate
                                title={'Forma de Recebimento'}
                                selectSection={selectSection}
                                checkSection={sections?.forma_recebimento}
                                section={3}
                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                            />
                            <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`Como deseja receber?`}
                                        name={`recebimento_pagamento`}
                                        value={proprietario?.forma_recebimento?.descricao ?? null}
                                        hidden={nulos.includes(proprietario?.forma_recebimento?.descricao)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.recebimento_pagamento ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <InputValidate
                                        title={`Qual o tipo da conta?`}
                                        name={`tipo_conta`}
                                        value={proprietario?.dados_bancarios?.tipo_conta?.descricao ?? null}
                                        hidden={nulos.includes(proprietario?.dados_bancarios?.tipo_conta?.descricao)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.tipo_conta ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <InputValidate
                                        title={`O titular da conta é outra pessoa?*`}
                                        name={`outra_pessoa_titular_conta`}
                                        value={!proprietario?.dados_bancarios?.titular_conta ? 'Sim' : 'Não'}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.outra_pessoa_titular_conta ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                </div>
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`Pessoa Autorizada a Receber`}
                                        name={`pessoa_autorizada`}
                                        value={proprietario?.dados_bancarios?.favorecido}
                                        hidden={proprietario?.forma_recebimento_id !== 2}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.pessoa_autorizada ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <InputValidate
                                        title={`Banco`}
                                        name={`banco`}
                                        value={proprietario?.dados_bancarios?.banco ?? null}
                                        hidden={nulos.includes(proprietario?.dados_bancarios?.banco)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.banco ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <InputValidate
                                        title={`Titular`}
                                        name={`favorecido`}
                                        value={proprietario?.dados_bancarios?.favorecido ?? null}
                                        hidden={nulos.includes(proprietario?.dados_bancarios?.favorecido)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.favorecido ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <InputValidate
                                        title={`CPF Favorecido`}
                                        name={`cpf_favorecido`}
                                        value={mask(proprietario?.dados_bancarios?.cpf_favorecido, 'cpf_cnpj') ?? null}
                                        hidden={nulos.includes(proprietario?.dados_bancarios?.cpf_favorecido)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.cpf_favorecido ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-1">
                                        <InputValidate
                                            title={`Agência`}
                                            name={`agencia`}
                                            value={proprietario?.dados_bancarios?.agencia ?? null}
                                            hidden={nulos.includes(proprietario?.dados_bancarios?.agencia)}
                                            onClick={handleClick}
                                            className={`flex w-5/6 h-12`}
                                            status={itsView ? null : campos.agencia ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Operação`}
                                            name={`operacao`}
                                            value={proprietario?.dados_bancarios?.operacao ?? null}
                                            hidden={nulos.includes(proprietario?.dados_bancarios?.operacao)}
                                            onClick={handleClick}
                                            className={`flex w-5/6 h-12`}
                                            status={itsView ? null : campos.operacao ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Número`}
                                            name={`numero_conta`}
                                            value={proprietario?.dados_bancarios?.conta ?? null}
                                            hidden={nulos.includes(proprietario?.dados_bancarios?.conta)}
                                            onClick={handleClick}
                                            className={`flex w-5/6 h-12`}
                                            status={itsView ? null : campos.numero_conta ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* Dados do Procurador - 19 campos*/}
                        <div className={`border-b border-blue-500 mt-12 ${proprietario?.procurador === undefined || proprietario?.procurador === null ? 'hidden' : ''}`}>
                            <TitleValidate
                                title={'Dados do Procurador'}
                                selectSection={selectSection}
                                checkSection={sections?.procurador ?? null}
                                section={4}
                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                            />
                            <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`Nome`}
                                        name={`nome_procurador`}
                                        value={proprietario?.procurador?.informacao_basica?.nome_cliente ?? null}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.nome_procurador ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Telefone 1`}
                                            name={`telefone_procurador`}
                                            value={mask(proprietario?.procurador?.informacao_basica?.telefone1, 'telefone') ?? null}
                                            onClick={handleClick}
                                            status={itsView ? null : campos.telefone_procurador ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Telefone 2`}
                                            name={`telefone2_procurador`}
                                            value={mask(proprietario?.procurador?.informacao_basica?.telefone2, 'telefone') ?? null}
                                            onClick={handleClick}
                                            status={itsView ? null : campos.telefone2_procurador ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>
                                    <span className="font-extrabold text-lg">Documento de Identificação Oficial com Foto*</span>
                                    <div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
                                        <InputValidate
                                            title={`${rgOrCnhProcurador} - Frente`}
                                            name={`rg_cnh_frente_procurador`}
                                            value={proprietario?.procurador?.informacao_basica?.rg_cnh_frente?.nome ?? null}
                                            onClick={handleClick}
                                            isFile={true}
                                            file={proprietario?.procurador?.informacao_basica?.rg_cnh_frente?.caminho ?? null}
                                            status={itsView ? null : campos.rg_cnh_frente_procurador ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`${rgOrCnhProcurador} - Verso`}
                                            name={`rg_cnh_verso_procurador`}
                                            value={proprietario?.procurador?.informacao_basica?.rg_cnh_verso?.nome ?? null}
                                            onClick={handleClick}
                                            isFile={true}
                                            file={proprietario?.procurador?.informacao_basica?.rg_cnh_verso?.caminho ?? null}
                                            status={itsView ? null : campos.rg_cnh_verso_procurador ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>

                                </div>
                                <div className="flex flex-col gap-4">
                                    <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2 ${proprietario?.procurador?.informacao_basica?.nacionalidade === null ? 'hidden' : ''}`}>
                                        <InputValidate
                                            title={`Naturalidade`}
                                            name={`naturalidade_procurador`}
                                            value={proprietario?.procurador?.informacao_basica?.naturalidade ?? null}
                                            hidden={proprietario?.procurador?.informacao_basica?.naturalidade === null}
                                            onClick={handleClick}
                                            status={itsView ? null : campos.naturalidade_procurador ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Nacionalidade`}
                                            name={`nacionalidade_procurador`}
                                            value={proprietario?.procurador?.informacao_basica?.nacionalidade ?? null}
                                            hidden={proprietario?.procurador?.informacao_basica?.nacionalidade === null}
                                            onClick={handleClick}
                                            status={itsView ? null : campos.nacionalidade_procurador ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>
                                    <InputValidate
                                        title={`Profissão`}
                                        name={`profissao_procurador`}
                                        value={proprietario?.procurador?.informacao_basica?.profissao ?? null}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.profissao_procurador ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <InputValidate
                                        title={`CPF`}
                                        name={`cpf_procurador`}
                                        value={mask(proprietario?.procurador?.informacao_basica?.cpf, 'cpf_cnpj') ?? null}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.cpf_procurador ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <InputValidate
                                        title={`E-mail`}
                                        name={`email_procurador`}
                                        value={proprietario?.procurador?.informacao_basica?.email ?? null}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.email_procurador ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <InputValidate
                                        title={`Estado Civil`}
                                        name={`estado_civil_procurador`}
                                        value={proprietario?.procurador?.informacao_basica?.estado_civil?.descricao ?? null}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.estado_civil_procurador ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <InputValidate
                                        title={`Documento da Procuração`}
                                        name={`documento_procurador`}
                                        value={proprietario?.procurador?.documento_procuracao?.nome ?? null}
                                        onClick={handleClick}
                                        isFile={true}
                                        file={proprietario?.procurador?.documento_procuracao?.caminho ?? null}
                                        status={itsView ? null : campos.documento_procurador ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />

                                </div>
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`CEP`}
                                        name={`cep_procurador`}
                                        value={mask(proprietario?.procurador?.endereco?.cep, 'cep') ?? null}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.cep_procurador ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Logradouro`}
                                            name={`logradouro_procurador`}
                                            value={proprietario?.procurador?.endereco?.logradouro ?? null}

                                            onClick={handleClick}
                                            status={itsView ? null : campos.logradouro_procurador ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Número`}
                                            name={`numero_procurador`}
                                            value={proprietario?.procurador?.endereco?.numero ?? null}

                                            onClick={handleClick}
                                            status={itsView ? null : campos.numero_procurador ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>
                                    <InputValidate
                                        title={`Complemento`}
                                        name={`complemento_procurador`}
                                        value={proprietario?.procurador?.endereco?.complemento ?? null}
                                        onClick={handleClick}
                                        hidden={proprietario?.procurador?.endereco?.complemento === null}
                                        status={itsView ? null : campos.complemento_procurador ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                </div>
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`Bairro`}
                                        name={`bairro_procurador`}
                                        value={proprietario?.procurador?.endereco?.bairro ?? null}
                                        onClick={handleClick}
                                        status={itsView ? null : campos.bairro_procurador ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Cidade`}
                                            name={`cidade_procurador`}
                                            value={proprietario?.procurador?.endereco?.cidade ?? null}
                                            onClick={handleClick}
                                            status={itsView ? null : campos.cidade_procurador ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                        <InputValidate
                                            title={`Estado`}
                                            name={`estado_procurador`}
                                            value={proprietario?.procurador?.endereco?.estado ?? null}
                                            onClick={handleClick}
                                            status={itsView ? null : campos.estado_procurador ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)} 
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* comentario*/}
                        <div className={`${itsView ? 'hidden' : ''} mt-5`}>
                            <p className="font-extrabold text-lg text-gray-system-mega-imoveis">Instruções para reenvio*</p>
                            <textarea
                                rows={2}
                                name={`instrucao_reenvio`}
                                value={proprietario?.instrucao_reenvio}
                                style={{ maxHeight: "20rem", minHeight: "3.5rem" }}
                                className="block p-4 w-full h-32 mt-2 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                                placeholder={'Adicione instruções para reenvio'}
                                onChange={onChange}
                                required={existFalse}
                            />
                        </div>

                    </div>
                    {!statusNotButton.includes(proprietario?.ultimo_historico?.status?.id) ? <div>
                        {itsFullIsTrue() ? <div className="w-full flex flex-row gap-4 justify-center items-center">
                            <div>
                                <button
                                    type="button"
                                    onClick={() => openModal(true)}
                                    className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                                    style={{ backgroundColor: '#FF3B3B' }}
                                >
                                    reprovar cadastro
                                </button>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    onClick={() => handleStatusCadastro("aprovado", 3)}
                                    className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                                    style={{ backgroundColor: '#0DCB64' }}
                                >
                                    aprovar cadastro
                                </button>
                            </div>
                        </div> : <div className="w-full flex flex-row gap-4 justify-center items-center">
                            <div>
                                <button
                                    type="submit"
                                    className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs border-2 border-blue-mega-imoveis text-blue-dark-mega-imoveis bg-transparent cursor-pointer rounded-lg justify-center items-center`}
                                >
                                    Salvar e continuar depois
                                </button>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    disabled={!itsFull() ?? true}
                                    onClick={() => handleStatusCadastro("reenviado", 3)}
                                    className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs ${itsFull() ? 'bg-blue-dark-mega-imoveis text-white' : 'bg-gray-300 text-gray-700'}   cursor-pointer rounded-lg justify-center items-center`}
                                >
                                    enviar para usuário revisar
                                </button>
                            </div>
                        </div>}
                    </div> : ''}
                </form>
            </div>
        </Fragment>
    );
}

export default FormularioCadastroProprietarioPessoaFisica;