import React from 'react';
import Header from '../Universal/HeaderPDF';
import Footer from '../Universal/FooterPDF';
import Linha from '../Universal/Linha';
import { mask } from 'src/helpers/functions';
import { formatarValor } from 'src/components/Basicos/funcoes';

interface ModeloProps {
    fiador?: any
}

const Modelo2: React.FC<ModeloProps> = ({ fiador }) => {
    return (
        <>
            {/* Página 1 */}
            <div className='pagina-pdf'>
                <Header
                    tipo='Fiador'
                    nome={fiador?.informacoes_basicas?.nome_cliente}
                    tipo_garantia={fiador?.garantia?.descricao}
                    status={fiador?.ultimo_historico?.etapa_historico?.descricao}
                />
                <div className='body-pdf'>
                    <h1 className='sub-title estilo-fonte-negrito'>
                        Identificação
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <Linha
                        campo10='CPF*' valor10={mask(fiador?.informacoes_basicas?.cpf, "cpf_cnpj")}
                        campo20='E-mail*' valor20={fiador?.informacoes_basicas?.email} />
                    <Linha
                        campo10='Nome*' valor10={fiador?.informacoes_basicas?.nome_cliente}
                        campo20='Estado Civil*' valor20={fiador?.informacoes_basicas?.estado_civil?.descricao}
                        campo21='N° de Dependentes*' valor21={fiador?.informacoes_basicas?.qtd_dependentes} />
                    <h1 className='text-xs'>Documento de Identificação Oficial com Foto*</h1>
                    <Linha
                        campo10='RG Frente*' valor10={fiador?.informacoes_basicas?.rg_cnh_frente?.nome}
                        campo20='A Locação é por procuração?*' valor20={fiador?.procurador_id != null ? 'Sim' : 'Não'} />
                    <Linha
                        campo10='RG Verso*' valor10={fiador?.informacoes_basicas?.rg_cnh_verso?.nome} />
                    <Linha
                        campo10='Telefone 1*' valor10={mask(fiador?.informacoes_basicas?.telefone1, "telefone")}
                        campo11='Telefone 2' valor11={mask(fiador?.informacoes_basicas?.telefone2, "telefone")} />

                    <div className='borda-principal-pdf mt-4'></div>

                    <h1 className='sub-title estilo-fonte-negrito'>
                        Residência Atual
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <Linha
                        campo10='Sua Residência é*' valor10={fiador?.residencia_atual?.tipo_residencia?.descricao}
                        campo20='Complemento' valor20={fiador?.endereco?.complemento} />
                    <Linha
                        campo10='CEP' valor10={mask(fiador?.endereco?.cep, "cep")}
                        campo20='Bairro*' valor20={fiador?.endereco?.bairro} />
                    <Linha
                        campo10='Comprovante de Endereço' valor10={fiador?.residencia_atual?.arquivo?.nome}
                        campo20='Cidade' valor20={fiador?.endereco?.cidade}
                        campo21='Estado' valor21={fiador?.endereco?.estado} />
                    <Linha isEndereco1={true}
                        campo10='Logradouro*' valor10={fiador?.endereco?.logradouro}
                        campo11='Numero*' valor11={fiador?.endereco?.numero} />

                    <h1 className='sub-title estilo-fonte-negrito'>
                        Atividade Profissional
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    {fiador?.atividade_profissional.tipo_atividade_id != 1 ?
                        <>
                            <Linha campo10='Tipo de Atividade Profissional*' valor10={fiador?.atividade_profissional?.tipo_atividade?.descricao} campo11='Renda Mensal*' valor11={formatarValor(fiador?.atividade_profissional?.renda_mensal, "dinheiro") ?? null} isEndereco2={true} campo20='Logradouro' valor20={fiador?.atividade_profissional?.endereco?.logradouro ?? null} campo21='Número' valor21={fiador?.atividade_profissional?.endereco?.numero ?? null} />
                            <Linha campo10='Nome da Empresa' valor10={fiador?.atividade_profissional?.nome_empresa} campo20='Complemento' valor20={fiador?.atividade_profissional?.endereco?.complemento ?? null} />

                            <div className='borda-principal-pdf mt-4'></div>

                            <h1 className='sub-title estilo-fonte-negrito'>
                                Imóvel em Garantia
                            </h1>
                            <div className='borda-pdf mb-3 mt-3'></div>
                            <Linha campo10='Matrícula do Imóvel Quitado e Sem Ônus' valor10={fiador?.imovel_garantia?.matricula_imovel} campo20='Certidão de Matrícula Atualizada' valor20={fiador?.imovel_garantia?.certidao_matricula?.nome} />
                        </>
                        :
                        <>
                            <Linha campo10='Tipo de Atividade Profissional*' valor10={fiador?.atividade_profissional?.tipo_atividade?.descricao} campo11='Renda Mensal*' valor11={formatarValor(fiador?.atividade_profissional?.renda_mensal, "dinheiro") ?? null} />
                            <Linha campo10='Último Extrato do Cartão de Crédito*' valor10={fiador?.atividade_profissional?.arquivo_extrato_bancario?.nome} />
                        </>
                    }

                </div>

                <div className='footer-pdf'>
                    <Footer />
                </div>
            </div>

            {/* Página 2 */}
            {fiador?.atividade_profissional.tipo_atividade_id != 1 ?
                <div className='pagina-pdf'>
                    <Header
                        tipo='Fiador'
                        nome={fiador?.informacoes_basicas?.nome_cliente}
                        tipo_garantia={fiador?.garantia?.descricao}
                        status={fiador?.ultimo_historico?.etapa_historico?.descricao}
                    />
                    <div className='body-pdf'>
                        <Linha campo10='Tempo de Empresa' valor10={fiador?.atividade_profissional?.tempo_empresa?.descricao ?? null} campo11='Cargo Atual' valor11={fiador?.atividade_profissional?.cargo_atual ?? null} campo20='Bairro' valor20={fiador?.atividade_profissional?.endereco?.bairro ?? null} />
                        <Linha campo10='CEP' valor10={mask(fiador?.atividade_profissional?.endereco?.cep, "cep") ?? null} campo20='Cidade' valor20={fiador?.atividade_profissional?.endereco?.cidade ?? null} campo21='Estado' valor21={fiador?.atividade_profissional?.endereco?.estado ?? null} />
                        <Linha campo10='Último Extrato do Cartão de Crédito*' valor10={fiador?.atividade_profissional?.arquivo_extrato_bancario?.nome} />

                        <div className='borda-principal-pdf mt-4'></div>

                        <h1 className='sub-title estilo-fonte-negrito'>
                            Imóvel em Garantia
                        </h1>
                        <div className='borda-pdf mb-3 mt-3'></div>
                        <Linha campo10='Matrícula do Imóvel Quitado e Sem Ônus' valor10={fiador?.imovel_garantia?.matricula_imovel} campo20='Certidão de Matrícula Atualizada' valor20={fiador?.imovel_garantia?.certidao_matricula?.nome} />

                    </div>

                    <div className='footer-pdf'>
                        <Footer />
                    </div>
                </div>
                :
                ''}

        </>
    );
};

export default Modelo2;