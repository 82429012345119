import React, { useEffect, useRef, useState } from "react";
import '../DropDown/style.css';
import { BiChevronDown } from "react-icons/bi";


type Props = {
    optionsData: Array<String>;
    setStateOption: any;
    etapa: number;
    setStateEtapa: any
}

const ButtonWithDropDownCmp = ({
    optionsData = [],
    setStateOption,
    etapa,
    setStateEtapa
}: Props) => {
    const [open, setOpen] = useState(false);
    const drop = useRef(null) as any;

    const lastEtapas = [6, 8];

    const handleClick = (e: any) => {
        if (!e.target.closest(`.${drop?.current?.className}`) && open) {
            setOpen(false);
            setStateOption(undefined);
        }
    }

    const handleOption = (value: String) => {
        setOpen(false);
        setStateOption(value);
        setStateEtapa(etapa);
    };
    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    return (
        <div
            className="dropdown"
            ref={drop}
            style={{
                position: "relative",
                margin: "10px",
                right: 0,
                width: "auto",
                display: "inline-block"
            }}
        >
            <button className="flex focus:outline-none">
                <BiChevronDown
                    className="text-gray-system-mega-imoveis"
                    size={`2rem`}
                    onClick={() => setOpen(open => !open)}
                />
            </button>
            {open && <div className="h-auto w-56 absolute">
                <ul className={`text-left ${lastEtapas.includes(etapa) ? 'last-card' : ''}`}>
                    {optionsData.map((item: any, i) => (
                        <li
                            key={i}
                        >
                            <button
                                name="optionValue"
                                className={"p-3 border w-full text-gray-700 bg-white hover:text-white hover:bg-primary-blue cursor-pointer"}
                                onClick={() => handleOption(item?.value)}
                            >
                                {item?.label}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>}
        </div>
    );
};

export default ButtonWithDropDownCmp;