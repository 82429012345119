import React, { Fragment } from 'react';
import './style.css';
import Popup from 'reactjs-popup';
import iconZip from "src/assets/icones/zip-icon.svg";
import iconPdf from "src/assets/icones/pdf-icon.svg";

type Props = {
    isOpen: any
    lockScroll: any
    toPDF: any
    exportFiles: any
    isClose: any
    closeOnOutsideClick: any
    openCloseModal: any
}

const ModalFilesMobile: React.FunctionComponent<Props> = ({
    isOpen,
    lockScroll,
    toPDF,
    exportFiles,
    isClose,
    closeOnOutsideClick,
    openCloseModal
}) => {
    return (
        <Popup
            modal
            open={isOpen}
            lockScroll={lockScroll}
            onClose={openCloseModal}
        >
            <Fragment>
                <div className={`botoes-pdf modal-files-mobile${window.innerWidth <= 370 ? '-pequeno' : ''}`}>
                    <div onClick={() => toPDF()} className={`botao-gerar-pdf-mobile${window.innerWidth <= 370 ? '-pequeno' : ''}`} style={{ backgroundColor: '#6C7AFF' }}>
                        <img className="icon-zip-file" alt="icon_crown" src={iconPdf} /> Gerar PDF
                    </div>
                    <div onClick={() => exportFiles()} className={`botao-gerar-pdf-mobile${window.innerWidth <= 370 ? '-pequeno' : ''}`} style={{ backgroundColor: '#6C7AFF' }}>
                        <img className="icon-zip-file" alt="icon_crown" src={iconZip} /> Arquivos
                    </div>
                </div>
            </Fragment>
        </Popup>
    );
}

export default ModalFilesMobile;