import React, { Fragment, useMemo, useState, memo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    useAsyncDebounce,
    usePagination,
    useTable,
    useGlobalFilter,
} from "react-table";
import { FiEdit, FiEye, FiFileText } from "react-icons/fi";
import iconSingle from "src/assets/icones/icon-single.svg"
import iconDouble from "src/assets/icones/icon-double.svg"
import iconGroup from "src/assets/icones/icon-group.svg"
import Pagination from "src/components/Basicos/Pagination";
import './style.css';
import { converterDataBr, formatarValor } from "src/components/Basicos/funcoes";
import { mask } from "src/helpers/functions";
import { useControlContextCadastro } from "src/contexts/CadastroControlContext";
import EmptyPlaceholder from "src/components/Basicos/EmptyPlaceholder";
import StageBar from "src/components/Basicos/StageBar";
import StatusBar from "src/components/Basicos/StatusBar";
import { useControlContextSolicitacao } from "src/contexts/SolicitacaoControlContext";
import SelectForm from "src/components/Basicos/Form/SelectForm";
import SelectInput from "src/components/Basicos/Form/SelectSimpleInput";
import { useControlContextUsuarios } from "src/contexts/UsuariosContext";
import ThreeDotsIcon from "src/assets/icones/three-dots.svg"
import ModalDelete from "src/components/Funcionalidades/ModalDelete";
import CheckBox from "src/components/Basicos/Form/CheckBox";
import Inputlabel from "src/components/Basicos/Form/Input/InputLabel";

function TableConsulta({
    columns,
    data,
    pageSize = 10,
    maxHeigth = false,
    itsCount = false
}) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columnsMemo = useMemo(() => columns, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dataMemo = useMemo(() => data, [data]);
    const [infoModalDelete, setInfoModalDelete] = useState([]);
    const [modalDelete, setModalDelete] = useState(false);

    const openModalDelete = (id, rota, titulo) => {
        setInfoModalDelete({ id: id, rota: rota, titulo: titulo })
        setModalDelete(true);
    }

    const closeModalDelete = () => {
        setModalDelete(false);
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        gotoPage,
        pageCount,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        rows,

        state: { pageIndex },
    } = useTable(
        {
            columns: columnsMemo,
            data: dataMemo,
            initialState: { pageIndex: 0, pageSize: pageSize },
        },
        useGlobalFilter,
        usePagination
    );

    const generatePagesLinks = () => {
        const pages = [];
        const range = 3;
        const numberOfPages = pageCount;
        const currentPage = pageIndex;
        let toIndex =
            numberOfPages > range * 2 ? currentPage + range : numberOfPages;
        let fromIndex = numberOfPages > range * 2 ? currentPage - range : 1;

        if (toIndex > numberOfPages) {
            fromIndex = numberOfPages - range * 2;
            toIndex = numberOfPages;
        } else if (fromIndex < 1) {
            fromIndex = 1;
            toIndex = 1 + range * 2;
        }

        for (let index = fromIndex; index <= toIndex; index++) {
            const active = index - 1 === pageIndex ? "bg-blue-dark-mega-imoveis text-white" : "bg-white text-blue-dark-mega-imoveis";
            pages.push(
                <div
                    onClick={() => gotoPage(index - 1)}
                    key={index}
                    className={`p-2 border-blue-dark-mega-imoveis border-2 font-semibold cursor-pointer rounded-lg w-10 h-10 flex justify-center items-center ${active}`}
                >
                    {index}
                </div>
            );
        }

        return pages;
    };
    return (
        <Fragment>
            <div className="mt-2 mb-2 text-right">
                <span className="py-2 text-blue-600">{rows.length} Resultados</span>
            </div>
            <div className={`overflow-x-auto TableWithGlobalSearchConsulta shadow-md`} style={{ maxHeight: `${maxHeigth ? "261px" : "none"}` }}>
                {page.length === 0 ? (
                    <EmptyPlaceholder content={"Dados não Encontrados!"} />
                ) : (
                    <table
                        {...getTableProps()}
                        className="min-w-max w-full table-auto mb-5 select-text"
                    >
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr
                                    {...headerGroup.getHeaderGroupProps()}
                                    className="text-base leading-normal table-consulta"
                                >
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps()}
                                            className="py-3 px-6 text-center font-medium"
                                            key={column.id}
                                        >
                                            {column.render(`Header`)}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody
                            {...getTableBodyProps()}
                            className="text-gray-system-mega-imoveis text-base font-normal"
                        >
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className={`border-t ${row.original.status == 0 || row.original.status == 2 ? 'cor-desativado' : ''}`}
                                    >
                                        {row.cells.map((cell, index) => {
                                            switch (cell.column.type) {
                                                case "data":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-1 px-6 text-center whitespace-nowrap text-sm"
                                                        >
                                                            {cell.value !== undefined ? converterDataBr(cell.value) : "-"}
                                                        </td>
                                                    );
                                                case "telefone":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-1 px-2 text-center whitespace-nowrap text-sm"
                                                        >
                                                            {cell.value !== undefined ? mask(cell.value, "telefone") : "-"}
                                                        </td>
                                                    );
                                                case "dias":
                                                    const label = cell.value === '1' ? "dia" : "dias"
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-1 px-2 text-center whitespace-nowrap text-sm"
                                                        >

                                                            {cell.value !== undefined ? cell.value + ` ${label}` : "-"}
                                                        </td>
                                                    );
                                                case "YesOrNot":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-1 px-2 text-center whitespace-nowrap text-sm"
                                                        >
                                                            {cell.value !== null ? "Sim" : "Não"}
                                                        </td>
                                                    );
                                                case "cpf_cnpj":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-1 px-2 text-center whitespace-nowrap text-sm"
                                                        >
                                                            {cell.value !== undefined ? mask(cell.value, "cpf_cnpj") : "-"}
                                                        </td>
                                                    );
                                                case "mensagem":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-2 px-1 text-center whitespace-nowrap text-sm"
                                                        >
                                                            <div className={`flex w-full h-full p-4 border-gray-400 border rounded-lg overflow-hidden items-center justify-center`}>
                                                                <span>{cell.value !== undefined ? cell.value : "-"}</span>
                                                            </div>
                                                        </td>
                                                    );
                                                case "locatario/fiador":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-2 px-1 text-center whitespace-nowrap text-sm"
                                                        >
                                                            <span>{cell.value === "fiador" ? "Fiador" : "Locatário"}</span>
                                                        </td>
                                                    );
                                                case "dinheiro":
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-2 px-1 text-center whitespace-nowrap text-sm"
                                                        >
                                                            <span>{formatarValor(cell.value, "dinheiro")}</span>
                                                        </td>
                                                    );
                                                default:
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="py-4 px-3 text-center whitespace-nowrap text-base"
                                                        >
                                                            {cell.value !== undefined ? cell.render(`Cell`) : "-"}
                                                        </td>
                                                    );
                                            }
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            {
                pageCount === 1 || pageCount === 0 ? (
                    ""
                ) : (
                    <Pagination
                        onClickPrev={() => previousPage()}
                        onClickNext={() => nextPage()}
                        disabledPrev={!canPreviousPage}
                        disabledNext={!canNextPage}
                        pageLinks={generatePagesLinks()}
                    />
                )
            }
        </Fragment >
    );
}

export default memo(TableConsulta);
