import React from 'react';
import Popup from 'reactjs-popup';
import "./style.css"

type Props = {
  open: boolean;
  closeModalDocumento: any;
  children: React.ReactNode;
  dados: number;
}

/**
 * @deprecated
 */
function ModalDocumento
  ({ open, closeModalDocumento, children }: Props): JSX.Element {
  return (
    <div>
      <Popup
        open={open}
        closeOnDocumentClick
        lockScroll
        className={"modal-solicitacao"}
        onClose={closeModalDocumento}>
        {(closeModalDocumento: any) => (
          <div className="modal">

            <div className="topo-modal-adicionar-editar">
              {/* <div className="icone-voltar-modal" onClick={closeModal}>
                <img src={require('../../../assets/icones/seta-esquerda.png')} />
              </div> */}
            </div>

            <div className="conteudo-modal-adicionari-editar">
              {children}
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default ModalDocumento;