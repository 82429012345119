/* eslint-disable no-implied-eval */
import axios from "axios";
import axiosRetry from "axios-retry";
import { error as errorToast } from "../helpers/functions";

const token = localStorage.getItem("token");

if (
  window.location.protocol !== "https:" &&
  process.env.NODE_ENV === "production" &&
  window.location.hostname !== "localhost" &&
  window.location.hostname !== "127.0.0.1"
) {
  window.location.replace(
    `https:${window.location.href.substring(window.location.protocol.length)}`
  );
}

export const axiosApi = axios.create({
  // baseURL: "https://dev-megaimoveis.softbuilder.com.br/api",
  baseURL: process.env.REACT_APP_AXIOS_BASE_URL,
  // baseURL: "http://127.0.0.1:8000/api",
  timeout: 144000000,
  headers: {
    Authorization: token ? `bearer ${token}` : "",
    "Content-Type": "application/json",
  },
});

export const ativarRefreshToken = (setDadosAtualizados) => {
  if (
    localStorage.getItem("data_expiracao") &&
    new Date(JSON.parse(localStorage.getItem("data_expiracao"))) >= new Date()
  ) {
    const expires_in = localStorage.getItem("expires_in");
    setInterval(async () => {
      const response = await axiosApi.post("/auth/refresh");
      const dataExpiracao = new Date(
        new Date().getTime() + response.data.expires_in
      );
      localStorage.setItem("token", response.data.access_token.token);
      localStorage.setItem("expires_in", response.data.expires_in);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      localStorage.setItem("data_expiracao", JSON.stringify(dataExpiracao));
      axiosApi.defaults.headers.common[
        "authorization"
      ] = `bearer ${response.data.refresh_token}`;
      setDadosAtualizados(
        response.data.access_token,
        JSON.parse(localStorage.getItem("usuario") ?? "null"),
        expires_in,
        response.data.refresh_token
      );
    }, 14400000);
  } else {
    localStorage.clear();
    window.location.reload();
  }
};

const errorInterceptor = axiosApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.config?.url?.split("/")?.[1] != "analise-financeira") {
      if (error.response?.data.mensagem) {
        errorToast(error.response.data.mensagem);
      } else if (error.response?.data.error) {
        errorToast(error.response.data.error);
      } else if (error.response?.data.message) {
        errorToast(error.response.data.message);
      } else if (error.response?.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

axiosRetry(axiosApi, { retries: 3 });
