import React from 'react';
import { AuthProvider } from './AuthContext';
import { ControlModalProvider } from './ModalControlContext';
import { ControllCadastroProvider } from './CadastroControlContext';
import { ControlSolicitacaoProvider } from './SolicitacaoControlContext';
import { ControllMenuProvider } from './MenuContext';
import { PermissoesProvider } from './PermissoesContext';
import { UsuariosProvider } from './UsuariosContext';
import { AnaliseFinanceiraProvider } from './AnaliseFinanceiraContext';
import { ControllCargoProvider } from './CargoContext';
import { ControllSedesProvider } from './SedesContext';
import { ControllLocatarioProvider } from './LocatarioContext';
import { ControllProprietarioProvider } from './ProprietarioContext';
import { ControllImovelProvider } from './ImovelContext';
import { ControllMobileProvider } from './MobileContext';
import { ControllFiadorProvider } from './FiadorContext';

interface IApplicationProvider {
    children: React.ReactNode
}

const ApplicationProvider: React.FC<IApplicationProvider> = ({ children }) => {

    return (
        <AuthProvider>
            <ControlModalProvider>
                <ControllCadastroProvider>
                    <ControlSolicitacaoProvider>
                        <ControllMenuProvider>
                            <PermissoesProvider>
                                <UsuariosProvider>
                                    <AnaliseFinanceiraProvider>
                                        <ControllCargoProvider>
                                            <ControllLocatarioProvider>
                                                <ControllProprietarioProvider>
                                                    <ControllImovelProvider>
                                                        <ControllSedesProvider>
                                                            <ControllMobileProvider>
                                                                <ControllFiadorProvider>
                                                                    {children}
                                                                </ControllFiadorProvider>
                                                            </ControllMobileProvider>
                                                        </ControllSedesProvider>
                                                    </ControllImovelProvider>
                                                </ControllProprietarioProvider>
                                            </ControllLocatarioProvider>
                                        </ControllCargoProvider>
                                    </AnaliseFinanceiraProvider>
                                </UsuariosProvider>
                            </PermissoesProvider>
                        </ControllMenuProvider>
                    </ControlSolicitacaoProvider>
                </ControllCadastroProvider>
            </ControlModalProvider>
        </AuthProvider>
    );
}

export default ApplicationProvider;