import React, { useEffect } from 'react';
import Loader from 'src/components/Basicos/Loader';
import { useControlContextUsuarios } from 'src/contexts/UsuariosContext';
import './style.css'

const Cliente: React.FunctionComponent = () => {
    const {
        loader,
        getUsuario,
        usuario,
        setUsuario
    } = useControlContextUsuarios();

    useEffect(() => {
        getUsuario();
    }, []);

    const onChange = (event: any) => {
        const { value } = event.target;
        setUsuario({
            ...usuario,
            email: value
        })
    }

    return (
        <Loader isActive={loader}>
            <div className='p-8'>
                <div className='novo-usuario p-8'>
                    <div className='form-novo-usuario'>
                        <FormaLabelInput
                            titulo="Nome"
                            nome_do_campo="nome"
                            valor_campo={usuario?.nome}
                            tipo_campo="text"
                            marginRigth="1.5rem"
                            disabled={true}
                        />
                        <FormaLabelInput
                            titulo="Sobrenome"
                            nome_do_campo="sobrenome"
                            valor_campo={usuario?.sobrenome}
                            tipo_campo="text"
                            marginRigth="1.5rem"
                            disabled={true}
                        />
                        <FormaLabelInput
                            titulo="Email"
                            nome_do_campo="email"
                            funcao={onChange}
                            valor_campo={usuario?.email}
                            tipo_campo="text"
                            marginRigth="1.5rem"
                        />
                        <FormaLabelInput
                            titulo="Telefone"
                            nome_do_campo="telefone"
                            valor_campo={usuario?.telefone}
                            tipo_campo="text"
                            marginRigth="1.5rem"
                            disabled={true}
                        />
                    </div>
                </div>
            </div>
        </Loader>
    );
}

interface CamposForm {
    titulo?: string,
    nome_do_campo?: string,
    valor_campo?: number | string,
    tipo_campo?: string,
    required?: boolean,
    funcao?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    width?: string,
    height?: string,
    marginLeft?: string,
    marginRigth?: string,
    displayBlock?: string,
    disabled?: boolean
}

const FormaLabelInput: React.FC<CamposForm> = (props) => {
    return (
        <>
            <div className='form-input-novo-usuario' style={{ width: props.width }}>
                <label className="form-label-titulo mt-3" style={{ display: props.displayBlock }}>{props.titulo}</label>
                <input
                    disabled={props.disabled}
                    required={props.required ? true : false}
                    type={props.tipo_campo}
                    name={props.nome_do_campo}
                    onChange={props.funcao}
                    value={props.valor_campo ?? ""}
                    className={`${props.disabled == true ? 'disabled-true' : ''} modal-input mb-3 p-1 novo-usuario`}
                    style={{ width: props.width, height: props.height, marginLeft: props.marginLeft, marginRight: props.marginRigth }}
                />
            </div>
        </>
    );
};

export default Cliente;