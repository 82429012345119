import React from 'react';
import logoMegaImoveis from 'src/assets/login/logo-mega-imoveis.svg';

interface Props {
    style?: any
}

const Footer = ({style}: Props) => {
    return (
        <>
            <div className={`borda-footer-pdf${style == undefined ? '' : '-' + style}`}></div>
            <div>
                <img src={logoMegaImoveis} width='120' height='120' alt='logo Mega Imoveis' />
            </div>
        </>
    );
};

export default Footer;