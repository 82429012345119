import React, { Fragment, useEffect, useState } from "react";
import iconAnalise from "src/assets/icones/icon-analise.svg"
// import { AccordionTimeLine } from "../../AccordionTimeLine";
import CardTimeLapse from "../../Cards/CardTimeLapse";
// import { BiTrash } from "react-icons/bi";
import iconCrownFill from "src/assets/icones/icon-crown-fill.svg"
import iconCrown from "src/assets/icones/icon-crown.svg"
import { Link } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { mask } from "src/helpers/functions";

export type Props = {
    fiadorId?: number;
    timeline?: any;
    setStatusTimeLine?: any,
    setEtapaAtual?: any,
    etapaAtual?: number,
    itsButtons?: boolean,
    statusSectionTimeline?: boolean,
    isPrincipal?: boolean,
    isSecundario?: boolean,
    fiador?: any
}

export default function TimelineCadastroFiadorMobile({
    fiadorId,
    timeline,
    setStatusTimeLine,
    setEtapaAtual,
    etapaAtual,
    itsButtons = true,
    statusSectionTimeline = true,
    fiador,
    isPrincipal = false,
    isSecundario = false
}: Props): JSX.Element {

    const [dataOption, setDataOption] = useState();
    const [estiloCard, setEstiloCard] = useState<any>([]);
    const [cardExpandido, setCardExpandido] = useState<boolean>(false);

    const optionsAvaliacaoImovel: Array<any> = [
        { value: 10, label: "Realizado" },
        { value: 11, label: "Não Realizado" },
    ];

    const itsButtonCard2 = timeline[2]?.status_id === 7 || timeline[2]?.status_id === 8;
    // const itsButtonCard3 = timeline[3]?.status_id === 1 || timeline[3]?.status_id === 3;

    const loadEstiloAnalise = () => {
        var estilo1 = fiador?.analise_financeira != null ? true : false;
        var estilo2 = 2;
        if (estilo1 == true) {
            var estilo2 = fiador?.timeline?.[2].aprovado_renda == true && fiador?.timeline?.[2].aprovado_score == true && fiador?.timeline?.[2].aprovado_restricao == true ? 1 : 2;
        }
        setEstiloCard({
            estilo1: estilo1,
            estilo2: estilo2,
            titulo: "Fiador"
        })
    }

    const expandirCard = () => {
        setCardExpandido(!cardExpandido);
    }

    const statusWithButton = [1, 2, 3, 5];

    useEffect(() => {
        if (dataOption !== undefined) {
            setStatusTimeLine(dataOption, fiadorId);
        }
    }, [dataOption]);

    useEffect(() => {
        loadEstiloAnalise();
    }, [])

    return (
        <Fragment>
            <div className={`${isPrincipal ? 'bg-gray-ligth-system-mega-imoveis' : 'bg-white3-mega-imoveis card-content'} card-${isPrincipal ? 'principal' : 'secundario'}-mobile${cardExpandido ? '-expandido' : ''} pt-5 pb-5 pl-5 pr-5 mt-2`} style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>
                <div className={`title-card-mobile-secundario${cardExpandido ? '-expandido' : ''} w-full`}>
                    <h1 className={`font-mont-bold text-xl`}>Fiador - {fiador?.informacoes_basicas?.nome_cliente}</h1>
                    <div className="infos-inline">
                        <h1 className="text-gray-system-mega-imoveis">{mask(fiador?.informacoes_basicas?.cpf ?? fiador?.informacoes_basicas?.cnpj, "cpf_cnpj")}</h1>
                        <h1 className="text-gray-system-mega-imoveis">{mask(fiador?.informacoes_basicas?.telefone1, "telefone")}</h1>
                    </div>
                    <h1 className="text-gray-system-mega-imoveis">{fiador?.informacoes_basicas?.email}</h1>
                    <div className="icons-secundario">
                        {/* {
                            fiador?.principal ? '' : <BiTrash size={`2rem`} style={{ color: "#807E7E" }} />
                        } */}
                        <img src={!fiador?.principal ? iconCrown : iconCrownFill} className={`inset-0 ${!fiador?.principal ? 'h-8 w-8' : 'h-12 w-12'} object-center`} alt="icon_crown" />
                        <Link
                            to={`/cadastro/fiador/detalhes/${fiador?.id}`}
                            onClick={() => undefined}
                            className="flex text-white bg-gray-ligth-system-mega-imoveis border border-gray-system-mega-imoveis p-2 focus:outline-none rounded-xl items-center justify-center">
                            <FiEye
                                size={'1.2rem'}
                                className={`text-gray-system-mega-imoveis`} />
                        </Link>
                        {cardExpandido
                            ? <MdArrowDropUp className={`inline-block text-blue-mega-imoveis`} size={`2rem`} onClick={() => expandirCard()} />
                            : <MdArrowDropDown className={`inline-block text-blue-mega-imoveis`} size={`2rem`} onClick={() => expandirCard()} />
                        }
                    </div>
                </div>
                <div className={`${cardExpandido ? "historico-expandido" : "historico-nao-expandido"} flex w-full scale-150`}>
                    <div className={`flex p-4 ${isPrincipal ? 'bg-gray-ligth-system-mega-imoveis' : 'bg-white'} rounded-xl gap-2 ${fiador?.timeLine?.[3]?.motivo != null || fiador?.timeLine?.[4]?.motivo != null ? 'time-line-expandido' : ''}`}>
                        <div className={`flex justify-center items-center md:flex-row gap-4 w-full md:justify-between`}>
                            <CardTimeLapse
                                etapa={1}
                                isIcon={timeline?.[1]?.status_id == 1 ? true : false}
                                status={timeline[1]?.status_id}
                                image={iconAnalise}
                                nextPathName={'view'}
                                cadastroId={fiadorId}
                                itsButton={itsButtons ?? true}
                                titleCard={'Cadastro Recebido'}
                                subTitleCard={timeline[1]?.updated_at ?? null}
                                active={timeline[1]?.status !== null}
                                buttonTitle={'visualizar cadastro'}
                                statusTimeline={statusSectionTimeline}
                                timeline={timeline}
                                cancelado={fiador?.status}
                            />
                            <CardTimeLapse
                                etapa={2}
                                colorText={'text-yellow-500'}
                                isIcon={false}
                                message={true}
                                status={timeline[2]?.status_id}
                                messageWarning={'Cadastro pré-aprovado'}
                                image={iconAnalise}
                                nextPathName={'#'}
                                cadastroId={fiadorId}
                                itsButton={itsButtons ? itsButtonCard2 : false}
                                titleCard={'Pré-análise Financeira'}
                                subTitleCard={timeline[2]?.updated_at ?? null}
                                active={timeline[2]?.status !== null}
                                buttonTitle={'acessar spc/serasa'}
                                statusTimeline={statusSectionTimeline}
                                estiloCard={estiloCard}
                                analiseFinanceira={fiador?.analise_financeira}
                                fiador={fiador}
                                conjugeId={fiador?.conjuge_id}
                                timeline={timeline}
                                cancelado={fiador?.status}
                            />
                            <CardTimeLapse
                                etapa={3}
                                color={'bg-orange-500'}
                                colorText={'text-orange-500'}
                                status={timeline[3]?.status_id}
                                image={iconAnalise}
                                nextPathName={'analise-cadastro'}
                                cadastroId={fiadorId}
                                motivo={timeline[3]?.motivo ?? null}
                                itsButton={itsButtons && statusWithButton.includes(timeline[3]?.status_id)}
                                titleCard={'Análise do Cadastro'}
                                subTitleCard={timeline[3]?.updated_at ?? null}
                                buttonTitle={'Analisar cadastro'}
                                active={timeline[3]?.status !== null}
                                statusTimeline={statusSectionTimeline}
                                fiador={fiador}
                                disponivelAlterar={timeline[3]?.alterar}
                                cancelado={fiador?.status}
                            />
                            <CardTimeLapse
                                etapa={4}
                                status={timeline[4]?.status_id}
                                image={iconAnalise}
                                cadastroId={fiadorId}
                                motivo={timeline[4]?.motivo ?? null}
                                itsButton={itsButtons && statusWithButton.includes(timeline[4]?.status_id) && timeline[4]?.alterar}
                                titleCard={'Análise pela Diretoria'}
                                subTitleCard={timeline[4]?.updated_at ?? null}
                                buttonTitle={'Analisar cadastro'}
                                nextPathName={'analise-diretoria'}
                                active={timeline[4]?.status !== null}
                                statusTimeline={statusSectionTimeline}
                                disponivelAlterar={timeline[4]?.alterar}
                                cancelado={fiador?.status}
                                color={'bg-orange-500'}
                                colorText={'text-orange-500'}
                            />
                            <CardTimeLapse
                                etapa={5}
                                status={timeline[5]?.status_id}
                                image={iconAnalise}
                                cadastroId={fiadorId}
                                motivo={timeline[5]?.motivo ?? null}
                                itsButton={false}
                                titleCard={'Vistoria de Entrada'}
                                itsButtonDropDown={timeline[5]?.status_id !== 10 && statusSectionTimeline}
                                options={optionsAvaliacaoImovel}
                                subTitleCard={timeline[5]?.updated_at ?? null}
                                active={timeline[5]?.status !== null}
                                setStateOption={setDataOption}
                                setStateEtapa={setEtapaAtual}
                                currentValue={timeline[5]?.status_id ?? null}
                                statusTimeline={statusSectionTimeline}
                                disponivelAlterar={timeline[5]?.alterar}
                                cancelado={fiador?.status}
                            />
                            <CardTimeLapse
                                etapa={6}
                                status={timeline[6]?.status_id}
                                image={iconAnalise}
                                cadastroId={fiadorId}
                                motivo={timeline[6]?.motivo ?? null}
                                itsButton={false}
                                titleCard={'Assinatura do Contrato'}
                                itsButtonDropDown={timeline[6]?.status_id !== 10 && statusSectionTimeline}
                                options={optionsAvaliacaoImovel}
                                subTitleCard={timeline[6]?.updated_at ?? null}
                                active={timeline[6]?.status !== null}
                                setStateOption={setDataOption}
                                setStateEtapa={setEtapaAtual}
                                currentValue={timeline[6]?.status_id ?? null}
                                statusTimeline={statusSectionTimeline}
                                disponivelAlterar={timeline[6]?.alterar}
                                cancelado={fiador?.status}
                            />
                        </div>
                    </div>
                </div>
            </div >
        </Fragment >
    );
}