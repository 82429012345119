import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';


const COLORS = [
  '#00CFC3',
  '#6271FF',
  '#FF6091',
  '#FFD670',
  '#FF7E62'
];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {value}
    </text>
  );
};


// const renderLegend = (props) => {
//   const { payload } = props;
//   return (
//     <ul>
//       {payload.map((entry, index) => (
//         <li key={`item-${index}`}>{entry.value}</li>
//       ))}
//     </ul>
//   );
// }

const renderColorfulLegendText = (value, entry) => {
  return <span className='xl:text-sm lg:text-xs' style={{ 'color': '#807E7E' }}>{value}</span>;
};

export default class PieChartMegaImoveis extends PureComponent {
  render() {

    const { legenda, data = [], colorsType = COLORS } = this.props;

    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          {legenda ? <Legend
            layout="vertical"
            verticalAlign="top"
            align="right"
            iconType='circle'
            formatter={renderColorfulLegendText}

          /> : ''}
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={145}
            fill="#8884d8"
            dataKey="value"
            className='focus:outline-none'
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colorsType[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}