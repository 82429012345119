import React from 'react';
import Linha from '../../Universal/Linha';

type Props = {
    codigo_imovel?: any;
    bairro?: any;
    logradouro?: any;
    numero?: any;
    cidade?: any;
    estado?: any;
    complemento?: any;
};

const BlocoImovelPretendido = ({
    codigo_imovel,
    bairro,
    logradouro,
    numero,
    cidade,
    estado,
    complemento
}: Props) => {
    return (
        <>
            <h1 className='sub-title estilo-fonte-negrito'>
                Imóvel Pretendido
            </h1>
            <div className='borda-pdf mb-3 mt-3'></div>
            <Linha campo10='Código do Imóvel*' valor10={codigo_imovel} campo20='Bairro' valor20={bairro} />
            <Linha isEndereco1={true} campo10='Logradouro' valor10={logradouro} campo11='Número' valor11={numero} campo20='Cidade' valor20={cidade} campo21='Estado' valor21={estado} />
            <Linha campo10='Complemento' valor10={complemento} />
        </>
    );
};

export default BlocoImovelPretendido;