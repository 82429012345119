import React, { Fragment } from 'react';
import { ClassicProps } from "src/@types/types";
import "./style.css";


type Props = {
  onClick?: any;
  etapaActive: number;
  statusActive: number;
  location?: string | undefined
};

export default function SubStageBar({
  onClick,
  etapaActive,
  statusActive,
  location
}: Props & ClassicProps): JSX.Element {

  const campos = {
    '3': [
      { 'stage': 1, 'label': 'Pendente', 'style': 'pendente-active' },
      { 'stage': 2, 'label': 'Aguardando Reenvio', 'style': 'reenvio-active' },
      { 'stage': 3, 'label': 'Reenviado', 'style': 'reenviado-active' },
      { 'stage': 4, 'label': 'Aprovado', 'style': 'aprovado-active' },
      { 'stage': 5, 'label': 'Reprovado', 'style': 'reprovado-active' }
    ],
    '4': [
      { 'stage': 1, 'label': 'Pendente', 'style': 'pendente-active' },
      { 'stage': 2, 'label': 'Aguardando Reenvio', 'style': 'reenvio-active' },
      { 'stage': 3, 'label': 'Reenviado', 'style': 'reenviado-active' },
      { 'stage': 4, 'label': 'Aprovado', 'style': 'aprovado-active' },
      { 'stage': 5, 'label': 'Reprovado', 'style': 'reprovado-active' }
    ],
    '5': [
      { 'stage': 1, 'label': 'Pendente', 'style': 'pendente-active' },
      { 'stage': 10, 'label': 'Realizado', 'style': 'realizado-active' },
      { 'stage': 11, 'label': 'Não Realizado', 'style': 'naorealizado-active' }
    ],
    '6': [
      { 'stage': 1, 'label': 'Pendente', 'style': 'pendente-active' },
      { 'stage': 10, 'label': 'Realizado', 'style': 'realizado-active' },
      { 'stage': 11, 'label': 'Não Realizado', 'style': 'naorealizado-active' }
    ]
  };

  return (
    <Fragment>
      <div className="rounded-xl w-full flex-row gap-2 md:gap-1 flex h-auto mb-10 md:px-6 card-grid-sub-stage-bar">
        {
          campos[etapaActive].map((campo: any) => {
            return (
              <div className={`flex ${statusActive === campo.stage ? campo.style : 'text-gray-500 border-gray-500'} border-2 rounded-xl h-10 w-full md:w-1/2 text-left pr-6 pl-3 py-2 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-xs`} onClick={() => onClick(campo.stage)}>{campo?.label}</div>
            )
          })
        }
      </div>
    </Fragment>
  );
}
