import React, { useEffect, useState } from 'react';
import Loader from 'src/components/Basicos/Loader';
import CamIcon from "src/assets/icones/cam-new-photo.svg"
import ClockIcon from "src/assets/icones/icon-clock.svg"
import './style.css'
import { maskInput, newMaskWpp } from 'src/helpers/functions';
import { useControlContextSedes } from 'src/contexts/SedesContext';
import { MdOutlineModeEdit } from 'react-icons/md';
import { opcoesRegiao } from './opcoes-regiao.json'
import SelectForm from 'src/components/Basicos/Form/SelectForm';

const Sede: React.FunctionComponent = () => {
    const { sede, loader, getSede, setSede } = useControlContextSedes();
    const [image, setImage] = useState(
        {
            preview: "",
            raw: ""
        }
    );

    useEffect(() => {
        var id = window.location.href.split("?")[1];
        if (id != undefined)
            getSede(id)
        else setSede(undefined)
    }, [window.location.href.split("?")[1] != undefined]);

    const onChange = (e: any) => {
        var name = e.target.name;
        var value = e.target.value;
        setSede({
            ...sede, [name]: value
        })
    }

    const onImageChange = (event: any) => {
        const { files } = event.target;
        setImage({
            ...image,
            preview: URL.createObjectURL(files[0]),
            raw: files[0],
        });
        setSede(({
            ...sede,
            imagem: files[0]
        }))
    };

    const onChangeEventSelect = (name: string, value: number) => {
        var select = opcoesRegiao?.find(element => element.value === value)
        setSede(({
            ...sede,
            regiao: select?.label
        }))
    }

    return (
        <Loader isActive={loader}>
            <div className='p-8'>
                <div className='novo-usuario p-8'>
                    <div className='form-nova-sede'>
                        {sede?.arquivo?.caminho || image?.preview != '' ?
                            <>
                                <input type="file" className="sede-file" accept="image/png, image/jpg, image/jpeg" onChange={onImageChange} />
                                <MdOutlineModeEdit size={'1.3rem'} pointerEvents={'none'} className={`icon-modal-sede`} />
                                <img src={image?.preview == '' ? sede?.arquivo?.caminho : image?.preview} width='150' height='150' alt='logo Mega Imoveis' className="foto-sede mb-2" />
                            </>
                            :
                            <>
                                <input type="file" className="sede-file" accept="image/png, image/jpg, image/jpeg" onChange={onImageChange} />
                                <div className='foto-nova-sede'>
                                    <img className="icon-camera-nova-foto-sede" alt="icon_group" src={CamIcon} />
                                </div>
                            </>}
                        <div className='mt-4 w-1/2'>
                            <h1 className='cor-cinza'>As imagens devem ser nos formatos <span className='estilo-fonte-negrito'>.png</span>,
                                <span className='estilo-fonte-negrito'>.jpg</span> e <span className='estilo-fonte-negrito'>.jpeg</span>,
                                com proporção <span className='estilo-fonte-negrito'>2x1</span>, tamanho mínimo de
                                <span className='estilo-fonte-negrito'> 296x592 pixels</span> e máximo de
                                <span className='estilo-fonte-negrito'> 592x1184 pixels</span>.</h1>
                        </div>
                        <div>
                            <h1 className='text-left text-xl mt-4 estilo-fonte-negrito'>Informações da Sede</h1>
                            <div className='form-novo-sede'>
                                <div className='form-sede-20 mb-4'>
                                    <h3 className='label-usuario'>Nome</h3>
                                    <input
                                        type='text'
                                        name='nome'
                                        onChange={onChange}
                                        value={sede?.nome ?? ""}
                                        className="modal-input p-1 input-editar-sede"
                                    />
                                </div>
                                <div className='form-sede-40 mb-4'>
                                    <h3 className='label-usuario'>Endereço</h3>
                                    <input
                                        type='text'
                                        name='endereco'
                                        onChange={onChange}
                                        value={sede?.endereco ?? ""}
                                        className="modal-input p-1 input-editar-sede"
                                    />
                                </div>
                                <div className='form-sede-20 mb-4'>
                                    <SelectForm
                                        name={'regiao'}
                                        options={opcoesRegiao}
                                        label={'Região'}
                                        value={opcoesRegiao.find(element => element.label === sede?.regiao)}
                                        onChange={onChangeEventSelect}
                                        placeholder={"Região"}
                                    />
                                </div>
                                <div className='form-sede-20 mb-4'>
                                    <h3 className='label-usuario'>Whatsapp</h3>
                                    <input
                                        type='text'
                                        name='whatsapp'
                                        onChange={onChange}
                                        maxLength={20}
                                        value={newMaskWpp(sede?.whatsapp) ?? ""}
                                        className="modal-input p-1 input-editar-sede"
                                    />
                                </div>
                            </div>
                            <h1 className='text-left text-xl mt-4 estilo-fonte-negrito'>Horário de Funcionamento</h1>
                            <div className='form-novo-sede'>
                                <div className='form-sede-30 mb-4'>
                                    <h3 className='label-usuario'>Segunda à Sexta</h3>
                                    <div className='input-hora-icon'>
                                        <input
                                            id="hora"
                                            type='text'
                                            name='hor_seg_sex'
                                            onChange={onChange}
                                            value={sede?.hor_seg_sex ?? ""}
                                            onKeyUp={maskInput}
                                            className="modal-input p-1 input-editar-sede-hora"
                                        />
                                        <img src={ClockIcon} width='150' height='150' alt='logo Mega Imoveis' className="foto-sede-clock" />
                                    </div>
                                </div>
                                <div className='form-sede-30 mb-4'>
                                    <h3 className='label-usuario'>Sábado</h3>
                                    <div className='input-hora-icon'>
                                        <input
                                            id="hora"
                                            type='text'
                                            name='hor_sab'
                                            onChange={onChange}
                                            value={sede?.hor_sab ?? ""}
                                            onKeyUp={maskInput}
                                            className="modal-input p-1 input-editar-sede-hora"
                                        />
                                        <img src={ClockIcon} width='150' height='150' alt='logo Mega Imoveis' className="foto-sede-clock" />
                                    </div>
                                </div>
                                <div className='form-sede-30 mb-4'>
                                    <h3 className='label-usuario'>Domingos e Feriados</h3>
                                    <div className='input-hora-icon'>
                                        <input
                                            id="hora"
                                            type='text'
                                            name='hor_dom'
                                            onChange={onChange}
                                            value={sede?.hor_dom ?? ""}
                                            onKeyUp={maskInput}
                                            className="modal-input p-1 input-editar-sede-hora"
                                        />
                                        <img src={ClockIcon} width='150' height='150' alt='logo Mega Imoveis' className="foto-sede-clock" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Loader >
    );
}

export default Sede;