import React, { Fragment } from "react";
import InputValidate from "src/components/Basicos/Form/Input/inputValidate";
import { formatarValor } from "src/components/Basicos/funcoes";
import TitleValidate from "src/components/Basicos/TitleSelectedValidate";
import { mask } from "src/helpers/functions";

export type Props = {
    fiador?: any;
    campos?: any;
    handleClick?: any;
    selectSection?: any;
    sections?: any;
    onChange?: any;
    openModal?: any;
    itsView?: boolean;
    etapaId?: any;
    onSubmit?: any;
    rgOrCnhConjuge?: any;
    rgOrCnh?: any;
    nulos?: any;
    nameInterpriseOrServicePublic?: any;
    existFalse?: any;
    itsFullIsTrue?: any;
    handleStatusCadastro?: any;
    itsFull?: any;
}

const FormularioCadastroFiadorMobile: React.FunctionComponent<Props> = ({
    fiador,
    campos,
    handleClick,
    selectSection,
    sections,
    onChange,
    openModal,
    itsView,
    etapaId,
    onSubmit,
    rgOrCnhConjuge,
    rgOrCnh,
    nulos,
    nameInterpriseOrServicePublic,
    existFalse,
    itsFullIsTrue,
    handleStatusCadastro,
    itsFull
}) => {
    return (
        <Fragment>
            <form onSubmit={onSubmit}>
                <div className="bg-white p-4 flex flex-col rounded-lg border-white border h-full w-full" style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>

                    {/* Identificação */}
                    <div>
                        <TitleValidate
                            title={'Identificação'}
                            selectSection={selectSection}
                            checkSection={sections?.identificacao}
                            section={0}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`CPF`}
                            name={`cpf_fiador`}
                            value={mask(fiador?.informacoes_basicas?.cpf, 'cpf_cnpj') ?? null}
                            onClick={handleClick}
                            status={campos.cpf_fiador ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Nome`}
                            name={`nome_fiador`}
                            value={fiador?.informacoes_basicas?.nome_cliente ?? null}
                            onClick={handleClick}
                            status={campos.nome_fiador ?? null}
                            itsButton={itsView}
                        />
                        <span className={`font-extrabold text-lg ${fiador?.informacoes_basicas?.rg_cnh_frente_arquivo_id === null ? 'hidden' : ''}`}>Documento de Identificação Oficial com Foto*</span>
                        <span className={`font-extrabold text-base mt-3 ${fiador?.informacoes_basicas?.rg_cnh_frente_arquivo_id === null ? 'hidden' : ''}`}>Documento: {rgOrCnh ?? 'RG ou CNH'}</span>
                        <div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
                            <InputValidate
                                title={`Frente`}
                                name={`rg_cnh_frente_fiador`}
                                value={fiador?.informacoes_basicas?.rg_cnh_frente?.nome ?? null}
                                onClick={handleClick}
                                isFile={true}
                                hidden={fiador?.informacoes_basicas?.rg_cnh_frente_arquivo_id === null}
                                file={fiador?.informacoes_basicas?.rg_cnh_frente?.caminho ?? null}
                                status={campos.rg_cnh_frente_fiador ?? null}
                                itsButton={itsView}
                            />
                            <InputValidate
                                title={`Verso`}
                                name={`rg_cnh_verso_fiador`}
                                value={fiador?.informacoes_basicas?.rg_cnh_verso?.nome ?? null}
                                onClick={handleClick}
                                isFile={true}
                                hidden={fiador?.informacoes_basicas?.rg_cnh_verso_arquivo_id === null}
                                file={fiador?.informacoes_basicas?.rg_cnh_verso?.caminho ?? null}
                                status={campos.rg_cnh_verso_fiador ?? null}
                                itsButton={itsView}
                            />
                        </div>
                        <InputValidate
                            title={`Telefone 1`}
                            name={`telefone_fiador`}
                            value={mask(fiador?.informacoes_basicas?.telefone1, 'telefone') ?? null}
                            onClick={handleClick}
                            status={campos.telefone_fiador ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Telefone 2`}
                            name={`telefone2_fiador`}
                            value={mask(fiador?.informacoes_basicas?.telefone2, 'telefone') ?? null}
                            onClick={handleClick}
                            status={campos.telefone2_fiador ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`E-mail`}
                            name={`email_fiador`}
                            value={fiador?.informacoes_basicas?.email ?? null}
                            onClick={handleClick}
                            status={campos.email_fiador ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Estado Civil`}
                            name={`estado_civil_fiador`}
                            value={fiador?.informacoes_basicas?.estado_civil?.descricao ?? null}
                            onClick={handleClick}
                            status={campos.estado_civil_fiador ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Nº de Dependentes`}
                            name={`numb_dependentes_fiador`}
                            value={fiador?.informacoes_basicas?.qtd_dependentes ?? null}
                            onClick={handleClick}
                            status={campos.numb_dependentes_fiador ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Naturalidade`}
                            name={`naturalidade_fiador`}
                            value={fiador?.informacoes_basicas?.naturalidade ?? null}
                            hidden={fiador?.informacoes_basicas?.naturalidade === null}
                            onClick={handleClick}
                            status={campos.naturalidade_fiador ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Nacionalidade`}
                            name={`nacionalidade_fiador`}
                            value={fiador?.informacoes_basicas?.nacionalidade ?? null}
                            hidden={fiador?.informacoes_basicas?.nacionalidade === null}
                            onClick={handleClick}
                            status={campos.nacionalidade_fiador ?? null}
                            itsButton={itsView}
                        />
                    </div>


                    {/* Cônjuge */}
                    <div className={`mt-12 ${fiador?.conjuge === null ? 'hidden' : ''}`}>
                        <TitleValidate
                            title={'Cônjuge'}
                            selectSection={selectSection}
                            checkSection={sections?.conjuge ?? null}
                            section={1}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Nome`}
                            name={`nome_conjuge`}
                            value={fiador?.conjuge?.informacoes_basicas.nome_cliente ?? null}
                            onClick={handleClick}
                            status={campos.nome_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Telefone 1`}
                            name={`telefone_conjuge`}
                            value={mask(fiador?.conjuge?.informacoes_basicas.telefone1, 'telefone') ?? null}
                            onClick={handleClick}
                            status={campos.telefone_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Telefone 2`}
                            name={`telefone2_conjuge`}
                            value={mask(fiador?.conjuge?.informacoes_basicas.telefone2, 'telefone') ?? null}
                            onClick={handleClick}
                            status={campos.telefone2_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <span className="font-extrabold text-lg">Documento de Identificação Oficial com Foto*</span>
                        <div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
                            <InputValidate
                                title={`${rgOrCnhConjuge} - Frente`}
                                name={`rg_cnh_frente_conjuge`}
                                value={fiador?.conjuge?.informacoes_basicas?.rg_cnh_frente?.nome ?? null}
                                onClick={handleClick}
                                isFile={true}
                                file={fiador?.conjuge?.informacoes_basicas?.rg_cnh_frente?.caminho ?? null}
                                status={campos.rg_cnh_frente_conjuge ?? null}
                                itsButton={itsView}
                            />
                            <InputValidate
                                title={`${rgOrCnhConjuge} - Verso`}
                                name={`rg_cnh_verso_conjuge`}
                                value={fiador?.conjuge?.informacoes_basicas?.rg_cnh_verso?.nome ?? null}
                                onClick={handleClick}
                                isFile={true}
                                file={fiador?.conjuge?.informacoes_basicas?.rg_cnh_verso?.caminho ?? null}
                                status={campos.rg_cnh_verso_conjuge ?? null}
                                itsButton={itsView}
                            />
                        </div>
                        <InputValidate
                            title={`CPF`}
                            name={`cpf_conjuge`}
                            value={mask(fiador?.conjuge?.informacoes_basicas.cpf, 'cpf_cnpj') ?? null}
                            onClick={handleClick}
                            status={campos.cpf_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`E-mail`}
                            name={`email_conjuge`}
                            value={fiador?.conjuge?.informacoes_basicas.email ?? null}
                            onClick={handleClick}
                            status={campos.email_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Naturalidade`}
                            name={`naturalidade_conjuge`}
                            value={fiador?.conjuge?.informacoes_basicas.naturalidade ?? null}
                            hidden={fiador?.conjuge?.informacoes_basicas?.naturalidade === null}
                            onClick={handleClick}
                            status={campos.naturalidade_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Nacionalidade`}
                            name={`nacionalidade_conjuge`}
                            value={fiador?.conjuge?.informacoes_basicas.nacionalidade ?? null}
                            hidden={fiador?.conjuge?.informacoes_basicas?.nacionalidade === null}
                            onClick={handleClick}
                            status={campos.nacionalidade_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Tipo de Atividade Profissional`}
                            name={`tipo_atividade_profissional_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.tipo_atividade?.descricao ?? null}
                            onClick={handleClick}
                            status={campos.tipo_atividade_profissional_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Renda Mensal`}
                            name={`renda_mensal_conjuge`}
                            value={formatarValor(fiador?.conjuge?.atividade_profissional?.renda_mensal, "dinheiro") ?? null}
                            onClick={handleClick}
                            status={campos.renda_mensal_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={'Nome da Empresa'}
                            name={`nome_empresa_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.nome_empresa ?? null}
                            onClick={handleClick}
                            hidden={fiador?.conjuge?.atividade_profissional?.nome_empresa === null}
                            status={campos.nome_empresa_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Ramo da Atividade`}
                            name={`ramo_atividade_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.ramo_atividade ?? null}
                            onClick={handleClick}
                            status={campos?.ramo_atividade_conjuge ?? null}
                            itsButton={itsView}
                            hidden={nulos.includes(fiador?.conjuge?.atividade_profissional?.ramo_atividade) || fiador?.conjuge?.atividade_profissional?.ramo_atividade == null}
                        />
                        <InputValidate
                            title={`Tempo de Empresa`}
                            name={`tempo_empresa_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.tempo_empresa?.descricao ?? null}
                            onClick={handleClick}
                            hidden={fiador?.conjuge?.atividade_profissional?.tempo_empresa?.descricao === undefined || fiador?.conjuge?.atividade_profissional?.tempo_empresa?.descricao == null}
                            status={campos.tempo_empresa_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Cargo Atual`}
                            name={`cargo_atual_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.cargo_atual ?? null}
                            onClick={handleClick}
                            hidden={fiador?.conjuge?.atividade_profissional?.cargo_atual === undefined || fiador?.conjuge?.atividade_profissional?.cargo_atual == null}
                            status={campos.cargo_atual_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Último Extrato do Cartão de Crédito`}
                            name={`extrato_cartao_credito_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho === undefined}
                            file={fiador?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho ?? null}
                            status={campos.extrato_cartao_credito_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <p className={`font-extrabold text-lg text-gray-system-mega-imoveis ml-6`}>{fiador?.conjuge?.atividade_profissional?.arquivo_outro?.caminho !== undefined
                            || fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.caminho !== undefined
                            || fiador?.conjuge?.atividade_profissional?.arquivo_ctps?.caminho !== undefined
                            || fiador?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.caminho !== undefined ? 'Comprovante de Renda*' : ''}</p>
                        <InputValidate
                            title={`Contracheque`}
                            name={`contracheque_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.caminho === undefined}
                            file={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.caminho ?? null}
                            status={campos.contracheque_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Contracheque 2`}
                            name={`contracheque2_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.caminho === undefined}
                            file={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.caminho ?? null}
                            status={campos.contracheque2_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Contracheque 3`}
                            name={`contracheque3_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.caminho === undefined}
                            file={fiador?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.caminho ?? null}
                            status={campos.contracheque3_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`CTPS`}
                            name={`carteira_trabalho_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.arquivo_ctps?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.conjuge?.atividade_profissional?.arquivo_ctps?.caminho === undefined}
                            file={fiador?.conjuge?.atividade_profissional?.arquivo_ctps?.caminho ?? null}
                            status={campos.carteira_trabalho_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Imposto de Renda`}
                            name={`imposto_renda_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.caminho === undefined}
                            file={fiador?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.caminho ?? null}
                            status={campos.imposto_renda_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Extrato Bancário`}
                            name={`extrato_bancario_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.caminho === undefined}
                            file={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.caminho ?? null}
                            status={campos.extrato_bancario_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Extrato Bancário 2`}
                            name={`extrato_bancario2_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.caminho === undefined}
                            file={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.caminho ?? null}
                            status={campos.extrato_bancario2_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Extrato Bancário 3`}
                            name={`extrato_bancario3_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.caminho === undefined}
                            file={fiador?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.caminho ?? null}
                            status={campos.extrato_bancario3_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Outro`}
                            name={`outro_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.arquivo_outro?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.conjuge?.atividade_profissional?.arquivo_outro?.caminho === undefined}
                            file={fiador?.conjuge?.atividade_profissional?.arquivo_outro?.caminho ?? null}
                            status={campos.outro_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`CEP`}
                            name={`cep_empresa_atual_conjuge`}
                            value={mask(fiador?.conjuge?.atividade_profissional?.endereco?.cep, 'cep') ?? null}
                            onClick={handleClick}
                            hidden={fiador?.conjuge?.atividade_profissional?.endereco?.cep === undefined}
                            status={campos.cep_empresa_atual_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Logradouro`}
                            name={`logradouro_empresa_atual_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.endereco?.logradouro ?? null}
                            onClick={handleClick}
                            hidden={fiador?.conjuge?.atividade_profissional?.endereco?.logradouro === undefined}
                            status={campos.logradouro_empresa_atual_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Número`}
                            name={`numero_empresa_atual_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.endereco?.numero ?? null}
                            onClick={handleClick}
                            hidden={fiador?.conjuge?.atividade_profissional?.endereco?.numero === undefined}
                            status={campos.numero_empresa_atual_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Bairro`}
                            name={`bairro_empresa_atual_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.endereco?.bairro ?? null}
                            onClick={handleClick}
                            hidden={fiador?.conjuge?.atividade_profissional?.endereco?.bairro === undefined}
                            status={campos.bairro_empresa_atual_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Cidade`}
                            name={`cidade_empresa_atual_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.endereco?.cidade ?? null}
                            onClick={handleClick}
                            hidden={fiador?.conjuge?.atividade_profissional?.endereco?.cidade === undefined}
                            status={campos.cidade_empresa_atual_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Estado`}
                            name={`estado_empresa_atual_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.endereco?.estado ?? null}
                            onClick={handleClick}
                            hidden={fiador?.conjuge?.atividade_profissional?.endereco?.estado === undefined}
                            status={campos.estado_empresa_atual_conjuge ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Complemento`}
                            name={`complemento_empresa_atual_conjuge`}
                            value={fiador?.conjuge?.atividade_profissional?.endereco?.complemento ?? null}
                            onClick={handleClick}
                            hidden={fiador?.conjuge?.atividade_profissional?.endereco?.complemento === undefined || fiador?.conjuge?.atividade_profissional?.endereco?.complemento === null}
                            status={campos.complemento_empresa_atual_conjuge ?? null}
                            itsButton={itsView}
                        />
                    </div>


                    {/* Residência Atual */}
                    <div className={`mt-12 ${fiador?.endereco === null ? 'hidden' : ''}`}>
                        <TitleValidate
                            title={'Residência Atual'}
                            selectSection={selectSection}
                            checkSection={sections?.residencia_atual}
                            section={2}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Sua Residência é`}
                            name={`sua_residencia_atual_e`}
                            value={fiador?.residencia_atual?.tipo_residencia?.descricao ?? null}
                            onClick={handleClick}
                            status={campos.sua_residencia_atual_e ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Comprovante de Endereço`}
                            name={`comprovante_endereco_residencia_atual`}
                            value={fiador?.residencia_atual?.arquivo?.nome ?? null}
                            isFile={true}
                            file={fiador?.residencia_atual?.arquivo?.caminho ?? null}
                            onClick={handleClick}
                            status={campos.comprovante_endereco_residencia_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`CEP`}
                            name={`cep_residencia_atual`}
                            value={mask(fiador?.endereco?.cep, 'cep') ?? null}
                            onClick={handleClick}
                            status={campos.cep_residencia_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Logradouro`}
                            name={`logradouro_residencia_atual`}
                            value={fiador?.endereco?.logradouro ?? null}
                            onClick={handleClick}
                            status={campos.logradouro_residencia_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Número`}
                            name={`numero_residencia_atual`}
                            value={fiador?.endereco?.numero ?? null}
                            onClick={handleClick}
                            status={campos.numero_residencia_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Complemento`}
                            name={`complemento_residencia_atual`}
                            value={fiador?.endereco?.complemento ?? null}
                            onClick={handleClick}
                            hidden={fiador?.endereco?.complemento === null}
                            status={campos.complemento_residencia_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Bairro`}
                            name={`bairro_residencia_atual`}
                            value={fiador?.endereco?.bairro ?? null}
                            onClick={handleClick}
                            status={campos.bairro_residencia_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Cidade`}
                            name={`cidade_residencia_atual`}
                            value={fiador?.endereco?.cidade ?? null}

                            onClick={handleClick}
                            status={campos.cidade_residencia_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Estado`}
                            name={`estado_residencia_atual`}
                            value={fiador?.endereco?.estado ?? null}

                            onClick={handleClick}
                            status={campos.estado_residencia_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Valor do Aluguel`}
                            name={`valor_aluguel_residencia_atual`}
                            value={formatarValor(fiador?.residencia_atual?.valor_aluguel, "dinheiro") ?? null}
                            hidden={fiador?.residencia_atual?.valor_aluguel === null}
                            onClick={handleClick}
                            status={campos.valor_aluguel_residencia_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Tempo de Locação`}
                            name={`tempo_locacao_residencia_atual`}
                            value={fiador?.residencia_atual?.tempo_locacao ?? null}
                            hidden={fiador?.residencia_atual?.tempo_locacao === null}
                            onClick={handleClick}
                            status={campos.tempo_locacao_residencia_atual ?? null}
                            itsButton={itsView}
                        />
                    </div>

                    {/* Atividade Profissional */}
                    <div className={`mt-12 ${fiador?.atividade_profissional === null ? 'hidden' : ''}`}>
                        <TitleValidate
                            title={'Atividade Profissional'}
                            selectSection={selectSection}
                            checkSection={sections.atividade_profissional}
                            section={3}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Tipo de Atividade Profissional`}
                            name={`tipo_atividade_profissional`}
                            value={fiador?.atividade_profissional?.tipo_atividade?.descricao ?? null}
                            onClick={handleClick}
                            status={campos.tipo_atividade_profissional ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Atividade Profissional`}
                            name={`atividade_profissional`}
                            value={fiador?.atividade_profissional?.outra_atividade_profissional ?? null}
                            onClick={handleClick}
                            status={campos?.atividade_profissional ?? null}
                            itsButton={itsView}
                            hidden={nulos.includes(fiador?.atividade_profissional?.outra_atividade_profissional)}
                        />
                        <InputValidate
                            title={`Ramo da Atividade`}
                            name={`ramo_atividade`}
                            value={fiador?.atividade_profissional?.ramo_atividade ?? null}
                            onClick={handleClick}
                            status={campos?.ramo_atividade ?? null}
                            itsButton={itsView}
                            hidden={nulos.includes(fiador?.atividade_profissional?.ramo_atividade)}
                        />
                        <InputValidate
                            title={`Renda Mensal`}
                            name={`renda_mensal`}
                            value={formatarValor(fiador?.atividade_profissional?.renda_mensal, "dinheiro") ?? null}
                            onClick={handleClick}
                            status={campos.renda_mensal ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Último Extrato do Cartão de Crédito`}
                            name={`extrato_cartao_credito`}
                            value={fiador?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho === undefined}
                            file={fiador?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho ?? null}
                            status={campos.extrato_cartao_credito ?? null}
                            itsButton={itsView}
                        />
                        <p className={`font-extrabold text-lg text-gray-system-mega-imoveis ml-6`}>{fiador?.atividade_profissional?.arquivo_outro?.caminho !== undefined
                            || fiador?.atividade_profissional?.arquivo_contra_cheque?.caminho !== undefined
                            || fiador?.atividade_profissional?.arquivo_ctps?.caminho !== undefined
                            || fiador?.atividade_profissional?.arquivo_imposto_renda?.caminho !== undefined ? 'Comprovante de Renda*' : ''}</p>
                        <InputValidate
                            title={`Contracheque`}
                            name={`contracheque`}
                            value={fiador?.atividade_profissional?.arquivo_contra_cheque?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.atividade_profissional?.arquivo_contra_cheque?.caminho === undefined}
                            file={fiador?.atividade_profissional?.arquivo_contra_cheque?.caminho ?? null}
                            status={campos.contracheque ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Contracheque 2`}
                            name={`contracheque2`}
                            value={fiador?.atividade_profissional?.arquivo_contra_cheque2?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.atividade_profissional?.arquivo_contra_cheque2?.caminho === undefined}
                            file={fiador?.atividade_profissional?.arquivo_contra_cheque2?.caminho ?? null}
                            status={campos.contracheque2 ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Contracheque 3`}
                            name={`contracheque3`}
                            value={fiador?.atividade_profissional?.arquivo_contra_cheque3?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.atividade_profissional?.arquivo_contra_cheque3?.caminho === undefined}
                            file={fiador?.atividade_profissional?.arquivo_contra_cheque3?.caminho ?? null}
                            status={campos.contracheque3 ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`CTPS`}
                            name={`carteira_trabalho`}
                            value={fiador?.atividade_profissional?.arquivo_ctps?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.atividade_profissional?.arquivo_ctps?.caminho === undefined}
                            file={fiador?.atividade_profissional?.arquivo_ctps?.caminho ?? null}
                            status={campos.carteira_trabalho ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Imposto de Renda`}
                            name={`imposto_renda`}
                            value={fiador?.atividade_profissional?.arquivo_imposto_renda?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.atividade_profissional?.arquivo_imposto_renda?.caminho === undefined}
                            file={fiador?.atividade_profissional?.arquivo_imposto_renda?.caminho ?? null}
                            status={campos.imposto_renda ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Extrato Bancário`}
                            name={`extrato_bancario`}
                            value={fiador?.atividade_profissional?.arquivo_extrato_bancario?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.atividade_profissional?.arquivo_extrato_bancario?.caminho === undefined}
                            file={fiador?.atividade_profissional?.arquivo_extrato_bancario?.caminho ?? null}
                            status={campos.extrato_bancario ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Extrato Bancário 2`}
                            name={`extrato_bancario2`}
                            value={fiador?.atividade_profissional?.arquivo_extrato_bancario2?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.atividade_profissional?.arquivo_extrato_bancario2?.caminho === undefined}
                            file={fiador?.atividade_profissional?.arquivo_extrato_bancario2?.caminho ?? null}
                            status={campos.extrato_bancario2 ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Extrato Bancário 3`}
                            name={`extrato_bancario3`}
                            value={fiador?.atividade_profissional?.arquivo_extrato_bancario3?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.atividade_profissional?.arquivo_extrato_bancario3?.caminho === undefined}
                            file={fiador?.atividade_profissional?.arquivo_extrato_bancario3?.caminho ?? null}
                            status={campos.extrato_bancario ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Outro`}
                            name={`outro`}
                            value={fiador?.atividade_profissional?.arquivo_outro?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            hidden={fiador?.atividade_profissional?.arquivo_outro?.caminho === undefined}
                            file={fiador?.atividade_profissional?.arquivo_outro?.caminho ?? null}
                            status={campos.outro ?? null}
                            itsButton={itsView}
                        />

                        <InputValidate
                            title={nameInterpriseOrServicePublic}
                            name={`nome_empresa`}
                            value={fiador?.atividade_profissional?.nome_empresa ?? null}
                            onClick={handleClick}
                            hidden={nulos.includes(fiador?.atividade_profissional?.nome_empresa)}
                            status={campos.nome_empresa ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Tempo de Empresa`}
                            name={`tempo_empresa`}
                            value={fiador?.atividade_profissional?.tempo_empresa?.descricao ?? null}
                            onClick={handleClick}
                            hidden={nulos.includes(fiador?.atividade_profissional?.tempo_empresa?.descricao)}
                            status={campos.tempo_empresa ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Cargo Atual`}
                            name={`cargo_atual`}
                            value={fiador?.atividade_profissional?.cargo_atual ?? null}
                            onClick={handleClick}
                            hidden={nulos.includes(fiador?.atividade_profissional?.cargo_atual)}
                            status={campos.cargo_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`CEP`}
                            name={`cep_empresa_atual`}
                            value={mask(fiador?.atividade_profissional?.endereco?.cep, 'cep') ?? null}
                            onClick={handleClick}
                            hidden={nulos.includes(fiador?.atividade_profissional?.endereco?.cep)}
                            status={campos.cep_empresa_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Logradouro`}
                            name={`logradouro_empresa_atual`}
                            value={fiador?.atividade_profissional?.endereco?.logradouro ?? null}
                            onClick={handleClick}
                            hidden={nulos.includes(fiador?.atividade_profissional?.endereco?.logradouro)}
                            status={campos.logradouro_empresa_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Número`}
                            name={`numero_empresa_atual`}
                            value={fiador?.atividade_profissional?.endereco?.numero ?? null}
                            onClick={handleClick}
                            hidden={nulos.includes(fiador?.atividade_profissional?.endereco?.numero)}
                            status={campos.numero_empresa_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Bairro`}
                            name={`bairro_empresa_atual`}
                            value={fiador?.atividade_profissional?.endereco?.bairro ?? null}
                            onClick={handleClick}
                            hidden={nulos.includes(fiador?.atividade_profissional?.endereco?.bairro)}
                            status={campos.bairro_empresa_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Cidade`}
                            name={`cidade_empresa_atual`}
                            value={fiador?.atividade_profissional?.endereco?.cidade ?? null}
                            onClick={handleClick}
                            hidden={nulos.includes(fiador?.atividade_profissional?.endereco?.cidade)}
                            status={campos.cidade_empresa_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Estado`}
                            name={`estado_empresa_atual`}
                            value={fiador?.atividade_profissional?.endereco?.estado ?? null}
                            onClick={handleClick}
                            hidden={nulos.includes(fiador?.atividade_profissional?.endereco?.estado)}
                            status={campos.estado_empresa_atual ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Complemento`}
                            name={`complemento_empresa_atual`}
                            value={fiador?.atividade_profissional?.endereco?.complemento ?? null}
                            onClick={handleClick}
                            hidden={nulos.includes(fiador?.atividade_profissional?.endereco?.complemento)}
                            status={campos.complemento_empresa_atual ?? null}
                            itsButton={itsView}
                        />
                    </div>


                    {/* Imóvel em Garantia */}
                    <div className={`mt-12 ${fiador?.imovel_garantia === null ? 'hidden' : ''}`}>
                        <TitleValidate
                            title={'Imóvel em Garantia'}
                            selectSection={selectSection}
                            checkSection={sections?.imovel_garantia ?? null}
                            section={4}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Matrícula do Imóvel Quitado e Sem Ônus*`}
                            name={`matricula_imovel`}
                            value={fiador?.imovel_garantia?.matricula_imovel ?? null}
                            onClick={handleClick}
                            status={campos.matricula_imovel ?? null}
                            itsButton={itsView}
                        />
                        <InputValidate
                            title={`Certidão de Matrícula Atualizada`}
                            name={`certidao_matricula`}
                            value={fiador?.imovel_garantia?.certidao_matricula?.nome ?? null}
                            onClick={handleClick}
                            isFile={true}
                            file={fiador?.imovel_garantia?.certidao_matricula?.caminho ?? null}
                            status={campos.certidao_matricula ?? null}
                            itsButton={itsView}
                        />
                    </div>
                    {/* comentario*/}
                    <div className={`${itsView ? 'hidden' : ''} mt-5`}>
                        <p className="font-extrabold text-lg text-gray-system-mega-imoveis">Instruções para reenvio*</p>
                        <textarea
                            rows={2}
                            name={`instrucao_reenvio`}
                            value={fiador?.instrucao_reenvio}
                            style={{ maxHeight: "20rem", minHeight: "3.5rem" }}
                            className="block p-4 w-full h-32 mt-2 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus focus:outline-none"
                            placeholder={'Adicione instruções para reenvio'}
                            onChange={onChange}
                            required={existFalse}
                        />
                    </div>

                </div>
                {itsFullIsTrue() ? <div className="w-full flex flex-row gap-4 justify-center items-center">
                    <div>
                        <button
                            type="button"
                            onClick={() => openModal(true)}
                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                            style={{ backgroundColor: '#FF3B3B' }}
                        >
                            reprovar cadastro
                        </button>
                    </div>
                    <div>
                        <button
                            type="submit"
                            onClick={() => handleStatusCadastro("aprovado", etapaId)}
                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                            style={{ backgroundColor: '#0DCB64' }}
                        >
                            aprovar cadastro
                        </button>
                    </div>
                </div> : <div className="w-full flex flex-row gap-4 justify-center items-center">
                    <div>
                        <button
                            type="submit"
                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs border-2 lue-mega-imoveis text-blue-dark-mega-imoveis bg-transparent cursor-pointer rounded-lg justify-center items-center`}
                        >
                            Salvar e continuar depois
                        </button>
                    </div>
                    <div>
                        <button
                            type="submit"
                            disabled={!itsFull() ?? true}
                            onClick={() => handleStatusCadastro("reenviado", etapaId)}
                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs ${itsFull() ? 'bg-blue-dark-mega-imoveis text-white' : 'bg-gray-300 text-gray-700'}   cursor-pointer rounded-lg justify-center items-center`}
                        >
                            enviar para usuário revisar
                        </button>
                    </div>
                </div>
                }

            </form>
        </Fragment>
    );
}

export default FormularioCadastroFiadorMobile;