import { axiosApi } from "src/services/axiosInstance";
import * as actionTypes from "../types";

export const setDadosAtualizados = (
  token: string,
  usuario: actionTypes.Usuario,
  expires_in: number,
  refresh_token: string
): actionTypes.AuthAction => {
  axiosApi.defaults.headers.common["authorization"] = `bearer ${refresh_token}`;
  return {
    type: actionTypes.INITIAL_STATE,
    payload: {
      access_token: {
        token,
        usuario,
      },
      refresh_token,
      expires_in,
    },
  };
};

export const setToken = (
  token: string,
  expires_in: number
): actionTypes.AuthAction => {
  axiosApi.defaults.headers.common["authorization"] = `bearer ${token}`;
  return {
    type: actionTypes.UPDATE_TOKEN,
    payload: {
      access_token: {
        token,
      },
      expires_in,
    },
  };
};

export const setUpdatePerfil = (
  usuario: actionTypes.Usuario
): actionTypes.AuthAction => {
  localStorage.setItem("usuario", JSON.stringify(usuario));
  return {
    type: actionTypes.UPDATE_PERFIL,
    payload: {
      access_token: {
        usuario,
      },
    },
  };
};
