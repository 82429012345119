import React, { useEffect } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useControlContextCadastro } from 'src/contexts/CadastroControlContext';
import { useControlContextMenu } from 'src/contexts/MenuContext';
import { icon } from 'src/helpers/functions';

function SidebarItem({ data, chave }: any): JSX.Element {
    const { active, menuActive, setMenu, setSubMenu, subMenuActive } = useControlContextMenu();
    const { setEtapa, setPaginaFiltro } = useControlContextCadastro();
    const location = useLocation();
    const history = useHistory();
    const itsDetails = location.pathname?.split('/').includes('detalhes');
    const lengthPath = !itsDetails ? location.pathname.split('/').length - 1 : 2;
    const lastNameMenu = location.pathname.split('/')[lengthPath];
    const activeMenu = menuActive === data?.state;
    const menusWithSubmenus = ["/cadastro", "/solicitacoes", "/auxiliares"];

    const handleButton = (state: String, pathname: any) => {
        setMenu(state);
        if (!menusWithSubmenus.includes(pathname)) {
            history.push(pathname);
        }
    }

    useEffect(() => {
        setMenu(lastNameMenu);
    }, []);

    const onClick = (state: any) => {
        setSubMenu(state);
        setEtapa(0);
        setPaginaFiltro(0);
    }

    return (
        <div key={chave}>
            <div onClick={() => handleButton(data?.state, data.path)} className={`w-full flex flex-row h-12 font-mont-bold text-xl text-left hover:bg-gray-ligth-system-mega-imoveis hover:text-blue-dark-mega-imoveis cursor-pointer`} >
                <div className={`h-auto ${activeMenu ? 'bg-red-mega-imoveis' : ''}`} style={{ width: '1rem' }} />
                <div className={`flex w-full h-full ${activeMenu ? 'bg-blue-dark-mega-imoveis text-white' : ''} items-center justify-items-center gap-2`}>
                    <div className={`flex items-center ml-2`} >
                        {icon(data.sidebarProps.icon, `${activeMenu ? ' text-white' : ''}`)}
                    </div>
                    <div className={`flex items-center`} >
                        <span className={`ml-2 px-1 ${activeMenu ? ' text-white' : ''}`}>{data?.sidebarProps?.displayText}</span>
                    </div>
                </div>
            </div>
            <div className={`flex flex-col text-base transition-all text-left ${activeMenu ?? active ? 'h-full' : 'h-0 hidden'} text-gray-system-mega-imoveis`}>
                {data?.child?.map((subItem: any, index: number) => {
                    const activeSubMenu = subMenuActive === subItem?.state;
                    return (
                        <NavLink exact to={`${subItem.path}`} key={index} onClick={() => onClick(subItem?.state)} className={`w-full flex flex-row h-12 ${activeSubMenu ? 'bg-gray-ligth-system-mega-imoveis text-blue-dark-mega-imoveis' : ''} hover:bg-gray-ligth-system-mega-imoveis hover:text-blue-dark-mega-imoveis`} >
                            <div className={`flex w-full h-full items-center justify-items-center gap-2`}>
                                <div className={`flex items-center`} >
                                    <span className={`ml-12 px-1`}>{subItem?.sidebarProps?.displayText}</span>
                                </div>
                            </div>
                        </NavLink>
                    );
                })}
            </div>
        </div>

    );
}

export default SidebarItem;