import React, { createContext, useContext, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import JSZip from 'jszip';
import ComponentToPDF from 'src/pages/Cadastros/Imovel/ExportPDF/ComponentToPdf';

const ControllImovelContext = createContext({});

interface IControllContextMenuProps {
    children: React.ReactNode
}

const ControllImovelProvider: React.FC<IControllContextMenuProps> = React.memo(({ children }) => {
    const [imovelPDF, setImovelPDF] = useState<any>();

    const exportImovelPDF = async () => {
        const element = document.createElement('div');
        const componentHTML = ReactDOMServer.renderToString(<ComponentToPDF imovel={imovelPDF} />);
        element.innerHTML = componentHTML;

        const pageBreakElement = document.createElement('div');
        pageBreakElement.classList.add('html2pdf__page-break');
        element.appendChild(pageBreakElement);

        const options = {
            filename: `Ficha Imovel - ${imovelPDF?.matricula_imovel}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };

        html2pdf().set(options).from(element).save();
    };

    const downloadImovelFiles = async () => {
        var urls: { [key: string]: string } = {};
        urls["Cópia da Matrícula, Escritura ou Contrato de Compra e Venda do Imóvel"] = "https://" + window.location.hostname + "/storage/images/" + imovelPDF?.arquivo_matricula_escritura?.nome
        urls["Água"] = "https://" + window.location.hostname + "/storage/images/" + imovelPDF?.arquivo_agua?.nome
        urls["Gás"] = "https://" + window.location.hostname + "/storage/images/" + imovelPDF?.arquivo_gas?.nome
        urls["Condomínio"] = "https://" + window.location.hostname + "/storage/images/" + imovelPDF?.arquivo_condominio?.nome
        urls["IPTU"] = "https://" + window.location.hostname + "/storage/images/" + imovelPDF?.arquivo_iptu?.nome
        urls["Energia Elétrica"] = "https://" + window.location.hostname + "/storage/images/" + imovelPDF?.arquivo_arquivo_energia?.nome

        const zip = new JSZip();

        try {
            const promises = Object.keys(urls).map(async (key, index) => {
                const url = urls[key];
                if (url != "https://" + window.location.hostname + "/storage/images/undefined") {
                    const response = await fetch(url);
                    if (!response.ok) {
                        throw new Error('Erro ao baixar o arquivo: ' + response.status);
                    }
                    const blob = await response.blob();
                    const fileName = key + "." + url.substring(url.lastIndexOf('.') + 1);
                    zip.file(fileName, blob);
                }
            });

            await Promise.all(promises);

            const content = await zip.generateAsync({ type: 'blob' });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(content);
            link.download = `Arquivos Imovel - ${imovelPDF?.informacoes_basicas?.nome_cliente}.zip`;
            link.click();

            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Erro ao criar o arquivo zip:', error);
        }
    }

    return (
        <ControllImovelContext.Provider value={{
            imovelPDF,
            setImovelPDF,
            exportImovelPDF,
            downloadImovelFiles
        }}>
            {children}
        </ControllImovelContext.Provider>
    );
});

function useControlContextImovel(): any {
    const context = useContext(ControllImovelContext);
    if (!context) {
        throw new Error("useControll must a be used with ControllImovelProvider");
    }
    return context;
}

export { ControllImovelProvider, useControlContextImovel };
