import React from 'react';
import { formatarValor } from 'src/components/Basicos/funcoes';
import { dateLaravelToPtBr, dateToPtBr, mask } from 'src/helpers/functions';
import './style.css';

type Props = {
    infos?: any;
    conjuge?: any;
    timeline?: any;
}

const ModalPF: React.FC<Props> = ({
    infos,
    conjuge,
    timeline
}: Props): JSX.Element => {
    return (
        <div className={conjuge != null ? `modal-analise-financeira-filho-1` : `modal-analise-financeira-filho-2`}>
            <div className='flex header-box mt-2 mb-2'>
                <div className={`box-analise-financeira-${infos?.analise_financeira?.restricao == false ? '1' : '2'}`}>
                    <h1>{infos?.analise_financeira?.restricao == true ? 'Com Restrição' : 'Sem Restrição'}</h1>
                </div>
                <div className={`box-analise-financeira-${infos?.aprovado_score ?? timeline?.aprovado_score ? '1' : '2'}`}>
                    <h1>Score: {infos?.analise_financeira?.score == 0 ? infos?.analise_financeira?.score + '0' : infos?.analise_financeira?.score}</h1>
                </div>
                <div className={`box-analise-financeira-${infos?.aprovado_renda ?? timeline?.aprovado_renda ? '1' : '2'}`}>
                    <h1>Renda: {formatarValor(infos?.atividade_profissional?.renda_mensal, "dinheiro")}</h1>
                </div>
            </div>
            <div className='flex mt-5'>
                <h1>Nome:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.nome}</h1>
            </div>
            <div className='flex'>
                <h1>Data de Nascimento:</h1>
                <h1 className='ml-2 cor-cinza'>{dateToPtBr(infos?.analise_financeira?.data_nascimento)}</h1>
                <h1 className='ml-2'>Telefone:</h1>
                {/* <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.telefone_ddd}{infos?.analise_financeira?.numero_celular}</h1> */}

                <h1 className='ml-2 cor-cinza'>{mask(infos?.analise_financeira?.telefone_ddd != null ? infos?.analise_financeira?.telefone_ddd + infos?.analise_financeira?.numero_celular : infos?.analise_financeira?.numero_celular, "telefone")}</h1>
            </div>
            <div className='flex'>
                <h1>Idade:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.idade}</h1>
                <h1 className='ml-2'>Sexo:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.sexo}</h1>
                <h1 className='ml-2'>Pessoa Estrangeira:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.pessoa_estrangeira == true ? "Sim" : "Não"}</h1>
            </div>
            <div className='borda-analise mb-3 mt-3'></div>
            <div className='flex'>
                <h1>Região:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.regiao}</h1>
            </div>
            <div className='flex'>
                <h1>Situação:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.situacao}</h1>
            </div>
            <div className='flex'>
                <h1>Data Situação:</h1>
                <h1 className='ml-2 cor-cinza'>{dateLaravelToPtBr(infos?.analise_financeira?.data_situacao)}</h1>
            </div>
            <div className='borda-analise mb-3 mt-3'></div>
            <div className='flex'>
                <h1>Logradouro:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.endereco?.logradouro}</h1>
            </div>
            <div className='flex'>
                <h1>Número:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.endereco?.numero}</h1>
                <h1 className='ml-2'>Complemento:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.endereco?.complemento}</h1>
            </div>
            <div className='flex'>
                <h1>Bairro:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.endereco?.bairro}</h1>
            </div>
            <div className='flex'>
                <h1>CEP:</h1>
                <h1 className='ml-2 cor-cinza'>{mask(infos?.analise_financeira?.endereco?.cep, "cep")}</h1>
                <h1 className='ml-2'>Cidade:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.endereco?.cidade}</h1>
                <h1 className='ml-2'>Estado:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.endereco?.estado}</h1>
            </div>
            <div className='borda-analise mb-3 mt-3'></div>
            <div className='flex'>
                <h1>Data da Consulta:</h1>
                <h1 className='ml-2 cor-cinza'>{dateLaravelToPtBr(infos?.analise_financeira?.data_consulta)}</h1>
            </div>
            <div className='flex'>
                <h1>Quantidade Dias Consultados:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.quantidade_dias_consultados}</h1>
            </div>
            <div className='borda-analise mb-3 mt-3'></div>
            <div className='flex'>
                <h1>Classe:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.classe}</h1>
                <h1 className='ml-2'>Horizonte:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.horizonte}</h1>
                <h1 className='ml-2'>Probabilidade:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.probabilidade}</h1>
            </div>
            <div className='flex'>
                <h1>Tipo Score:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.tipo_score}</h1>
            </div>
            {infos?.analise_financeira?.restricoes_pf?.length > 0 ?
                <>
                    <div className='borda-analise mb-3 mt-3'></div>
                    <h1 className='label-restricao estilo-fonte-negrito'>Restrições</h1>
                    {
                        infos?.analise_financeira?.restricoes_pf?.map((item: any) => {
                            return (
                                <>
                                    <div className='flex'>
                                        <h1>Nome Associado:</h1>
                                        <h1 className='ml-2 cor-cinza'>{item?.nome_associado}</h1>
                                        <h1 className='ml-2'>Código Entidade:</h1>
                                        <h1 className='ml-2 cor-cinza'>{item?.codigo_entidade}</h1>
                                    </div>
                                    <div className='flex'>
                                        <h1>Comprador Fiador Avalista:</h1>
                                        <h1 className='ml-2 cor-cinza'>{item?.comprador_fiador_avalista}</h1>
                                        <h1 className='ml-2'>Contrato:</h1>
                                        <h1 className='ml-2 cor-cinza'>{item?.contrato}</h1>
                                    </div>
                                    <div className='flex'>
                                        <h1>Data de Inclusão:</h1>
                                        <h1 className='ml-2 cor-cinza'>{dateToPtBr(item?.data_inclusao)}</h1>
                                        <h1 className='ml-2'>Data de Vencimento:</h1>
                                        <h1 className='ml-2 cor-cinza'>{dateToPtBr(item?.data_vencimento)}</h1>
                                    </div>
                                    <div className='flex'>
                                        <h1>Nome Entidade:</h1>
                                        <h1 className='ml-2 cor-cinza'>{item?.nome_entidade}</h1>
                                        <h1 className='ml-2'>Registro Instituição Financeira:</h1>
                                        <h1 className='ml-2 cor-cinza'>{item?.registro_instituicao_financeira}</h1>
                                    </div>
                                    <div className='flex'>
                                        <h1>Valor:</h1>
                                        <h1 className='ml-2 cor-cinza'>{formatarValor(item?.valor, "dinheiro")}</h1>
                                        <h1 className='ml-2'>Conta Contrato:</h1>
                                        <h1 className='ml-2 cor-cinza'>{item?.conta_contrato}</h1>
                                    </div>
                                    <div className='borda-analise mb-3 mt-3'></div>
                                </>
                            );
                        })
                    }
                </>
                : <div className='borda-analise mb-3 mt-3'></div>}
            <div>
                <h1>Mensagem Interpretativa</h1>
                <textarea
                    name="mensagem"
                    readOnly={true}
                    value={infos?.analise_financeira?.mesagem_interpretativa ?? ""}
                    className="modal-textarea-analise-financeira"
                ></textarea>
            </div>
        </div>
    );
}

export default ModalPF;