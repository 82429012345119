import React from "react";
import { BiCalendarAlt } from "react-icons/bi";
import { FaPercent } from "react-icons/fa";
import { FiClock } from "react-icons/fi";
import { ClassicProps } from "src/@types/types";
import { Title } from "src/components";

export type Props = {
  onChange?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  onKeyUp?: ((e: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
  onKeyDown?: ((e: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
  onKeyPress?: ((e: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
  onClick?: any;
  onBlur?: any;
  colClassname?: string;
  titleClassname?: string;
  inputTitle?: string;
  addTagDescription?: boolean;
  tagDescription?: string;
  pattern?: any;
  patternMsg?: string;
  disabled?: boolean;
  iconClass?: string;
  icon?: string | Array<JSX.Element> | JSX.Element;
  iconStyle?: string;
  iconSize?: number;
  onFocus?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
};

export default function Inputlabel({
  id,
  name,
  className = "bg-gray-50 text-gray-900 text-sm border border-gray-500 rounded-lg focus:ring-blue-500 focus:border-blue-500 px-4 w-full h-10 md:text-base",
  type,
  required,
  placeholder,
  maxLength,
  onChange,
  onKeyUp,
  onClick,
  onKeyDown,
  onKeyPress,
  min,
  max,
  value,
  defaultValue,
  border,
  readOnly,
  colClassname = "col-span-12 md:col-span-6 pb-3",
  titleClassname = "text-base font-bold text-gray-900",
  iconClass = "z-30 text-gray-900 absolute right-0 mr-5 self-center",
  inputTitle,
  onBlur,
  addTagDescription = false,
  tagDescription,
  pattern,
  patternMsg,
  disabled,
  icon,
  iconStyle,
  iconSize = 1,
  onFocus
}: Props & ClassicProps): JSX.Element {

  return (
    <div className={`${colClassname}`}>
      {inputTitle && (
        <Title className={`${titleClassname}`} content={`${inputTitle}`} />
      )}
      
      {addTagDescription && (
        <span className="text-gray-800 ml-2">{tagDescription}</span>
      )}
      {icon ? (
        <div className="flex justify-between relative ">
          <input
            type={type}
            className={`${className} outline-none pr-10 ${border}`}
            id={id}
            name={name}
            required={required}
            autoComplete="off"
            placeholder={placeholder}
            maxLength={maxLength}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            // onChange={onChange}
            min={min}
            max={max}
            value={value}
            defaultValue={defaultValue}
            onKeyPress={onKeyPress}
            disabled={disabled}
            readOnly={readOnly}
            onBlur={onChange}
            pattern={pattern}
            title={patternMsg}
            onClick={onClick}
            onFocus={onFocus}
          />
          {(icon === "date" && (
            <BiCalendarAlt
              className={` ${iconStyle} ${iconClass}`}
              size={`${iconSize}rem`}
            />
          )) ||
            (icon === "%" && (
              <FaPercent
                className={`${iconStyle} ${iconClass}`}
                size={`${iconSize}rem`}
              />
            )) ||
            (icon === "clock" && (
              <FiClock
                className={`${iconStyle} ${iconClass}`}
                size={`${iconSize}rem`}
              />
            )) ||
            icon}
        </div>
      ) : (
          <input
            type={type}
            className={`${className} outline-none ${border}`}
            id={id}
            name={name}
            required={required}
            autoComplete="off"
            placeholder={placeholder}
            maxLength={maxLength}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            // onChange={onChange}
            max={max}
            // value={value}
            defaultValue={defaultValue}
            onKeyPress={onKeyPress}
            disabled={disabled}
            readOnly={readOnly}
            onBlur={onChange}
            pattern={pattern}
            title={patternMsg}
            onFocus={onFocus}
          />
        )}
    </div>
  );
}
