import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { axiosApi } from 'src/services/axiosInstance';

const Logout = ():JSX.Element => {
    useEffect(() => {
        const logout = async () => {
          try {
            await axiosApi.get("auth/logout");
          } catch (error) {
            console.log(error)
          } finally {
            axiosApi.defaults.headers.common["authorization"] = "";
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
          }
        };
        logout();
      }, []);
    
      return (
        <Redirect exact to="/login" />
      );
}
export default Logout;
  