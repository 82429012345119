import React, { useState } from 'react';
import Button from 'src/components/Button';
// import { useControlContextAnaliseFinanceira } from 'src/contexts/AnaliseFinanceiraContext';
import { alertMsg } from 'src/helpers/functions';
import { axiosApi } from 'src/services/axiosInstance';
import Popup from 'reactjs-popup';
import './style.css'

type Props = {
    open: boolean;
    isClose?: any;
    locatario?: any;
    fiador?: any;
    lockScroll?: any;
}

const ModalDesativarLocSecMobile: React.FC<Props> = ({
    isClose,
    open,
    locatario,
    lockScroll,
}: Props): JSX.Element => {
    const [loader, setLoader] = useState<boolean>(false);

    const reloadApi = async () => {
        try {
            setLoader(true)
            var id = locatario?.id;
            await axiosApi.put(`/remover-locatario-secundario/${id}`);

            alertMsg("success", "Locatário Secundário excluído com sucesso!");
            document.location.reload();
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
            isClose();
        }
    }

    return (
        <Popup
            modal
            open={open}
            closeOnDocumentClick={isClose}
            lockScroll={lockScroll}
            onClose={isClose}
        >
            <div className='modal-desativar-mobile'>
                <div className="w-full">
                    <div className="flex">
                        <p className="font-extrabold text-center text-2xl mb-4 font-mont-bold" style={{ color: "#081E52" }}>Deseja realmente excluir esse locatário secundário?</p>
                    </div>

                    <div className="flex flex-row w-full justify-center botoes-modal-api">
                        <button
                            onClick={() => isClose()}
                            className={`flex w-5/12 h-10 p-2 focus:outline-none uppercase font-medium text-xs bg-blue-mega-imoveis text-white cursor-pointer rounded-lg justify-center items-center`}
                        >
                            NÃO
                        </button>

                        <Button
                            type={`submit`}
                            className={`botao-modal-api-mobile`}
                            id={``}
                            name={``}
                            content={`SIM`}
                            onClick={reloadApi}
                            loader={loader}
                            colorLoader={'blue'}
                            styleSpan={'ml-4'} />

                    </div>
                </div>
            </div>
        </Popup>
    );
}

export default ModalDesativarLocSecMobile;