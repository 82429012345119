import React, { Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AnaliseGarantia from "src/components/Basicos/AnaliseGarantia";
import CheckBox from "src/components/Basicos/Form/CheckBox";
import InputValidate from "src/components/Basicos/Form/Input/inputValidate";
import { formatarValor } from "src/components/Basicos/funcoes";
import TitleValidate from "src/components/Basicos/TitleSelectedValidate";
import { alertMsg, mask } from "src/helpers/functions";
import { axiosApi } from "src/services/axiosInstance";
import './style.css'
import HistoricoLocatarioSecundario from "src/components/Basicos/HistoricoLocatarioSecundario";
import { useControlContextMobile } from "src/contexts/MobileContext";
import FormularioCadastroPessoaFisicaMobile from "./indexMobile";
// import { typeFormValue } from "./types";

export type Props = {
    locatario?: any;
    campos?: any;
    handleClick?: any;
    selectSection?: any;
    sections?: any;
    onChange?: any;
    openModal?: any;
    itsView?: boolean;
    etapaId?: any
}

const FormularioCadastroPessoaFisica: React.FunctionComponent<Props> = ({
    locatario,
    campos,
    handleClick,
    selectSection,
    sections,
    onChange,
    openModal,
    itsView,
    etapaId
}) => {
    const { locatarioId }: any = useParams();
    const { mobile } = useControlContextMobile();

    const history = useHistory();

    const existFalse = Object.values(campos).filter(function (item: any) { return item === false }).length > 0;

    //ids dos tipos de atividade que não precisa ter o restante dos campos de Atividade Profissional
    const tipos_atividade_ids = [1, 2, 4, 5, 6, 7];

    //ids das garantias que não precisa ter no restante dos campos de Atividade Profissional
    const garantia_locaticias_ids = [1, 3, 4, 5];

    const rgOrCnh = locatario?.informacoes_basicas?.nacionalidade === null && locatario?.informacoes_basicas?.naturalidade === null ? 'RG' : 'CNH';

    const rgOrCnhConjuge = locatario?.conjuge?.informacoes_basicas?.nacionalidade === null && locatario?.conjuge?.informacoes_basicas?.naturalidade === null ? 'RG' : 'CNH';

    const rgOrCnhProcurador = locatario?.procurador?.informacao_basica?.nacionalidade === null && locatario?.procurador?.informacao_basica?.naturalidade === null ? 'RG' : 'CNH';

    const nameInterpriseOrServicePublic = locatario?.atividade_profissional?.tipo_atividade_id === 5 ? 'Órgão Público' : 'Nome da Empresa';

    const nameInterpriseOrServicePublicConjuge = locatario?.conjuge?.atividade_profissional?.tipo_atividade_id === 5 ? 'Órgão Público' : 'Nome da Empresa';

    const statusNotButton = [2, 5];

    const [addLocatario, setAddLocatario] = useState<boolean>(true);

    let nulos = [null, undefined];

    // const handleStatusCadastro = async (status: string, etapa_id: number) => {
    //     try {
    // if (locatario?.instrucao_reenvio !== "") {
    //     const data = {
    //         locatario_id: locatarioId,
    //         etapa_id: etapa_id,
    //         comentario: null
    //     }
    //     await axiosApi.post(`locatario/status/${status}`, data);
    //     if (status === 'reprovado') {
    //         history.goBack();
    //     }
    // }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const onSubmit = async (status: any, etapa_id: any) => {
        // e.preventDefault();
        try {
            if ((locatario?.instrucao_reenvio == "" || locatario?.instrucao_reenvio == null) && status == "reenviado")
                alertMsg("error", "Necessário informar a Instruções para reenvio!");
            else {
                if (locatarioId !== null) {
                    const ncampos = { ...campos, novo_locatario: addLocatario }
                    await axiosApi.post(`validacao/locatario/${locatarioId}?etapa=${etapaId}`, ncampos).then(async (response) => {
                        const data = {
                            instrucao_reenvio: locatario?.instrucao_reenvio
                        }
                        await axiosApi.put(`locatario-instrucao/${locatarioId}`, data);
                    });
                    if (locatario?.instrucao_reenvio !== "") {
                        const data = {
                            locatario_id: locatarioId,
                            etapa_id: etapa_id,
                            comentario: null
                        }
                        await axiosApi.post(`locatario/status/${status}`, data);
                        if (status === 'reprovado') {
                            history.goBack();
                        }
                    }
                    history.goBack();
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    //No total são 89 campos para validar

    // identificacao no maximo 12 campos    
    // conjuge 27 campos
    // residencia atual 11 campos
    // atividade profissional 20 campos
    // imovel pretendido 16 campos - seção não selecionavel
    // dados do procurador 19 campos


    const itsFull = () => {
        const size = Object.keys(campos ?? null).length;

        // quantidades de campos necessario para ser validado
        // esse número pode variar de acordo com o tipo de garantia e o tipo de atividades e conjuge
        let qtdCamposGeral = 89;

        if (nulos.includes(locatario?.informacoes_basicas?.nome_cliente)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.informacoes_basicas?.telefone1)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.informacoes_basicas?.telefone2)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.informacoes_basicas?.rg_cnh_frente?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.informacoes_basicas?.rg_cnh_verso?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.informacoes_basicas?.cpf)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.informacoes_basicas?.naturalidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.informacoes_basicas?.nacionalidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.informacoes_basicas?.email)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.informacoes_basicas?.estado_civil?.descricao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.informacoes_basicas?.qtd_dependentes)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.garantia?.descricao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.informacoes_basicas.nome_cliente)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.informacoes_basicas.telefone1)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.informacoes_basicas.telefone2)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.informacoes_basicas?.rg_cnh_frente?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.informacoes_basicas?.rg_cnh_verso?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.informacoes_basicas.cpf)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.informacoes_basicas.naturalidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.informacoes_basicas.nacionalidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.informacoes_basicas.email)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.tipo_atividade?.descricao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.outra_atividade_profissional)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.renda_mensal)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.nome_empresa)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.tempo_empresa?.descricao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.cargo_atual)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.arquivo_ctps?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.arquivo_outro?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.endereco?.cep)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.endereco?.logradouro)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.endereco?.numero)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.endereco?.bairro)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.endereco?.cidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.endereco?.estado)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.conjuge?.atividade_profissional?.endereco?.complemento)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.residencia_atual?.tipo_residencia?.descricao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.residencia_atual?.arquivo?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.endereco?.cep)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.endereco?.logradouro)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.endereco?.numero)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.endereco?.bairro)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.endereco?.cidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.endereco?.estado)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.endereco?.complemento)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.residencia_atual?.valor_aluguel)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.residencia_atual?.tempo_locacao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.tipo_atividade?.descricao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.outra_atividade_profissional)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.ramo_atividade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.renda_mensal)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.arquivo_contra_cheque?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.arquivo_contra_cheque2?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.arquivo_contra_cheque3?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.arquivo_ctps?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.arquivo_imposto_renda?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.arquivo_extrato_bancario?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.arquivo_extrato_bancario2?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.arquivo_extrato_bancario3?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.arquivo_outro?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.nome_empresa)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.tempo_empresa?.descricao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.cargo_atual)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.endereco?.cep)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.endereco?.logradouro)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.endereco?.numero)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.endereco?.bairro)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.endereco?.cidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.endereco?.estado)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.atividade_profissional?.endereco?.complemento)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.informacao_basica?.nome_cliente)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.informacao_basica?.telefone1)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.informacao_basica?.telefone2)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.informacao_basica?.rg_cnh_frente?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.informacao_basica?.rg_cnh_verso?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.informacao_basica?.naturalidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.informacao_basica?.nacionalidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.informacao_basica?.profissao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.informacao_basica?.cpf)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.informacao_basica?.email)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.informacao_basica?.estado_civil?.descricao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.documento_procuracao?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.endereco?.cep)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.endereco?.logradouro)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.endereco?.numero)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.endereco?.complemento)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.endereco?.bairro)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.endereco?.cidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(locatario?.procurador?.endereco?.estado)) {
            qtdCamposGeral -= 1;
        }

        return size >= qtdCamposGeral;
    }

    const itsFullIsTrue = () => {
        //arranjo tecnico fino
        if (locatario?.ultimo_historico?.status_validacao_id == 3 && etapaId == 4)
            delete campos.novo_locatario;

        const value = Object.values(campos ?? null);
        return !value.includes(false) && itsFull() && value?.length > 10;
    }

    const onClickLocatarioSecundario = (event: any) => {
        setAddLocatario(!addLocatario)
        handleClick(event.target.id, !addLocatario)
    }

    const fiadorSection = ((fiador: any) => {
        return <div className="flex flex-col gap-4">
            <p className="font-mont-bold text-xl text-gray-system-mega-imoveis mb-2">Fiador</p>
            <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2 ${garantia_locaticias_ids.includes(locatario?.garantia?.id) ? 'hidden' : ''}`}>
                <InputValidate
                    title={`CPF`}
                    name={`cpf_fiador`}
                    value={mask(fiador?.informacoes_basicas?.cpf, "cpf_cnpj") ?? null}
                    onClick={handleClick}
                    status={itsView ? null : campos.cpf_fiador ?? null}
                    itsButton={true}
                />
                <InputValidate
                    title={`Nome do Fiador`}
                    name={`nome_fiador`}
                    value={fiador?.informacoes_basicas?.nome_cliente ?? null}
                    onClick={handleClick}
                    status={itsView ? null : campos.nome_fiador ?? null}
                    itsButton={true}
                />
            </div>
            <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2 ${garantia_locaticias_ids.includes(locatario?.garantia?.id) ? 'hidden' : ''}`}>
                <InputValidate
                    title={`E-mail do Fiador`}
                    name={`email_fiador`}
                    value={fiador?.informacoes_basicas?.email ?? null}
                    onClick={handleClick}
                    status={itsView ? null : campos.email_fiador ?? null}
                    itsButton={true}
                />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                    <InputValidate
                        title={`Telefone 1`}
                        name={`telefone_fiador`}
                        value={mask(fiador?.informacoes_basicas?.telefone1, 'telefone') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.telefone_fiador ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Telefone 2`}
                        name={`telefone2_fiador`}
                        value={mask(fiador?.informacoes_basicas?.telefone2, 'telefone') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.telefone2_fiador ?? null}
                        itsButton={true}
                    />
                </div>
            </div>
            <div className={`flex flex-col mb-8 my-2 gap-2 ${garantia_locaticias_ids.includes(locatario?.garantia?.id) ? 'hidden' : ''}`}>
                {/* <p className="font-extrabold text-lg text-gray-system-mega-imoveis">Responsável pelo preenchimento da Ficha do Fiador*</p> */}
                <div className="flex">
                    <div className="flex items-center mr-4">
                        <input id="inline-radio" type="radio" value="" name={`inline-radio-group1-${fiador?.id}`} className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2" checked={fiador?.responsavel_ficha === "locatario"} />
                        <label htmlFor="inline-radio" className="ml-2 text-base font-semibold text-gray-system-mega-imoveis">Locatário Principal irá responder</label>
                    </div>
                    <div className="flex items-center mr-4">
                        <input id="inline-2-radio" type="radio" value="" name={`inline-radio-group2-${fiador?.id}`} className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2" checked={fiador?.responsavel_ficha === "fiador"} />
                        <label htmlFor="inline-2-radio" className="ml-2 text-base font-semibold text-gray-system-mega-imoveis">Fiador irá responder</label>
                    </div>
                </div>
            </div>
        </div>
    });

    const locatarioSecundarioSection = ((locatarioSecundario: any) => {
        return <div className="flex flex-col gap-4">
            <p className="font-mont-bold text-xl text-gray-system-mega-imoveis mb-2">Locatário Secundário</p>
            <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2`}>
                <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2`}>
                    <InputValidate
                        title={`O Locatário Secundário é`}
                        name={`tipo_locatario_secundario`}
                        value={locatarioSecundario?.tipo_locatario?.descricao ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.tipo_locatario_secundario ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`CPF`}
                        name={`cpf_locatario_secundario`}
                        value={mask(locatarioSecundario?.informacoes_basicas?.cpf, "cpf_cnpj") ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.cpf_locatario_secundario ?? null}
                        itsButton={true}
                    />
                </div>
                <InputValidate
                    title={`Nome do Locatário Secundário`}
                    name={`nome_locatario_secundario`}
                    value={locatarioSecundario?.informacoes_basicas?.nome_cliente ?? null}
                    onClick={handleClick}
                    status={itsView ? null : campos.nome_locatario_secundario ?? null}
                    itsButton={true}
                />
            </div>
            <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2`}>
                <InputValidate
                    title={`E-mail do Locatário Secundário`}
                    name={`email_locatario_secundario`}
                    value={locatarioSecundario?.informacoes_basicas?.email ?? null}
                    onClick={handleClick}
                    status={itsView ? null : campos.email_locatario_secundario ?? null}
                    itsButton={true}
                />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                    <InputValidate
                        title={`Telefone 1`}
                        name={`telefone_locatario_secundario`}
                        value={mask(locatarioSecundario?.informacoes_basicas?.telefone1, 'telefone') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.telefone_locatario_secundario ?? null}
                        itsButton={true}
                    />
                    <InputValidate
                        title={`Telefone 2`}
                        name={`telefone2_locatario_secundario`}
                        value={mask(locatarioSecundario?.informacoes_basicas?.telefone2, 'telefone') ?? null}
                        onClick={handleClick}
                        status={itsView ? null : campos.telefone2_locatario_secundario ?? null}
                        itsButton={true}
                    />
                </div>
            </div>
            <div className={`flex flex-col mb-8 my-2 gap-2`}>
                <div className="flex">
                    <div className="flex items-center mr-4">
                        <input id="inline-radio" type="radio" value="" name={`inline-radio-group1-${locatarioSecundario?.id}`} className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2" checked={locatarioSecundario?.locatario_imovel?.responsavel_ficha_locatario === "principal"} />
                        <label htmlFor="inline-radio" className="ml-2 text-base font-semibold text-gray-system-mega-imoveis">Locatário Principal irá responder</label>
                    </div>
                    <div className="flex items-center mr-4">
                        <input id="inline-2-radio" type="radio" value="" name={`inline-radio-group2-${locatarioSecundario?.id}`} className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2" checked={locatarioSecundario?.locatario_imovel?.responsavel_ficha_locatario === "secundario"} />
                        <label htmlFor="inline-2-radio" className="ml-2 text-base font-semibold text-gray-system-mega-imoveis">Locatário Secundário irá responder</label>
                    </div>
                </div>
            </div>
        </div>
    });

    return (
        <Fragment>
            <div className="p-6">
                {/* <form onSubmit={onSubmit}> */}
                {
                    mobile ? <FormularioCadastroPessoaFisicaMobile
                        locatario={locatario}
                        campos={campos}
                        handleClick={handleClick}
                        selectSection={selectSection}
                        sections={sections}
                        onChange={onChange}
                        openModal={openModal}
                        itsView={itsView}
                        etapaId={etapaId}
                        locatarioId={locatarioId}
                        statusNotButton={statusNotButton}
                        rgOrCnhConjuge={rgOrCnhConjuge}
                        rgOrCnh={rgOrCnh}
                        tipos_atividade_ids={tipos_atividade_ids}
                        nameInterpriseOrServicePublicConjuge={nameInterpriseOrServicePublicConjuge}
                        nulos={nulos}
                        nameInterpriseOrServicePublic={nameInterpriseOrServicePublic}
                        rgOrCnhProcurador={rgOrCnhProcurador}
                        locatarioSecundarioSection={locatarioSecundarioSection}
                        fiadorSection={fiadorSection}
                        addLocatario={addLocatario}
                        onClickLocatarioSecundario={onClickLocatarioSecundario}
                        existFalse={existFalse}
                        itsFullIsTrue={itsFullIsTrue}
                        onSubmit={onSubmit}
                        itsFull={itsFull}
                    /> :
                        <>
                            <div className="bg-white p-10 flex flex-col rounded-lg border-white border h-full w-full" style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>
                                <div className="flex">
                                    <AnaliseGarantia
                                        locatarioId={locatarioId}
                                    />

                                    <HistoricoLocatarioSecundario
                                        locatarioId={locatarioId}
                                    />
                                </div>

                                {/* Identificação */}
                                <div className="border-b border-blue-500">
                                    <TitleValidate
                                        title={'Identificação'}
                                        selectSection={selectSection}
                                        checkSection={sections?.identificacao_pessoa_fisica}
                                        section={0}
                                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                    />
                                    <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                        <div className="flex flex-col gap-4">
                                            <InputValidate
                                                title={`Nome`}
                                                name={`nome_locatario`}
                                                value={locatario?.informacoes_basicas?.nome_cliente ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.nome_locatario ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Telefone 1`}
                                                    name={`telefone_locatario`}
                                                    value={mask(locatario?.informacoes_basicas?.telefone1, 'telefone') ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.telefone_locatario ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Telefone 2`}
                                                    name={`telefone2_locatario`}
                                                    value={mask(locatario?.informacoes_basicas?.telefone2, 'telefone') ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.telefone2_locatario ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <span className="font-extrabold text-lg ">Documento de Identificação Oficial com Foto*</span>
                                            <div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
                                                <InputValidate
                                                    title={`${rgOrCnh} - Frente`}
                                                    name={`rg_cnh_frente_locatario`}
                                                    value={locatario?.informacoes_basicas?.rg_cnh_frente?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    file={locatario?.informacoes_basicas?.rg_cnh_frente?.caminho ?? null}
                                                    status={itsView ? null : campos.rg_cnh_frente_locatario ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`${rgOrCnh} - Verso`}
                                                    name={`rg_cnh_verso_locatario`}
                                                    value={locatario?.informacoes_basicas?.rg_cnh_verso?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    file={locatario?.informacoes_basicas?.rg_cnh_verso?.caminho ?? null}
                                                    status={itsView ? null : campos.rg_cnh_verso_locatario ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`CPF`}
                                                name={`cpf_locatario`}
                                                value={mask(locatario?.informacoes_basicas?.cpf, 'cpf_cnpj') ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.cpf_locatario ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-4">
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Naturalidade`}
                                                    name={`naturalidade_locatario`}
                                                    value={locatario?.informacoes_basicas?.naturalidade ?? null}
                                                    hidden={locatario?.informacoes_basicas?.naturalidade === null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.naturalidade_locatario ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Nacionalidade`}
                                                    name={`nacionalidade_locatario`}
                                                    value={locatario?.informacoes_basicas?.nacionalidade ?? null}
                                                    hidden={locatario?.informacoes_basicas?.nacionalidade === null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.nacionalidade_locatario ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`E-mail`}
                                                name={`email_locatario`}
                                                value={locatario?.informacoes_basicas?.email ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.email_locatario ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Estado Civil`}
                                                    name={`estado_civil_locatario`}
                                                    value={locatario?.informacoes_basicas?.estado_civil?.descricao ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.estado_civil_locatario ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Nº de Dependentes`}
                                                    name={`numb_dependentes_locatario`}
                                                    value={locatario?.informacoes_basicas?.qtd_dependentes ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.numb_dependentes_locatario ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`Garantia Locatícia`}
                                                name={`garantia_locaticia_locatario`}
                                                value={locatario?.garantia?.descricao ?? locatario?.locatario_imovel?.locatario_principal?.garantia?.descricao ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.garantia_locaticia_locatario ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Cônjuge */}
                                <div className={`border-b border-blue-500 mt-12 ${locatario?.conjuge === null ? 'hidden' : ''}`}>
                                    <TitleValidate
                                        title={'Cônjuge'}
                                        selectSection={selectSection}
                                        checkSection={sections?.conjuge ?? null}
                                        section={1}
                                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                    />
                                    <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                        <div className="flex flex-col gap-4">
                                            <InputValidate
                                                title={`Nome`}
                                                name={`nome_conjuge`}
                                                value={locatario?.conjuge?.informacoes_basicas.nome_cliente ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.nome_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Telefone 1`}
                                                    name={`telefone_conjuge`}
                                                    value={mask(locatario?.conjuge?.informacoes_basicas.telefone1, 'telefone') ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.telefone_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Telefone 2`}
                                                    name={`telefone2_conjuge`}
                                                    value={mask(locatario?.conjuge?.informacoes_basicas.telefone2, 'telefone') ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.telefone2_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <span className="font-extrabold text-lg">Documento de Identificação Oficial com Foto*</span>
                                            <div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
                                                <InputValidate
                                                    title={`${rgOrCnhConjuge} - Frente`}
                                                    name={`rg_cnh_frente_conjuge`}
                                                    value={locatario?.conjuge?.informacoes_basicas?.rg_cnh_frente?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    file={locatario?.conjuge?.informacoes_basicas?.rg_cnh_frente?.caminho ?? null}
                                                    status={itsView ? null : campos.rg_cnh_frente_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`${rgOrCnhConjuge} - Verso`}
                                                    name={`rg_cnh_verso_conjuge`}
                                                    value={locatario?.conjuge?.informacoes_basicas?.rg_cnh_verso?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    file={locatario?.conjuge?.informacoes_basicas?.rg_cnh_verso?.caminho ?? null}
                                                    status={itsView ? null : campos.rg_cnh_verso_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-4">
                                            <InputValidate
                                                title={`CPF`}
                                                name={`cpf_conjuge`}
                                                value={mask(locatario?.conjuge?.informacoes_basicas.cpf, 'cpf_cnpj') ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.cpf_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2 ${locatario?.conjuge?.informacoes_basicas?.naturalidade === null ? 'hidden' : ''}`}>
                                                <InputValidate
                                                    title={`Naturalidade`}
                                                    name={`naturalidade_conjuge`}
                                                    value={locatario?.conjuge?.informacoes_basicas.naturalidade ?? null}
                                                    hidden={locatario?.conjuge?.informacoes_basicas?.naturalidade === null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.naturalidade_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Nacionalidade`}
                                                    name={`nacionalidade_conjuge`}
                                                    value={locatario?.conjuge?.informacoes_basicas.nacionalidade ?? null}
                                                    hidden={locatario?.conjuge?.informacoes_basicas?.nacionalidade === null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.nacionalidade_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`E-mail`}
                                                name={`email_conjuge`}
                                                value={locatario?.conjuge?.informacoes_basicas.email ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.email_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                        <div className="flex flex-col gap-4">
                                            <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2 ${!tipos_atividade_ids.includes(locatario?.conjuge?.atividade_profissional?.tipo_atividade_id) ? 'hidden' : ''}`}>
                                                <InputValidate
                                                    title={`Tipo de Atividade Profissional`}
                                                    name={`tipo_atividade_profissional_conjuge`}
                                                    value={locatario?.conjuge?.atividade_profissional?.tipo_atividade?.descricao ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.tipo_atividade_profissional_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Atividade Profissional`}
                                                    name={`atividade_profissional_conjuge`}
                                                    value={locatario?.conjuge?.atividade_profissional?.outra_atividade_profissional ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.atividade_profissional_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.conjuge?.ultimo_historico?.status?.id)}
                                                    hidden={locatario?.conjuge?.atividade_profissional?.tipo_atividade?.id == 1 || locatario?.conjuge?.atividade_profissional?.tipo_atividade?.id == 2}
                                                />
                                                <InputValidate
                                                    title={`Ramo da Atividade`}
                                                    name={`ramo_atividade_conjuge`}
                                                    value={locatario?.conjuge?.atividade_profissional?.ramo_atividade ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos?.ramo_atividade_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.conjuge?.ultimo_historico?.status?.id)}
                                                    hidden={locatario?.conjuge?.atividade_profissional?.tipo_atividade?.id != 2}
                                                />
                                                <InputValidate
                                                    title={`Renda Mensal`}
                                                    name={`renda_mensal_conjuge`}
                                                    value={formatarValor(locatario?.conjuge?.atividade_profissional?.renda_mensal, "dinheiro") ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.renda_mensal_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={nameInterpriseOrServicePublicConjuge}
                                                name={`nome_empresa_conjuge`}
                                                value={locatario?.conjuge?.atividade_profissional?.nome_empresa ?? null}
                                                onClick={handleClick}
                                                hidden={nulos.includes(locatario?.conjuge?.atividade_profissional?.nome_empresa)}
                                                status={itsView ? null : campos.nome_empresa_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2 ${!tipos_atividade_ids.includes(locatario?.conjuge?.atividade_profissional?.tipo_atividade_id) ? 'hidden' : ''}`}>
                                                <InputValidate
                                                    title={`Tempo de Empresa`}
                                                    name={`tempo_empresa_conjuge`}
                                                    value={locatario?.conjuge?.atividade_profissional?.tempo_empresa?.descricao ?? null}
                                                    onClick={handleClick}
                                                    hidden={locatario?.conjuge?.atividade_profissional?.tempo_empresa?.descricao === undefined || locatario?.conjuge?.atividade_profissional?.tipo_atividade?.id == 1 || locatario?.conjuge?.atividade_profissional?.tipo_atividade?.id == 2}
                                                    status={itsView ? null : campos.tempo_empresa_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Cargo Atual`}
                                                    name={`cargo_atual_conjuge`}
                                                    value={locatario?.conjuge?.atividade_profissional?.cargo_atual ?? null}
                                                    onClick={handleClick}
                                                    hidden={nulos.includes(locatario?.conjuge?.atividade_profissional?.cargo_atual)}
                                                    status={itsView ? null : campos.cargo_atual_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`Último Extrato do Cartão de Crédito`}
                                                name={`extrato_cartao_credito_conjuge`}
                                                value={locatario?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho === undefined}
                                                file={locatario?.conjuge?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho ?? null}
                                                status={itsView ? null : campos.extrato_cartao_credito_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <p className={`font-extrabold text-lg text-gray-system-mega-imoveis ml-6`}>{locatario?.conjuge?.atividade_profissional?.arquivo_outro?.caminho !== undefined
                                                || locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.caminho !== undefined
                                                || locatario?.conjuge?.atividade_profissional?.arquivo_ctps?.caminho !== undefined
                                                || locatario?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.caminho !== undefined ? 'Comprovante de Renda*' : ''}</p>
                                            <InputValidate
                                                title={`Contracheque`}
                                                name={`contracheque_conjuge`}
                                                value={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.caminho === undefined}
                                                file={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.caminho ?? null}
                                                status={itsView ? null : campos.contracheque_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Contracheque 2`}
                                                name={`contracheque2_conjuge`}
                                                value={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.caminho === undefined}
                                                file={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.caminho ?? null}
                                                status={itsView ? null : campos.contracheque2_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Contracheque 3`}
                                                name={`contracheque3_conjuge`}
                                                value={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.caminho === undefined}
                                                file={locatario?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.caminho ?? null}
                                                status={itsView ? null : campos.contracheque3_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`CTPS`}
                                                name={`carteira_trabalho_conjuge`}
                                                value={locatario?.conjuge?.atividade_profissional?.arquivo_ctps?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.conjuge?.atividade_profissional?.arquivo_ctps?.caminho === undefined}
                                                file={locatario?.conjuge?.atividade_profissional?.arquivo_ctps?.caminho ?? null}
                                                status={itsView ? null : campos.carteira_trabalho_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Imposto de Renda`}
                                                name={`imposto_renda_conjuge`}
                                                value={locatario?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.caminho === undefined}
                                                file={locatario?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.caminho ?? null}
                                                status={itsView ? null : campos.imposto_renda_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Extrato Bancário`}
                                                name={`extrato_bancario_conjuge`}
                                                value={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.caminho === undefined}
                                                file={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.caminho ?? null}
                                                status={itsView ? null : campos.extrato_bancario_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Extrato Bancário 2`}
                                                name={`extrato_bancario2_conjuge`}
                                                value={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.caminho === undefined}
                                                file={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.caminho ?? null}
                                                status={itsView ? null : campos.extrato_bancario2_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Extrato Bancário 3`}
                                                name={`extrato_bancario3_conjuge`}
                                                value={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.caminho === undefined}
                                                file={locatario?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.caminho ?? null}
                                                status={itsView ? null : campos.extrato_bancario3_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Outro`}
                                                name={`outro_conjuge`}
                                                value={locatario?.conjuge?.atividade_profissional?.arquivo_outro?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.conjuge?.atividade_profissional?.arquivo_outro?.caminho === undefined}
                                                file={locatario?.conjuge?.atividade_profissional?.arquivo_outro?.caminho ?? null}
                                                status={itsView ? null : campos.outro_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                        </div>
                                        <div className={`flex flex-col gap-4`}>
                                            <InputValidate
                                                title={`CEP`}
                                                name={`cep_empresa_atual_conjuge`}
                                                value={mask(locatario?.conjuge?.atividade_profissional?.endereco?.cep, 'cep') ?? null}
                                                onClick={handleClick}
                                                hidden={locatario?.conjuge?.atividade_profissional?.endereco?.cep === undefined}
                                                status={itsView ? null : campos.cep_empresa_atual_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Logradouro`}
                                                    name={`logradouro_empresa_atual_conjuge`}
                                                    value={locatario?.conjuge?.atividade_profissional?.endereco?.logradouro ?? null}
                                                    onClick={handleClick}
                                                    hidden={locatario?.conjuge?.atividade_profissional?.endereco?.logradouro === undefined}
                                                    status={itsView ? null : campos.logradouro_empresa_atual_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Número`}
                                                    name={`numero_empresa_atual_conjuge`}
                                                    value={locatario?.conjuge?.atividade_profissional?.endereco?.numero ?? null}
                                                    onClick={handleClick}
                                                    hidden={locatario?.conjuge?.atividade_profissional?.endereco?.numero === undefined}
                                                    status={itsView ? null : campos.numero_empresa_atual_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`Bairro`}
                                                name={`bairro_empresa_atual_conjuge`}
                                                value={locatario?.conjuge?.atividade_profissional?.endereco?.bairro ?? null}
                                                onClick={handleClick}
                                                hidden={locatario?.conjuge?.atividade_profissional?.endereco?.bairro === undefined}
                                                status={itsView ? null : campos.bairro_empresa_atual_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Cidade`}
                                                    name={`cidade_empresa_atual_conjuge`}
                                                    value={locatario?.conjuge?.atividade_profissional?.endereco?.cidade ?? null}
                                                    onClick={handleClick}
                                                    hidden={locatario?.conjuge?.atividade_profissional?.endereco?.cidade === undefined}
                                                    status={itsView ? null : campos.cidade_empresa_atual_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Estado`}
                                                    name={`estado_empresa_atual_conjuge`}
                                                    value={locatario?.conjuge?.atividade_profissional?.endereco?.estado ?? null}
                                                    onClick={handleClick}
                                                    hidden={locatario?.conjuge?.atividade_profissional?.endereco?.estado === undefined}
                                                    status={itsView ? null : campos.estado_empresa_atual_conjuge ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`Complemento`}
                                                name={`complemento_empresa_atual_conjuge`}
                                                value={locatario?.conjuge?.atividade_profissional?.endereco?.complemento ?? null}
                                                onClick={handleClick}
                                                hidden={locatario?.conjuge?.atividade_profissional?.endereco?.complemento === undefined || locatario?.conjuge?.atividade_profissional?.endereco?.complemento === null}
                                                status={itsView ? null : campos.complemento_empresa_atual_conjuge ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Residência Atual - 11 campos*/}
                                <div className={`border-b border-blue-500 mt-12 ${locatario?.residencia_atual === null ? 'hidden' : ''}`}>
                                    <TitleValidate
                                        title={'Residência Atual'}
                                        selectSection={selectSection}
                                        checkSection={sections?.residencia_atual}
                                        section={2}
                                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                    />
                                    <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                        <div className="flex flex-col gap-4">
                                            <InputValidate
                                                title={`Sua Residência é`}
                                                name={`sua_residencia_atual_e`}
                                                value={locatario?.residencia_atual?.tipo_residencia?.descricao ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.sua_residencia_atual_e ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Comprovante de Endereço`}
                                                name={`comprovante_endereco_residencia_atual`}
                                                value={locatario?.residencia_atual?.arquivo?.nome ?? null}
                                                isFile={true}
                                                file={locatario?.residencia_atual?.arquivo?.caminho ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.comprovante_endereco_residencia_atual ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`CEP`}
                                                name={`cep_residencia_atual`}
                                                value={mask(locatario?.endereco?.cep, 'cep') ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.cep_residencia_atual ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Logradouro`}
                                                    name={`logradouro_residencia_atual`}
                                                    value={locatario?.endereco?.logradouro ?? null}

                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.logradouro_residencia_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Número`}
                                                    name={`numero_residencia_atual`}
                                                    value={locatario?.endereco?.numero ?? null}

                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.numero_residencia_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>

                                        </div>
                                        <div className="flex flex-col gap-4">
                                            <InputValidate
                                                title={`Bairro`}
                                                name={`bairro_residencia_atual`}
                                                value={locatario?.endereco?.bairro ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.bairro_residencia_atual ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Cidade`}
                                                    name={`cidade_residencia_atual`}
                                                    value={locatario?.endereco?.cidade ?? null}

                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.cidade_residencia_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Estado`}
                                                    name={`estado_residencia_atual`}
                                                    value={locatario?.endereco?.estado ?? null}

                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.estado_residencia_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`Complemento`}
                                                name={`complemento_residencia_atual`}
                                                value={locatario?.endereco?.complemento ?? null}
                                                onClick={handleClick}
                                                hidden={locatario?.endereco?.complemento === null}
                                                status={itsView ? null : campos.complemento_residencia_atual ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Valor do Aluguel`}
                                                    name={`valor_aluguel_residencia_atual`}
                                                    value={formatarValor(locatario?.residencia_atual?.valor_aluguel, "dinheiro") ?? null}
                                                    hidden={locatario?.residencia_atual?.valor_aluguel === null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.valor_aluguel_residencia_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Tempo de Locação`}
                                                    name={`tempo_locacao_residencia_atual`}
                                                    value={locatario?.residencia_atual?.tempo_locacao ?? null}
                                                    hidden={locatario?.residencia_atual?.tempo_locacao === null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.tempo_locacao_residencia_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Atividade Profissional - 20 campos */}
                                <div className={`border-b border-blue-500 mt-12 ${locatario?.atividade_profissional === null ? 'hidden' : ''}`}>
                                    <TitleValidate
                                        title={'Atividade Profissional'}
                                        selectSection={selectSection}
                                        checkSection={sections.atividade_profissional}
                                        section={3}
                                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                    />
                                    <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                        <div className="flex flex-col gap-4">
                                            <InputValidate
                                                title={`Tipo de Atividade Profissional`}
                                                name={`tipo_atividade_profissional`}
                                                value={locatario?.atividade_profissional?.tipo_atividade?.descricao ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.tipo_atividade_profissional ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Atividade Profissional`}
                                                name={`atividade_profissional`}
                                                value={locatario?.atividade_profissional?.outra_atividade_profissional ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos?.atividade_profissional ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                hidden={locatario?.atividade_profissional?.tipo_atividade?.id !== 6 && locatario?.atividade_profissional?.tipo_atividade?.id !== 7}
                                            />
                                            <InputValidate
                                                title={`Ramo da Atividade`}
                                                name={`ramo_atividade`}
                                                value={locatario?.atividade_profissional?.ramo_atividade ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos?.ramo_atividade ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                hidden={locatario?.atividade_profissional?.ramo_atividade === null}
                                            />
                                            <InputValidate
                                                title={`Renda Mensal`}
                                                name={`renda_mensal`}
                                                value={formatarValor(locatario?.atividade_profissional?.renda_mensal, "dinheiro") ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.renda_mensal ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Último Extrato do Cartão de Crédito`}
                                                name={`extrato_cartao_credito`}
                                                value={locatario?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho === undefined}
                                                file={locatario?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.caminho ?? null}
                                                status={itsView ? null : campos.extrato_cartao_credito ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <p className={`font-extrabold text-lg text-gray-system-mega-imoveis ml-6`}>{locatario?.atividade_profissional?.arquivo_outro?.caminho !== undefined
                                                || locatario?.atividade_profissional?.arquivo_contra_cheque?.caminho !== undefined
                                                || locatario?.atividade_profissional?.arquivo_ctps?.caminho !== undefined
                                                || locatario?.atividade_profissional?.arquivo_imposto_renda?.caminho !== undefined ? 'Comprovante de Renda*' : ''}</p>
                                            <InputValidate
                                                title={`Contracheque`}
                                                name={`contracheque`}
                                                value={locatario?.atividade_profissional?.arquivo_contra_cheque?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.atividade_profissional?.arquivo_contra_cheque?.caminho === undefined}
                                                file={locatario?.atividade_profissional?.arquivo_contra_cheque?.caminho ?? null}
                                                status={itsView ? null : campos.contracheque ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Contracheque 2`}
                                                name={`contracheque2`}
                                                value={locatario?.atividade_profissional?.arquivo_contra_cheque2?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.atividade_profissional?.arquivo_contra_cheque2?.caminho === undefined}
                                                file={locatario?.atividade_profissional?.arquivo_contra_cheque2?.caminho ?? null}
                                                status={itsView ? null : campos.contracheque2 ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Contracheque 3`}
                                                name={`contracheque3`}
                                                value={locatario?.atividade_profissional?.arquivo_contra_cheque3?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.atividade_profissional?.arquivo_contra_cheque3?.caminho === undefined}
                                                file={locatario?.atividade_profissional?.arquivo_contra_cheque3?.caminho ?? null}
                                                status={itsView ? null : campos.contracheque3 ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`CTPS`}
                                                name={`carteira_trabalho`}
                                                value={locatario?.atividade_profissional?.arquivo_ctps?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.atividade_profissional?.arquivo_ctps?.caminho === undefined}
                                                file={locatario?.atividade_profissional?.arquivo_ctps?.caminho ?? null}
                                                status={itsView ? null : campos.carteira_trabalho ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Imposto de Renda`}
                                                name={`imposto_renda`}
                                                value={locatario?.atividade_profissional?.arquivo_imposto_renda?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.atividade_profissional?.arquivo_imposto_renda?.caminho === undefined}
                                                file={locatario?.atividade_profissional?.arquivo_imposto_renda?.caminho ?? null}
                                                status={itsView ? null : campos.imposto_renda ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Extrato Bancário`}
                                                name={`extrato_bancario`}
                                                value={locatario?.atividade_profissional?.arquivo_extrato_bancario?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.atividade_profissional?.arquivo_extrato_bancario?.caminho === undefined}
                                                file={locatario?.atividade_profissional?.arquivo_extrato_bancario?.caminho ?? null}
                                                status={itsView ? null : campos.extrato_bancario ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Extrato Bancário 2`}
                                                name={`extrato_bancario2`}
                                                value={locatario?.atividade_profissional?.arquivo_extrato_bancario2?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.atividade_profissional?.arquivo_extrato_bancario2?.caminho === undefined}
                                                file={locatario?.atividade_profissional?.arquivo_extrato_bancario2?.caminho ?? null}
                                                status={itsView ? null : campos.extrato_bancario2 ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Extrato Bancário 3`}
                                                name={`extrato_bancario3`}
                                                value={locatario?.atividade_profissional?.arquivo_extrato_bancario3?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.atividade_profissional?.arquivo_extrato_bancario3?.caminho === undefined}
                                                file={locatario?.atividade_profissional?.arquivo_extrato_bancario3?.caminho ?? null}
                                                status={itsView ? null : campos.extrato_bancario3 ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Outro`}
                                                name={`outro`}
                                                value={locatario?.atividade_profissional?.arquivo_outro?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={locatario?.atividade_profissional?.arquivo_outro?.caminho === undefined}
                                                file={locatario?.atividade_profissional?.arquivo_outro?.caminho ?? null}
                                                status={itsView ? null : campos.outro ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />

                                            <InputValidate
                                                title={nameInterpriseOrServicePublic}
                                                name={`nome_empresa`}
                                                value={locatario?.atividade_profissional?.nome_empresa ?? null}
                                                onClick={handleClick}
                                                hidden={locatario?.atividade_profissional?.nome_empresa === null}
                                                status={itsView ? null : campos.nome_empresa ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2 ${tipos_atividade_ids.includes(locatario?.atividade_profissional?.tipo_atividade_id) ? 'hidden' : ''}`}>
                                                <InputValidate
                                                    title={`Tempo de Empresa`}
                                                    name={`tempo_empresa`}
                                                    value={locatario?.atividade_profissional?.tempo_empresa?.descricao ?? null}
                                                    onClick={handleClick}
                                                    hidden={locatario?.atividade_profissional?.tempo_empresa?.descricao === undefined}
                                                    status={itsView ? null : campos.tempo_empresa ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Cargo Atual`}
                                                    name={`cargo_atual`}
                                                    value={locatario?.atividade_profissional?.cargo_atual ?? null}
                                                    onClick={handleClick}
                                                    hidden={locatario?.atividade_profissional?.cargo_atual === null}
                                                    status={itsView ? null : campos.cargo_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>

                                        </div>
                                        <div className={`flex flex-col gap-4`}>
                                            <InputValidate
                                                title={`CEP`}
                                                name={`cep_empresa_atual`}
                                                value={mask(locatario?.atividade_profissional?.endereco?.cep, 'cep') ?? null}
                                                onClick={handleClick}
                                                hidden={locatario?.atividade_profissional?.endereco?.cep === undefined}
                                                status={itsView ? null : campos.cep_empresa_atual ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Logradouro`}
                                                    name={`logradouro_empresa_atual`}
                                                    value={locatario?.atividade_profissional?.endereco?.logradouro ?? null}
                                                    onClick={handleClick}
                                                    hidden={locatario?.atividade_profissional?.endereco?.logradouro === undefined}
                                                    status={itsView ? null : campos.logradouro_empresa_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Número`}
                                                    name={`numero_empresa_atual`}
                                                    value={locatario?.atividade_profissional?.endereco?.numero ?? null}
                                                    onClick={handleClick}
                                                    hidden={locatario?.atividade_profissional?.endereco?.numero === undefined}
                                                    status={itsView ? null : campos.numero_empresa_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`Bairro`}
                                                name={`bairro_empresa_atual`}
                                                value={locatario?.atividade_profissional?.endereco?.bairro ?? null}
                                                onClick={handleClick}
                                                hidden={locatario?.atividade_profissional?.endereco?.bairro === undefined}
                                                status={itsView ? null : campos.bairro_empresa_atual ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Cidade`}
                                                    name={`cidade_empresa_atual`}
                                                    value={locatario?.atividade_profissional?.endereco?.cidade ?? null}
                                                    onClick={handleClick}
                                                    hidden={locatario?.atividade_profissional?.endereco?.cidade === undefined}
                                                    status={itsView ? null : campos.cidade_empresa_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Estado`}
                                                    name={`estado_empresa_atual`}
                                                    value={locatario?.atividade_profissional?.endereco?.estado ?? null}
                                                    onClick={handleClick}
                                                    hidden={locatario?.atividade_profissional?.endereco?.estado === undefined}
                                                    status={itsView ? null : campos.estado_empresa_atual ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`Complemento`}
                                                name={`complemento_empresa_atual`}
                                                value={locatario?.atividade_profissional?.endereco?.complemento ?? null}
                                                onClick={handleClick}
                                                hidden={locatario?.atividade_profissional?.endereco?.complemento === null || locatario?.atividade_profissional?.endereco?.complemento === undefined}
                                                status={itsView ? null : campos.complemento_empresa_atual ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Dados do Procurador - 19 campos*/}
                                <div className={`border-b border-blue-500 mt-12 ${locatario?.procurador === undefined || locatario?.procurador === null ? 'hidden' : ''}`}>
                                    <TitleValidate
                                        title={'Dados do Procurador'}
                                        selectSection={selectSection}
                                        checkSection={sections?.procurador ?? null}
                                        section={8}
                                        itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                    />
                                    <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                        <div className="flex flex-col gap-4">
                                            <InputValidate
                                                title={`Nome`}
                                                name={`nome_procurador`}
                                                value={locatario?.procurador?.informacao_basica?.nome_cliente ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.nome_procurador ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Telefone 1`}
                                                    name={`telefone_procurador`}
                                                    value={mask(locatario?.procurador?.informacao_basica?.telefone1, 'telefone') ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.telefone_procurador ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Telefone 2`}
                                                    name={`telefone2_procurador`}
                                                    value={mask(locatario?.procurador?.informacao_basica?.telefone2, 'telefone') ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.telefone2_procurador ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <span className="font-extrabold text-lg">Documento de Identificação Oficial com Foto*</span>
                                            <div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
                                                <InputValidate
                                                    title={`${rgOrCnhProcurador} - Frente`}
                                                    name={`rg_cnh_frente_procurador`}
                                                    value={locatario?.procurador?.informacao_basica?.rg_cnh_frente?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    file={locatario?.procurador?.informacao_basica?.rg_cnh_frente?.caminho ?? null}
                                                    status={itsView ? null : campos.rg_cnh_frente_procurador ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`${rgOrCnhProcurador} - Verso`}
                                                    name={`rg_cnh_verso_procurador`}
                                                    value={locatario?.procurador?.informacao_basica?.rg_cnh_verso?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    file={locatario?.procurador?.informacao_basica?.rg_cnh_verso?.caminho ?? null}
                                                    status={itsView ? null : campos.rg_cnh_verso_procurador ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>

                                        </div>
                                        <div className="flex flex-col gap-4">
                                            <div className={`grid grid-cols-1 lg:grid-cols-2 gap-2 ${locatario?.procurador?.informacao_basica?.nacionalidade === null ? 'hidden' : ''}`}>
                                                <InputValidate
                                                    title={`Naturalidade`}
                                                    name={`naturalidade_procurador`}
                                                    value={locatario?.procurador?.informacao_basica?.naturalidade ?? null}
                                                    hidden={locatario?.procurador?.informacao_basica?.naturalidade === null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.naturalidade_procurador ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Nacionalidade`}
                                                    name={`nacionalidade_procurador`}
                                                    value={locatario?.procurador?.informacao_basica?.nacionalidade ?? null}
                                                    hidden={locatario?.procurador?.informacao_basica?.nacionalidade === null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.nacionalidade_procurador ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`Profissão`}
                                                name={`profissao_procurador`}
                                                value={locatario?.procurador?.informacao_basica?.profissao ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.profissao_procurador ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`CPF`}
                                                name={`cpf_procurador`}
                                                value={mask(locatario?.procurador?.informacao_basica?.cpf, 'cpf_cnpj') ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.cpf_procurador ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`E-mail`}
                                                name={`email_procurador`}
                                                value={locatario?.procurador?.informacao_basica?.email ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.email_procurador ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Estado Civil`}
                                                name={`estado_civil_procurador`}
                                                value={locatario?.procurador?.informacao_basica?.estado_civil?.descricao ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.estado_civil_procurador ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Documento da Procuração`}
                                                name={`documento_procurador`}
                                                value={locatario?.procurador?.documento_procuracao?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                file={locatario?.procurador?.documento_procuracao?.caminho ?? null}
                                                status={itsView ? null : campos.documento_procurador ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />

                                        </div>
                                        <div className="flex flex-col gap-4">
                                            <InputValidate
                                                title={`CEP`}
                                                name={`cep_procurador`}
                                                value={mask(locatario?.procurador?.endereco?.cep, 'cep') ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.cep_procurador ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Logradouro`}
                                                    name={`logradouro_procurador`}
                                                    value={locatario?.procurador?.endereco?.logradouro ?? null}

                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.logradouro_procurador ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Número`}
                                                    name={`numero_procurador`}
                                                    value={locatario?.procurador?.endereco?.numero ?? null}

                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.numero_procurador ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`Complemento`}
                                                name={`complemento_procurador`}
                                                value={locatario?.procurador?.endereco?.complemento ?? null}
                                                onClick={handleClick}
                                                hidden={locatario?.procurador?.endereco?.complemento === null}
                                                status={itsView ? null : campos.complemento_procurador ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-4">
                                            <InputValidate
                                                title={`Bairro`}
                                                name={`bairro_procurador`}
                                                value={locatario?.procurador?.endereco?.bairro ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.bairro_procurador ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Cidade`}
                                                    name={`cidade_procurador`}
                                                    value={locatario?.procurador?.endereco?.cidade ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.cidade_procurador ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Estado`}
                                                    name={`estado_procurador`}
                                                    value={locatario?.procurador?.endereco?.estado ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.estado_procurador ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(locatario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* Outros Dados - x campos*/}
                                <div className={`border-b border-blue-500 mt-12 ${locatario?.fiador.filter((item: any) => { return item?.status === 1 }).length > 0 || locatario?.locatario_secundario.filter((item: any) => { return item?.principal === false }).length > 0 ? '' : 'hidden'}`}>
                                    <TitleValidate
                                        title={'Outros Dados'}
                                        selectSection={selectSection}
                                        checkSection={sections?.outros_dados ?? null}
                                        section={9}
                                        itsButton={true}
                                    />
                                    {locatario?.locatario_secundario.filter((item: any) => { return item?.principal === false }).map((lo: any) => {
                                        return locatarioSecundarioSection(lo);
                                    })}

                                    {locatario?.fiador.map((item: any) => {
                                        return fiadorSection(item);
                                    })}
                                </div>

                                {/* Imóvel Pretendido */}
                                <div className={`mt-12 ${locatario?.imovel_pretendido === null ? 'hidden' : ''}`}>
                                    <TitleValidate
                                        title={'Imóvel Pretendido'}
                                        selectSection={selectSection}
                                        checkSection={sections?.imovel_pretendido ?? null}
                                        section={4}
                                        itsButton={true}
                                    />
                                    <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                        <div className="flex flex-col gap-4">
                                            <InputValidate
                                                title={`Finalidade`}
                                                name={`finalidade_imovel_pretendido`}
                                                value={locatario?.imovel_pretendido?.finalidade?.descricao ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.finalidade_imovel_pretendido ?? null}
                                                itsButton={true}
                                            />
                                            <InputValidate
                                                title={`Código do Imóvel`}
                                                name={`codigo_imovel_pretendido`}
                                                value={locatario?.imovel_pretendido?.imovel?.codigo_imovel ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.codigo_imovel_pretendido ?? null}
                                                itsButton={true}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Logradouro`}
                                                    name={`logradouro_imovel_pretendido`}
                                                    value={locatario?.imovel_pretendido?.imovel?.endereco ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.logradouro_imovel_pretendido ?? null}
                                                    itsButton={true}
                                                />
                                                <InputValidate
                                                    title={`Número`}
                                                    name={`numero_imovel_pretendido`}
                                                    value={locatario?.imovel_pretendido?.imovel?.numero ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.numero_imovel_pretendido ?? null}
                                                    itsButton={true}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`Complemento`}
                                                name={`complemento_imovel_pretendido`}
                                                value={locatario?.imovel_pretendido?.imovel?.complemento ?? null}
                                                onClick={handleClick}
                                                hidden={locatario?.imovel_pretendido?.imovel?.complemento === null}
                                                status={itsView ? null : campos.complemento_imovel_pretendido ?? null}
                                                itsButton={true}
                                            />
                                            <InputValidate
                                                title={`Bairro`}
                                                name={`bairro_imovel_pretendido`}
                                                value={locatario?.imovel_pretendido?.imovel?.bairro ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.bairro_imovel_pretendido ?? null}
                                                itsButton={true}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Cidade`}
                                                    name={`cidade_imovel_pretendido`}
                                                    value={locatario?.imovel_pretendido?.imovel?.cidade ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.cidade_imovel_pretendido ?? null}
                                                    itsButton={true}
                                                />
                                                <InputValidate
                                                    title={`Estado`}
                                                    name={`estado_imovel_pretendido`}
                                                    value={locatario?.imovel_pretendido?.imovel?.estado ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.estado_imovel_pretendido ?? null}
                                                    itsButton={true}
                                                />
                                            </div>
                                            <div className="grid grid-cols-1 lg:grid-cols-3 gap-1 justify-evenly">
                                                <InputValidate
                                                    title={`Aluguel`}
                                                    name={`aluguel_imovel_pretendido`}
                                                    value={formatarValor(locatario?.imovel_pretendido?.imovel?.preco_locacao, "dinheiro") ?? null}
                                                    className={`flex w-5/6 h-12`}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.aluguel_imovel_pretendido ?? null}
                                                    itsButton={true}
                                                />
                                                <InputValidate
                                                    title={`IPTU`}
                                                    name={`iptu_imovel_pretendido`}
                                                    value={formatarValor(locatario?.imovel_pretendido?.imovel?.iptu, "dinheiro") ?? null}
                                                    className={`flex w-5/6 h-12`}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.iptu_imovel_pretendido ?? null}
                                                    itsButton={true}
                                                />
                                                <InputValidate
                                                    title={`Condomínio`}
                                                    name={`condominio_imovel_pretendido`}
                                                    value={formatarValor(locatario?.imovel_pretendido?.imovel?.condominio, "dinheiro") ?? null}
                                                    className={`flex w-5/6 h-12`}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos.condominio_imovel_pretendido ?? null}
                                                    itsButton={true}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`Sede Responsável pelo Atendimento`}
                                                name={`sede_responsavel_atendimento`}
                                                value={locatario?.sede_responsavel?.nome ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos.sede_responsavel_atendimento ?? null}
                                                itsButton={true}
                                            />
                                        </div>

                                    </div>
                                </div>

                                {locatario?.principal ?
                                    <CheckBox
                                        id="novo_locatario"
                                        checked={!addLocatario}
                                        funcao={onClickLocatarioSecundario}
                                        label={'Solicitar adição de novos Locatários Secundários?'}
                                        style={'mb-2 mt-2'}
                                    // id={'1'}
                                    />
                                    : ""
                                }
                                {/* comentario*/}
                                <div className={`${itsView ? 'hidden' : 'instrucoes-reenvio'} mt-2`}>
                                    <div className="w-1/2">
                                        <p className="font-extrabold text-lg text-gray-system-mega-imoveis">Instruções para reenvio*</p>
                                        <textarea
                                            rows={2}
                                            name={`instrucao_reenvio`}
                                            value={locatario?.instrucao_reenvio}
                                            style={{ maxHeight: "20rem", minHeight: "3.5rem", resize: "none" }}
                                            className="block p-4 w-full h-32 mt-2 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                                            placeholder={'Adicione instruções para reenvio'}
                                            onChange={onChange}
                                            required={existFalse}
                                        />
                                    </div>
                                </div>
                            </div>
                            {!statusNotButton.includes(locatario?.ultimo_historico?.status?.id) ? <div>
                                {itsFullIsTrue() ? <div className="w-full flex flex-row gap-4 justify-center items-center">
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => openModal(true)}
                                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                                            style={{ backgroundColor: '#FF3B3B' }}
                                        >
                                            reprovar cadastro
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            type="submit"
                                            onClick={() => onSubmit("aprovado", etapaId)}
                                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                                            style={{ backgroundColor: '#0DCB64' }}
                                        >
                                            aprovar cadastro
                                        </button>
                                    </div>
                                </div> : <div className="w-full flex flex-row gap-4 justify-center items-center">
                                    <div>
                                        <button
                                            type="submit"
                                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs border-2 border-blue-mega-imoveis text-blue-dark-mega-imoveis bg-transparent cursor-pointer rounded-lg justify-center items-center`}
                                        >
                                            Salvar e continuar depois
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            type="submit"
                                            disabled={!itsFull() ?? true}
                                            onClick={() => onSubmit("reenviado", etapaId)}
                                            className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs ${itsFull() ? 'bg-blue-dark-mega-imoveis text-white' : 'bg-gray-300 text-gray-700'}   cursor-pointer rounded-lg justify-center items-center`}
                                        >
                                            enviar para usuário revisar
                                        </button>
                                    </div>
                                </div>}
                            </div> : ''}
                        </>
                }
                {/* </form> */}
            </div>
        </Fragment>
    );
}

export default FormularioCadastroPessoaFisica;