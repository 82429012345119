import React, { useEffect } from 'react';
import CheckBox from 'src/components/Basicos/Form/CheckBox';
import Form from './Form';
import { permissoes } from "./data-permissoes.json"
import './style.css';
// import { axiosApi } from 'src/services/axiosInstance';
import Loader from 'src/components/Basicos/Loader';
import { useControlContextPermissoes } from 'src/contexts/PermissoesContext';

const Permissoes: React.FunctionComponent = () => {
    const {
        // dadosPermissao,
        loader,
        dadosPermissaoFormatado,
        getPermissoes,
        getValueCheckBox,
        getUsuariosGrupo,
        // dadosPermissao
    } = useControlContextPermissoes();

    useEffect(() => {
        getPermissoes();
        getUsuariosGrupo();
    }, []);

    return (
        <Loader isActive={loader}>
            <div className='p-8'>
                <div className='permissoes p-8'>
                    <Form />
                    {
                        permissoes?.map((permissao: any) => {
                            return (
                                <>
                                    <div className={permissao.estiloPai}>
                                        <p className={permissao.estiloLabel}>{permissao.label}</p>
                                        {permissao?.tipoEstrutura == 1
                                            ? permissao?.campos?.map((campo: string) => {
                                                return (
                                                    <>
                                                        <CheckBox
                                                            checked={dadosPermissaoFormatado?.[permissao.label + '/' + campo]}
                                                            funcao={getValueCheckBox}
                                                            label={campo}
                                                            style={permissao.estiloCampos}
                                                            id={permissao.label + '/' + campo}
                                                        />
                                                    </>
                                                )
                                            })
                                            : permissao?.tipoEstrutura == 2 ? permissao?.labelFilho?.map((labelFilho: string) => {
                                                return (
                                                    <>
                                                        <p className={permissao?.estiloLabelFilho}>{labelFilho}</p>
                                                        <div className={permissao.estiloFilho}>
                                                            {
                                                                permissao?.campos?.map((campo: string) => {
                                                                    return (
                                                                        <>
                                                                            {campo == "Excluir Locatário Secundário" ?
                                                                                labelFilho == "Locatário" ? <CheckBox
                                                                                    checked={dadosPermissaoFormatado?.[permissao.label + '/' + labelFilho + '/' + campo]}
                                                                                    funcao={getValueCheckBox}
                                                                                    label={campo}
                                                                                    id={permissao.label + '/' + labelFilho + '/' + campo} /> : ""
                                                                                : <CheckBox
                                                                                    checked={dadosPermissaoFormatado?.[permissao.label + '/' + labelFilho + '/' + campo]}
                                                                                    funcao={getValueCheckBox}
                                                                                    label={campo}
                                                                                    id={permissao.label + '/' + labelFilho + '/' + campo} />
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            })
                                                : permissao?.tipoEstrutura == 4 ?
                                                    <div className={permissao.estiloFilho}>
                                                        {permissao?.campos?.map((campo: string) => {
                                                            return (
                                                                <>
                                                                    <CheckBox
                                                                        checked={dadosPermissaoFormatado?.[campo + '/Visualizar']}
                                                                        funcao={getValueCheckBox}
                                                                        label={campo}
                                                                        style={permissao.estiloCampos}
                                                                        id={'Consulta/' + campo + '/Visualizar'}
                                                                    />
                                                                </>
                                                            )
                                                        })}
                                                    </div> : (
                                                        <>
                                                            {permissao?.colunas?.map((coluna: string) => {
                                                                return (
                                                                    <div className={permissao.estiloFilho}>
                                                                        {permissao?.[coluna]?.map((labelFilho: string) => {
                                                                            return (
                                                                                <div className={permissao.estiloNeto}>
                                                                                    <p className={permissao?.estiloLabelFilho}>{labelFilho}</p>
                                                                                    <div className={permissao.estiloNeto2}>
                                                                                        {
                                                                                            permissao?.campos?.map((campo: string) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <CheckBox
                                                                                                            checked={dadosPermissaoFormatado?.[permissao.label + '/' + labelFilho + '/' + campo]}
                                                                                                            funcao={getValueCheckBox}
                                                                                                            label={campo}
                                                                                                            id={permissao.label + '/' + labelFilho + '/' + campo} />
                                                                                                    </>

                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                )
                                                            })}
                                                        </>
                                                    )
                                        }
                                    </div>
                                    <div className='borda-permissao'></div>
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </Loader>
    );
}

export default Permissoes;