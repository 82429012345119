import React, { Fragment, useEffect, useState } from 'react';
// import SelectForm from '../../components/Basicos/Form/SelectForm';
// import { statusSolicitacao, statusSolicitacaoBasic, verifyStatus } from '../../components/Basicos/funcoes';
import { verifyStatus, jsonToFormData, converterDataBr, colorStatus, ajustUserAgent } from '../../components/Basicos/funcoes';
import { success } from 'src/components/Basicos/funcoes';
import { UseTableCellProps } from 'react-table';
import { axiosApi } from 'src/services/axiosInstance';
import Table from 'src/components/Basicos/Table';
import ModalDocumento from 'src/components/Funcionalidades/ModalDocumento';

import './style.css';
import PrimeiraLinha from 'src/components/Basicos/Solicitacao/FormsPorSolicitacao/primeiraLinha';
import SegundaLinha from 'src/components/Basicos/Solicitacao/FormsPorSolicitacao/segundaLinha';
import TerceiraLinha from 'src/components/Basicos/Solicitacao/FormsPorSolicitacao/terceiraLinha';
import { MdCircle } from 'react-icons/md';
import QuartaLinha from 'src/components/Basicos/Solicitacao/FormsPorSolicitacao/quartaLinha';
import FormCorretor from 'src/components/Basicos/Solicitacao/FormsPorSolicitacao/formCorretor';
import { useLocation } from 'react-router';
import HorizontalGallery from 'react-dynamic-carousel';
import ModalImg from './ModalImg';
import LinhaEndereco from './LinhaEndereco';

interface SolicitacaoProps {
    id?: number;
    usuario_id?: number;
    imovel_id?: number;
    nome?: string;
    created_at?: string;
    updated_at?: string;
    data?: string;
    tempo_atendimento?: string;
    telefone1?: string;
    hora?: string;
    telefone2?: string;
    email?: string;
    codigo_imovel?: string;
    status_solicitacao_id?: number;
    sede_id?: number;
    tipo_manutencao_id?: number;
    seguro_id?: number;
    tipo_solicitacao_id?: number;
    tipo_anuncio_imovel_id?: number;
    mensagem?: string;
    arquivo_id?: number;
    comentario?: string;
    seguro?: SeguroProps;
    tipo_anuncio?: TipoAnuncio;
    imovel?: ImovelProps;
    venda?: boolean;
    locacao?: boolean;
    arquivo: ArquivoProps;
    endereco_id?: number;
    endereco?: EnderecoProps;
    informacoes_dispositivo?: Array<informacoesDispositivo>;
    arquivos_solicitacao: any;
    // st_ativo: boolean;
}

interface EnderecoProps {
    id?: number;
    complemento?: string;
    cep?: string;
    bairro?: string;
    cidade?: string;
    estado?: string;
    logradouro?: string;
    numero?: string;
}
interface informacoesDispositivo {
    id?: number;
    browser_name?: string;
    browser_version?: string;
    device_type?: string;
    os_name?: string;
    os_version?: number;
    created_at?: number;
    user_agent?: string;
}
interface ArquivoProps {
    id?: number;
    nome?: string;
    caminho?: string;
    tipo_mime?: string;
}
interface ImovelProps {
    id?: number;
    codigo_imovel?: string;
}
interface TipoAnuncio {
    id?: number;
    descricao?: string;
}

interface SeguroProps {
    id?: number;
    nome?: string;
}
interface HistoricoStatusProps {
    id?: number;
    descricao?: string;
    created_at?: string;
    status_id?: number;
}

// interface SedesProps {
//     value?: number;
//     label?: string;
// }

type Props = {
    dadosSolicitacao?: SolicitacaoProps;
    dadosHistorico?: HistoricoStatusProps;
    formComplete?: any;
    anuncieImovel?: any;
    solicitacaoImovel?: any;
    solicitacaoChamado?: any;
    avaliacaoImovel?: any;
    cotacaoSeguro?: any;
    declaracaoIR?: any;
    loadAgendamentoVisita?: any;
    closeModal: any;
    dataArray: any;
    titulo: any;
    styleStatus?: string;
    opcoesStatus: Array<number>;
    corretor?: boolean;
    // sedes: SedesProps;
}

const Agendamento: React.FC<Props> = ({
    dadosSolicitacao,
    closeModal,
    dataArray,
    formComplete,
    titulo,
    anuncieImovel,
    solicitacaoImovel,
    solicitacaoChamado,
    avaliacaoImovel,
    declaracaoIR,
    loadAgendamentoVisita,
    opcoesStatus,
    styleStatus,
    corretor,
    cotacaoSeguro,
    // sedes
}: Props) => {

    const [data, setData] = useState<SolicitacaoProps>();
    const [dataOrigemAcesso, setDataOrigemAcesso] = useState<undefined | Array<informacoesDispositivo>>([]);
    const [dadosHistoricos, setDadosHistoricos] = useState([]);
    const [openDocumento, setOpenDocumento] = useState<boolean>(false);
    const [idDocumento, setIdDocumento] = useState<any>();
    const location = useLocation();
    const [openModalImg, setOpenModalImg] = useState<boolean>(false);
    const [indexImg, setIndexImg] = useState<any>();

    const closeModalDocumento = (): void => {
        setOpenDocumento(false);
        setIdDocumento(undefined);
    }

    const loadHistoricoStatus = async (): Promise<void> => {
        const response = await axiosApi.get(`historico-${corretor ? 'indicacoes/' : 'solicitacoes/'}${dadosSolicitacao?.id}`);
        setDadosHistoricos(response.data.data.map((historicos: any) => ({
            id: historicos.id,
            created_at: historicos.created_at,
            descricao: historicos.status?.descricao,
            status_id: historicos.status_solicitacao_id,
            usuario: historicos.usuario?.nome
        })));
    }

    const onChangeEventInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { name, value, } = event.target;
        setData((data: any) => ({ ...data, [name]: value }));
    }

    const onChangeEventSelect = (name: string, value: number): void => {
        setData((prevState: any) => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        if (dadosSolicitacao) {
            setData({
                ...dadosSolicitacao,
            });
            setDataOrigemAcesso(dadosSolicitacao?.informacoes_dispositivo);
            // setDataOrigemAcesso(dadosSolicitacao?.informacoes_dispositivo.map(()));
            loadHistoricoStatus();
        }
    }, [dadosSolicitacao]);

    const submitForm = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            // setData({...data, data: formatLaravelDateFunction(data?.data)});              
            const endpoint = 'solicitacao';
            const method = dadosSolicitacao ? "PUT" : "POST";
            const dadosFrom = jsonToFormData({ ...data }, method);
            const url = dadosSolicitacao ? `${endpoint}/${dadosSolicitacao.id}?` : endpoint;

            var urlCompleta;

            switch (location.pathname) {
                case "/solicitacoes/indicacao-corretor":
                    urlCompleta = `atualizar-indicacao/${dadosSolicitacao?.id}`
                    break;
                case "/solicitacoes/devolucao-imovel":
                    urlCompleta = url + 'solicitacao=9&tipo_manutencao=4'
                    break;
                case "/solicitacoes/avaliacao-atendimento":
                    urlCompleta = url + 'solicitacao=9&tipo_manutencao=3'
                    break;
                case "/solicitacoes/manutencao-imovel":
                    urlCompleta = url + 'solicitacao=9&tipo_manutencao=1'
                    break;
                case "/solicitacoes/renovacao-contrato":
                    urlCompleta = url + 'solicitacao=9&tipo_manutencao=2'
                    break;
                default:
                    urlCompleta = url + `solicitacao=${dadosSolicitacao?.tipo_solicitacao_id}`
                    break;
            }

            const response = await axiosApi(
                {
                    method: 'POST',
                    headers: { "Content-Type": "multipart/form-data;" },
                    url: urlCompleta,
                    data: dadosFrom
                }
            );

            if (response.status === 201 && method === "POST") {
                const { dadosSolicitacao } = response.data;
                success('Adicionado com sucesso!');
                dataArray.push(dadosSolicitacao)
            } else {
                success('Atualizado com sucesso!');
            }
            loadAgendamentoVisita();
            closeModal();
        } catch (error) {
            console.log(error);
        }
    }

    const modalImg = (imagem: any): any => {
        var index = dadosSolicitacao?.arquivos_solicitacao.findIndex((element: any) => element.id == imagem?.id)
        setIndexImg(index)
        setOpenModalImg(true)
    }

    const columnsModal = [
        {
            Header: "Data",
            accessor: "created_at",
            Cell: ({ row }: UseTableCellProps<SolicitacaoProps>) => {
                return (
                    <Fragment>
                        <p>{converterDataBr(row.original.created_at)}</p>
                    </Fragment>
                );
            }
        },
        {
            Header: "Nome do Usuário",
            accessor: "usuario"
        },
        {
            Header: "Status",
            accessor: "status.descricao",
            Cell: ({ row }: UseTableCellProps<HistoricoStatusProps>) => {
                return (
                    <Fragment>
                        <label className="status-historico" htmlFor={verifyStatus(row.original.status_id)}>
                            <MdCircle className="color-status" style={{ color: colorStatus(row.original.status_id) }} />
                            {row.original.descricao}
                        </label>
                    </Fragment>
                );
            }
        }
    ];

    const columnsModalOrigemAcesso = [
        {
            Header: "Data e Hora",
            accessor: "created_at",
            Cell: ({ row }: UseTableCellProps<informacoesDispositivo>) => {
                return (
                    <Fragment>
                        <p>{converterDataBr(row.original.created_at)}</p>
                    </Fragment>
                );
            }
        },
        {
            Header: "Dispositivo",
            accessor: "device_type"
        },
        {
            Header: "Versão",
            accessor: "user_agent",
            Cell: ({ row }: UseTableCellProps<informacoesDispositivo>) => {
                return (
                    <Fragment>
                        <p>{ajustUserAgent(row.original.user_agent)}</p>
                    </Fragment>
                );
            }
        },
        {
            Header: "Navegador",
            accessor: "browser_name"
        },
        {
            Header: "Versão do Navegador",
            accessor: "browser_version"
        },
        {
            Header: "Sistema Operacional",
            accessor: "os_name"
        },

    ];

    const onClose = (): any => {
        setOpenModalImg(false)
    }

    return (
        <Fragment>
            <ModalImg
                open={openModalImg}
                imgs={dadosSolicitacao?.arquivos_solicitacao}
                indexImg={indexImg}
                isClose={() => onClose()}
            />
            <form className="flex flex-col" onSubmit={submitForm} >
                <div className="flex flex-row w-full">
                    <div className="w-4/5 titulo-modal-adicionar-voltar">
                        <h1>{titulo}</h1>
                    </div>
                    <div className='flex flex-row-reverse w-1/5'>
                        <button className="btn-salvar-solicitacao">Salvar</button>
                        <button className="btn-cancelar-solicitacao" onClick={closeModal} >Cancelar</button>
                    </div>
                </div>
                <div className="flex">
                    <PrimeiraLinha
                        tipoSolicitacaoId={data?.tipo_solicitacao_id}
                        data={data}
                        onChangeEventSelect={onChangeEventSelect}
                        opcoesStatus={opcoesStatus}
                    />
                </div>
                <div className="flex">
                    <SegundaLinha
                        tipoSolicitacaoId={data?.tipo_solicitacao_id}
                        data={data}
                        onChangeEventSelect={onChangeEventSelect}
                        opcoesStatus={opcoesStatus}
                    />
                </div>
                <div className="flex">
                    <TerceiraLinha
                        tipoSolicitacaoId={data?.tipo_solicitacao_id}
                        data={data}
                        onChangeEventSelect={onChangeEventSelect}
                        opcoesStatus={opcoesStatus}
                    />
                </div>
                {
                    data?.tipo_solicitacao_id == 9 || data?.tipo_solicitacao_id == 1 ?
                        <div className="flex">
                            <QuartaLinha
                                tipoSolicitacaoId={data?.tipo_solicitacao_id}
                                data={data}
                                onChangeEventSelect={onChangeEventSelect}
                                opcoesStatus={opcoesStatus}
                            />
                        </div> : ""
                }
                {
                    corretor ? <FormCorretor tipoSolicitacaoId={data?.tipo_solicitacao_id}
                        data={data}
                        onChangeEventSelect={onChangeEventSelect}
                        opcoesStatus={opcoesStatus} /> : ""
                }
                {
                    dadosSolicitacao?.arquivos_solicitacao != undefined ?
                        <div className='mt-4 mb-4 ml-4 mr-4'>
                            <HorizontalGallery
                                tiles={dadosSolicitacao?.arquivos_solicitacao?.map((imagem: any) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: 100,
                                            height: 100,
                                            backgroundColor: '#D0D0D0',
                                            borderRadius: 10
                                        }}
                                        onClick={() => modalImg(imagem)}
                                    >
                                        <img src={`${imagem?.imagem?.caminho}`} />
                                    </div>
                                ))}
                                elementWidth={250}
                                fadeDistance={100}
                                minPadding={20}
                            />
                        </div>
                        : ''}
                {data?.tipo_solicitacao_id == 8
                    ? <>
                        <LinhaEndereco
                            data={data}
                        />
                        <div className='form-input-solicitacao'>
                            <label className="form-label-titulo my-3">Diferenciais do Imóvel</label>
                            <textarea
                                style={{ marginRight: '1.5rem' }}
                                rows={3}
                                cols={25}
                                name="mensagem"
                                onChange={onChangeEventInput}
                                value={data?.mensagem ?? ""}
                                disabled={true}
                                className="modal-input mensagem-block mb-3 p-1"
                            ></textarea>
                        </div>
                    </>
                    : ''}
                <div className="flex">
                    {data?.tipo_solicitacao_id == 8
                        ? '' : <div className='form-input-solicitacao'>
                            {data?.endereco_id && data?.tipo_solicitacao_id != 9 ?
                                <>
                                    <label className="form-label-titulo my-3">Endereço</label>
                                    <textarea
                                        style={{ marginRight: '1.5rem' }}
                                        rows={3}
                                        cols={25}
                                        name="mensagem"
                                        onChange={onChangeEventInput}
                                        value={data?.endereco?.logradouro + ", "
                                            + data?.endereco?.numero + ", "
                                            + data?.endereco?.bairro + ", "
                                            + data?.endereco?.cidade + ", "
                                            + data?.endereco?.estado ?? ""}
                                        className="modal-input mb-3 p-1"
                                    ></textarea></>
                                :
                                <>
                                    <label className="form-label-titulo my-3">{solicitacaoImovel ? 'Como deseja sua casa?' : 'Mensagem'}</label><textarea
                                        style={{ marginRight: '1.5rem' }}
                                        rows={3}
                                        cols={25}
                                        name="mensagem"
                                        onChange={onChangeEventInput}
                                        value={data?.mensagem ?? ""}
                                        className="modal-input mb-3 p-1"
                                    ></textarea>
                                </>
                            }

                        </div>}

                    <div className='form-input-solicitacao'>
                        <label className="form-label-titulo my-3">Comentário</label>
                        <textarea
                            style={{ marginRight: '1.5rem' }}
                            rows={3}
                            cols={25}
                            name="comentario"
                            placeholder='Adicione um comentário'
                            onChange={onChangeEventInput}
                            value={data?.comentario ?? ""}
                            className="modal-input-comentario mb-3 p-1 "
                        ></textarea>
                    </div>
                </div>
                <div className="mt-6 grid lg:col-span-12 lg:col-start-1 table-solicitacao-modal">
                    {dataOrigemAcesso !== undefined && dataOrigemAcesso !== null ? <div><h4 className='table-title-modal'>Origem da Solicitação</h4>
                        <Table columns={columnsModalOrigemAcesso} data={[dataOrigemAcesso]} pagination={false} total={false} /></div> : ""}
                    <h4 className='table-title-modal'>Histórico de Alterações de Status</h4>
                    <Table columns={columnsModal} data={dadosHistoricos} pagination={false} total={false} />
                </div>
            </form>
            <ModalDocumento
                open={openDocumento}
                closeModalDocumento={closeModalDocumento}
                dados={idDocumento ?? undefined}
            >
            </ModalDocumento>
        </Fragment>
    );
}

export default Agendamento;