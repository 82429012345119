import React, { useState } from 'react';
import Button from 'src/components/Button';
// import { useControlContextAnaliseFinanceira } from 'src/contexts/AnaliseFinanceiraContext';
import { alertMsg } from 'src/helpers/functions';
import { axiosApi } from 'src/services/axiosInstance';
import Modal from '../ModalNoticia';
import './style.css';

type Props = {
    open: boolean;
    isClose?: any;
    locatario?: any;
    fiador?: any;
}

const ModalApi: React.FC<Props> = ({
    isClose,
    open,
    locatario,
    fiador
}: Props): JSX.Element => {
    const [loader, setLoader] = useState<boolean>(false);
    // const { loadAnaliseFinanceira } = useControlContextAnaliseFinanceira();

    const reloadApi = async () => {
        try {
            setLoader(true)
            var id = locatario ? locatario?.id : fiador?.id;
            var tipo_usuario = locatario ? "locatario" : "fiador";
            var dataForm = { id: id, tipo_usuario: tipo_usuario }
            await axiosApi.post(`/salvar-info-spc`, dataForm);

            // loadAnaliseFinanceira(dataForm)
            alertMsg("success", "Informações salvas com sucesso!");
            document.location.reload();
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
            isClose();
        }
    }

    return (
        <Modal
            isOpen={open}
            isClose={() => isClose()}
            headerStyle={''}
            closeOnOutsideClick={true}
            widthModal={`35%`}
            modalStyle={`modal-style bg-white rounded-lg py-5 px-10 modal-api-responde`}
        >
            <div>
                <div className={`modal-api-response`}>
                    <div className="flex">
                        <p className="font-extrabold text-center text-2xl mb-4 font-mont-bold" style={{ color: "#081E52" }}>Deseja tentar novamente?</p>
                    </div>

                    <div className="flex flex-row w-full justify-center botoes-modal-api">
                        <button
                            onClick={() => isClose()}
                            className={`flex w-5/12 h-10 p-2 focus:outline-none uppercase font-medium text-xs bg-blue-mega-imoveis text-white cursor-pointer rounded-lg justify-center items-center`}
                        >
                            NÃO
                        </button>

                        <Button
                            type={`submit`}
                            className={`botao-modal-api`}
                            id={``}
                            name={``}
                            content={`SIM`}
                            onClick={reloadApi}
                            loader={loader}
                            colorLoader={'blue'}
                            styleSpan={'ml-4'} />

                    </div>
                </div>

            </div>
        </Modal>
    );
}

export default ModalApi;