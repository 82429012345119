import React, { Fragment } from 'react';
import SelectForm from '../../Form/SelectForm';
import { statusSolicitacao } from '../../funcoes';

interface SolicitacaoProps {
    id?: number;
    usuario_id?: number;
    imovel_id?: number;
    nome?: string;
    created_at?: string;
    updated_at?: string;
    data?: string;
    tempo_atendimento?: string;
    telefone1?: string;
    hora?: string;
    telefone2?: string;
    email?: string;
    codigo_imovel?: string;
    status_solicitacao_id?: number;
    sede_id?: number;
    tipo_manutencao_id?: number;
    seguro_id?: number;
    tipo_solicitacao_id?: number;
    tipo_anuncio_imovel_id?: number;
    mensagem?: string;
    arquivo_id?: number;
    comentario?: string;
    seguro?: SeguroProps;
    tipo_anuncio?: TipoAnuncio;
    imovel?: ImovelProps;
    venda?: boolean;
    locacao?: boolean;
    tipo_solicitacao_locatario?: TipoSolicitacaoLocatarioProps;
    pessoa_indicada?: PessoaIndicadaProps;
    arquivo: ArquivoProps;
    status_id?: number;
    // st_ativo: boolean;
}

interface ArquivoProps {
    id?: number;
    nome?: string;
    caminho?: string;
    tipo_mime?: string;
}

type PessoaIndicadaProps = {
    id?: string,
    nome_cliente: string,
    razao_social: string,
    nome_fantasia: string,
    cpf: string,
    cnpj: string,
    telefone1: string,
    telefone2: string,
    email: string,
    profissao: string,
    naturalidade: string,
    nacionalidade: string,
    qtd_dependentes: string,
    estado_civil_id: string,
    rg_cnh_arquivo_id: string,
}

interface TipoSolicitacaoLocatarioProps {
    id?: number;
    descricao?: string;
}

interface ImovelProps {
    id?: number;
    codigo_imovel?: string;
}
interface TipoAnuncio {
    id?: number;
    descricao?: string;
}

interface SeguroProps {
    id?: number;
    nome?: string;
}

type Props = {
    tipoSolicitacaoId?: number;
    data?: SolicitacaoProps;
    onChangeEventSelect?: any;
    opcoesStatus: Array<number>;
    corretor?: boolean
}

const TerceiraLinha: React.FunctionComponent<Props> = ({
    tipoSolicitacaoId,
    data,
    corretor,
    onChangeEventSelect,
    opcoesStatus
}) => {
    switch (tipoSolicitacaoId) {
        case 1: return (
            <Fragment>
                <FormaLabelInput
                    titulo="E-mail"
                    nome_do_campo="email"
                    valor_campo={data?.email ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo="Data para Visitoria"
                    nome_do_campo="data_visitoria"
                    valor_campo={data?.data ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo=""
                    nome_do_campo=""
                    valor_campo={""}
                    tipo_campo="hidden"
                    marginRigth="1.5rem"
                />

            </Fragment>
        )
        case 5:
        case 6:
        case 10:
            return (
                <Fragment>
                    <FormaLabelInput
                        titulo="E-mail"
                        nome_do_campo="email"
                        valor_campo={data?.email ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                    <FormaLabelInput
                        titulo="Tempo Atendimento"
                        nome_do_campo="tempo_atendimento"
                        valor_campo={data?.tempo_atendimento ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                    <div className='form-input-solicitacao'>
                        <SelectForm
                            name={'status_solicitacao_id'}
                            options={statusSolicitacao(opcoesStatus)}
                            label={'Status'}
                            value={statusSolicitacao(opcoesStatus).find(element => element.value === data?.status_solicitacao_id)}
                            onChange={onChangeEventSelect}
                            placeholder={"Status"}
                        // className={"modal-input mb-3 p-1"}
                        />
                    </div>
                </Fragment>
            )
        case 2:
        case 3:
        case 7:
        case 8:
            return (
                <Fragment>
                    <FormaLabelInput
                        titulo="E-mail"
                        nome_do_campo="email"
                        valor_campo={data?.email ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                    <FormaLabelInput
                        titulo="Tempo Atendimento"
                        nome_do_campo="tempo_atendimento"
                        valor_campo={data?.tempo_atendimento ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                    <FormaLabelInput
                        titulo=""
                        nome_do_campo=""
                        valor_campo={""}
                        tipo_campo="hidden"
                        marginRigth="1.5rem"
                    />
                </Fragment>
            )
        case 4:
        case 9:
            switch (data?.tipo_manutencao_id) {
                case 1: return (
                    <Fragment>
                        <FormaLabelInput
                            titulo="E-mail"
                            nome_do_campo="email"
                            valor_campo={data?.email ?? ""}
                            tipo_campo="text"
                            marginRigth="1.5rem"
                        />
                        <FormaLabelInput
                            titulo="Data para Visitoria"
                            nome_do_campo="data_visitoria"
                            valor_campo={data?.data ?? ""}
                            tipo_campo="text"
                            marginRigth="1.5rem"
                        />
                        <FormaLabelInput
                            titulo=""
                            nome_do_campo=""
                            valor_campo={""}
                            tipo_campo="hidden"
                            marginRigth="1.5rem"
                        />
                    </Fragment>
                )
                default:
                    return (
                        <Fragment>
                            <FormaLabelInput
                                titulo="E-mail"
                                nome_do_campo="email"
                                valor_campo={data?.email ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                            <FormaLabelInput
                                titulo="Documento Anexado"
                                nome_do_campo="documento_anexado"
                                valor_campo={data?.arquivo?.nome ?? ""}
                                tipo_campo="text"
                                marginRigth="1.5rem"
                            />
                            <FormaLabelInput
                                titulo=""
                                nome_do_campo=""
                                valor_campo={""}
                                tipo_campo="hidden"
                                marginRigth="1.5rem"
                            />
                        </Fragment>
                    )
            }
        default:
            return (
                <Fragment>
                    <FormaLabelInput
                        titulo="E-mail"
                        nome_do_campo="email"
                        valor_campo={data?.pessoa_indicada?.email ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                    <FormaLabelInput
                        titulo="Tempo de Atendimento"
                        nome_do_campo="tempo_atendimento"
                        valor_campo={data?.tempo_atendimento ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                    {/* <FormaLabelInput
                        titulo=""
                        nome_do_campo=""
                        valor_campo={""}
                        tipo_campo="hidden"
                        marginRigth="1.5rem"
                    /> */}
                    <div className='form-input-solicitacao'>
                        <SelectForm
                            name={'status_solicitacao_id'}
                            options={statusSolicitacao(opcoesStatus)}
                            label={'Status'}
                            value={statusSolicitacao(opcoesStatus).find(element => element.value === data?.status_solicitacao_id)}
                            onChange={onChangeEventSelect}
                            placeholder={"Status"}
                        // className={"modal-input mb-3 p-1"}
                        />
                    </div>
                </Fragment>
            )
    }

}

interface CamposForm {
    titulo?: string,
    nome_do_campo?: string,
    valor_campo?: number | string,
    tipo_campo?: string,
    required?: boolean,
    funcao?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    width?: string,
    height?: string,
    marginLeft?: string,
    marginRigth?: string,
    displayBlock?: string,
}

const FormaLabelInput: React.FC<CamposForm> = (props) => {
    return (
        <Fragment>
            <div className='form-input-solicitacao' style={{ width: props.width }}>
                <label className="form-label-titulo my-3" style={{ display: props.displayBlock }}>{props.titulo}</label>
                <input
                    required={props.required ? true : false}
                    type={props.tipo_campo}
                    name={props.nome_do_campo}
                    onChange={props.funcao}
                    value={props.valor_campo ?? ""}
                    className="modal-input mb-3 p-1"
                    style={{ width: props.width, height: props.height, marginLeft: props.marginLeft, marginRight: props.marginRigth }}
                />
            </div>
        </Fragment>
    );
};

export default TerceiraLinha;