import React, { useEffect } from 'react';
import Loader from 'src/components/Basicos/Loader';
import TableWithGlobalSearch from 'src/components/Basicos/TableWithGlobalSearch';
import { sedesColumns } from "./data-columns.json";
import './style.css'
import { useControlContextSedes } from 'src/contexts/SedesContext';

const Sedes: React.FunctionComponent = () => {
    const {
        sedes,
        loader,
        loadSedes,
    } = useControlContextSedes();

    useEffect(() => {
        loadSedes();
    }, [])

    return (
        <Loader isActive={loader}>
            <div className='p-10'>
                <div className="card-content">
                    <div className="grid flex-col p-6">
                        <TableWithGlobalSearch
                            columns={sedesColumns.columns}
                            data={sedes ?? []}
                            actions={{
                                show: () => { }
                            }}
                            withModal={false}
                            itsCount={true}
                            isButtons={true}
                            pathName={`auxiliares/sedes`}
                            placeholder='Pesquise por nome, endereço, whatsapp'
                            requestClose={null}
                        />
                    </div>
                </div>
            </div>
        </Loader >
    );
}

export default Sedes;