import React, { Fragment, useEffect, useState } from 'react';

import Loader from 'src/components/Basicos/Loader';
import TableWithGlobalSearch from 'src/components/Basicos/TableWithGlobalSearch';
import { compararDatas } from 'src/helpers/functions';
import { axiosApi } from 'src/services/axiosInstance';
import { useControlContextCadastro } from '../../../contexts/CadastroControlContext';
import { imovel } from "./data-columns.json";


const CadastroImovel: React.FunctionComponent = () => {
    const [data, setData] = useState<Array<any>>([]);
    const { etapaActive, dateActive, setDateFilter, setEtapa, checkCancelado } = useControlContextCadastro();
    const [loader, setLoader] = useState(false);

    const getImovel = async () => {
        try {
            setLoader(true)
            const { data } = await axiosApi.get(`/adm/imovel-proprietario`);
            setData(data?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
            setDateFilter(null, null);
            setEtapa(0);
        }
    }

    const filterImovelByStatus = (statusId: Number) => {
        if (statusId !== 0) {
            return data.filter((item: any) => { return item?.ultimo_historico?.etapa_historico_validacao_id === statusId });
        }
        return data;
    };

    const filterImovelByDate = (dateInicial: any, dateFinal: any) => {
        return compararDatas(dateInicial, dateFinal, filterImovelByStatus(etapaActive));
    };

    const filterCancelado = (data: any) => {
        if (checkCancelado == true)
            return data.filter((item: any) => { return item?.status == 2 })
        else
            return data.filter((item: any) => { return item?.status == 1 })
    }

    useEffect(() => {
        getImovel();
    }, []);

    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
            <div className='row-auto' style={classes.container}>
                <div className="card-content">
                    <div className="grid flex-col p-6">
                        <TableWithGlobalSearch
                            columns={imovel.columns}
                            pathName={`imovel/detalhes`}
                            data={filterCancelado(filterImovelByDate(dateActive.dateInicial, dateActive.dateFinal).sort((a: any, b: any) => Number(b.id) - Number(a.id)))}
                            actions={{
                                show: () => { }
                            }}
                            isButtons={true}
                            itsCount={true}
                            requestClose={getImovel}
                        />
                    </div>
                </div>
            </div>
            </Loader>
        </Fragment>
    );
}

const classes = {
    container: {
        marginTop: '3.5rem',
        marginButtom: '3.5rem',
        marginLeft: '2rem',
        marginRight: '2rem',
    },
};

export default CadastroImovel;