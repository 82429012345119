import React, { useState } from "react";
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { typeFormValue } from "./types";

export type Props = {
    handleLogin?: any;
    changeInput?: any;
    errorLogin?: any;
    isRevealPassword?: any;
    togglePassword?: any;
    iconRef?: any;
    faEye?: any;
    faEyeSlash?: any;
    faArrowRight?: any
}

const IndexMobile: React.FunctionComponent<Props> = ({
    handleLogin,
    changeInput,
    errorLogin,
    isRevealPassword,
    togglePassword,
    iconRef,
    faEye,
    faEyeSlash,
    faArrowRight
}) => {
    const [vertical, setVertical] = useState<boolean>();

    window.addEventListener('orientationchange', () => {
        // 'orientation' será 0 para orientação vertical e 90 ou -90 para orientação horizontal
        setVertical(!window.matchMedia("(orientation: portrait)").matches);
    });

    return (
        <div className="grid place-items-center h-screen ">
            <div className={`${vertical ? "form-login-mobile" : "form-login-mobile-vertical"}`}>
                <img className={`mx-auto ${vertical ? "p-8" : ""}`}
                    src={require("src/assets/login/logo-mega-imoveis.svg")}
                    alt="logo" />
                <p className="mt-2 titulo">Faça login para continuar</p>
                <div id="form-login">
                    <form onSubmit={handleLogin} className={`flex flex-col ${vertical ? "mt-8" : ""}`}>
                        <label htmlFor="email" className="font-arial">Email</label>
                        <input
                            id="email"
                            name="email"
                            placeholder="Digite seu email"
                            type="text"
                            onChange={changeInput}
                            required={true}
                            className={`mx-auto my-3 input-login pl-3 ${errorLogin && 'treme-animation'}`}
                        />
                        <label htmlFor="senha" className="font-arial">Senha</label>
                        <input
                            name="password"
                            placeholder="Senha"
                            type={isRevealPassword ? "text" : "password"}
                            onChange={changeInput}
                            required={true}
                            className={`mx-auto my-3 input-login pl-3 ${errorLogin && 'treme-animation'}`}
                        />
                        <span onClick={togglePassword} ref={iconRef}>
                            {isRevealPassword ?
                                <FontAwesomeIcon icon={faEye} className="customIcon-mobile" /> :
                                <FontAwesomeIcon icon={faEyeSlash} className="customIcon-mobile" />
                            }
                        </span>
                        <button
                            type="submit"
                            className="btn-login mx-auto"
                        >Entrar <FontAwesomeIcon icon={faArrowRight} className="customIcon" /></button>
                    </form>

                </div>
            </div>
        </div>
    );
}

export default IndexMobile;