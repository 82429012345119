import React, { useState, useEffect, useRef } from 'react';
import { ClassicProps } from "src/@types/types";
import logoMegaImoveis from 'src/assets/login/logo-mega-imoveis.svg';
import people from "src/assets/icones/people.svg"
import { sidebar } from "./data/data-sidebar-mobile.json";
import SidebarItem from './SidebarItem';
import './style.css'
import { useControlContextMobile } from 'src/contexts/MobileContext';

function Sidebar({ children }: ClassicProps): JSX.Element {
    const [usuario, setUsuario] = useState<any>();
    const { activeSideBar, activeSideBarMobile } = useControlContextMobile();
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const handleOutsideClick = () => {
        // Lógica a ser executada quando ocorre um clique fora da div principal
        activeSideBarMobile();
    };

    // Adiciona o ouvinte de eventos para detectar cliques fora da div principal
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node) && activeSideBar == true) {
                handleOutsideClick();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef, handleOutsideClick]);

    useEffect(() => {
        var usuarioLogado = localStorage.getItem('usuario');
        var user = typeof usuarioLogado === 'string' ? JSON.parse(usuarioLogado) : 'nada';
        setUsuario(user);
    }, []);

    return (
        <div ref={wrapperRef} className={`${activeSideBar ? "z-40 fixed bg-white align-content-middle shadow-xl top-0 h-full w-full flex flex-col sidebar-content-mobile" : "desativar-sidebarmobile"}`}>
            <div className='align-content-middle my-8 m-auto flex home-logo-edit'>
                <img src={logoMegaImoveis} width='120' height='120' alt='logo Mega Imoveis' />
                <div className='info-user-home'>
                    {usuario?.arquivo ?
                        <>
                            <img src={usuario?.arquivo.caminho} width='120' height='120' alt='logo Mega Imoveis' className="foto-perfil" />
                            <h2>{usuario?.nome}</h2>
                        </>
                        :
                        <>
                            <div className='foto-nova'>
                                <img className="icon-usuario-sem-foto" alt="icon_group" src={people} />
                            </div>
                            <h2>{usuario?.nome}</h2>
                        </>
                    }
                </div>
            </div>
            <div className={`flex flex-col h-full mt-4 mb-12 text-gray-system-mega-imoveis`}>
                {sidebar.menus.map((item: any, index: number) => (
                    <div key={index}>
                        <SidebarItem data={item} chave={index} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Sidebar;
