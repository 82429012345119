import React, { createContext, useContext, useEffect, useState } from 'react';
import { transformDataToOptionsSelectForm } from 'src/components/Basicos/funcoes';
import { compararDatas } from 'src/helpers/functions';
import { axiosApi } from 'src/services/axiosInstance';


const ControlSolicitacaoContext = createContext({});

interface IControlContextSolicitacaoProps {
    children: React.ReactNode
}

type DateType = {
    dateInicial: String | null,
    dateFinal: String | null,
}

const ControlSolicitacaoProvider: React.FC<IControlContextSolicitacaoProps> = React.memo(({ children }) => {
    const [loader, setLoader] = useState(false);
    const [dataSolicitacao, setDataSolicitacao] = useState<any>([]);
    const [requestName, setRequestName] = useState('');
    const [open, setOpen] = useState<boolean>(false);
    const [id, setId] = useState<any>();
    const [estilo, setEstilo] = useState<string>();
    const [statusActive, setStatusActive] = useState(0);
    const [statusActiveIndicacao, setStatusActiveIndicacao] = useState(0);
    const [tiposSedes, setTipoSedes] = useState<any>();
    const [sedeActiveSolicitacao, setSedeActiveSolicitacao] = useState(0);

    const setSede = (sedeId: Number): void => {
        setSedeActiveSolicitacao(Number(sedeId));
    }

    const [dateActive, setDateActive] = useState<DateType>({
        dateInicial: null,
        dateFinal: null
    });

    // 1 - agendamento visita
    // 2 - informação Imovel
    // 3 - Solicitacao Imovel
    // 4 - Avaliacao Imovel
    // 5 - cotação seguro
    // 6 - Declaração antecipação
    // 7 - Fale conosco
    // 8 - Anuncie Imovel
    // 9 - Solicitacao Chamado
    // indicacoes - indicação corretor

    // const 


    const requestSolicitacao = (pathName: string) => {
        switch (pathName) {
            case 'agendamento-visita':
                return '1';
            case 'informacao-imovel':
                return '2';
            case 'solicitacao-imovel':
                return '3';
            case 'avaliacao-imovel':
                return '4';
            case 'cotacao-seguro':
                return '5';
            case 'fale-conosco':
                return '7';
            case 'declaracao-ir':
                return '6';
            case 'anuncie-seu-imovel':
                return '8';
            case 'solicitacao-de-chamado':
                return '9';
            case 'manutencao-imovel':
                return '9&tipo_manutencao=1';
            case 'renovacao-contrato':
                return '9&tipo_manutencao=2';
            case 'avaliacao-atendimento':
                return '9&tipo_manutencao=3';
            case 'devolucao-imovel':
                return '9&tipo_manutencao=4';
            case 'indicacao-corretor':
                return 'indicacoes';
            default:
                return;
        }
    }

    const getSolicitacao = async () => {
        try {
            setLoader(true)
            if (requestSolicitacao(requestName) && requestSolicitacao(requestName) !== "indicacoes") {
                const { data } = await axiosApi.get(`/solicitacao?solicitacao=${requestSolicitacao(requestName)}`);
                setDataSolicitacao(data?.data);
                setSedesOptions();
            } else if (requestSolicitacao(requestName) === "indicacoes") {
                const { data } = await axiosApi.get(`/indicacoes`);
                setDataSolicitacao(data?.data);
                setSedesOptions();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
            setDateActive({ dateFinal: null, dateInicial: null });
            setStatusActive(0);
            setStatusActiveIndicacao(0);
            setSedeActiveSolicitacao(0);
        }
    }

    const setSedesOptions = async () => {
        const { data } = await axiosApi.get(`sede-responsavel`);
        var opcaoDefault = { id: 0, nome: "Selecione" }
        const opcaoDefaul2t = [opcaoDefault].concat(data.data)
        setTipoSedes(transformDataToOptionsSelectForm(opcaoDefaul2t))
    }

    const setRequest = (request: string) => {
        if (request !== '') {
            setRequestName(request);
        }
    };

    const setStatusSolicitacao = (statusId: Number): void => {
        if (statusId === statusActive) {
            setStatusActive(0);
        } else {
            setStatusActive(Number(statusId));
        }
    }

    const setStatusIndicacao = (statusId: Number): void => {
        if (statusId === statusActiveIndicacao) {
            setStatusActiveIndicacao(0);
        } else {
            setStatusActiveIndicacao(Number(statusId));
        }
    }

    const setDateFilterSolicitacao = (dateInicial: String, dateFinal: String): void => {
        setDateActive({
            ...dateActive,
            dateInicial: dateInicial,
            dateFinal: dateFinal
        });
    }

    const filterSolicitacaoByStatus = (statusId: Number) => {
        if (statusId !== 0) {
            return dataSolicitacao.filter((item: any) => { return item?.status_solicitacao_id === statusId });
        }
        return dataSolicitacao;
    };

    const filterSolicitacaoByStatusIndicacao = (statusId: Number) => {
        if (statusId !== 0) {
            return dataSolicitacao.filter((item: any) => { return item?.status_solicitacao_id === statusId });
        }
        return dataSolicitacao;
    };

    const filterSolicitacaoByDate = (dateInicial: any, dateFinal: any) => {
        return compararDatas(dateInicial, dateFinal, filterSolicitacaoByStatus(statusActive));
    };

    const filterSede = (dataTable: any, itsSolicitacao: boolean) => {
        if (sedeActiveSolicitacao !== 0) {
            if (itsSolicitacao)
                return dataTable.filter((item: any) => { return item?.sede_id === sedeActiveSolicitacao });
            else
                return dataTable.filter((item: any) => { return item?.sede_responsavel_id === sedeActiveSolicitacao });
        }
        return dataTable
    }

    const filterSolicitacaoByDateIndicacao = (dateInicial: any, dateFinal: any) => {
        return compararDatas(dateInicial, dateFinal, filterSolicitacaoByStatusIndicacao(statusActiveIndicacao));
    };

    const closeModal = (): void => {
        setOpen(false);
        setId(undefined);
    }

    const openModal = (id: number): void => {
        setOpen(true);
        setId(id);
    }

    const verifyStatus = (statusId: number) => {
        var background = statusId === 3 ? "status-pendente"
            : statusId === 4 ? "status-nao-realizado"
                : statusId === 7 ? "status-agendada" : statusId === 1 ? "status-em-atendimento" : "status-realizada";
        setEstilo(background)
    }

    const getSolicitacaoPeloIndice = (index: number): any => {
        return filterSolicitacaoByDate(dateActive?.dateInicial, dateActive?.dateFinal).filter((item: any) => {
            return item.id === index;
        }).map((itemMap: any) => { return itemMap })[0] ?? undefined;
    }

    const getSolicitacaoPeloIndiceIndicacao = (index: number): any => {
        return filterSolicitacaoByDateIndicacao(dateActive?.dateInicial, dateActive?.dateFinal).filter((item: any) => {
            return item.id === index;
        }).map((itemMap: any) => { return itemMap })[0] ?? undefined;
    }

    useEffect(() => {
        getSolicitacao();
    }, [requestName]);

    return (
        <ControlSolicitacaoContext.Provider value={{
            loader,
            dataSolicitacao,
            requestName,
            dateActive,
            statusActive,
            open,
            id,
            estilo,
            statusActiveIndicacao,
            tiposSedes,
            sedeActiveSolicitacao,
            setDataSolicitacao,
            setStatusIndicacao,
            closeModal,
            openModal,
            setRequest,
            setStatusSolicitacao,
            setDateFilterSolicitacao,
            filterSolicitacaoByDate,
            filterSolicitacaoByDateIndicacao,
            verifyStatus,
            getSolicitacaoPeloIndice,
            getSolicitacaoPeloIndiceIndicacao,
            setSede,
            filterSede
        }}>
            {children}
        </ControlSolicitacaoContext.Provider>
    );
});

function useControlContextSolicitacao(): any {
    const context = useContext(ControlSolicitacaoContext);
    if (!context) {
        throw new Error("useControll must a be used with ControlSolicitacaoProvider");
    }
    return context;
}

export { ControlSolicitacaoProvider, useControlContextSolicitacao };
