import React, { Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import InputValidate from "src/components/Basicos/Form/Input/inputValidate";
import TitleValidate from "src/components/Basicos/TitleSelectedValidate";
import { mask } from "src/helpers/functions";
import { axiosApi } from "src/services/axiosInstance";

export type Props = {
    proprietario?: any;
    campos?: any;
    handleClick?: any;
    selectSection?: any;
    sections?: any;
    onChange?: any;
    openModal?: any;
    itsView?: boolean
}

const FormularioCadastroProprietarioPessoaJuridica: React.FunctionComponent<Props> = ({
    proprietario,
    campos,
    handleClick,
    selectSection,
    sections,
    onChange,
    openModal,
    itsView
}) => {
    const { proprietarioId }: any = useParams();
    const history = useHistory();

    const existFalse = Object.values(campos).filter(function (item: any) { return item === false }).length > 0;

    let nulos = [null, undefined];

    const rgOrCnh = (proprietarioItem: any) => {
        return proprietarioItem?.informacao_basica?.nacionalidade === null && proprietarioItem?.informacao_basica?.naturalidade === null ? 'RG' : 'CNH';
    }

    const statusNotButton = [2, 5];

    const handleStatusCadastro = async (status: string, etapa_id: number) => {
        try {
            if (proprietario?.instrucao_reenvio !== "") {
                const data = {
                    proprietario_id: proprietarioId,
                    etapa_id: etapa_id,
                    comentario: null,
                    imovel_proprietario_id: proprietario?.imovel_proprietario?.id ?? null,
                }
                await axiosApi.post(`proprietario/status/${status}`, data);
                if (status === 'reprovado') {
                    history.goBack();
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onSubmit = async (e: any) => {
        e.preventDefault();
        try {
            if (proprietarioId !== null) {
                await axiosApi.post(`validacao/proprietario/${proprietarioId}`, campos).then(async (response) => {
                    const data = {
                        instrucao_reenvio: proprietario?.instrucao_reenvio
                    }
                    await axiosApi.put(`proprietario-instrucao/${proprietarioId}`, data);
                    history.goBack();
                });
            }

        } catch (error) {
            console.log(error);
        }
    }

    const itsFull = () => {
        const size = Object.keys(campos ?? null).length;

        let qtdCamposGeral = 26;

        if (proprietario?.representantes.length > 0) {
            let camposRepresentantes = proprietario?.representantes.length * 21;
            qtdCamposGeral += camposRepresentantes;
        }

        if (nulos.includes(proprietario.informacoes_basicas?.razao_social)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.nome_fantasia)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.cnpj)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.email)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.telefone1)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.informacoes_basicas?.telefone2)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.endereco?.cep)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.endereco?.logradouro)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.endereco?.numero)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.endereco?.complemento)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.endereco?.bairro)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.endereco?.cidade)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.endereco?.estado)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.arquivos_empresa?.arquivo_inscricao_cnpj?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.arquivos_empresa?.arquivo_contrato_social?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.arquivos_empresa?.arquivo_ultimo_aditivo_contrato?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.arquivos_empresa?.imposto_renda_pj?.nome)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.forma_recebimento?.descricao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.tipo_conta?.descricao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.titular_conta)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.favorecido)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.banco)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.favorecido)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.cpf_favorecido)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.agencia)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.operacao)) {
            qtdCamposGeral -= 1;
        }
        if (nulos.includes(proprietario?.dados_bancarios?.conta)) {
            qtdCamposGeral -= 1;
        }

        // console.log([size, qtdCamposGeral]);
        return size >= qtdCamposGeral;
    }

    const itsFullIsTrue = () => {
        const value = Object.values(campos ?? null);
        return !value.includes(false) && itsFull() && value?.length > 10; 
    }

    return (
        <Fragment>
            <div className="p-6">
                <form onSubmit={onSubmit}>
                    <div className="bg-white p-10 flex flex-col rounded-lg border-white border h-full w-full" style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>

                        <div className="border-b border-blue-500 ">
                            <TitleValidate
                                title={'Dados da Empresa'}
                                selectSection={selectSection}
                                checkSection={sections?.identificacao_pessoa_juridica}
                                section={5}
                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                            />
                            <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`Razão Social`}
                                        name={`razao_social_proprietario`}
                                        value={proprietario.informacoes_basicas?.razao_social ?? null}
                                        hidden={nulos.includes(proprietario.informacoes_basicas?.razao_social) ?? null}
                                        onClick={handleClick}
                                        status={itsView ? null : campos?.razao_social_proprietario ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <InputValidate
                                        title={`Nome Fantasia`}
                                        name={`nome_fantasia_proprietario`}
                                        value={proprietario?.informacoes_basicas?.nome_fantasia ?? null}
                                        onClick={handleClick}
                                        hidden={nulos.includes(proprietario?.informacoes_basicas?.nome_fantasia)}
                                        status={itsView ? null : campos?.nome_fantasia_proprietario ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <InputValidate
                                        title={`CNPJ`}
                                        name={`cnpj_proprietario`}
                                        value={mask(proprietario?.informacoes_basicas?.cnpj, "cpf_cnpj") ?? null}
                                        onClick={handleClick}
                                        status={itsView ? null : campos?.cnpj_proprietario ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <InputValidate
                                        title={`E-mail`}
                                        name={`email_proprietario`}
                                        value={proprietario?.informacoes_basicas?.email ?? null}
                                        onClick={handleClick}
                                        hidden={nulos.includes(proprietario?.informacoes_basicas?.email)}
                                        status={itsView ? null : campos?.email_proprietario ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Telefone 1`}
                                            name={`telefone_proprietario`}
                                            value={mask(proprietario?.informacoes_basicas?.telefone1, "telefone") ?? null}
                                            onClick={handleClick}
                                            status={itsView ? null : campos?.telefone_proprietario ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                        />
                                        <InputValidate
                                            title={`Telefone 2`}
                                            name={`telefone2_proprietario`}
                                            value={mask(proprietario?.informacoes_basicas?.telefone2, "telefone") ?? null}
                                            onClick={handleClick}
                                            status={itsView ? null : campos?.telefone2_proprietario ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                        />
                                    </div>
                                    <InputValidate
                                        title={`CEP`}
                                        name={`cep`}
                                        value={mask(proprietario?.endereco?.cep, "cep") ?? null}
                                        onClick={handleClick}
                                        hidden={nulos.includes(proprietario?.endereco?.cep)}
                                        status={itsView ? null : campos?.cep ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Logradouro`}
                                            name={`logradouro`}
                                            value={proprietario?.endereco?.logradouro ?? null}
                                            hidden={nulos.includes(proprietario?.endereco?.logradouro)}
                                            onClick={handleClick}
                                            status={itsView ? null : campos?.logradouro ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                        />
                                        <InputValidate
                                            title={`Número`}
                                            name={`numero`}
                                            value={proprietario?.endereco?.numero ?? null}
                                            onClick={handleClick}
                                            hidden={nulos.includes(proprietario?.endereco?.numero)}
                                            status={itsView ? null : campos?.numero ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                        />
                                    </div>
                                    <InputValidate
                                        title={`Complemento`}
                                        name={`complemento`}
                                        value={proprietario?.endereco?.complemento ?? null}
                                        onClick={handleClick}
                                        hidden={nulos.includes(proprietario?.endereco?.complemento)}
                                        status={itsView ? null : campos?.complemento ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                </div>
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`Bairro`}
                                        name={`bairro`}
                                        value={proprietario?.endereco?.bairro ?? null}
                                        hidden={nulos.includes(proprietario?.endereco?.bairro)}
                                        onClick={handleClick}
                                        status={itsView ? null : campos?.bairro ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                        <InputValidate
                                            title={`Cidade`}
                                            name={`cidade`}
                                            value={proprietario?.endereco?.cidade ?? null}
                                            onClick={handleClick}
                                            hidden={nulos.includes(proprietario?.endereco?.cidade)}
                                            status={itsView ? null : campos?.cidade ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                        />
                                        <InputValidate
                                            title={`Estado`}
                                            name={`estado`}
                                            value={proprietario?.endereco?.estado ?? null}
                                            onClick={handleClick}
                                            hidden={nulos.includes(proprietario?.endereco?.estado)}
                                            status={itsView ? null : campos?.estado ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                        />
                                    </div>
                                    <span className={`font-extrabold text-lg text-gray-system-mega-imoveis ${nulos.includes(proprietario?.arquivos_empresa) ? 'hidden' : ''}`}>Documentos da Empresa</span>
                                    <InputValidate
                                        title={`Cartão Inscrição CNPJ`}
                                        name={`cartao_inscricao_cnpj`}
                                        value={proprietario?.arquivos_empresa?.arquivo_inscricao_cnpj?.nome ?? null}
                                        onClick={handleClick}
                                        hidden={nulos.includes(proprietario?.arquivos_empresa?.arquivo_inscricao_cnpj?.nome)}
                                        isFile={true}
                                        file={proprietario?.arquivos_empresa?.arquivo_inscricao_cnpj?.caminho ?? null}
                                        status={itsView ? null : campos?.cartao_inscricao_cnpj ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <InputValidate
                                        title={`Contrato Social`}
                                        name={`contrato_social_proprietario`}
                                        value={proprietario?.arquivos_empresa?.arquivo_contrato_social?.nome ?? null}
                                        onClick={handleClick}
                                        hidden={nulos.includes(proprietario?.arquivos_empresa?.arquivo_contrato_social?.nome)}
                                        isFile={true}
                                        file={proprietario?.arquivos_empresa?.arquivo_contrato_social?.caminho}
                                        status={itsView ? null : campos?.contrato_social_proprietario ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <InputValidate
                                        title={`Último Aditivo do Contrato*`}
                                        name={`ultimo_aditivo_contrato`}
                                        value={proprietario?.arquivos_empresa?.arquivo_ultimo_aditivo_contrato?.nome ?? null}
                                        onClick={handleClick}
                                        hidden={nulos.includes(proprietario?.arquivos_empresa?.arquivo_ultimo_aditivo_contrato?.nome)}
                                        isFile={true}
                                        file={proprietario?.arquivos_empresa?.arquivo_ultimo_aditivo_contrato?.caminho}
                                        status={itsView ? null : campos?.ultimo_aditivo_contrato ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <InputValidate
                                        title={`Imposto de Renda PJ`}
                                        name={`imposto_renda_pj`}
                                        value={proprietario?.arquivos_empresa?.imposto_renda_pj?.nome ?? null}
                                        onClick={handleClick}
                                        hidden={nulos.includes(proprietario?.arquivos_empresa?.imposto_renda_pj?.nome)}
                                        isFile={true}
                                        file={proprietario?.arquivos_empresa?.imposto_renda_pj?.caminho}
                                        status={itsView ? null : campos?.imposto_renda_pj ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />

                                </div>
                            </div>
                        </div>


                        {/* Representante Legal */}
                        <div className={`border-b border-blue-500 mt-12 ${proprietario.representantes.length === 0 ? 'hidden' : ''}`}>
                            <TitleValidate
                                title={'Representante Legal'}
                                selectSection={selectSection}
                                checkSection={sections?.representantes_legal}
                                section={6}
                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                            />
                            {/* Array representantes */}
                            {proprietario?.representantes.map((item: any, index: number) => {
                                let numberRepresentante = index + 1;
                                return <div>
                                    <p className="font-extrabold text-xl text-gray-system-mega-imoveis mb-2">Representante {numberRepresentante}</p>
                                    <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                        <div className="flex flex-col gap-4">
                                            <InputValidate
                                                title={`Nome do Representante Legal`}
                                                name={`nome_representante${numberRepresentante}`}
                                                value={item?.informacao_basica?.nome_cliente ?? null}
                                                // required={true}
                                                onClick={handleClick}
                                                status={itsView ? null : campos?.[`nome_representante${numberRepresentante}`] ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`E-mail`}
                                                name={`email_representante${numberRepresentante}`}
                                                value={item?.informacao_basica?.email ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos?.[`email_representante${numberRepresentante}`] ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Telefone 1`}
                                                    name={`telefone1_representante${numberRepresentante}`}
                                                    value={mask(item?.informacao_basica?.telefone1, "telefone") ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos?.[`telefone1_representante${numberRepresentante}`] ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Telefone 2`}
                                                    name={`telefone2_representante${numberRepresentante}`}
                                                    value={mask(item?.informacao_basica?.telefone2, "telefone") ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos?.[`telefone2_representante${numberRepresentante}`] ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <span className="font-extrabold text-base mt-3">Documento: {rgOrCnh(item) ?? 'RG ou CNH'}</span>
                                            <div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
                                                <InputValidate
                                                    title={`Frente`}
                                                    name={`rg_cnh_frente_representante${numberRepresentante}`}
                                                    value={item?.informacao_basica?.rg_cnh_frente?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    file={item?.informacao_basica?.rg_cnh_frente?.caminho ?? null}
                                                    status={itsView ? null : campos?.[`rg_cnh_frente_representante${numberRepresentante}`] ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Verso`}
                                                    name={`rg_cnh_verso_representante${numberRepresentante}`}
                                                    value={item?.informacao_basica?.rg_cnh_verso?.nome ?? null}
                                                    onClick={handleClick}
                                                    isFile={true}
                                                    file={item?.informacao_basica?.rg_cnh_verso?.caminho ?? null}
                                                    status={itsView ? null : campos?.[`rg_cnh_verso_representante${numberRepresentante}`] ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`CPF`}
                                                name={`cpf_representante${numberRepresentante}`}
                                                value={mask(item?.informacao_basica?.cpf, "cpf_cnpj") ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos?.[`cpf_representante${numberRepresentante}`] ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Naturalidade`}
                                                    name={`naturalidade_representante${numberRepresentante}`}
                                                    value={item?.informacao_basica?.naturalidade ?? null}
                                                    hidden={item?.informacao_basica?.naturalidade === null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos?.[`naturalidade_representante${numberRepresentante}`] ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Nacionalidade`}
                                                    name={`nacionalidade_representante${numberRepresentante}`}
                                                    value={item?.informacao_basica?.nacionalidade ?? null}
                                                    hidden={item?.informacao_basica?.nacionalidade === null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos?.[`nacionalidade_representante${numberRepresentante}`] ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`Estado Civil`}
                                                name={`estado_civil_representante${numberRepresentante}`}
                                                value={item?.informacao_basica?.estado_civil?.descricao ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos?.[`estado_civil_representante${numberRepresentante}`] ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`CEP`}
                                                name={`cep_representante${numberRepresentante}`}
                                                value={mask(item?.endereco?.cep, "cep") ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos?.[`cep_representante${numberRepresentante}`] ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Logradouro`}
                                                    name={`logradouro_representante${numberRepresentante}`}
                                                    value={item?.endereco?.logradouro ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos?.[`logradouro_representante${numberRepresentante}`] ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Número`}
                                                    name={`numero_representante${numberRepresentante}`}
                                                    value={item?.endereco?.numero ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos?.[`numero_representante${numberRepresentante}`] ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>

                                        </div>
                                        <div className="flex flex-col gap-4">
                                            <InputValidate
                                                title={`Complemento`}
                                                name={`complemento_representante${numberRepresentante}`}
                                                value={item?.endereco?.complemento ?? null}
                                                onClick={handleClick}
                                                status={itsView ? null : campos?.[`complemento_representante${numberRepresentante}`] ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Bairro`}
                                                name={`bairro_representante${numberRepresentante}`}
                                                value={item?.endereco?.bairro ?? null}

                                                onClick={handleClick}
                                                status={itsView ? null : campos?.[`bairro_representante${numberRepresentante}`] ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                            />
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                                <InputValidate
                                                    title={`Cidade`}
                                                    name={`cidade_representante${numberRepresentante}`}
                                                    value={item?.endereco?.cidade ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos?.[`cidade_representante${numberRepresentante}`] ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                                />
                                                <InputValidate
                                                    title={`Estado`}
                                                    name={`estado_representante${numberRepresentante}`}
                                                    value={item?.endereco?.estado ?? null}
                                                    onClick={handleClick}
                                                    status={itsView ? null : campos?.[`estado_representante${numberRepresentante}`] ?? null}
                                                    itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                                />
                                            </div>
                                            <InputValidate
                                                title={`Comprovante de Residência*`}
                                                name={`comprovante_residencia_representante${numberRepresentante}`}
                                                value={item?.arquivo_comprovante_residencia?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={item?.arquivo_comprovante_residencia === null}
                                                file={item?.arquivo_comprovante_residencia?.caminho ?? null}
                                                status={itsView ? null : campos?.[`comprovante_residencia_representante${numberRepresentante}`] ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                            />
                                            <p className={`font-extrabold text-lg text-gray-system-mega-imoveis ml-6`}>{item?.arquivo_outros?.caminho !== undefined
                                                || item?.arquivo_extrato_bancario?.caminho !== undefined
                                                || item?.arquivo_ctps?.caminho !== undefined
                                                || item?.arquivo_imposto_renda?.caminho !== undefined ? 'Comprovante de Renda*' : ''}</p>

                                            <InputValidate
                                                title={`Imposto de Renda PJ`}
                                                name={`imposto_renda_pj_representante${numberRepresentante}`}
                                                value={item?.arquivo_imposto_renda?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={item?.arquivo_imposto_renda === null}
                                                file={item?.arquivo_imposto_renda?.caminho ?? null}
                                                status={itsView ? null : campos?.[`imposto_renda_pj_representante${numberRepresentante}`] ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Extrato Bancário`}
                                                name={`extrato_bancario_representante${numberRepresentante}`}
                                                value={item?.arquivo_extrato_bancario?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={item?.arquivo_extrato_bancario === null}
                                                file={item?.arquivo_extrato_bancario?.caminho ?? null}
                                                status={itsView ? null : campos?.[`extrato_bancario_representante${numberRepresentante}`] ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                            />
                                            <InputValidate
                                                title={`Outros`}
                                                name={`outros_documentos_representante${numberRepresentante}`}
                                                value={item?.arquivo_outros?.nome ?? null}
                                                onClick={handleClick}
                                                isFile={true}
                                                hidden={item?.arquivo_outros === null}
                                                file={item?.arquivo_outros?.caminho ?? null}
                                                status={itsView ? null : campos?.[`outros_documentos_representante${numberRepresentante}`] ?? null}
                                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                            />

                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>

                        {/* Forma de Recebimento */}
                        <div className={`border-b border-blue-500 mt-12 ${nulos.includes(proprietario?.forma_recebimento) ? 'hidden' : ''}`}>
                            <TitleValidate
                                title={'Forma de Recebimento'}
                                selectSection={selectSection}
                                checkSection={sections?.forma_recebimento}
                                section={3}
                                itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                            />
                            <div className="grid grid-cols-2 gap-4 w-full mb-8">
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`Como deseja receber?`}
                                        name={`recebimento_pagamento`}
                                        value={proprietario?.forma_recebimento?.descricao ?? null}
                                        hidden={nulos.includes(proprietario?.forma_recebimento?.descricao)}
                                        onClick={handleClick}
                                        status={campos.recebimento_pagamento ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <InputValidate
                                        title={`Qual o tipo da conta?`}
                                        name={`tipo_conta`}
                                        value={proprietario?.dados_bancarios?.tipo_conta?.descricao ?? null}
                                        hidden={nulos.includes(proprietario?.dados_bancarios?.tipo_conta?.descricao)}
                                        onClick={handleClick}
                                        status={campos.tipo_conta ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <InputValidate
                                        title={`O titular da conta é outra pessoa?*`}
                                        name={`outra_pessoa_titular_conta`}
                                        value={!proprietario?.dados_bancarios?.titular_conta ? 'Sim' : 'Não'}
                                        onClick={handleClick}
                                        status={campos.outra_pessoa_titular_conta ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                </div>
                                <div className="flex flex-col gap-4">
                                    <InputValidate
                                        title={`Pessoa Autorizada a Receber`}
                                        name={`pessoa_autorizada`}
                                        value={proprietario?.dados_bancarios?.favorecido}
                                        hidden={proprietario?.forma_recebimento_id !== 2}
                                        onClick={handleClick}
                                        status={campos.pessoa_autorizada ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <InputValidate
                                        title={`Banco`}
                                        name={`banco`}
                                        value={proprietario?.dados_bancarios?.banco ?? null}
                                        hidden={nulos.includes(proprietario?.dados_bancarios?.banco)}
                                        onClick={handleClick}
                                        status={campos.banco ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <InputValidate
                                        title={`Titular`}
                                        name={`favorecido`}
                                        value={proprietario?.dados_bancarios?.favorecido ?? null}
                                        hidden={nulos.includes(proprietario?.dados_bancarios?.favorecido)}
                                        onClick={handleClick}
                                        status={campos.favorecido ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <InputValidate
                                        title={`CPF Favorecido`}
                                        name={`cpf_favorecido`}
                                        value={mask(proprietario?.dados_bancarios?.cpf_favorecido, 'cpf_cnpj') ?? null}
                                        hidden={nulos.includes(proprietario?.dados_bancarios?.cpf_favorecido)}
                                        onClick={handleClick}
                                        status={campos.cpf_favorecido ?? null}
                                        itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-1">
                                        <InputValidate
                                            title={`Agência`}
                                            name={`agencia`}
                                            value={proprietario?.dados_bancarios?.agencia ?? null}
                                            hidden={nulos.includes(proprietario?.dados_bancarios?.agencia)}
                                            onClick={handleClick}
                                            className={`flex w-5/6 h-12`}
                                            status={campos.agencia ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                        />
                                        <InputValidate
                                            title={`Operação`}
                                            name={`operacao`}
                                            value={proprietario?.dados_bancarios?.operacao ?? null}
                                            hidden={nulos.includes(proprietario?.dados_bancarios?.operacao)}
                                            onClick={handleClick}
                                            className={`flex w-5/6 h-12`}
                                            status={campos.operacao ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                        />
                                        <InputValidate
                                            title={`Número`}
                                            name={`numero_conta`}
                                            value={proprietario?.dados_bancarios?.conta ?? null}
                                            hidden={nulos.includes(proprietario?.dados_bancarios?.conta)}
                                            onClick={handleClick}
                                            className={`flex w-5/6 h-12`}
                                            status={campos.numero_conta ?? null}
                                            itsButton={itsView ? itsView : statusNotButton.includes(proprietario?.ultimo_historico?.status?.id)}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* comentario*/}
                        <div className={`${itsView ? 'hidden' : ''} mt-5`}>
                            <p className="font-extrabold text-lg text-gray-system-mega-imoveis">Instruções para reenvio*</p>
                            <textarea
                                rows={2}
                                name={`instrucao_reenvio`}
                                value={proprietario?.instrucao_reenvio}
                                style={{ maxHeight: "20rem", minHeight: "3.5rem" }}
                                className="block p-4 w-full h-32 mt-2 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                                placeholder={'Adicione instruções para reenvio'}
                                onChange={onChange}
                                required={existFalse}
                            />
                        </div>
                    </div>
                    {!statusNotButton.includes(proprietario?.ultimo_historico?.status?.id) ? <div>
                        {itsFullIsTrue() ? <div className="w-full flex flex-row gap-4 justify-center items-center">
                            <div>
                                <button
                                    type="button"
                                    onClick={() => openModal(true)}
                                    className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                                    style={{ backgroundColor: '#FF3B3B' }}
                                >
                                    reprovar cadastro
                                </button>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    onClick={() => handleStatusCadastro("aprovado", 3)}
                                    className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs text-white cursor-pointer rounded-lg justify-center items-center`}
                                    style={{ backgroundColor: '#0DCB64' }}
                                >
                                    aprovar cadastro
                                </button>
                            </div>
                        </div> : <div className="w-full flex flex-row gap-4 justify-center items-center">
                            <div>
                                <button
                                    type="submit"
                                    className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs border-2 border-blue-mega-imoveis text-blue-dark-mega-imoveis bg-transparent cursor-pointer rounded-lg justify-center items-center`}
                                >
                                    Salvar e continuar depois
                                </button>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    disabled={!itsFull() ?? true}
                                    onClick={() => handleStatusCadastro("reenviado", 3)}
                                    className={`${itsView ? 'hidden' : 'flex'} h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs ${itsFull() ? 'bg-blue-dark-mega-imoveis text-white' : 'bg-gray-300 text-gray-700'}   cursor-pointer rounded-lg justify-center items-center`}
                                >
                                    enviar para usuário revisar
                                </button>
                            </div>
                        </div>}
                    </div> : ''}
                </form>
            </div>
        </Fragment>
    );
}

export default FormularioCadastroProprietarioPessoaJuridica;