import React, { createContext, useContext, useState } from 'react';


const ControllCargoContext = createContext({});

interface IControllContextCargoProps {
    children: React.ReactNode
}

const ControllCargoProvider: React.FC<IControllContextCargoProps> = React.memo(({ children }) => {
    const [novoCargo, setNovoCargo] = useState<any>();

    const criarNovoCargo = () => {
        setNovoCargo({
            descricao: "Novo Cargo"
        })
    }

    return (
        <ControllCargoContext.Provider value={{
            novoCargo,
            setNovoCargo,
            criarNovoCargo
        }}>
            {children}
        </ControllCargoContext.Provider>
    );
});

function useControlContextCargo(): any {
    const context = useContext(ControllCargoContext);
    if (!context) {
        throw new Error("useControll must a be used with ControllCargoProvider");
    }
    return context;
}

export { ControllCargoProvider, useControlContextCargo };
