import React from 'react';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import './styles.css';
import Modelo1 from 'src/components/PDF/ModelosFiador/Modelo1';
import Modelo2 from 'src/components/PDF/ModelosFiador/Modelo2';

interface ComponenteProps {
    fiador?: any
}

const ComponentToPDF: React.FC<ComponenteProps> = ({ fiador }) => {

    return (
        <>
            {fiador?.conjuge_id != null ? <Modelo1 fiador={fiador} /> :
                <Modelo2 fiador={fiador} />}
        </>
    );
};

export default ComponentToPDF;