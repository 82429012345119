import React from 'react';
import CheckBox from "src/assets/icones/checkbox-on.svg";
import CheckBoxOff from "src/assets/icones/checkbox-off.svg";
import Linha from '../../Universal/Linha';

type Props = {
    cpf?: any;
    nome?: any;
    email?: any;
    telefone1?: any;
    telefone2?: any;
    responsavel?: any;
};

const BlocoFiador = ({
    cpf,
    nome,
    email,
    telefone1,
    telefone2,
    responsavel
}: Props) => {
    return (
        <>
            <h1 className='sub-sub-title estilo-fonte-negrito mt-4'>
                Fiador
            </h1>
            <Linha campo10='CPF*' valor10={cpf} campo20='Nome do Fiador*' valor20={nome} />
            <Linha campo10='E-mail do Fiador*' valor10={email} campo20='Telefone 1*' valor20={telefone1} campo21='Telefone 2' valor21={telefone2} />
            <div className="flex check-pdf">
                <div className="flex mr-4">
                    <img src={responsavel == "locatario" ? CheckBox : CheckBoxOff} alt="icon_crown" />
                    <label htmlFor="inline-radio" className="ml-2 check-font-pdf">Locatário Principal irá responder</label>
                </div>
                <div className="flex mr-4">
                    <img src={responsavel != "locatario" ? CheckBox : CheckBoxOff} alt="icon_crown" />
                    <label htmlFor="inline-2-radio" className="ml-2 check-font-pdf">Fiador irá responder</label>
                </div>
            </div>
        </>
    );
};

export default BlocoFiador;