import React, { Fragment, useEffect, useState } from "react";
import { axiosApi } from "src/services/axiosInstance";

export type Props = {
    locatarioId: number
}

export type hitoricoGarantiaData = {
    id: number
    locatario_id: number
    status: number
    usuario_id: number
    tipo_garantia_id: number,
    garantia: {
        id: number,
        descricao: string
    }
    updated_at: string
    created_at: string
}

function AnaliseGarantia({
    locatarioId
}: Props): JSX.Element {
    const [historicoGarantia, setHistoricoGarantia] = useState<hitoricoGarantiaData[]>([]);

    const getHistoricoAnalise = async (locatarioId: number) => {
        try {
            if (locatarioId !== null) {
                const { data } = await axiosApi.get(`/historico-garantia/${locatarioId}`);
                setHistoricoGarantia(data?.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getHistoricoAnalise(locatarioId);
    }, [locatarioId])

    return (
        <Fragment>
            <div className={`w-full h-full  ${historicoGarantia?.length > 0 ? 'mb-8 mt-3': 'hidden'}`}>
                <p className="font-extrabold text-2xl mb-3 font-mont-bold"> Análise Garantia Locatícia </p>
                <div className="flex flex-row">
                    <table className="border-collapse">
                        {historicoGarantia?.map((item: hitoricoGarantiaData) => {
                            return (
                                <tr className="h-10" key={item.id}>
                                    <td className="font-extrabold text-lg font-mont-bold pr-16 text-left"> {item?.garantia?.descricao } </td>
                                    <td>
                                        <div className="flex items-center mr-4">
                                            <input id="inline-radio" type="radio" value="" className="w-5 h-5 accent-green bg-gray-100 border-gray-300 focus:ring-2" checked={item?.status === 1} />
                                            <label htmlFor="inline-radio" className="ml-2 text-base font-semibold">Aprovada</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex items-center mr-4">
                                            <input id="inline-radio" type="radio" value="" className="w-5 h-5 accent-red bg-gray-100 border-gray-300 focus:ring-2" checked={item?.status === 0} />
                                            <label htmlFor="inline-radio" className="ml-2 text-base font-semibold">Reprovada</label>
                                        </div>
                                    </td>
                                    <td className="ml-2 text-base font-semibold text-gray-system-mega-imoveis"> {item?.updated_at} </td>
                                </tr>
                            );
                        })}

                    </table>
                </div>
            </div>
        </Fragment>
    );
}

export default AnaliseGarantia;