import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HomeLayout from "./layouts/Home";
import Login from "./pages/Login";
// import Main from "./pages/Main";
import AgendamentoVisita from "./pages/Solicitacoes/AgendamentoVisita";
import AvaliacaoImovel from "./pages/Solicitacoes/AvaliacaoImovel";
import CotacaoSeguro from "./pages/Solicitacoes/CotacaoSeguro";
import InformacaoImovel from "./pages/Solicitacoes/InformacaoImovel";
import DeclaracaoAntecipacao from "./pages/Solicitacoes/DeclaracaoAntecipacao";
import FaleConosco from "./pages/Solicitacoes/FaleConosco";
import SolicitacaoImovel from "./pages/Solicitacoes/SolicitacaoImovel";
import Noticias from "./pages/Noticias";
import NovaNoticia from "./pages/Noticias/NovaNoticia";
import AnuncieImovel from "./pages/Solicitacoes/AnuncieImovel";
import SolicitacaoChamado from "./pages/Solicitacoes/SolicitacaoChamado";
import CadastroLocatario from "./pages/Cadastros/Locatario";
import CadastroFiador from "./pages/Cadastros/Fiador";
import CadastroProprietario from "./pages/Cadastros/Proprietario";
import DetalhesCadastroLocatario from "./pages/Cadastros/Locatario/DetalheCadastro";
import FormularioValidateCadastroLocatario from "./pages/Cadastros/Locatario/FormValidacao/index";
import Logout from "./pages/Logout";
import IndicacaoCorretor from "./pages/Solicitacoes/IndicacoesCorretor";
import DetalhesCadastroFiador from "./pages/Cadastros/Fiador/DetalheCadastro";
import FormularioValidateCadastroFiador from "./pages/Cadastros/Fiador/FormValidacao";
import DetalhesCadastroProprietario from "./pages/Cadastros/Proprietario/DetalheCadastro";
import FormularioValidateCadastroProprietario from "./pages/Cadastros/Proprietario/FormValidacao";
import ManutencaoImovel from "./pages/Solicitacoes/ManutencaoImovel";
import DevolucaoImovel from "./pages/Solicitacoes/DevolucaoImovel";
import RenovacaoContrato from "./pages/Solicitacoes/RenovacaoContrato";
import AvaliacaoAtendimento from "./pages/Solicitacoes/AvaliacaoAtendimento";
import GruposUsuarios from "./pages/Auxiliares/GruposUsuarios";
import Permissoes from "./pages/Auxiliares/GruposUsuarios/Permissoes";
import Colaboradores from "./pages/Auxiliares/Colaboradores";
import NovoColaborador from "./pages/Auxiliares/Colaboradores/NovoUsuario";
import Clientes from "./pages/Auxiliares/Clientes";
import Cliente from "./pages/Auxiliares/Clientes/Cliente";
import Cargos from "./pages/Auxiliares/Cargos";
import CadastroImovel from "./pages/Cadastros/Imovel";
import DetalhesCadastroImovel from "./pages/Cadastros/Imovel/DetalheCadastro";
import FormularioValidateCadastroImovel from "./pages/Cadastros/Imovel/FormValidacao";
import Sedes from "./pages/Auxiliares/Sedes";
import Sede from "./pages/Auxiliares/Sedes/Sede";
import Consultas from "./pages/Consultas";
import Consulta from "./pages/Consultas/Consulta";
import Dashboard from "./pages/Dashboard";

type Props = {
  isToken?: string | null | undefined;
};

function Rotas({ isToken }: Props): JSX.Element {
  return isToken ? (
    <Route path="/">
      <HomeLayout>
        <Switch>
          <Route exact path="/home" component={Dashboard} />

          <Route exact path="/solicitacoes/agendamento-visita" component={AgendamentoVisita} />
          <Route exact path="/solicitacoes/informacao-imovel" component={InformacaoImovel} />
          <Route exact path="/solicitacoes/solicitacao-imovel" component={SolicitacaoImovel} />
          <Route exact path="/solicitacoes/avaliacao-imovel" component={AvaliacaoImovel} />
          <Route exact path="/solicitacoes/cotacao-seguro" component={CotacaoSeguro} />
          <Route exact path="/solicitacoes/fale-conosco" component={FaleConosco} />
          <Route exact path="/solicitacoes/declaracao-ir" component={DeclaracaoAntecipacao} />
          <Route exact path="/solicitacoes/anuncie-seu-imovel" component={AnuncieImovel} />
          <Route exact path="/solicitacoes/solicitacao-de-chamado" component={SolicitacaoChamado} />
          <Route exact path="/solicitacoes/indicacao-corretor" component={IndicacaoCorretor} />

          <Route exact path="/solicitacoes/manutencao-imovel" component={ManutencaoImovel} />
          <Route exact path="/solicitacoes/devolucao-imovel" component={DevolucaoImovel} />
          <Route exact path="/solicitacoes/renovacao-contrato" component={RenovacaoContrato} />
          <Route exact path="/solicitacoes/avaliacao-atendimento" component={AvaliacaoAtendimento} />

          <Route exact path="/noticias" component={Noticias} />
          <Route exact path="/noticias/nova-noticia" component={NovaNoticia} />

          <Route exact path="/cadastro/locatario" component={CadastroLocatario} />
          <Route exact path="/cadastro/locatario/detalhes/:locatarioId" component={DetalhesCadastroLocatario} />
          <Route exact path="/cadastro/locatario/detalhes/:locatarioId/analise-cadastro" component={FormularioValidateCadastroLocatario} />
          <Route exact path="/cadastro/locatario/detalhes/:locatarioId/analise-diretoria" component={FormularioValidateCadastroLocatario} />
          <Route exact path="/cadastro/locatario/detalhes/:locatarioId/view" component={FormularioValidateCadastroLocatario} />

          <Route exact path="/cadastro/fiador" component={CadastroFiador} />
          <Route exact path="/cadastro/fiador/detalhes/:fiadorId" component={DetalhesCadastroFiador} />
          <Route exact path="/cadastro/fiador/detalhes/:fiadorId/analise-cadastro" component={FormularioValidateCadastroFiador} />
          <Route exact path="/cadastro/fiador/detalhes/:fiadorId/analise-diretoria" component={FormularioValidateCadastroFiador} />
          <Route exact path="/cadastro/fiador/detalhes/:fiadorId/view" component={FormularioValidateCadastroFiador} />

          <Route exact path="/cadastro/proprietario" component={CadastroProprietario} />
          <Route exact path="/cadastro/proprietario/detalhes/:proprietarioId" component={DetalhesCadastroProprietario} />
          <Route exact path="/cadastro/proprietario/detalhes/:proprietarioId/analise-cadastro" component={FormularioValidateCadastroProprietario} />
          <Route exact path="/cadastro/proprietario/detalhes/:proprietarioId/view" component={FormularioValidateCadastroProprietario} />

          <Route exact path="/cadastro/imovel" component={CadastroImovel} />
          <Route exact path="/cadastro/imovel/detalhes/:imovelProprietarioId" component={DetalhesCadastroImovel} />
          <Route exact path="/cadastro/imovel/detalhes/:imovelProprietarioId/analise-cadastro" component={FormularioValidateCadastroImovel} />
          <Route exact path="/cadastro/imovel/detalhes/:imovelProprietarioId/view" component={FormularioValidateCadastroImovel} />



          <Route exact path="/auxiliares/grupos-usuarios" component={GruposUsuarios} />
          <Route exact path="/auxiliares/grupos-usuarios/permissoes" component={Permissoes} />

          <Route exact path="/auxiliares/colaboradores" component={Colaboradores} />
          <Route exact path="/auxiliares/colaboradores/novo-colaborador" component={NovoColaborador} />

          <Route exact path="/auxiliares/clientes" component={Clientes} />
          <Route exact path="/auxiliares/cliente" component={Cliente} />

          <Route exact path="/auxiliares/cargos" component={Cargos} />

          <Route exact path="/auxiliares/sedes" component={Sedes} />
          <Route exact path="/auxiliares/sede" component={Sede} />

          <Route exact path="/consultas" component={Consultas} />
          <Route exact path="/consulta" component={Consulta} />

          <Route path="/logout" component={Logout} />
          {/* <Route exact path="/" component={Main} /> */}
          <Redirect to="/home" />
        </Switch>
      </HomeLayout>
    </Route>
  ) : (
    <Route path="/">
      <Switch>
        <Route exact path="/login" component={Login} />
        <Redirect to="/login" />
      </Switch>
    </Route>
  );
}

export default Rotas;
