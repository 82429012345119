import React from 'react';

export const setArraySecundarios = (locatario) => {
    var sec = [];
    for (var i = 0; i < locatario?.locatario_secundario.length; i++) {
        console.log('primeiro')
        sec.push(locatario?.locatario_secundario[i]);
    }
    for (var i = 0; i < locatario?.fiador.length; i++) {
        console.log('segundo')
        sec.push(locatario?.fiador[i]);
    }
    return sec;
}

export const setHistoricoImovel = (historico, setHistorico, secundarios, modelo) => {
    const combinacoes = {
        1: {
            0: [19, 38, 57],
            1: [8, 27, 46],
            2: [3, 22, 41],
            3: [19, 38, 57],
            4: [13, 32, 51],
            5: [9, 28, 51],
            6: [4, 23, 51],
            7: [19, 38, 57],
            8: [13, 32, 51],
            9: [9, 28, 51],
            10: [4, 23, 51]
        },
        2: {
            0: [9, 29, 48],
            1: [3, 22, 50],
            2: [19, 38, 57],
            3: [13, 32, 51],
            4: [8, 27, 46],
            5: [3, 22, 41],
            6: [19, 38, 57],
            7: [13, 32, 51],
            8: [8, 27, 46],
            9: [3, 22, 41],
            10: [19, 38, 57]
        }
    };

    const combinacaoAtual = combinacoes[modelo][secundarios.length] || [0, 0, 0];
    const hist = setHistoricoDinamico(combinacaoAtual, setHistorico, historico);
    return hist;
}

export const setHistoricoDinamico = (combinacao, setHistorico, historico) => {
    const [posicaoHist1, posicaoHist2, posicaoHist3] = combinacao;

    const historico1 = setHistorico(historico, posicaoHist1);
    const historico2 = setHistorico(historico?.slice(posicaoHist1, posicaoHist2), posicaoHist2 - posicaoHist1);
    const historico3 = setHistorico(historico?.slice(posicaoHist2, posicaoHist3), posicaoHist3 - posicaoHist2);

    return [historico1, historico2, historico3];
}