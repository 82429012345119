import React, { useMemo, useState } from "react";
import { useAsyncDebounce, usePagination, useTable, useFilters, useGlobalFilter } from 'react-table';
import { matchSorter } from 'match-sorter';
import './style.css';
import Seta from "../Seta";


function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const [paginationValue, setPaginationValue] = React.useState(false)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span style={{ marginLeft: '6.5%' }}>
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={''}
        className="input-search"
        id="estilo-placeholder-table-usuario"
        style={{
          backgroundColor: '#FFF',
          borderRadius: '24px',
          width: '29rem',
          height: '36px',
          border: '1.2px solid rgba(41, 41, 44, 0.12)',
          outline: 'none',
          padding: '20px'
        }}
      />
    </span>
  )
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = val => !val

const Table = ({ columns, data, pagination, total }) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )
  const filterTypes = useMemo(
    () => ({

      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      pagination,
      total,
      initialState: { pageIndex: 0, hiddenColumns: ['Tempo de Atendimento'] }
    },
    useFilters,
    useGlobalFilter,
    usePagination,
  );

  const generatePagesLinks = () => {
    const pages = [];
    const range = 3;
    const numberOfPages = pageCount;
    const currentPage = pageIndex;
    let toIndex = numberOfPages > (range * 2) ? currentPage + range : numberOfPages;
    let fromIndex = numberOfPages > (range * 2) ? currentPage - range : 1;

    if (toIndex > numberOfPages) {
      fromIndex = numberOfPages - (range * 2);
      toIndex = numberOfPages;
    } else if (fromIndex < 1) {
      fromIndex = 1;
      toIndex = 1 + (range * 2);
    }

    for (let index = fromIndex; index <= toIndex; index++) {
      // index - 1 === pageIndex
      const active = index - 1 === pageIndex ? "bg-blue-dark-mega-imoveis text-white" : 'bg-white text-blue-dark-mega-imoveis';
      pages.push(
        <div onClick={() => gotoPage(index - 1)} key={index} className={`p-2 border-blue-dark-mega-imoveis border-2 font-semibold cursor-pointer rounded-lg w-10 h-10 flex justify-center items-center ${active}`}>{index}</div>//aqui
      );
    }

    return pages;
  }

  return (
    <>
      {total &&
        <p className='count-table-solicitação' style={{ padding: "24px" }}> {Object.keys(data).length} Resultados</p>
      }
      <table {...getTableProps()} className="admin-tbl-collapse table table-borderless">
        <thead className="admin-tbl-head">
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()} className="admin-tbl-linha">
              {headerGroup.headers.map(column => (
                column.Header != 'nao-mostrar' ?
                  <th {...column.getHeaderProps()} className="admin-tbl-column" scope="col">{column.render("Header")}</th>
                  : ""
              ))}
            </tr>
          ))}

        </thead>
        <tbody {...getTableBodyProps()} className="admin-tbl-body">
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()}>
                {
                  row.cells.map(cell => {
                    return (
                      cell.column.id != 'nao.mostrar' ?
                        <td {...cell.getCellProps()}>{cell.render("Cell")} </td> : ""
                    );
                  })
                }
              </tr>
            );
          })}

        </tbody>

      </table>
      {pagination &&
        <div className="pagination">
          <button className="flex h-10 p-2 focus:outline-none uppercase font-semibold border-blue-dark-mega-imoveis bg-blue-dark-mega-imoveis text-white border-2 cursor-pointer rounded-lg justify-center items-center" onClick={() => previousPage()} disabled={!canPreviousPage}>
            ANTERIOR
          </button>{" "}
          <span className="mx-2">
            <div className="flex flex-row space-x-4 justify-center">
              {generatePagesLinks()}
            </div>{" "}
          </span>
          <button className="flex h-10 p-2 focus:outline-none uppercase font-semibold border-blue-dark-mega-imoveis bg-blue-dark-mega-imoveis text-white border-2 cursor-pointer rounded-lg justify-center items-center" onClick={() => nextPage()} disabled={!canNextPage}>
            PRÓXIMO
          </button>{" "}
        </div>
      }

    </>
  );
};
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

filterGreaterThan.autoRemove = val => typeof val !== 'number';
export default React.memo(Table);
