import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
// import Header from "src/components/Basicos/Header";
import Loader from "src/components/Basicos/Loader";
import Modal from "src/components/Funcionalidades/ModalNoticia";
import { axiosApi } from "src/services/axiosInstance";
import FormularioCadastroFiador from "./FormFiador";
import { useControlContextFiador } from "src/contexts/FiadorContext";


const FormularioValidateCadastroFiador: React.FunctionComponent = () => {
    const { fiadorId }: any = useParams();
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const [motivo, setMotivo] = useState<any>();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    const itsView = location.pathname.split('/').includes('view');
    const [etapa, setEtapa] = useState<any>();
    // const statusNotButton = [2, 5];
    const {
        setFiaPDF
    } = useControlContextFiador();

    let nulos = [null, undefined];

    const [valueForm, setValueForm] = useState<any>({
        nome_fiador: null,
        telefone_fiador: null,
        telefone2_fiador: null,
        rg_cnh_fiador: null,
        nacionalidade_fiador: null,
        naturalidade_fiador: null,
        cpf_fiador: null,
        email_fiador: null,
        estado_civil_fiador: null,
        numb_dependentes_fiador: null,

        nome_conjuge: null,
        telefone_conjuge: null,
        telefone2_conjuge: null,
        rg_conjuge: null,
        cpf_conjuge: null,
        email_conjuge: null,
        profissao_conjuge: null,
        naturalidade_conjuge: null,
        nacionalidade_conjuge: null,

        sua_residencia_atual_e: null,
        comprovante_endereco_residencia_atual: null,
        cep_residencia_atual: null,
        logradouro_residencia_atual: null,
        numero_residencia_atual: null,
        bairro_residencia_atual: null,
        cidade_residencia_atual: null,
        estado_residencia_atual: null,
        complemento_residencia_atual: null,
        valor_aluguel_residencia_atual: null,
        tempo_locacao_residencia_atual: null,

        tipo_atividade_profissional: null,
        ramo_atividade: null,
        atividade_profissional: null,
        renda_mensal: null,
        contracheque: null,
        carteira_trabalho: null,
        nome_empresa: null,
        tempo_empresa: null,
        cargo_atual: null,
        cep_empresa_atual: null,
        logradouro_empresa_atual: null,
        numero_empresa_atual: null,
        bairro_empresa_atual: null,
        cidade_empresa_atual: null,
        estado_empresa_atual: null,
        complemento_empresa_atual: null,

        matricula_imovel: null,
        certidao_matricula: null
    });

    const [fiadorValue, setfiadorValue] = useState<any>();

    const [sections, setSections] = useState<any>({
        identificacao: null,
        conjuge: null,
        residencia_atual: null,
        atividade_profissional: null,
        imovel_garantia: null
    });

    const handleClick = (name: string, value: boolean) => {
        setValueForm({
            ...valueForm,
            [name]: value
        });
    };

    const handleRejected = (value: boolean) => {
        setOpen(value);
    };

    const onchange = (e: any) => {
        const { name, value } = e.target;
        setfiadorValue({
            ...fiadorValue,
            [name]: value
        });
        if (name === 'motivoReprovacao') {
            setMotivo(value);
        }
    };

    const onSubmitReprovacao = async (e: any) => {
        try {
            e.preventDefault();
            if (fiadorId !== null && motivo !== '') {
                setLoader(true)
                const data = {
                    fiador_id: fiadorId,
                    etapa_id: location.pathname.split('/')[5] == "analise-diretoria" ? 4 : 3,
                    comentario: motivo ?? null
                }
                await axiosApi.post(`fiador/status/reprovado`, data);
                setOpen(false);
                history.goBack();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const getFiador = async () => {
        try {
            if (fiadorId !== null) {
                setLoader(true)
                const { data } = await axiosApi.get(`/fiador/${fiadorId}`);
                setfiadorValue(data?.data);
                setFiaPDF(data?.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const getValidacao = async () => {
        try {
            if (fiadorId !== null) {
                var analise = location.pathname.split('/');
                var etapaId = analise[5] == 'analise-diretoria' ? 4 : 3;
                setEtapa(etapaId)
                setLoader(true)
                const { data } = await axiosApi.get(`/validacao/fiador/${fiadorId}?etapa=${etapaId}`);
                const validacoes = data?.data;
                setValueForm({ ...validacoes });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }


    const selectOneSecao = (secao: number, value: boolean) => {

        switch (secao) {
            case 0:
                const identificacaoFiadorArray = [];
                identificacaoFiadorArray.push(
                    { [`nome_fiador`]: value },
                    { [`telefone_fiador`]: value },
                    { [`telefone2_fiador`]: value },
                    { [`rg_cnh_frente_fiador`]: value },
                    { [`rg_cnh_verso_fiador`]: value },
                    { [`cpf_fiador`]: value },
                    { [`email_fiador`]: value },
                    { [`estado_civil_fiador`]: value },
                    { [`numb_dependentes_fiador`]: value },
                );
                if (!nulos.includes(fiadorValue?.informacoes_basicas?.naturalidade)) {
                    identificacaoFiadorArray.push({ [`naturalidade_fiador`]: value });
                }
                if (!nulos.includes(fiadorValue?.informacoes_basicas?.nacionalidade)) {
                    identificacaoFiadorArray.push({ [`nacionalidade_fiador`]: value });
                }

                const mergeObjectIdentificacaoFiador = identificacaoFiadorArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectIdentificacaoFiador });
            case 1:
                const identificacaoConjuge = [];
                identificacaoConjuge.push(
                    { [`nome_conjuge`]: value },
                    { [`telefone_conjuge`]: value },
                    { [`telefone2_conjuge`]: value },
                    { [`rg_cnh_frente_conjuge`]: value },
                    { [`rg_cnh_verso_conjuge`]: value },
                    { [`cpf_conjuge`]: value },
                    { [`email_conjuge`]: value },
                    { [`tipo_atividade_profissional_conjuge`]: value },
                    { [`renda_mensal_conjuge`]: value }
                );

                if (!nulos.includes(fiadorValue?.conjuge?.informacoes_basicas.naturalidade)) {
                    identificacaoConjuge.push({ [`naturalidade_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.informacoes_basicas.nacionalidade)) {
                    identificacaoConjuge.push({ [`nacionalidade_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.nome_empresa)) {
                    identificacaoConjuge.push({ [`nome_empresa_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.ramo_atividade)) {
                    identificacaoConjuge.push({ [`ramo_atividade_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.tempo_empresa?.descricao)) {
                    identificacaoConjuge.push({ [`tempo_empresa_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.cargo_atual)) {
                    identificacaoConjuge.push({ [`cargo_atual_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.nome)) {
                    identificacaoConjuge.push({ [`contracheque_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.nome)) {
                    identificacaoConjuge.push({ [`contracheque2_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.nome)) {
                    identificacaoConjuge.push({ [`contracheque3_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_ctps?.nome)) {
                    identificacaoConjuge.push({ [`carteira_trabalho_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.nome)) {
                    identificacaoConjuge.push({ [`imposto_renda_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.nome)) {
                    identificacaoConjuge.push({ [`extrato_bancario_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.nome)) {
                    identificacaoConjuge.push({ [`extrato_bancario2_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.nome)) {
                    identificacaoConjuge.push({ [`extrato_bancario3_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_outro?.nome)) {
                    identificacaoConjuge.push({ [`outro_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.endereco?.cep)) {
                    identificacaoConjuge.push({ [`cep_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.endereco?.logradouro)) {
                    identificacaoConjuge.push({ [`logradouro_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.endereco?.numero)) {
                    identificacaoConjuge.push({ [`numero_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.endereco?.bairro)) {
                    identificacaoConjuge.push({ [`bairro_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.endereco?.cidade)) {
                    identificacaoConjuge.push({ [`cidade_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.endereco?.estado)) {
                    identificacaoConjuge.push({ [`estado_empresa_atual_conjuge`]: value });
                }
                if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.endereco?.complemento)) {
                    identificacaoConjuge.push({ [`complemento_empresa_atual_conjuge`]: value });
                }

                const mergeObjectIdentificacaoConjuge = identificacaoConjuge.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectIdentificacaoConjuge });
            case 2:
                const enderecoArray = [];
                enderecoArray.push(
                    { [`sua_residencia_atual_e`]: value },
                    { [`comprovante_endereco_residencia_atual`]: value },
                    { [`cep_residencia_atual`]: value },
                    { [`logradouro_residencia_atual`]: value },
                    { [`numero_residencia_atual`]: value },
                    { [`bairro_residencia_atual`]: value },
                    { [`cidade_residencia_atual`]: value },
                    { [`estado_residencia_atual`]: value }
                );
                if (!nulos.includes(fiadorValue?.endereco?.complemento)) {
                    enderecoArray.push({ [`complemento_residencia_atual`]: value });
                }
                if (!nulos.includes(fiadorValue?.residencia_atual?.valor_aluguel)) {
                    enderecoArray.push({ [`valor_aluguel_residencia_atual`]: value });
                }
                if (!nulos.includes(fiadorValue?.residencia_atual?.tempo_locacao)) {
                    enderecoArray.push({ [`tempo_locacao_residencia_atual`]: value });
                }

                const mergeObjectEndereco = enderecoArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectEndereco });
            case 3:
                const atividadeProfissionalArray = [];
                atividadeProfissionalArray.push(
                    { [`tipo_atividade_profissional`]: value },
                    { [`renda_mensal`]: value }
                );

                if (!nulos.includes(fiadorValue?.atividade_profissional?.outra_atividade_profissional)) {
                    atividadeProfissionalArray.push({ [`atividade_profissional`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.ramo_atividade)) {
                    atividadeProfissionalArray.push({ [`ramo_atividade`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_contra_cheque?.nome)) {
                    atividadeProfissionalArray.push({ [`contracheque`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_contra_cheque2?.nome)) {
                    atividadeProfissionalArray.push({ [`contracheque2`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_contra_cheque3?.nome)) {
                    atividadeProfissionalArray.push({ [`contracheque3`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_ctps?.nome)) {
                    atividadeProfissionalArray.push({ [`carteira_trabalho`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_imposto_renda?.nome)) {
                    atividadeProfissionalArray.push({ [`imposto_renda`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_extrato_bancario?.nome)) {
                    atividadeProfissionalArray.push({ [`extrato_bancario`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_extrato_bancario2?.nome)) {
                    atividadeProfissionalArray.push({ [`extrato_bancario2`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_extrato_bancario3?.nome)) {
                    atividadeProfissionalArray.push({ [`extrato_bancario3`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_outro?.nome)) {
                    atividadeProfissionalArray.push({ [`outro`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.nome_empresa)) {
                    atividadeProfissionalArray.push({ [`nome_empresa`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.tempo_empresa?.descricao)) {
                    atividadeProfissionalArray.push({ [`tempo_empresa`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.cargo_atual)) {
                    atividadeProfissionalArray.push({ [`cargo_atual`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.endereco?.cep)) {
                    atividadeProfissionalArray.push({ [`cep_empresa_atual`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.endereco?.logradouro)) {
                    atividadeProfissionalArray.push({ [`logradouro_empresa_atual`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.endereco?.numero)) {
                    atividadeProfissionalArray.push({ [`numero_empresa_atual`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.endereco?.bairro)) {
                    atividadeProfissionalArray.push({ [`bairro_empresa_atual`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.endereco?.cidade)) {
                    atividadeProfissionalArray.push({ [`cidade_empresa_atual`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.endereco?.estado)) {
                    atividadeProfissionalArray.push({ [`estado_empresa_atual`]: value });
                }
                if (!nulos.includes(fiadorValue?.atividade_profissional?.endereco?.complemento)) {
                    atividadeProfissionalArray.push({ [`complemento_empresa_atual`]: value });
                }

                const mergeObjectAtividadeProfissional = atividadeProfissionalArray.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectAtividadeProfissional });
            //imovel em garantia
            case 4:
                const imovelGarantia = [];
                imovelGarantia.push(
                    { [`matricula_imovel`]: value },
                    { [`certidao_matricula`]: value }
                );

                const mergeObjectImovelGarantia = imovelGarantia.reduce(function (result: any, current: any) {
                    return Object.assign(result, current);
                }, {});

                return setValueForm({ ...valueForm, ...mergeObjectImovelGarantia });
        }
    }

    // console.log(valueForm);

    const secaoItsFull = (secao: number) => {

        const values = [
            // 0 - Identificação 
            [
                "nome_fiador",
                "telefone_fiador",
                "telefone2_fiador",
                "rg_cnh_frente_fiador",
                "rg_cnh_verso_fiador",
                "cpf_fiador",
                "email_fiador",
                "estado_civil_fiador",
                "numb_dependentes_fiador",
            ],
            //1 - Cônjuge
            [
                "nome_conjuge",
                "telefone_conjuge",
                "telefone2_conjuge",
                "rg_cnh_frente_conjuge",
                "rg_cnh_verso_conjuge",
                "cpf_conjuge",
                "email_conjuge",
                "tipo_atividade_profissional_conjuge",
                "renda_mensal_conjuge"
            ],
            //2 - Residência Atual
            [
                "sua_residencia_atual_e",
                "comprovante_endereco_residencia_atual",
                "cep_residencia_atual",
                "logradouro_residencia_atual",
                "numero_residencia_atual",
                "bairro_residencia_atual",
                "cidade_residencia_atual",
                "estado_residencia_atual",
            ],
            // 3 - Atividade Profissional
            [
                "tipo_atividade_profissional",
                "renda_mensal",
            ],
            //4 - Imóvel Garantia
            [
                "certidao_matricula",
                "matricula_imovel",
            ],

        ];

        if (!nulos.includes(fiadorValue?.informacoes_basicas?.naturalidade)) {
            values[0].push("naturalidade_fiador");
        }
        if (!nulos.includes(fiadorValue?.informacoes_basicas?.nacionalidade)) {
            values[0].push("nacionalidade_fiador");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.informacoes_basicas?.naturalidade)) {
            values[1].push("naturalidade_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.informacoes_basicas?.nacionalidade)) {
            values[1].push("nacionalidade_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.nome_empresa)) {
            values[1].push("nome_empresa_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.tempo_empresa?.descricao)) {
            values[1].push("tempo_empresa_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.cargo_atual)) {
            values[1].push("cargo_atual_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_contra_cheque?.nome)) {
            values[1].push("contracheque_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_contra_cheque2?.nome)) {
            values[1].push("contracheque2_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_contra_cheque3?.nome)) {
            values[1].push("contracheque3_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_ctps?.nome)) {
            values[1].push("carteira_trabalho_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_imposto_renda?.nome)) {
            values[1].push("imposto_renda_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_extrato_bancario?.nome)) {
            values[1].push("extrato_bancario_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_extrato_bancario2?.nome)) {
            values[1].push("extrato_bancario2_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_extrato_bancario3?.nome)) {
            values[1].push("extrato_bancario3_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.ramo_atividade)) {
            values[1].push("ramo_atividade_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.arquivo_outro?.nome)) {
            values[1].push("outro_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.endereco?.cep)) {
            values[1].push("cep_empresa_atual_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.endereco?.logradouro)) {
            values[1].push("logradouro_empresa_atual_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.endereco?.numero)) {
            values[1].push("numero_empresa_atual_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.endereco?.bairro)) {
            values[1].push("bairro_empresa_atual_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.endereco?.cidade)) {
            values[1].push("cidade_empresa_atual_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.endereco?.estado)) {
            values[1].push("estado_empresa_atual_conjuge");
        }
        if (!nulos.includes(fiadorValue?.conjuge?.atividade_profissional?.endereco?.complemento)) {
            values[1].push("complemento_empresa_atual_conjuge");
        }
        if (!nulos.includes(fiadorValue?.endereco?.complemento)) {
            values[2].push("complemento_residencia_atual");
        }
        if (!nulos.includes(fiadorValue?.residencia_atual?.valor_aluguel)) {
            values[2].push("valor_aluguel_residencia_atual");
        }
        if (!nulos.includes(fiadorValue?.residencia_atual?.tempo_locacao)) {
            values[2].push("tempo_locacao_residencia_atual");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.outra_atividade_profissional)) {
            values[3].push("atividade_profissional");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.ramo_atividade)) {
            values[3].push("ramo_atividade");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_contra_cheque?.nome)) {
            values[3].push("contracheque");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_contra_cheque2?.nome)) {
            values[3].push("contracheque2");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_contra_cheque3?.nome)) {
            values[3].push("contracheque3");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_ctps?.nome)) {
            values[3].push("carteira_trabalho");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_imposto_renda?.nome)) {
            values[3].push("imposto_renda");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_extrato_bancario?.nome)) {
            values[3].push("extrato_bancario");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_extrato_bancario2?.nome)) {
            values[3].push("extrato_bancario2");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_extrato_bancario3?.nome)) {
            values[3].push("extrato_bancario3");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.arquivo_outro?.nome)) {
            values[3].push("outro");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.nome_empresa)) {
            values[3].push("nome_empresa");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.tempo_empresa?.descricao)) {
            values[3].push("tempo_empresa");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.cargo_atual)) {
            values[3].push("cargo_atual");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.endereco?.cep)) {
            values[3].push("cep_empresa_atual");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.endereco?.logradouro)) {
            values[3].push("logradouro_empresa_atual");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.endereco?.numero)) {
            values[3].push("numero_empresa_atual");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.endereco?.bairro)) {
            values[3].push("bairro_empresa_atual");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.endereco?.cidade)) {
            values[3].push("cidade_empresa_atual");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.endereco?.estado)) {
            values[3].push("estado_empresa_atual");
        }
        if (!nulos.includes(fiadorValue?.atividade_profissional?.endereco?.complemento)) {
            values[3].push("complemento_empresa_atual");
        }

        // console.log(values);

        const isFullTrue = Object.values(values[secao]).every(item => valueForm[`${item}`] === true);
        const isFullFalse = Object.values(values[secao]).every(item => valueForm[`${item}`] === false);

        return isFullTrue && !isFullFalse ? isFullTrue : !isFullTrue && isFullFalse ? !isFullFalse : null;
    }

    const onTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        getValidacao();
        getFiador();
        onTop();
    }, []);

    useEffect(() => {
        setSections({
            ...sections,
            identificacao: secaoItsFull(0),
            conjuge: secaoItsFull(1),
            residencia_atual: secaoItsFull(2),
            atividade_profissional: secaoItsFull(3),
            imovel_garantia: secaoItsFull(4),
        });
    }, [valueForm]);

    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
                <Modal
                    isOpen={open}
                    isClose={() => setOpen(false)}
                    headerStyle={''}
                    closeOnOutsideClick={true}
                    widthModal={`40%`}
                    modalStyle={`modal-style bg-white rounded-lg py-5 px-10`}
                >
                    <div>
                        <form onSubmit={onSubmitReprovacao} className={`flex flex-col items-center`}>
                            <div className="flex">
                                <p className="font-extrabold text-center text-2xl mb-4 font-mont-bold" style={{ color: "#081E52" }}>Adicione o motivo da reprovação</p>
                            </div>
                            <div className="flex w-full">
                                <textarea
                                    rows={2}
                                    name={`motivoReprovacao`}
                                    value={motivo ?? null}
                                    style={{ maxHeight: "15rem", minHeight: "3.5rem" }}
                                    className="block p-4 w-full h-20 mt-1 text-sm bg-gray-ligth-system-mega-imoveis text-gray-system-mega-imoveis rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                                    placeholder={'Dê detalhes do porquê o cadastro foi reprovado'}
                                    required={true}
                                    onChange={onchange}
                                />
                            </div>
                            <div className="flex w-full justify-center">
                                <button
                                    type="submit"
                                    className={`flex w-full h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs bg-blue-mega-imoveis text-white cursor-pointer rounded-lg justify-center items-center`}
                                >
                                    Enviar para o Fiador
                                </button>
                            </div>
                        </form>

                    </div>
                </Modal>
                <FormularioCadastroFiador
                    fiador={fiadorValue}
                    campos={valueForm}
                    handleClick={handleClick}
                    selectSection={selectOneSecao}
                    sections={sections}
                    onChange={onchange}
                    openModal={handleRejected}
                    itsView={itsView}
                    etapaId={etapa}
                />
            </Loader>
        </Fragment>
    );
}

export default FormularioValidateCadastroFiador;