import React from 'react';
import Header from '../Universal/HeaderPDF';
import Footer from '../Universal/FooterPDF';
import Linha from '../Universal/Linha';
import { mask } from 'src/helpers/functions';

interface ModeloProps {
    proprietario?: any
    tipo_proprietario?: any
    tipo_header?: any
    nome?: any
    data_imovel?: any
}

const Modelo4: React.FC<ModeloProps> = ({ proprietario, tipo_proprietario, tipo_header, nome, data_imovel }) => {
    const isCnh = proprietario?.informacoes_basicas?.nacionalidade != null && proprietario?.informacoes_basicas?.naturalidade != null ? true : false;
    return (
        <>
            {/* Página 1 */}
            <div className='pagina-pdf'>
                <Header
                    tipo={tipo_header}
                    nome={nome}
                    data_imovel={data_imovel}
                />
                <div className='body-pdf'>
                    <h1 className='sub-title estilo-fonte-negrito'>
                        Proprietário {tipo_proprietario}
                    </h1>
                    <h1 className='sub-sub-title estilo-fonte-negrito'>
                        Identificação
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <Linha campo10='CPF*' valor10={mask(proprietario?.informacoes_basicas?.cpf, "cpf_cnpj")} campo20='Telefone 1*' valor20={mask(proprietario?.informacoes_basicas?.telefone1, "telefone")} campo21='Telefone 2' valor21={mask(proprietario?.informacoes_basicas?.telefone2, "telefone")} />
                    <Linha campo10='Nome*' valor10={proprietario?.informacoes_basicas?.nome_cliente} campo20='E-mail*' valor20={proprietario?.informacoes_basicas?.email} />
                    <h1 className='text-xs'>Documento de Identificação Oficial com Foto*</h1>
                    <Linha campo10={`${isCnh ? 'CNH' : 'RG'} Frente*`} valor10={proprietario?.informacoes_basicas?.rg_cnh_frente?.nome} campo20='Estado Civil*' valor20={proprietario?.informacoes_basicas?.estado_civil?.descricao} />
                    <Linha campo10={`${isCnh ? 'CNH' : 'RG'} Verso*`} valor10={proprietario?.informacoes_basicas?.rg_cnh_verso?.nome} campo20={isCnh ? 'Naturalidade' : undefined} valor20={isCnh ? proprietario?.informacoes_basicas?.naturalidade : undefined} campo21={isCnh ? 'Nacionalidade' : undefined} valor21={isCnh ? proprietario?.informacoes_basicas?.nacionalidade : undefined} />

                    <div className='borda-principal-pdf mt-4'></div>

                    <h1 className='sub-sub-title estilo-fonte-negrito'>
                        Residência Atual
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <Linha campo10='CEP' valor10={mask(proprietario?.endereco?.cep, "cep")} campo20='Complemento' valor20={proprietario?.endereco?.complemento} />
                    <Linha isEndereco1={true} campo10='Logradouro*' valor10={proprietario?.endereco?.logradouro} campo11='Numero*' valor11={proprietario?.endereco?.numero} campo20='Bairro*' valor20={proprietario?.endereco?.bairro} />
                    <Linha campo10='Comprovante de Endereço' valor10={proprietario?.residencia_atual?.arquivo?.nome} campo20='Cidade' valor20={proprietario?.endereco?.cidade} campo21='Estado' valor21={proprietario?.endereco?.estado} />

                    <div className='borda-principal-pdf mt-4'></div>

                    <h1 className='sub-sub-title estilo-fonte-negrito'>
                        Forma de Recebimento
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <Linha campo10='Como deseja Receber?*' valor10={proprietario?.forma_recebimento?.descricao} campo20='Banco*' valor20={proprietario?.dados_bancarios?.banco} />
                    <Linha campo10='Qual o tipo da conta?*' valor10={proprietario?.dados_bancarios?.tipo_conta?.descricao} campo20='Titular*' valor20={proprietario?.dados_bancarios?.favorecido} />
                    <Linha campo10='O titular da conta é outra pessoa?*' valor10={proprietario?.dados_bancarios?.titular_conta == true ? 'Não' : 'Sim'} campo20='Agência*' valor20={proprietario?.dados_bancarios?.agencia} campo21='Operação' valor21={proprietario?.dados_bancarios?.operacao} campo22='N° da Conta*' valor22={proprietario?.dados_bancarios?.conta} />
                </div>

                <div className='footer-pdf'>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default Modelo4;