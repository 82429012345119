import React, { createContext, useContext, useState } from 'react';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import JSZip from 'jszip';
import ReactDOMServer from 'react-dom/server';
import ComponentToPDF from 'src/pages/Cadastros/Fiador/ExportPDF/ComponentToPdf';

const ControllFiadorContext = createContext({});

interface IControllContextMenuProps {
    children: React.ReactNode
}

const ControllFiadorProvider: React.FC<IControllContextMenuProps> = React.memo(({ children }) => {
    const [fiadorPDF, setFiadorPDF] = useState<any>();

    const setFiaPDF = (fiador: any) => {
        setFiadorPDF(fiador)
    }

    const exportFiadorPDF = async () => {
        const element = document.createElement('div');
        const componentHTML = ReactDOMServer.renderToString(<ComponentToPDF fiador={fiadorPDF} />);
        element.innerHTML = componentHTML;

        const pageBreakElement = document.createElement('div');
        pageBreakElement.classList.add('html2pdf__page-break');
        element.appendChild(pageBreakElement);

        const options = {
            filename: `Ficha Fiador - ${fiadorPDF?.informacoes_basicas?.nome_cliente}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };

        html2pdf().set(options).from(element).save();
    };

    const downloadFiadorFiles = async () => {
        var urls: { [key: string]: string } = {};
        urls["RGCNH Frente"]                    = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.informacoes_basicas?.rg_cnh_frente?.nome
        urls["RGCNH Verso"]                     = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.informacoes_basicas?.rg_cnh_verso?.nome
        urls["RGCNH Frente Cônjuge"]            = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.conjuge?.informacoes_basicas?.rg_cnh_frente?.nome
        urls["RGCNH Verso Cônjuge"]             = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.conjuge?.informacoes_basicas?.rg_cnh_verso?.nome
        urls["Último Extrato Cartão Cônjuge"]   = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.conjuge?.informacoes_basicas?.arquivo_ultimo_extrato_cartao?.nome
        urls["Contra Cheque"]                   = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.conjuge?.informacoes_basicas?.arquivo_contra_cheque?.nome
        urls["Imposto de Renda"]                = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.conjuge?.informacoes_basicas?.arquivo_imposto_renda?.nome
        urls["CTPS Cônjuge"]                    = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.conjuge?.informacoes_basicas?.ctps?.nome
        urls["CTPS"]                            = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.atividade_profissional?.arquivo_ctps?.nome
        urls["Contra Cheque"]                   = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.atividade_profissional?.arquivo_contra_cheque?.nome
        urls["Imposto de Renda"]                = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.atividade_profissional?.arquivo_imposto_renda?.nome
        urls["Extrato Bancário"]                = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.atividade_profissional?.arquivo_extrato_bancario?.nome
        urls["Outros"]                          = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.atividade_profissional?.arquivo_outro?.nome
        urls["Último Extrato Cartão"]           = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.atividade_profissional?.arquivo_ultimo_extrato_cartao?.nome
        urls["Residência Atual"]                = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.residencia_atual?.arquivo?.nome
        urls["RGCNH Frente Procurador"]         = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.procurador?.informacao_basica?.rg_cnh_frente?.nome
        urls["RGCNH Verso Procurador"]          = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.procurador?.informacao_basica?.rg_cnh_verso?.nome
        urls["Procuração"]                      = "https://" + window.location.hostname + "/storage/images/" + fiadorPDF?.procurador?.documento_procuracao?.nome

        const zip = new JSZip();

        try {
            const promises = Object.keys(urls).map(async (key, index) => {
                const url = urls[key];
                if (url != "https://" + window.location.hostname + "/storage/images/undefined") {
                    const response = await fetch(url);
                    if (!response.ok) {
                        throw new Error('Erro ao baixar o arquivo: ' + response.status);
                    }
                    const blob = await response.blob();
                    const fileName = key + "." + url.substring(url.lastIndexOf('.') + 1);
                    zip.file(fileName, blob);
                }
            });

            await Promise.all(promises);

            const content = await zip.generateAsync({ type: 'blob' });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(content);
            link.download = `Arquivos Fiador - ${fiadorPDF?.informacoes_basicas?.nome_cliente}.zip`;
            link.click();

            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Erro ao criar o arquivo zip:', error);
        }
    }

    return (
        <ControllFiadorContext.Provider value={{
            fiadorPDF,
            setFiaPDF,
            exportFiadorPDF,
            downloadFiadorFiles
        }}>
            {children}
        </ControllFiadorContext.Provider>
    );
});

function useControlContextFiador(): any {
    const context = useContext(ControllFiadorContext);
    if (!context) {
        throw new Error("useControll must a be used with ControllFiadorProvider");
    }
    return context;
}

export { ControllFiadorProvider, useControlContextFiador };
