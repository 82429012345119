import React, { createContext, useContext, useState } from 'react';


const ControllMobileContext = createContext({});

interface IControllContextMobileProps {
    children: React.ReactNode
}

const ControllMobileProvider: React.FC<IControllContextMobileProps> = React.memo(({ children }) => {
    const [mobile, setMobile] = useState<boolean>();
    const [activeSideBar, setActiveSideBar] = useState<boolean>(false);

    const activeSideBarMobile = () => {
        setActiveSideBar(!activeSideBar);
    }

    return (
        <ControllMobileContext.Provider value={{
            mobile,
            setMobile,
            activeSideBarMobile,
            setActiveSideBar,
            activeSideBar
        }}>
            {children}
        </ControllMobileContext.Provider>
    );
});

function useControlContextMobile(): any {
    const context = useContext(ControllMobileContext);
    if (!context) {
        throw new Error("useControll must a be used with ControllMobileProvider");
    }
    return context;
}

export { ControllMobileProvider, useControlContextMobile };
