import React, { Fragment } from 'react';
import { MdArrowBackIos } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';
import './style.css';
import { headers } from "./data-header.json"
import { useControlContextPermissoes } from 'src/contexts/PermissoesContext';
import { useControlContextUsuarios } from 'src/contexts/UsuariosContext';
import { useControlContextCargo } from 'src/contexts/CargoContext';
import { useControlContextSedes } from 'src/contexts/SedesContext';
import iconZip from "src/assets/icones/zip-icon.svg";
import iconPdf from "src/assets/icones/pdf-icon.svg";
import { useControlContextLocatario } from 'src/contexts/LocatarioContext';
import { useControlContextProprietario } from 'src/contexts/ProprietarioContext';
import { useControlContextImovel } from 'src/contexts/ImovelContext';
import { useControlContextFiador } from 'src/contexts/FiadorContext';

type Props = {
    children?: React.ReactNode;
};

function Header({ children }: Props) {
    const location = useLocation();
    const itsDetails = location.pathname?.split('/').includes('detalhes');
    const itsPDF = location.pathname?.split('/').includes('view') && location.pathname?.split('/')[2] == 'locatario' ||
        location.pathname?.split('/').includes('view') && location.pathname?.split('/')[2] == 'imovel' ||
        location.pathname?.split('/').includes('view') && location.pathname?.split('/')[2] == 'fiador' ||
        location.pathname?.split('/').includes('view') && location.pathname?.split('/')[2] == 'proprietario';
    const itsExportArquivos = location.pathname?.split('/').includes('view') && location.pathname?.split('/')[2] == 'locatario' ||
        location.pathname?.split('/').includes('view') && location.pathname?.split('/')[2] == 'imovel' ||
        location.pathname?.split('/').includes('view') && location.pathname?.split('/')[2] == 'fiador' ||
        location.pathname?.split('/').includes('view') && location.pathname?.split('/')[2] == 'proprietario';
    const itsNews = location.pathname?.split('/').includes('nova-noticia');
    const itsConsulta = location.pathname?.split('/').includes('consulta');
    const history = useHistory();
    const {
        savePermissoes,
    } = useControlContextPermissoes();
    const {
        saveUsuario,
        saveCliente
    } = useControlContextUsuarios();
    const {
        criarNovoCargo
    } = useControlContextCargo();
    const {
        saveSede
    } = useControlContextSedes();
    const {
        exportLocatarioPDF,
        downloadLocatarioFiles
    } = useControlContextLocatario();
    const {
        exportProprietarioPDF,
        downloadProprietarioFiles
    } = useControlContextProprietario();
    const {
        exportImovelPDF,
        downloadImovelFiles
    } = useControlContextImovel();
    const {
        exportFiadorPDF,
        downloadFiadorFiles
    } = useControlContextFiador();

    const path = location.pathname?.split('/detalhes')[0];

    const handleSave = () => {
        if (dataHeader[0]?.path == "/auxiliares/colaboradores/novo-colaborador") saveUsuario(history)
        else if (dataHeader[0]?.path == "/auxiliares/cliente") saveCliente(history)
        else if (dataHeader[0]?.path == "/auxiliares/cargos") criarNovoCargo()
        else if (dataHeader[0]?.path == "/auxiliares/sede") saveSede(history)
        else savePermissoes(history)
    }

    const toPDF = () => {
        if (location.pathname?.split('/')[2] == 'locatario')
            exportLocatarioPDF()
        else if (location.pathname?.split('/')[2] == 'proprietario')
            exportProprietarioPDF()
        else if (location.pathname?.split('/')[2] == 'imovel')
            exportImovelPDF()
        else if (location.pathname?.split('/')[2] == 'fiador')
            exportFiadorPDF()
    }

    const exportFiles = () => {
        if (location.pathname?.split('/')[2] == 'locatario')
            downloadLocatarioFiles()
        else if (location.pathname?.split('/')[2] == 'proprietario')
            downloadProprietarioFiles()
        else if (location.pathname?.split('/')[2] == 'imovel')
            downloadImovelFiles()
        else if (location.pathname?.split('/')[2] == 'fiador')
            downloadFiadorFiles()
    }

    const dataHeader = headers.filter((item: any) => { return item.path === path });
    const classNameButtom = "font-semibold my-4 mx-2 p-2 uppercase focus:outline-none text-white rounded-lg";

    return (<Fragment>
        <header className="header w-full z-10 ">
            <div className="flex justify-between">
                <div className='flex flex-row justify-center items-center'>
                    {
                        (itsDetails || itsNews || itsConsulta) && <button className="flex pl-10 focus:outline-none " onClick={() => history.goBack()}>
                            <MdArrowBackIos size={'2.3rem'} className={`text-white`} />
                        </button>
                    }
                    <h1 style={{ color: "$fff" }}>{dataHeader.map((e: any) => { return e?.header })} {itsDetails ? " > Detalhes do Cadastro" : ""}</h1>
                </div>
                {
                    itsPDF ?
                        <div className='botoes-pdf'>
                            <div onClick={() => toPDF()} className={`botao-gerar-pdf`} style={{ backgroundColor: '#6C7AFF' }}>
                                <img className="icon-zip-file" alt="icon_crown" src={iconPdf} /> Gerar PDF
                            </div>
                            {itsExportArquivos ?
                                <div onClick={() => exportFiles()} className={`botao-gerar-pdf`} style={{ backgroundColor: '#6C7AFF' }}>
                                    <img className="icon-zip-file" alt="icon_crown" src={iconZip} /> Arquivos
                                </div> : ''}
                        </div> :
                        dataHeader[0]?.contentButtom == "novo cargo" || dataHeader[0]?.contentButtom == "salvar alterações" ?
                            dataHeader.map((e: any) => { return e?.withButtom }) &&
                            <button onClick={() => handleSave()} className={`${dataHeader.map((e: any) => { return e?.classNameButtom })}` ?? classNameButtom} style={{ backgroundColor: '#6C7AFF' }}>
                                {dataHeader.map((e: any) => { return e?.contentButtom })}
                            </button>
                            : dataHeader[0]?.contentButtom != "salvar" ?
                                dataHeader.map((e: any) => { return e?.withButtom }) &&
                                <button onClick={() => history.push(`${dataHeader.map((e: any) => { return e?.nextPath })}`)} className={`${dataHeader.map((e: any) => { return e?.classNameButtom })}` ?? classNameButtom} style={{ backgroundColor: '#6C7AFF' }}>
                                    {dataHeader.map((e: any) => { return e?.contentButtom })}
                                </button>
                                :
                                dataHeader.map((e: any) => { return e?.withButtom }) &&
                                <button onClick={() => { handleSave() }} className={`${dataHeader.map((e: any) => { return e?.classNameButtom })}` ?? classNameButtom} style={{ backgroundColor: '#6C7AFF' }}>
                                    {dataHeader.map((e: any) => { return e?.contentButtom })}
                                </button>
                }
            </div>
        </header>
        {children}
    </Fragment>
    );
}

export default Header;
