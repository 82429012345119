import React from 'react';
import { ClassicProps } from 'src/@types/types';
import { formatarDataRestricao } from 'src/components/Basicos/funcoes';


type Props = {
    restricao?: any;
};

export default function ChequeSemFundo({
    restricao
}: Props & ClassicProps): JSX.Element {

    return (
        <>
            <div className='flex'>
                <h1>Tipo:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.tipo}</h1>
                <h1 className='ml-2'>Data Ocorrência:</h1>
                <h1 className='ml-2 cor-cinza'>{formatarDataRestricao(restricao?.dataOcorrencia)}</h1>
            </div>
            <div className='flex'>
                <h1>Número do cheque:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.numerocheque ?? restricao?.numCheque}</h1>
                <h1 className='ml-2'>Alínea do cheque:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.alineacheque ?? restricao?.alinea}</h1>
            </div>
            <div className='flex'>
                <h1>Quantidade de CCF para o banco:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.qtdeccf ?? restricao?.qtdeCcf}</h1>
                <h1 className='ml-2'>Valor total do cheque:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.valortotalcheque}</h1>
            </div>
            <div className='flex'>
                <h1>Número do banco:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.numerobanco ?? restricao?.numBanco}</h1>
                <h1 className='ml-2'>Nome do banco:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.nomebanco ?? restricao?.nomeBanco}</h1>
            </div>
            <div className='flex'>
                <h1>Valor:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.valor}</h1>
                <h1>Número da agência:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.numeroagencia ?? restricao?.agencia}</h1>
            </div>
            <div className='flex mb-4'>
                <h1>Cidade da agência:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.cidadeagencia ?? restricao?.cidade}</h1>
                <h1>UF da agência:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.cidadeagencia ?? restricao?.uf}</h1>
            </div>
        </>
    );
}
