import React, { useEffect, useState } from 'react';
import { formatarValor } from 'src/components/Basicos/funcoes';
import './style.css';
import Popup from 'reactjs-popup';
import ModalPFMobile from './ModalPFMobile';
import ModalPJMobile from './ModalPJMobile';

type Props = {
    open: boolean;
    isClose?: any;
    locatario?: any;
    fiador?: any;
    analiseFinanceira?: any;
    timeline?: any;
}

const ModalAnaliseFinanceiraMobile: React.FC<Props> = ({
    isClose,
    open,
    locatario,
    fiador,
    analiseFinanceira,
    timeline
}: Props): JSX.Element => {
    const [data, setData] = useState<any>();
    const [conjuge, setConjuge] = useState<any>();
    const [valorAluguel, setValorAluguel] = useState<any>();
    const [valorCondominio, setValorCondominio] = useState<any>();

    useEffect(() => {
        if (open == true && (locatario || fiador)) {
            setValorAluguel(locatario?.imovel_pretendido?.imovel?.preco_locacao ?? fiador?.locatario?.imovel_pretendido?.imovel?.preco_locacao ?? 0);
            setValorCondominio(locatario?.imovel_pretendido?.imovel?.condominio ?? fiador?.locatario?.imovel_pretendido?.imovel?.condominio ?? 0);
            setConjuge(locatario?.conjuge ?? fiador?.conjuge);
            setData(locatario ?? fiador);
        }
    }, [open])

    return (
        // widthModal={conjuge != null ? `85%` : `45%`}
        <Popup
            modal
            open={open}
            closeOnDocumentClick={isClose}
            onClose={isClose}
            lockScroll={true}
            contentStyle={{
                marginBottom: '50px', // Adicione o valor desejado para a margem inferior
            }}
        >
            <div className={`modal-analise-${conjuge != null ? 'conjuge-' : ''}mobile${window.innerWidth <= 375 ? '-pequeno' : ''}`}>
                <h1 className='text-2xl estilo-fonte-negrito'>Resultado Consulta Serasa</h1>
                <div className='flex header-box mt-4 mb-4'>
                    {data?.tipo_locatario_id == 1 ?
                        <>
                            <div className={`box-header-analise-financeira estilo-1`}>
                                <h1 className={`${window.innerWidth <= 360 ? "fonte-mobile-menor" : ""}`}>Valor do Aluguel</h1>
                                <h1 className={`${window.innerWidth <= 360 ? "fonte-mobile-menor" : ""}`}>{formatarValor(valorAluguel, "dinheiro")}</h1>
                            </div>
                            <div className={`box-header-analise-financeira estilo-1`}>
                                <h1 className={`${window.innerWidth <= 360 ? "fonte-mobile-menor" : ""}`}>Pacote de Locação</h1>
                                <h1 className={`${window.innerWidth <= 360 ? "fonte-mobile-menor" : ""}`}>{formatarValor(Number(valorAluguel) + Number(valorCondominio), "dinheiro")}</h1>
                            </div>
                            <div className='box-header-analise-financeira estilo-1'>
                                <h1 className={`${window.innerWidth <= 360 ? "fonte-mobile-menor" : ""}`}>Total da Renda</h1>
                                <h1 className={`${window.innerWidth <= 360 ? "fonte-mobile-menor" : ""}`}>{formatarValor(Number(timeline?.renda_total), "dinheiro")}</h1>
                            </div>
                        </>
                        : <div className={`box-header-analise-financeira estilo-pj-1`}>
                            <h1 className={`${window.innerWidth <= 360 ? "fonte-mobile-menor" : ""}`}>Valor do Aluguel</h1>
                            <h1 className={`${window.innerWidth <= 360 ? "fonte-mobile-menor" : ""}`}>{formatarValor(valorAluguel, "dinheiro")}</h1>
                        </div>}
                </div>
                {
                    data?.tipo_locatario_id == 2 ?
                        <ModalPJMobile infos={data} /> :
                        conjuge != null ?
                            <div>
                                <ModalPFMobile infos={data} conjuge={conjuge} timeline={timeline} />
                                <ModalPFMobile infos={conjuge} conjuge={conjuge} timeline={timeline} />
                            </div>
                            :
                            <ModalPFMobile infos={data} timeline={timeline} />
                }
            </div>
        </Popup>
    );
}

export default ModalAnaliseFinanceiraMobile;