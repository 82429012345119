import React, { useState, useEffect } from "react";
import { ClassicProps } from "src/@types/types";
import { useControlContextCadastro } from "src/contexts/CadastroControlContext";
import Inputlabel from "../Form/Input/InputLabel";
import InputSelect from "../Form/Input/InputSelect";
import Title from "../Form/Title";
import "../SearchBar/style.css";
import { useControlContextMobile } from "src/contexts/MobileContext";

type Props = {
  setValue?: any;
  onChange?: any;
  layoutChamado?: boolean;
  isButton?: boolean;
  typeof?: string;
  btnStyle?: string;
  changeModal?: () => void;
  onClick?: () => void;
  onSubmit?: () => void;
  setType?: (f: String) => void;
  btnContent?: string,
  link?: string | undefined,
  inputTitle: string,
  dates: boolean,
  itsSelectSede?: boolean,
  proprietario?: boolean
};

type DateType = {
  dateInicial: String | null,
  dateFinal: String | null,
}

export default function SearchBar({
  setValue,
  value,
  onChange,
  placeholder,
  isButton = false,
  inputTitle,
  dates = true,
  itsSelectSede = false,
  proprietario = false
}: Props & ClassicProps): JSX.Element {
  const [typeInicio, setTypeInicio] = useState("text");
  const [typeFim, setTypeFim] = useState("text");
  const { setDateFilter, dateActive, setSedeCadastro, sedeActive, tiposSedesCadastro, setSedesOptions } = useControlContextCadastro();
  const { mobile } = useControlContextMobile();

  const [dataForm, setDataForm] = useState<DateType>({
    dateInicial: null,
    dateFinal: null
  });

  const onChangeDate = (event: any) => {
    const { name, value } = event.target;
    setDataForm({
      ...dataForm,
      [name]: value
    })
  };

  const onChangeEventSelect = (item: any, action: any) => {
    setSedeCadastro(item.value);
  }

  useEffect(() => {
    setDateFilter(dataForm.dateInicial, dataForm.dateFinal);
  }, [dataForm]);

  useEffect(() => {
    setSedesOptions();
  }, []);

  return (
    <div className={`${mobile ? "w-2/3" : "w-full"}`}>
      {inputTitle && (
        <Title
          className={`text-2xl font-bold font-mont-bold text-gray-system-mega-imoveis lg:ml-6`}
          content={`${inputTitle}`} />
      )}
      <div className="flex justify-start flex-col lg:flex-row gap-2 w-full">
        <div className={`relative w-full ${mobile ? "" : dates ? itsSelectSede ? 'lg:w-3/6 my-6' : 'lg:w-3/5 my-6' : 'w-full mt-6 mb-2'} overflow-hidden lg:ml-6 ${proprietario ? 'bar-100' : ''}`}>
          {isButton === false && (
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </div>
          )}
          <input
            type="search"
            id="search"
            name="search"
            className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder={placeholder}
            autoComplete="off"
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </div>
        {itsSelectSede ? <div className="w-full lg:w-1/6">
          <Title
            className={`text-base font-bold text-gray-system-mega-imoveis`}
            content={`Sede`} />
          <InputSelect
            name={`sede_id`}
            options={tiposSedesCadastro}
            className={`bg-white w-full text-gray-900 text-sm border-gray-300 border rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2`}
            colClassname={`w-full`}
            onChange={onChangeEventSelect}
            placeholder={"Selecione"}
            value={tiposSedesCadastro?.filter((option: any) => (option?.value === sedeActive))}
          />
        </div> : ''}
        {dates ? <div className="flex w-full lg:w-2/5 mb-12 lg:mb-0 flex-col lg:flex-row gap-2">
          <Inputlabel
            type={typeInicio}
            className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 p-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full`}
            colClassname={`w-full`}
            name={"dateInicial"}
            placeholder={`Período Início Vigência`}
            onFocus={() => setTypeInicio("date")}
            value={dateActive?.dateInicial ?? ""}
            onChange={onChangeDate}
            inputTitle={`Data Cadastro Inicial`}
            titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
          />
          <Inputlabel
            type={typeFim}
            className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4`}
            colClassname={`w-full`}
            name={"dateFinal"}
            placeholder={`Período Fim Vigência`}
            onFocus={() => setTypeFim("date")}
            value={dateActive?.dateFinal ?? ""}
            onChange={onChangeDate}
            inputTitle={`Data Cadastro Final`}
            titleClassname={`text-base font-bold text-gray-system-mega-imoveis`}
          />
        </div> : ""}

      </div>
    </div>
  );
}
