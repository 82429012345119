import React from "react";
// import { alertMsg } from "src/helpers/functions";
import './style.css'
import { mask } from "src/helpers/functions";
import { dateLaravelToPtBr } from "src/helpers/functions";

export type Props = {
    procurador?: any;
}

export default function Procurador({
    procurador
}: Props): JSX.Element {

    return (
        <div className={`procurador-section flex flex-col px-4 border rounded-2xl shadow-lg mb-8 ${procurador?.status == 1 ? '' : 'procurador-section-inativo'}`}>
            <div className='estilo-fonte-negrito'>
                {procurador?.informacao_basica.nome_cliente}
            </div>
            <div className="subtitle-procurador-section">
                {procurador?.informacao_basica.email} | {mask(procurador?.informacao_basica.telefone1, 'telefone')} | {mask(procurador?.informacao_basica.cpf, "cpf_cnpj")} | {dateLaravelToPtBr(procurador?.created_at)} | <span className={procurador?.status == 1 ? 'cor-ativo' : ''}>{procurador?.status == 1 ? 'Ativo' : 'Inativo'}</span>
            </div>
        </div>
    );
}