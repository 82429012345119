import React, { Fragment, useEffect, useState } from "react";
import iconAnalise from "src/assets/icones/icon-analise.svg"
import { AccordionTimeLine } from "../../AccordionTimeLine";
import CardTimeLapse from "../../Cards/CardTimeLapse";

export type Props = {
    fiadorId?: number;
    timeline?: any;
    setStatusTimeLine?: any,
    setEtapaAtual?: any,
    etapaAtual?: number,
    itsButtons?: boolean,
    statusSectionTimeline?: boolean,
    isPrincipal?: boolean,
    isSecundario?: boolean,
    fiador?: any
}

export default function TimelineCadastroFiador({
    fiadorId,
    timeline,
    setStatusTimeLine,
    setEtapaAtual,
    etapaAtual,
    itsButtons = true,
    statusSectionTimeline = true,
    fiador,
    isPrincipal = false,
    isSecundario = false
}: Props): JSX.Element {

    const [dataOption, setDataOption] = useState();
    const [estiloCard, setEstiloCard] = useState<any>([]);
    const [estiloCardConjuge, setEstiloCardConjuge] = useState<any>([]);

    const optionsAvaliacaoImovel: Array<any> = [
        { value: 10, label: "Realizado" },
        { value: 11, label: "Não Realizado" },
    ];

    const itsButtonCard2 = timeline[2]?.status_id === 7 || timeline[2]?.status_id === 8;
    // const itsButtonCard3 = timeline[3]?.status_id === 1 || timeline[3]?.status_id === 3;

    const testeScore = () => {
        if (fiador?.locatario?.imovel_pretendido?.imovel?.preco_locacao <= 700 && fiador?.conjuge?.analise_financeira?.score >= 301)
            return true
        else if (700.01 <= fiador?.locatario?.imovel_pretendido?.imovel?.preco_locacao && fiador?.locatario?.imovel_pretendido?.imovel?.preco_locacao <= 1500 && fiador?.conjuge?.analise_financeira?.score >= 501)
            return true
        else if (1500.01 <= fiador?.locatario?.imovel_pretendido?.imovel?.preco_locacao && fiador?.locatario?.imovel_pretendido?.imovel?.preco_locacao <= 3000 && fiador?.conjuge?.analise_financeira?.score >= 701)
            return true
        else if (3000.01 <= fiador?.locatario?.imovel_pretendido?.imovel?.preco_locacao && fiador?.conjuge?.analise_financeira?.score >= 701)
            return true
        else
            return false;
    }

    const loadEstiloAnalise = () => {
        var estilo1 = fiador?.analise_financeira != null ? true : false;
        var estilo2 = 2;
        if (estilo1 == true) {
            var estilo2 = fiador?.timeline?.[2].aprovado_renda == true && fiador?.timeline?.[2].aprovado_score == true && fiador?.timeline?.[2].aprovado_restricao == true ? 1 : 2;
        }
        setEstiloCard({
            estilo1: estilo1,
            estilo2: estilo2,
            titulo: "Fiador"
        })
        if (fiador.conjuge != undefined) {
            var estilo1 = fiador.conjuge?.analise_financeira != null ? true : false;
            var estilo2 = 2;
            if (estilo1 == true) {
                var estilo2 = fiador.conjuge?.analise_financeira.restricao == false && fiador?.timeline?.[2].aprovado_renda == true && testeScore() ? 1 : 2;

            }
            setEstiloCardConjuge({
                estilo1: estilo1,
                estilo2: estilo2,
                titulo: "Locatário"
            })
        }
    }

    const statusWithButton = [1, 2, 3, 5];

    useEffect(() => {
        if (dataOption !== undefined) {
            setStatusTimeLine(dataOption, fiadorId);
        }
    }, [dataOption]);

    useEffect(() => {
        loadEstiloAnalise();
    }, [])

    return (
        <Fragment>
            <div className="flex flex-col w-full scale-150">
                {/* <div className="text-left font-extrabold text-xl mb-4 font-mont-bold">
                    <span>Linha do Tempo</span>
                </div> */}
                {isPrincipal ? <div className="text-left text-xl mb-4 font-mont-bold">
                    <span>Linha do Tempo</span>
                </div> : ""}
                <AccordionTimeLine
                    disable={isPrincipal}
                    data={fiador}
                    type={'fiador'}
                    responsibleRegister={fiador?.responsavel_ficha}
                    isLocatarioSecundario={isSecundario}
                >
                    <div className={`flex flex-col p-4 ${isPrincipal ? 'bg-gray-ligth-system-mega-imoveis' : 'bg-white'} rounded-xl gap-2`}>

                        {isPrincipal ? <div className="flex flex-row justify-between font-mont-bold">
                            <div className="mt-4 text-lg">Fiador - {fiador?.informacoes_basicas?.nome_cliente}</div>
                            {/* <div className={`flex ml-auto h-16 w-16 items-center justify-center p-1 focus:outline-none `} onClick={() => { }}>
                                <img src={!isPrincipal ? iconCrown : iconCrownFill} className={`inset-0 ${!isPrincipal ? 'h-8 w-8' : 'h-12 w-12'} object-center`} alt="icon_crown" />
                            </div> */}
                        </div> : ""}
                        <div className={`flex flex-col justify-center items-center md:flex-row gap-4 w-full md:justify-between p-4`}>
                            <CardTimeLapse
                                etapa={1}
                                isIcon={timeline?.[1]?.status_id == 1 ? true : false}
                                status={timeline[1]?.status_id}
                                image={iconAnalise}
                                nextPathName={'view'}
                                cadastroId={fiadorId}
                                itsButton={itsButtons ?? true}
                                titleCard={'Cadastro Recebido'}
                                subTitleCard={timeline[1]?.updated_at ?? null}
                                active={timeline[1]?.status !== null}
                                buttonTitle={'visualizar cadastro'}
                                statusTimeline={statusSectionTimeline}
                                timeline={timeline}
                                cancelado={fiador?.status}
                            />
                            <CardTimeLapse
                                etapa={2}
                                colorText={'text-yellow-500'}
                                isIcon={false}
                                message={true}
                                status={timeline[2]?.status_id}
                                messageWarning={'Cadastro pré-aprovado'}
                                image={iconAnalise}
                                nextPathName={'#'}
                                cadastroId={fiadorId}
                                itsButton={itsButtons ? itsButtonCard2 : false}
                                titleCard={'Pré-análise Financeira'}
                                subTitleCard={timeline[2]?.updated_at ?? null}
                                active={timeline[2]?.status !== null}
                                buttonTitle={'acessar spc/serasa'}
                                statusTimeline={statusSectionTimeline}
                                estiloCard={estiloCard}
                                analiseFinanceira={fiador?.analise_financeira}
                                fiador={fiador}
                                conjugeId={fiador?.conjuge_id}
                                timeline={timeline}
                                cancelado={fiador?.status}
                                estiloCardConjuge={estiloCardConjuge}
                            />
                            <CardTimeLapse
                                etapa={3}
                                color={'bg-orange-500'}
                                colorText={'text-orange-500'}
                                status={timeline[3]?.status_id}
                                image={iconAnalise}
                                nextPathName={'analise-cadastro'}
                                cadastroId={fiadorId}
                                motivo={timeline[3]?.motivo ?? null}
                                itsButton={itsButtons && statusWithButton.includes(timeline[3]?.status_id)}
                                titleCard={'Análise do Cadastro'}
                                subTitleCard={timeline[3]?.updated_at ?? null}
                                buttonTitle={'Analisar cadastro'}
                                active={timeline[3]?.status !== null}
                                statusTimeline={statusSectionTimeline}
                                fiador={fiador}
                                disponivelAlterar={timeline[3]?.alterar}
                                cancelado={fiador?.status}
                            />
                            <CardTimeLapse
                                etapa={4}
                                status={timeline[4]?.status_id}
                                fiador={fiador}
                                image={iconAnalise}
                                cadastroId={fiadorId}
                                motivo={timeline[4]?.motivo ?? null}
                                itsButton={itsButtons && statusWithButton.includes(timeline[4]?.status_id) && timeline[4]?.alterar}
                                titleCard={timeline[4]?.descricao_etapa}
                                subTitleCard={timeline[4]?.updated_at ?? null}
                                buttonTitle={'Analisar cadastro'}
                                nextPathName={'analise-diretoria'}
                                active={timeline[4]?.status !== null}
                                statusTimeline={statusSectionTimeline}
                                disponivelAlterar={timeline[4]?.alterar}
                                cancelado={fiador?.status}
                                color={'bg-orange-500'}
                                colorText={'text-orange-500'}
                                timeline={timeline}
                            />
                            <CardTimeLapse
                                etapa={5}
                                status={timeline[5]?.status_id}
                                image={iconAnalise}
                                cadastroId={fiadorId}
                                motivo={timeline[5]?.motivo ?? null}
                                itsButton={false}
                                titleCard={'Vistoria de Entrada'}
                                itsButtonDropDown={timeline[5]?.status_id !== 10 && statusSectionTimeline}
                                options={optionsAvaliacaoImovel}
                                subTitleCard={timeline[5]?.updated_at ?? null}
                                active={timeline[5]?.status !== null}
                                setStateOption={setDataOption}
                                setStateEtapa={setEtapaAtual}
                                currentValue={timeline[5]?.status_id ?? null}
                                statusTimeline={statusSectionTimeline}
                                disponivelAlterar={timeline[5]?.alterar}
                                cancelado={fiador?.status}
                            />
                            <CardTimeLapse
                                etapa={6}
                                status={timeline[6]?.status_id}
                                image={iconAnalise}
                                cadastroId={fiadorId}
                                motivo={timeline[6]?.motivo ?? null}
                                itsButton={false}
                                titleCard={'Assinatura do Contrato'}
                                itsButtonDropDown={timeline[6]?.status_id !== 10 && statusSectionTimeline}
                                options={optionsAvaliacaoImovel}
                                subTitleCard={timeline[6]?.updated_at ?? null}
                                active={timeline[6]?.status !== null}
                                setStateOption={setDataOption}
                                setStateEtapa={setEtapaAtual}
                                currentValue={timeline[6]?.status_id ?? null}
                                statusTimeline={statusSectionTimeline}
                                disponivelAlterar={timeline[6]?.alterar}
                                cancelado={fiador?.status}
                            />
                        </div>
                    </div>
                </AccordionTimeLine>
            </div>
        </Fragment>
    );
}