import React from 'react';
import './style.css';
import { ajustUserAgent, converterDataBr, formatarValor } from 'src/components/Basicos/funcoes';
import { mask, statusColor } from 'src/helpers/functions';
import TimelimeCadastroLocatarioMobile from 'src/components/Basicos/Timeline/TimelineCadastroLocatario/indexMobile';
import { FaCircle } from "react-icons/fa";


type Props = {
    data: any
    timelineLocatarioPrincipal: any
    timelineLocatarioSecundario: any
    fiadores: any
    fiadorSection: any
    statusTimeLine: any
    setEtapaAtual: any
    etapaAtual: any
}

const IndexMobile: React.FunctionComponent<Props> = ({
    data,
    timelineLocatarioPrincipal,
    timelineLocatarioSecundario,
    fiadores,
    fiadorSection,
    statusTimeLine,
    setEtapaAtual,
    etapaAtual
}) => {
    return (
        <div className="p-6">
            <div className={`${data?.status == 2 ? 'bg-cancelado' : 'bg-white'} pt-10 pb-10 pl-5 pr-5 mb-10 flex flex-col space-y-5 rounded-lg border-gray-100 border h-full w-full`} style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>
                <div className='informacoes-basicas-mobile'>
                    <h1 className='font-mont-bold text-xl'>Informações do Locatário</h1>
                    <h1>Nome: <span className='text-gray-600'>{data?.informacoes_basicas?.nome_cliente}</span></h1>
                    <h1>E-mail: <span className='text-gray-600'>{data?.informacoes_basicas?.email}</span></h1>
                    <h1>Telefone: <span className='text-gray-600'>{mask(data?.informacoes_basicas?.telefone1, "telefone")}</span></h1>
                    <h1>CPF: <span className='text-gray-600'>{mask(data?.informacoes_basicas?.cpf ?? data?.informacoes_basicas?.cnpj, "cpf_cnpj")}</span></h1>
                    <h1>Data Cadastro: <span className='text-gray-600'>{converterDataBr(data?.created_at)}</span></h1>
                    <h1>Tempo de Atendimento: <span className='text-gray-600'>{data?.tempo_atendimento}</span></h1>
                </div>
                <div className='borda-separando'></div>
                <div className='informacoes-basicas-mobile'>
                    <h1 className='font-mont-bold text-xl'>Origem do Cadastro</h1>
                    <h1>Data e Hora: <span className='text-gray-600'>{converterDataBr(data?.created_at)}</span></h1>
                    <h1>Dispositivo: <span className='text-gray-600'>{data?.informacoes_dispositivo?.device_type}</span></h1>
                    <h1>Versão: <span className='text-gray-600'>{ajustUserAgent(data?.informacoes_dispositivo?.user_agent)}</span></h1>
                    <h1>Navegador: <span className='text-gray-600'>{data?.informacoes_dispositivo?.browser_name}</span></h1>
                    <h1>Versão do Navegador: <span className='text-gray-600'>{data?.informacoes_dispositivo?.browser_version}</span></h1>
                    <h1>Sistema Operacional: <span className='text-gray-600'>{data?.informacoes_dispositivo?.os_name}</span></h1>
                </div>
                <div className='borda-separando'></div>
                <div className='informacoes-basicas-mobile'>
                    <h1 className='font-mont-bold text-xl'>Informações do Imóvel</h1>
                    <h1>Código do Imóvel: <span className='text-gray-600'>{data?.imovel_pretendido?.imovel?.codigo_imovel ?? data?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.codigo_imovel}</span></h1>
                    <h1>Valor do Aluguel: <span className='text-gray-600'>{formatarValor(data?.imovel_pretendido?.imovel?.preco_locacao ?? data?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.preco_locacao, "dinheiro")}</span></h1>
                    <h1>Garantia: <span className='text-gray-600'>{data?.garantia?.descricao ?? data?.locatario_imovel?.locatario_principal?.garantia?.descricao}</span></h1>
                    <h1>Endereço: <span className='text-gray-600'>{data?.imovel_pretendido?.imovel?.endereco + ", " + data?.imovel_pretendido?.imovel?.numero + " - " + data?.imovel_pretendido?.imovel?.bairro ?? data?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.endereco + ", " + data?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.numero + " - " + data?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.bairro}</span></h1>
                    <h1>Sede: <span className='text-gray-600'>{data?.sede_id ?? data?.imovel_pretendido?.imovel?.sede_id ?? "-"}</span></h1>
                </div>
                <div className='borda-separando'></div>
                <div className='first-card-mobile'>
                    <h1 className='font-mont-bold text-xl'>Linha do Tempo</h1>
                    <TimelimeCadastroLocatarioMobile
                        locatarioId={data?.id}
                        timeline={timelineLocatarioPrincipal}
                        setStatusTimeLine={statusTimeLine}
                        setEtapaAtual={setEtapaAtual}
                        etapaAtual={etapaAtual}
                        isPrincipal={data?.principal}
                        locatario={data}
                        itsButtons={true}
                        atual={true} //arranjo tecnico para saber qual o card principal
                    />
                </div>
                <div className="flex flex-col">
                    {timelineLocatarioSecundario.map((item: any) => {
                        return <TimelimeCadastroLocatarioMobile
                            locatario={item}
                            locatarioId={item.id}
                            timeline={item?.timeLine}
                            setStatusTimeLine={statusTimeLine}
                            setEtapaAtual={setEtapaAtual}
                            etapaAtual={etapaAtual}
                            itsButtons={true}
                            isSecundario={!item?.principal}
                            isPrincipal={item?.principal}
                        />
                    })}
                    {fiadores.map((item: any) => {
                        return (fiadorSection(item))
                    })}
                </div>
                <div className='borda-separando'></div>
                <h1 className='font-mont-bold text-xl'>Histórico de Alterações</h1>
                {
                    data?.historico?.map((historico: any) => {
                        return (
                            <div className={`card-content pt-2 pb-2s pl-5 pr-5 mb-5`} style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>
                                <h1>Data: <span className='text-gray-600'>{converterDataBr(historico.created_at)}</span></h1>
                                <h1>Nome do Usuário: <span className='text-gray-600'>{historico.usuario?.nome}</span></h1>
                                <h1>Etapa: <span className='text-gray-600'>{historico.etapa_historico?.descricao}</span></h1>
                                <h1 className='flex'>Status: <span className='text-gray-600 flex items-center'><FaCircle size={'0.8rem'} color={`${statusColor(historico?.status_validacao_id)?.color}`}/>{statusColor(historico?.status_validacao_id)?.title}</span></h1>
                            </div>
                        )
                    })
                }
            </div>
        </div >
    );
}

export default IndexMobile;