import React, { Fragment } from 'react';
import { ClassicProps } from "src/@types/types";
import "../StageBar/style.css";


type Props = {
  onClick?: any;
  etapaActive: number;
  location?: string | undefined
};

export default function StageBar({
  onClick,
  etapaActive,
  location
}: Props & ClassicProps): JSX.Element {

  const getSequence = (currentLocation: string | undefined) => {
    switch (currentLocation) {
      case "/cadastro/locatario":
      case "/cadastro/fiador":
        return {
          '1': { 'stage': 1, 'label': 'Cadastro Recebido' },
          '2': { 'stage': 2, 'label': 'Pré-análise Financeira' },
          '3': { 'stage': 3, 'label': 'Análise do Cadastro' },
          '4': { 'stage': 4, 'label': 'Análise pela Diretoria' },
          '5': { 'stage': 5, 'label': 'Vistoria de Entrada' },
          '6': { 'stage': 6, 'label': 'Assinatura do Contrato' }
        };
      case "/cadastro/proprietario":
        return {
          '1': { 'stage': 1, 'label': 'Cadastro Recebido' },
          '2': { 'stage': 3, 'label': 'Análise do Cadastro' },
          '3': { 'stage': 7, 'label': 'Imóvel em Avaliação' },
          '4': { 'stage': 6, 'label': 'Assinatura do Contrato' },
          '5': { 'stage': 8, 'label': 'Imóvel Publicado' },
          '6': { 'stage': null, 'label': null }
        };
      case "/cadastro/imovel":
        return {
          '1': { 'stage': 1, 'label': 'Cadastro Recebido' },
          '2': { 'stage': 3, 'label': 'Análise do Cadastro' },
          '3': { 'stage': 7, 'label': 'Imóvel em Avaliação' },
          '4': { 'stage': 6, 'label': 'Assinatura do Contrato' },
          '5': { 'stage': 8, 'label': 'Imóvel Publicado' },
          '6': { 'stage': null, 'label': null }
        };
      default:
        return {};
    }
  }
  const getValue = (StatusKey: number) => {
    const sequenceStageBar = getSequence(location);
    return sequenceStageBar[StatusKey];
  }

  return (
    <Fragment>
      <div className="rounded-xl w-full flex-row gap-2 md:gap-1 flex h-auto mb-10 md:px-6 card-grid-stage-bar">
        <div className={`flex md:flex-row flex-col gap-2 md:gap-1 ${getValue(6).stage === null ? 'w-2/5' : 'w-1/3'} items-center mr-2 md:mr-0`}>
          <div className={`flex ${etapaActive === getValue(1).stage ? 'border-blue-mega-imoveis text-blue-mega-imoveis' : 'text-gray-500 border-gray-500'} border-2 rounded-xl h-16 w-full md:w-1/2 text-left pr-6 pl-3 py-2 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-xs`} onClick={() => onClick(getValue(1).stage)}>{getValue(1)?.label}</div>
          <div className={`flex ${etapaActive === getValue(2).stage ? 'border-blue-mega-imoveis text-blue-mega-imoveis' : 'text-gray-500 border-gray-500'} border-2 rounded-xl h-16 w-full md:w-1/2 text-left pr-6 pl-2 py-2 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-sm`} onClick={() => onClick(getValue(2).stage)}>{getValue(2)?.label}</div>
        </div>
        <div className={`flex md:flex-row flex-col gap-2 md:gap-1 ${getValue(6).stage === null ? 'w-2/5' : 'w-1/3'} items-center mr-2 md:mr-0`}>
          <div className={`flex ${etapaActive === getValue(3).stage ? 'border-blue-mega-imoveis text-blue-mega-imoveis' : 'text-gray-500 border-gray-500'} border-2 rounded-xl h-16 w-full md:w-1/2 text-left pr-6 pl-2 py-2 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-sm`} onClick={() => onClick(getValue(3).stage)}>{getValue(3)?.label}</div>
          <div className={`flex ${etapaActive === getValue(4).stage ? 'border-blue-mega-imoveis text-blue-mega-imoveis' : 'text-gray-500 border-gray-500'} border-2 rounded-xl h-16 w-full md:w-1/2 text-left pr-6 pl-2 py-2 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-sm`} onClick={() => onClick(getValue(4).stage)}>{getValue(4)?.label}</div>
        </div>
        <div className={`flex md:flex-row flex-col gap-2 md:gap-1 ${getValue(6).stage === null ? 'w-1/5' : 'w-1/3'} items-center mr-0 md:mr-0`}>
          <div className={`flex ${etapaActive === getValue(5).stage ? 'border-blue-mega-imoveis text-blue-mega-imoveis' : 'text-gray-500 border-gray-500'} border-2 rounded-xl h-16 w-full ${getValue(6).stage === null ? 'md:w-full' : 'md:w-1/2'} text-left pr-6 pl-2 py-2 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-sm`} onClick={() => onClick(getValue(5).stage)}>{getValue(5)?.label}</div>
          <div className={`${getValue(6).stage === null ? 'hidden' : 'flex'} ${etapaActive === getValue(6).stage ? 'border-blue-mega-imoveis text-blue-mega-imoveis' : 'text-gray-500 border-gray-500'} border-2 rounded-xl h-16 w-full md:w-1/2 text-left pr-6 pl-2 py-2 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-sm`} onClick={() => onClick(getValue(6).stage)}>{getValue(6)?.label}</div>
        </div>
      </div>
    </Fragment>
  );
}
