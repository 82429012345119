import React from 'react';
import { formatarValor } from 'src/components/Basicos/funcoes';
import { dateLaravelToPtBr, dateToPtBr, mask } from 'src/helpers/functions';
import './style.css';

type Props = {
    infos?: any;
    conjuge?: any;
    timeline?: any;
    principal?: any;
}

const ModalPFNovo: React.FC<Props> = ({
    infos,
    conjuge,
    timeline,
    principal
}: Props): JSX.Element => {

    const testeScore = () => {
        if (conjuge != null) {
            if (principal?.locatario?.imovel_pretendido?.imovel?.preco_locacao <= 700 && conjuge?.analise_financeira?.score >= 301)
                return 1;
            else if (700.01 <= principal?.locatario?.imovel_pretendido?.imovel?.preco_locacao && principal?.locatario?.imovel_pretendido?.imovel?.preco_locacao <= 1500 && conjuge?.analise_financeira?.score >= 501)
                return 1;
            else if (1500.01 <= principal?.locatario?.imovel_pretendido?.imovel?.preco_locacao && principal?.locatario?.imovel_pretendido?.imovel?.preco_locacao <= 3000 && conjuge?.analise_financeira?.score >= 701)
                return 1;
            else if (3000.01 <= principal?.locatario?.imovel_pretendido?.imovel?.preco_locacao && conjuge?.analise_financeira?.score >= 701)
                return 1;
            else
                return 2;
        } else
            return infos?.aprovado_score ?? timeline?.aprovado_score ? '1' : '2'
    }

    return (
        <div className={conjuge != null ? `modal-analise-financeira-filho-1` : `modal-analise-financeira-filho-2`}>
            <div className='flex header-box mt-2 mb-2'>
                <div className={`box-analise-financeira-${infos?.analise_financeira?.restricao == false ? '1' : '2'}`}>
                    <h1>{infos?.analise_financeira?.restricao == true ? 'Com Restrição' : 'Sem Restrição'}</h1>
                </div>
                <div className={`box-analise-financeira-${testeScore()}`}>
                    <h1>Score: {infos?.analise_financeira?.score == 0 ? infos?.analise_financeira?.score + '0' : infos?.analise_financeira?.score}</h1>
                </div>
                <div className={`box-analise-financeira-${infos?.aprovado_renda ?? timeline?.aprovado_renda ? '1' : '2'}`}>
                    <h1>Renda: {formatarValor(infos?.atividade_profissional?.renda_mensal, "dinheiro")}</h1>
                </div>
            </div>
            <div className='flex mt-5'>
                <h1>Nome:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.nome}</h1>
            </div>
            <div className='flex'>
                <h1>Data de Nascimento:</h1>
                <h1 className='ml-2 cor-cinza'>{dateToPtBr(infos?.analise_financeira?.data_nascimento)}</h1>
            </div>
            <div className='borda-analise mb-3 mt-3'></div>
            <div className='flex'>
                <h1>Possui Cheque:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.possui_cheque ? "Sim" : "Não"}</h1>
                <h1 className='ml-2'>Data da Consulta:</h1>
                <h1 className='ml-2 cor-cinza'>{dateLaravelToPtBr(infos?.analise_financeira?.data_consulta)}</h1>
            </div>
            <div className='flex'>
                <h1>Situação:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.situacao}</h1>
                <h1 className='ml-2'>Data Situação:</h1>
                <h1 className='ml-2 cor-cinza'>{dateLaravelToPtBr(infos?.analise_financeira?.data_situacao)}</h1>
            </div>
            <div className='flex'>
                <h1>Indicador de Ocorrência:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.indicador_ocorrencia}</h1>
            </div>
            <div className='flex'>
                <h1>Participação Societária:</h1>
            </div>
            <div className='flex'>
                <h1 className='cor-cinza'>{infos?.analise_financeira?.mensagem_participacao_societaria}</h1>
            </div>
            {
                infos?.analise_financeira?.mensagem_documentos_roubados != null ?
                    <div className='flex'>
                        <h1>Documentos Roubados:</h1>
                        <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.mensagem_documentos_roubados}</h1>
                    </div>
                    : ""
            }
            <div className='borda-analise mb-3 mt-3'></div>
            <div className='flex'>
                <h1>Logradouro:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.endereco?.logradouro}</h1>
            </div>
            <div className='flex'>
                <h1>Número:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.endereco?.numero}</h1>
                <h1 className='ml-2'>Complemento:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.endereco?.complemento}</h1>
            </div>
            <div className='flex'>
                <h1>Bairro:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.endereco?.bairro}</h1>
            </div>
            <div className='flex'>
                <h1>CEP:</h1>
                <h1 className='ml-2 cor-cinza'>{mask(infos?.analise_financeira?.endereco?.cep, "cep")}</h1>
                <h1 className='ml-2'>Cidade:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.endereco?.cidade}</h1>
                <h1 className='ml-2'>Estado:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.endereco?.estado}</h1>
            </div>
            <div className='borda-analise mb-3 mt-3'></div>
            <h1 className='label-restricao estilo-fonte-negrito'>Quantidade de Consultas</h1>
            <div className='flex'>
                <h1>Últimos 15 dias:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.qtd_consulta_15}</h1>
                <h1 className='ml-2'>Últimos 60 dias:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.qtd_consulta_60}</h1>
            </div>
            <div className='flex'>
                <h1>Últimos 30 dias:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.qtd_consulta_30}</h1>
                <h1 className='ml-2'>Últimos 90 dias:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.qtd_consulta_90}</h1>
            </div>
            <div className='borda-analise mb-3 mt-3'></div>
            <div className='flex'>
                <h1>Score:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.score}</h1>
                <h1 className='ml-2'>Faixa:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.faixa}</h1>
            </div>
            <div className='flex'>
                <h1>Probabilidade de Inadiplência:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.probabilidade}</h1>
            </div>
            <div className='flex'>
                <h1>Mensagem Serasa Score:</h1>
                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.mensagem_serara_score}</h1>
            </div>
            {infos?.analise_financeira?.restricoes_pf?.length > 0 ?
                <>
                    <div className='borda-analise mb-3 mt-3'></div>
                    <h1 className='label-restricao estilo-fonte-negrito mb-2'>Restrições</h1>
                    {
                        infos?.analise_financeira?.protesto_mensagem != null ?
                            <div className='flex mb-2'>
                                <h1 className='estilo-fonte-negrito'>Protestos:</h1>
                                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.protesto_mensagem}</h1>
                            </div> : ""
                    }
                    {
                        infos?.analise_financeira?.cheque_s_fundo_mensagem != null ?
                            <div className='flex mb-2'>
                                <h1 className='estilo-fonte-negrito'>Cheque Sem Fundo Bacen:</h1>
                                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.cheque_s_fundo_mensagem}</h1>
                            </div> : ""
                    }
                    {
                        infos?.analise_financeira?.mensagem_pendencias_financeiras != null ?
                            <div className='flex mb-2'>
                                <h1 className='estilo-fonte-negrito'>Pendências Financeiras:</h1>
                                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.mensagem_pendencias_financeiras}</h1>
                            </div> : ""
                    }
                    {
                        infos?.analise_financeira?.mensagem_pendencias_internas != null ?
                            <div className='flex mb-2'>
                                <h1 className='estilo-fonte-negrito'>Pendências Internas:</h1>
                                <h1 className='ml-2 cor-cinza'>{infos?.analise_financeira?.mensagem_pendencias_internas}</h1>
                            </div> : ""
                    }
                    {
                        infos?.analise_financeira?.restricoes_pf?.map((item: any) => {
                            return (
                                <>
                                    <div className='flex'>
                                        <h1>Nome Associado:</h1>
                                        <h1 className='ml-2 cor-cinza'>{item?.nome_associado}</h1>
                                        <h1 className='ml-2'>Modalidade:</h1>
                                        <h1 className='ml-2 cor-cinza'>{item?.modalidade}</h1>
                                    </div>
                                    <div className='flex'>
                                        <h1>Subjudice:</h1>
                                        <h1 className='ml-2 cor-cinza'>{item?.subjudice ? "Sim" : "Não"}</h1>
                                        <h1 className='ml-2'>Mensagem Subjudice:</h1>
                                        <h1 className='ml-2 cor-cinza'>{item?.mensagem_subjudice}</h1>
                                    </div>
                                    <div className='flex'>
                                        <h1>Data de Inclusão:</h1>
                                        <h1 className='ml-2 cor-cinza'>{dateToPtBr(item?.data_inclusao)}</h1>
                                    </div>
                                    <div className='flex'>
                                        <h1>Tipo de Anotação:</h1>
                                        <h1 className='ml-2 cor-cinza'>{item?.tipo_anotacao}</h1>
                                        <h1 className='ml-2'>Tipo de Restrição:</h1>
                                        <h1 className='ml-2 cor-cinza'>{item?.tipo_restricao?.descricao}</h1>
                                    </div>
                                    <div className='flex'>
                                        <h1>Tipo Moeda:</h1>
                                        <h1 className='ml-2 cor-cinza'>{item?.tipo_moeda}</h1>
                                        <h1 className='ml-2'>Valor:</h1>
                                        <h1 className='ml-2 cor-cinza'>{formatarValor(item?.valor, "dinheiro")}</h1>
                                    </div>
                                    <div className='borda-analise mb-3 mt-3'></div>
                                </>
                            );
                        })
                    }
                </>
                : <div className='borda-analise mb-3 mt-3'></div>}
        </div>
    );
}

export default ModalPFNovo;