import React from 'react';
import Header from '../Universal/HeaderPDF';
import Footer from '../Universal/FooterPDF';
import Linha from '../Universal/Linha';
import { mask } from 'src/helpers/functions';

interface ModeloProps {
    locatario?: any
}

const ModeloPJ1: React.FC<ModeloProps> = ({ locatario }) => {
    return (
        <>
            {/* Página 1 */}
            <div className='pagina-pdf'>
                <Header
                    tipo='Locatário'
                    nome={locatario?.informacoes_basicas?.nome_cliente}
                    tipo_garantia={locatario?.garantia?.descricao}
                    status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                />
                <div className='body-pdf'>
                    <h1 className='sub-title estilo-fonte-negrito'>
                        {locatario?.tipo_locatario_id == 1 ? "Identificação" : "Dados da Empresa"}
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <Linha campo10='CNPJ' valor10={mask(locatario?.informacoes_basicas?.cpf, "cpf_cnpj")} campo20='E-mail*' valor20={locatario?.informacoes_basicas?.email} />
                    <Linha campo10='Razão Social' valor10={locatario?.informacoes_basicas?.razao_social} campo20='Nome Fanstasia' valor20={locatario?.informacoes_basicas?.nome_cliente} />
                    <Linha campo10='Telefone 1' valor10={locatario?.informacoes_basicas?.telefone1} campo20='Telefone 2' valor20={locatario?.informacoes_basicas?.telefone2} />
                    <Linha campo10='CEP' valor10={locatario?.endereco?.cep} campo20='Bairro' valor20={locatario?.endereco?.bairro} />
                    <Linha campo10='Logradouro' valor10={locatario?.endereco?.logradouro} campo11='Número' valor11={locatario?.endereco?.numero} campo20='Cidade' valor20={locatario?.endereco?.cidade} campo21='Estado' valor21={locatario?.endereco?.estado} />
                    <h1 className='text-xs'>Documento da Empresa</h1>
                    <Linha campo10='Cartão Inscrição CNPJ' valor10={locatario?.arquivos_empresa?.arquivo_inscricao_cnpj?.nome} campo20='Contrato Social' valor20={locatario?.arquivos_empresa?.arquivo_contrato_social?.nome} />
                    <Linha campo10='Último Aditivo do Contrato' valor10={locatario?.arquivos_empresa?.arquivo_ultimo_aditivo_contrato?.nome} campo20='Imposto de Renda PJ' valor20={locatario?.arquivos_empresa?.imposto_renda_pj?.nome} />

                    <div className='borda-principal-pdf mt-4'></div>

                    <h1 className='sub-sub-title estilo-fonte-negrito mt-4'>
                        Imóvel Pretendido
                    </h1>
                    <Linha campo10='Finalidade' valor10={locatario?.imovel_pretendido?.finalidade?.descricao} campo20='Código do Imóvel' valor20={locatario?.imovel_pretendido?.finalidade?.codigo_imovel} />
                    <Linha campo10='Logradouro' valor10={locatario?.imovel_pretendido?.imovel?.endereco} campo20='Número' valor20={locatario?.imovel_pretendido?.imovel?.numero} />
                    <Linha campo10='Complemento' valor10={locatario?.imovel_pretendido?.imovel?.complemento} campo20='Bairro' valor20={locatario?.imovel_pretendido?.imovel?.bairro_oficial} />
                    <Linha campo10='Cidade' valor10={locatario?.imovel_pretendido?.imovel?.cidade} campo11='Estado' valor11={locatario?.imovel_pretendido?.imovel?.estado} campo20='Aluguel' valor20={locatario?.imovel_pretendido?.imovel?.preco_locacao} campo21='IPTU' valor21={locatario?.imovel_pretendido?.imovel?.iptu} campo22='Condomínio' valor22={locatario?.imovel_pretendido?.imovel?.condominio} />

                </div>

                <div className='footer-pdf'>
                    <Footer />
                </div>
            </div>

            {/* Página 2 */}
            <div className='pagina-pdf'>
                <Header
                    tipo='Locatário'
                    nome={locatario?.informacoes_basicas?.nome_cliente}
                    tipo_garantia={locatario?.garantia?.descricao}
                    status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                />
                <div className='body-pdf'>
                    <h1 className='sub-sub-title estilo-fonte-negrito mt-4'>
                        Representante Legal
                    </h1>
                    <Linha campo10='Nome do Representante Legal' valor10={locatario?.representantes?.[0]?.informacao_basica?.nome_cliente} campo20='CPF' valor20={locatario?.representantes?.[0]?.informacao_basica?.cpf} />
                    <Linha campo10='Email' valor10={locatario?.representantes?.[0]?.informacao_basica?.email} campo20='Telefone 1' valor20={locatario?.representantes?.[0]?.informacao_basica?.telefone1} campo21='Telefone 2' valor21={locatario?.representantes?.[0]?.informacao_basica?.telefone2} />
                    <Linha campo10='Estado Civil' valor10={locatario?.representantes?.[0]?.informacao_basica?.estado_civil?.descricao} />
                    <Linha campo10='CEP' valor10={locatario?.representantes?.[0]?.endereco?.cep} campo20='Complemento' valor20={locatario?.representantes?.[0]?.endereco?.complemento} />
                    <Linha campo10='Bairro' valor10={locatario?.representantes?.[0]?.endereco?.bairro} campo20='Cidade' valor20={locatario?.representantes?.[0]?.endereco?.cidade} campo21='Estado' valor21={locatario?.representantes?.[0]?.endereco?.estado} />
                    <Linha campo10='Logradouro' valor10={locatario?.representantes?.[0]?.endereco?.logradouro} campo20='Número' valor20={locatario?.representantes?.[0]?.endereco?.numero} />
                    <h1 className='text-xs'>Documento</h1>
                    <Linha campo10='Frente' valor10={locatario?.representantes?.[0]?.informacao_basica?.rg_cnh_frente?.nome} campo20='Verso' valor20={locatario?.representantes?.[0]?.informacao_basica?.rg_cnh_verso?.nome} />
                    <Linha campo10='Comprovante de Residência' valor10={locatario?.representantes?.[0]?.arquivo_comprovante_residencia?.nome} campo20='Imposto de Renda' valor20={locatario?.representantes?.[0]?.arquivo_imposto_renda?.nome} />
                    <Linha campo10='Extrato Bancário' valor10={locatario?.representantes?.[0]?.arquivo_extrato_bancario?.nome} campo20='Outros' valor20={locatario?.representantes?.[0]?.arquivo_outros?.nome} />
                    <Linha campo10='Extrato Bancário 2' valor10={locatario?.representantes?.[0]?.arquivo_extrato_bancario2?.nome} campo20='Extrato Bancário 3' valor20={locatario?.representantes?.[0]?.arquivo_extrato_bancario3?.nome} />

                    {
                        locatario?.representantes.length == 1 ? '' :
                            <>
                                <h1 className='sub-sub-title estilo-fonte-negrito mt-4'>
                                    Representante Legal
                                </h1>
                                <Linha campo10='Nome do Representante Legal' valor10={locatario?.representantes?.[1]?.informacao_basica?.nome_cliente} campo20='CPF' valor20={locatario?.representantes?.[1]?.informacao_basica?.cpf} />
                                <Linha campo10='Email' valor10={locatario?.representantes?.[1]?.informacao_basica?.email} campo20='Telefone 1' valor20={locatario?.representantes?.[1]?.informacao_basica?.telefone1} campo21='Telefone 2' valor21={locatario?.representantes?.[1]?.informacao_basica?.telefone2} />
                                <Linha campo10='Estado Civil' valor10={locatario?.representantes?.[1]?.informacao_basica?.estado_civil?.descricao} />
                            </>
                    }

                </div>

                <div className='footer-pdf'>
                    <Footer />
                </div>
            </div>

            {
                locatario?.representantes.length >= 1 ? '' :
                    <>
                        {/* Página 3 */}
                        <div className='pagina-pdf'>
                            <Header
                                tipo='Locatário'
                                nome={locatario?.informacoes_basicas?.nome_cliente}
                                tipo_garantia={locatario?.garantia?.descricao}
                                status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                            />
                            <div className='body-pdf'>
                                <Linha campo10='CEP' valor10={locatario?.representantes?.[1]?.endereco?.cep} campo20='Complemento' valor20={locatario?.representantes?.[1]?.endereco?.complemento} />
                                <Linha campo10='Bairro' valor10={locatario?.representantes?.[1]?.endereco?.bairro} campo20='Cidade' valor20={locatario?.representantes?.[1]?.endereco?.cidade} campo21='Estado' valor21={locatario?.representantes?.[1]?.endereco?.estado} />
                                <Linha campo10='Logradouro' valor10={locatario?.representantes?.[1]?.endereco?.logradouro} campo20='Número' valor20={locatario?.representantes?.[1]?.endereco?.numero} />
                                <h1 className='text-xs'>Documento</h1>
                                <Linha campo10='Frente' valor10={locatario?.representantes?.[1]?.informacao_basica?.rg_cnh_frente?.nome} campo20='Verso' valor20={locatario?.representantes?.[1]?.informacao_basica?.rg_cnh_verso?.nome} />
                                <Linha campo10='Comprovante de Residência' valor10={locatario?.representantes?.[1]?.arquivo_comprovante_residencia?.nome} campo20='Imposto de Renda' valor20={locatario?.representantes?.[1]?.arquivo_imposto_renda?.nome} />
                                <Linha campo10='Extrato Bancário' valor10={locatario?.representantes?.[1]?.arquivo_extrato_bancario?.nome} campo20='Outros' valor20={locatario?.representantes?.[1]?.arquivo_outros?.nome} />

                                {
                                    locatario?.representantes.length >= 2 ? '' :
                                        <>
                                            <h1 className='sub-sub-title estilo-fonte-negrito mt-4'>
                                                Representante Legal
                                            </h1>
                                            <Linha campo10='Nome do Representante Legal' valor10={locatario?.representantes?.[2]?.informacao_basica?.nome_cliente} campo20='CPF' valor20={locatario?.representantes?.[2]?.informacao_basica?.cpf} />
                                            <Linha campo10='Email' valor10={locatario?.representantes?.[2]?.informacao_basica?.email} campo20='Telefone 1' valor20={locatario?.representantes?.[2]?.informacao_basica?.telefone1} campo21='Telefone 2' valor21={locatario?.representantes?.[2]?.informacao_basica?.telefone2} />
                                            <Linha campo10='Estado Civil' valor10={locatario?.representantes?.[2]?.informacao_basica?.estado_civil?.descricao} />
                                            <Linha campo10='CEP' valor10={locatario?.representantes?.[2]?.endereco?.cep} campo20='Complemento' valor20={locatario?.representantes?.[2]?.endereco?.complemento} />
                                            <Linha campo10='Bairro' valor10={locatario?.representantes?.[2]?.endereco?.bairro} campo20='Cidade' valor20={locatario?.representantes?.[2]?.endereco?.cidade} campo21='Estado' valor21={locatario?.representantes?.[2]?.endereco?.estado} />
                                            <Linha campo10='Logradouro' valor10={locatario?.representantes?.[2]?.endereco?.logradouro} campo20='Número' valor20={locatario?.representantes?.[2]?.endereco?.numero} />
                                            <h1 className='text-xs'>Documento</h1>
                                            <Linha campo10='Frente' valor10={locatario?.representantes?.[2]?.informacao_basica?.rg_cnh_frente?.nome} campo20='Verso' valor20={locatario?.representantes?.[2]?.informacao_basica?.rg_cnh_verso?.nome} />
                                        </>
                                }
                            </div>

                            <div className='footer-pdf'>
                                <Footer />
                            </div>
                        </div>
                    </>
            }

            {
                locatario?.representantes.length >= 2 ? '' :
                    <>
                        {/* Página 2 */}
                        < div className='pagina-pdf'>
                            <Header
                                tipo='Locatário'
                                nome={locatario?.informacoes_basicas?.nome_cliente}
                                tipo_garantia={locatario?.garantia?.descricao}
                                status={locatario?.ultimo_historico?.etapa_historico?.descricao}
                            />
                            <div className='body-pdf'>
                                <Linha campo10='Extrato Bancário' valor10={locatario?.representantes?.[2]?.arquivo_extrato_bancario?.nome} campo20='Outros' valor20={locatario?.representantes?.[2]?.arquivo_outros?.nome} />
                            </div>

                            <div className='footer-pdf'>
                                <Footer />
                            </div>
                        </div >
                    </>
            }
        </>
    );
};

export default ModeloPJ1;