import React, { useEffect, useState } from 'react';
import './style.css'
import { axiosApi } from 'src/services/axiosInstance';
import Loader from 'src/components/Basicos/Loader';
import { MdDeleteOutline, MdOutlineGroups } from 'react-icons/md';
import iconDown from "src/assets/icones/icon-down.svg";
import iconUp from "src/assets/icones/icon-up.svg";
import SlideButton from 'src/components/SlideButton';
import ModalDelete from 'src/components/Funcionalidades/ModalDelete';
import ModalUsuarios from 'src/components/Funcionalidades/ModalUsuarios';
import { searchFilterCargo } from 'src/helpers/functions';
import { useControlContextCargo } from 'src/contexts/CargoContext';

function Accordion({ cargo, salvarAlteracao, openModalInativar, openModalUsuario, openModalDelete }: any) {
    const [expanded, setExpanded] = useState(false);
    const [cargoAlterado, setCargoAlterado] = useState<any>();
    const toggleExpanded = () => setExpanded((current) => !current);

    const onChangeNome = (event: any) => {
        var novo = {
            id: event.target.id,
            descricao: event.target.value
        }
        setCargoAlterado({
            ...cargoAlterado,
            ...novo
        })
    }

    return (
        <div className={`item-accordion ${expanded ? "accordion-expandido" : "accordion-nao-expandido"}`}>
            <div className="px-6 text-left items-center select-none flex justify-between flex-row">
                <h5 className="flex-1 estilo-fonte-negrito" onClick={toggleExpanded}>
                    {cargo.descricao}
                </h5>
                <div className={`${expanded ? 'icons-accordion-cargos-expandido' : 'icons-accordion-cargos-nao-expandido'}`}>
                    <div className={`botao-salvar-cargo ${expanded ? '' : 'sumir-botao-grupo'}`} onClick={() => salvarAlteracao(cargoAlterado)}>
                        <p>SALVAR</p>
                    </div>
                    <a id={'1'} className="flex h-10 w-10 items-center justify-center text-gray-system-mega-imoveis bg-gray-ligth-system-mega-imoveis p-2 focus:outline-none rounded-xl cursor-pointer">
                        <MdDeleteOutline onClick={() => openModalDelete(cargo?.id)} id={'1'} size={'1.3rem'} className={`text-gray-system-mega-imoveis`} />
                    </a>
                    <a className={`flex h-10 w-10 items-center justify-center text-gray-system-mega-imoveis bg-gray-ligth-system-mega-imoveis p-2 focus:outline-none rounded-xl cursor-pointer ${expanded ? '' : 'sumir-botao-grupo'}`}>
                        <MdOutlineGroups onClick={() => openModalUsuario(cargo?.usuarios, cargo?.descricao)} size={'1.3rem'} className={`text-gray-system-mega-imoveis`} />
                    </a>
                    {
                        cargo?.id == '' || cargo?.id == undefined ? '' :
                            <SlideButton
                                id={cargo?.id}
                                name=""
                                onChange={() => openModalInativar(cargo?.id, cargo?.status)}
                                checked={cargo?.status} />
                    }
                    {expanded
                        ? <img className="icon-up-down-accordion" alt="icon_crown" src={iconUp} />
                        : <img className="icon-up-down-accordion" alt="icon_crown" src={iconDown} />
                    }
                </div>
            </div>

            <div className={`borda-accordion mb-3 mt-3 ${expanded ? "visivel" : "nao-visivel"}`}></div>
            <div className={`px-6 pt-0 overflow-hidden transition-[max-height] duration-500 ease-in ${expanded ? "visivel" : "nao-visivel"}`}>
                <p className='text-left mb-2'>Nome do Cargo</p>
                <input
                    id={cargo?.id}
                    type='text'
                    name='descricao'
                    onChange={onChangeNome}
                    value={cargoAlterado?.descricao ?? ""}
                    className="modal-input p-1 form-accordion"
                />
            </div>
        </div>
    )
}

const Cargos: React.FunctionComponent = () => {
    const [cargos, setCargos] = useState<any>();
    const [loader, setLoader] = useState<boolean>(false);
    const [modalInativar, setModalInativar] = useState(false);
    const [modalUsuario, setModalUsuario] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [idInativar, setIdInativar] = useState();
    const [idDelete, setIdDelete] = useState();
    const [status, setStatus] = useState<boolean>();
    const [usuariosModal, setUsuariosModal] = useState<any>();
    const [descricaoModal, setDescricaoModal] = useState<any>();
    const [cargosFiltrados, setCargosFiltrados] = useState<any>();
    const [filtro, setFiltro] = useState<any>();
    const {
        novoCargo,
        setNovoCargo
    } = useControlContextCargo();

    const openModalInativar = (id: any, status: boolean) => {
        setIdInativar(id);
        setStatus(status);
        setModalInativar(true);
    }

    const closeModalInativar = () => {
        setModalInativar(false);
    }

    const openModalDelete = (id: any) => {
        if (id == undefined)
            setNovoCargo(undefined)
        else {
            setIdDelete(id);
            setModalDelete(true);
        }
    }

    const closeModalDelete = () => {
        setModalDelete(false);
    }

    const openModalUsuario = (usuarios: any, descricaoCargo: any) => {
        setUsuariosModal(usuarios);
        setDescricaoModal(descricaoCargo)
        setModalUsuario(true);
    }

    const closeModalUsuario = () => {
        setModalUsuario(false);
    }

    const loadCargos = async () => {
        try {
            setLoader(true);
            const { data } = await axiosApi.get('cargos');
            setCargos(data.data);
            setCargosFiltrados(data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    const salvarAlteracao = async (cargoAlterado: any) => {
        try {
            setLoader(true);
            if (cargoAlterado?.id == '')
                await axiosApi.post(`cargos`, cargoAlterado);
            else
                await axiosApi.put(`cargos/${cargoAlterado?.id}`, cargoAlterado);
            cargos.find((element: any) => element?.id == cargoAlterado?.id).descricao = cargoAlterado.descricao;
            setCargos(cargos);
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
            loadCargos();
            setNovoCargo(undefined);
        }
    }

    const onChangeStatus = async (id: any) => {
        setLoader(true);
        var cargo = cargos.find((element: any) => element?.id == id);
        var alterar = {
            status: !cargo.status
        }
        await axiosApi.put(`cargos/${id}`, alterar);
        cargos.find((element: any) => element?.id == id).status = !cargo.status;
        setCargos(cargos);
        setLoader(false);
    }

    const onChange = (event: any) => {
        const { value } = event.target;
        setFiltro(value);
        var a = searchFilterCargo(value, cargos);
        setCargosFiltrados(a);
    }

    useEffect(() => {
        loadCargos();
    }, []);

    return (
        <Loader
            isActive={loader}
        >
            <ModalDelete
                modalId={idInativar}
                open={modalInativar}
                titulo={`Tem certeza que deseja ${status == true ? 'inativar' : 'ativar'} este cargo?`}
                nameRouter={'inativar-cargo'}
                isClose={closeModalInativar}
                onChangeStatus={onChangeStatus}
                status={status}
            />
            <ModalDelete
                modalId={idDelete}
                open={modalDelete}
                titulo={`Tem certeza que deseja deletar este cargo?`}
                nameRouter={'cargo'}
                isClose={closeModalDelete}
                requestData={loadCargos}
            />
            <ModalUsuarios
                open={modalUsuario}
                onCloseModal={closeModalUsuario}
                usuarios={usuariosModal}
                titulo={`Cargo - ${descricaoModal}`}
            />
            <div className='p-10'>
                <div className="card-content accordion">
                    <h1 className='filtro estilo-fonte-negrito'>Filtros</h1>
                    <input
                        name={`search_news`}
                        className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder={'Pesquise pelo Nome do Cargo'}
                        value={filtro ?? ""}
                        onChange={onChange}
                    />
                    <div className='borda-cargo mb-8 mt-8'></div>
                    {novoCargo != undefined ? <Accordion
                        cargo={novoCargo}
                        salvarAlteracao={salvarAlteracao}
                        openModalInativar={openModalInativar}
                        openModalUsuario={openModalUsuario}
                        openModalDelete={openModalDelete}
                    /> : ''}
                    {cargosFiltrados?.length > 0 ?
                        cargosFiltrados?.map((cargo: any) => {
                            return (
                                <Accordion
                                    cargo={cargo}
                                    salvarAlteracao={salvarAlteracao}
                                    openModalInativar={openModalInativar}
                                    openModalUsuario={openModalUsuario}
                                    openModalDelete={openModalDelete}
                                />
                            )
                        }) : <div className='sem-cargos'>Não há cargos cadastrados!</div>
                    }
                </div>
            </div>
        </Loader>
    );
}

export default Cargos;