import React, { Fragment } from "react";
import { statusColor } from "src/helpers/functions";
import { converterDataBr } from "../funcoes";
import './style.css'

type Props = {
    historico?: any,
    key?: number,
    title?: string
    isPDF?: boolean
}

export default function TableDetails({
    isPDF = false,
    historico,
    key,
    title = 'Histórico de Alterações'
}: Props): JSX.Element {
    return (
        <Fragment key={key}>
            <div className="flex flex-col w-full">
                <div className="text-left font-extrabold text-xl font-mont-bold">
                    <span>{title}</span>
                </div>
                <div className="h-full">
                    <table className={`w-full text-base text-center mt-4 table-fixed ${isPDF ? 'fonte-pdf' : ''}`}>
                        <thead>
                            <tr className="font-normal">
                                <th className="py-3 px-6">
                                    Data
                                </th>
                                {isPDF ? '' :
                                    <th className="py-3 px-6">
                                        Nome do Usuário
                                    </th>
                                }
                                <th className="py-3 px-6">
                                    Etapa
                                </th>
                                <th className="py-3 px-6">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody className={isPDF ? 'w-full overflow-auto' : 'w-full overflow-auto h-40'}>
                            {historico?.map((item: any) => {
                                return (
                                    <tr className={isPDF ? 'text-gray-600 linha-historico-pdf text-gray-600 w-full' : 'text-gray-600 w-full linha-historico'}>
                                        <td className="px-6 border-t">
                                            {converterDataBr(item?.created_at)}
                                        </td>
                                        {isPDF ? '' :
                                            <td className="px-6 border-t">
                                                {item?.usuario?.nome}
                                            </td>
                                        }
                                        <td className="px-6 border-t">
                                            {item?.etapa_historico?.descricao}
                                        </td>
                                        <td className="px-6 border-t">
                                            <div className="h-auto flex flex-row justify-center items-center gap-2 rounded-lg">
                                                <div className="rounded-full w-2 h-2" style={{ 'backgroundColor': statusColor(item?.status_validacao_id)?.color }} />
                                                {statusColor(item?.status_validacao_id)?.title}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    );
}
