import React from 'react';
import { ClassicProps } from 'src/@types/types';
import { formatarDataRestricao, formatarValorRestricao } from 'src/components/Basicos/funcoes';

type Props = {
    restricao?: any;
};

export default function PendenciasFinanceiras({
    restricao
}: Props & ClassicProps): JSX.Element {

    return (
        <>
            <div className='flex'>
                <h1>Origem:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.origem}</h1>
                <h1 className='ml-2'>Data da Ocorrência:</h1>
                <h1 className='ml-2 cor-cinza'>{formatarDataRestricao(restricao?.dataOcorrencia)}</h1>
            </div>
            <div className='flex'>
                <h1>Subtipo:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.subtipo == "pefin" ? "Pefin" : "Dívidas Vencidas"}</h1>
                <h1 className='ml-2'>Modalidade:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.modalidade}</h1>
            </div>
            <div className='flex'>
                <h1>Avalista:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.avalista}</h1>
                <h1 className='ml-2'>Contrato:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.contrato}</h1>
            </div>
            <div className='flex mb-4'>
                <h1>Tipo de Moeda:</h1>
                <h1 className='ml-2 cor-cinza'>{restricao?.tipoMoeda}</h1>
                <h1 className='ml-2'>Valor:</h1>
                <h1 className='ml-2 cor-cinza'>{formatarValorRestricao(restricao?.valor)}</h1>
            </div>
        </>
    );
}
