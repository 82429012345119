import React, { Fragment, useEffect, useState } from 'react';
import { ClassicProps } from "src/@types/types";
import { useControlContextSolicitacao } from 'src/contexts/SolicitacaoControlContext';
import Inputlabel from '../Form/Input/InputLabel';
import Title from '../Form/Title';
import '../StatusBar/style.css'

type Props = {
    onClick?: any;
    onClickIndicacao?: any;
    statusActive: number;
    statusActiveIndicacao: number;
    statusColor: any;
    statusColorIndicacao: any;
    location: string;
};

export default function StatusBar({
    onClick,
    onClickIndicacao,
    statusActive,
    statusActiveIndicacao,
    statusColor,
    statusColorIndicacao,
    location
}: Props & ClassicProps): JSX.Element {
    const [typeInicio, setTypeInicio] = useState("text");
    const [typeFim, setTypeFim] = useState("text");
    const [dataForm, setDataForm] = useState({
        dateInicial: '',
        dateFinal: ''
    });
    const { setDateFilterSolicitacao, dateActive } = useControlContextSolicitacao();

    const requestWithFourStatus = [
        "/solicitacoes/agendamento-visita",
        "/solicitacoes/cotacao-seguro",
        "/solicitacoes/indicacao-corretor"
    ];
    const onChangeDate = (event: any) => {
        const { name, value } = event.target;
        setDataForm({
            ...dataForm,
            [name]: value
        })
    };




    const getSequence = (currentLocation: string | undefined) => {
        switch (currentLocation) {
            case "/solicitacoes/agendamento-visita":
            case "/solicitacoes/cotacao-seguro":
                return {
                    '1': { 'stage': 3, 'label': 'Pendente' },
                    '2': { 'stage': 7, 'label': 'Agendada' },
                    '3': { 'stage': 8, 'label': 'Realizada' },
                    '4': { 'stage': 4, 'label': 'Não Realizada' },
                };
            case "/solicitacoes/informacao-imovel":
            case "/solicitacoes/solicitacao-imovel":
            case "/solicitacoes/avaliacao-imovel":
            case "/solicitacoes/anuncie-seu-imovel":
            case "/solicitacoes/declaracao-ir":
            case "/solicitacoes/solicitacao-de-chamado":
            case "/solicitacoes/fale-conosco":
            case "/solicitacoes/manutencao-imovel":
            case "/solicitacoes/devolucao-imovel":
            case "/solicitacoes/renovacao-contrato":
            case "/solicitacoes/avaliacao-atendimento":
                return {
                    '1': { 'stage': 3, 'label': 'Pendente' },
                    '2': { 'stage': 1, 'label': 'Em Atendimento' },
                    '3': { 'stage': 2, 'label': 'Atendido' },
                    '4': { 'stage': null, 'label': null }
                };
            case "/solicitacoes/indicacao-corretor":
                return {
                    '1': { 'stage': 9, 'label': 'Sob análise' },
                    '2': { 'stage': 10, 'label': 'Aprovada' },
                    '3': { 'stage': 11, 'label': 'Reprovada' },
                    '4': { 'stage': 12, 'label': 'Paga' },
                };
            default:
                return {};
        }
    }

    const getValue = (StatusKey: number) => {
        const sequenceStageBar = getSequence(location);
        return sequenceStageBar[StatusKey];
    }

    useEffect(() => {
        setDateFilterSolicitacao(dataForm.dateInicial, dataForm.dateFinal);
    }, [dataForm]);

    return (
        <Fragment>
            <div className="flex justify-start flex-col lg:flex-row gap-3 w-full">
                <div className={`flex flex-col w-full ${requestWithFourStatus.includes(location) ? 'lg:w-3/5' : 'lg:w-2/5'}  mb-3 status-bar`}>
                    <Title
                        className={`text-lg font-bold text-gray-system-mega-imoveis w-full lg:w-2/5 lg:ml-6`}
                        content={`Status`} />
                    {
                        location === "/solicitacoes/indicacao-corretor" ? <div className={`flex md:flex-row flex-col md:gap-1 mr-0 md:mr-0 lg:ml-6`}>
                            <div className={`flex ${statusActiveIndicacao === getValue(1).stage ? 'border-blue-mega-imoveis text-blue-mega-imoveis' : 'text-gray-500 border-gray-500'} border-2 rounded-lg w-full md:w-1/2 text-left pr-6 pl-3 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-xs gap-2`} onClick={() => onClickIndicacao(getValue(1).stage)} style={{ 'height': '52px' }} > <div className="rounded-full h-2 w-2" style={{ 'backgroundColor': statusColorIndicacao(getValue(1).stage) }}></div> {getValue(1).label}</div>
                            <div className={`flex ${statusActiveIndicacao === getValue(2).stage ? 'border-blue-mega-imoveis text-blue-mega-imoveis' : 'text-gray-500 border-gray-500'} border-2 rounded-lg w-full md:w-1/2 text-left pr-6 pl-2 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-sm gap-2`} onClick={() => onClickIndicacao(getValue(2).stage)} style={{ 'height': '52px' }} > <div className="rounded-full h-2 w-2" style={{ 'backgroundColor': statusColorIndicacao(getValue(2).stage) }}></div> {getValue(2).label}</div>
                            <div className={`flex ${statusActiveIndicacao === getValue(3).stage ? 'border-blue-mega-imoveis text-blue-mega-imoveis' : 'text-gray-500 border-gray-500'} border-2 rounded-lg w-full md:w-1/2 text-left pr-6 pl-2 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-sm gap-2`} onClick={() => onClickIndicacao(getValue(3).stage)} style={{ 'height': '52px' }} > <div className="rounded-full h-2 w-2" style={{ 'backgroundColor': statusColorIndicacao(getValue(3).stage) }}></div> {getValue(3).label}</div>
                            <div className={`flex ${statusActiveIndicacao === getValue(4).stage ? 'border-blue-mega-imoveis text-blue-mega-imoveis' : 'text-gray-500 border-gray-500'} ${getValue(4).stage === null ? 'hidden' : ''} border-2 rounded-lg w-full md:w-1/2 text-left pr-6 pl-2 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-sm gap-2`} onClick={() => onClickIndicacao(getValue(4).stage)} style={{ 'height': '52px' }} > <div className="rounded-full h-2 w-2" style={{ 'backgroundColor': statusColorIndicacao(getValue(4).stage) }}></div> {getValue(4).label}</div>
                        </div> :
                            <div className={`flex md:flex-row flex-col md:gap-1 mr-0 md:mr-0 lg:ml-6`}>
                                <div className={`flex ${statusActive === getValue(1).stage ? 'border-blue-mega-imoveis text-blue-mega-imoveis' : 'text-gray-500 border-gray-500'} border-2 rounded-lg w-full md:w-1/2 text-left pr-6 pl-3 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-xs gap-2`} onClick={() => onClick(getValue(1).stage)} style={{ 'height': '52px' }} > <div className="rounded-full h-2 w-2" style={{ 'backgroundColor': statusColor(getValue(1).stage) }}></div> {getValue(1).label}</div>
                                <div className={`flex ${statusActive === getValue(2).stage ? 'border-blue-mega-imoveis text-blue-mega-imoveis' : 'text-gray-500 border-gray-500'} border-2 rounded-lg w-full md:w-1/2 text-left pr-6 pl-2 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-sm gap-2`} onClick={() => onClick(getValue(2).stage)} style={{ 'height': '52px' }} > <div className="rounded-full h-2 w-2" style={{ 'backgroundColor': statusColor(getValue(2).stage) }}></div> {getValue(2).label}</div>
                                <div className={`flex ${statusActive === getValue(3).stage ? 'border-blue-mega-imoveis text-blue-mega-imoveis' : 'text-gray-500 border-gray-500'} border-2 rounded-lg w-full md:w-1/2 text-left pr-6 pl-2 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-sm gap-2`} onClick={() => onClick(getValue(3).stage)} style={{ 'height': '52px' }} > <div className="rounded-full h-2 w-2" style={{ 'backgroundColor': statusColor(getValue(3).stage) }}></div> {getValue(3).label}</div>
                                <div className={`flex ${statusActive === getValue(4).stage ? 'border-blue-mega-imoveis text-blue-mega-imoveis' : 'text-gray-500 border-gray-500'} ${getValue(4).stage === null ? 'hidden' : ''} border-2 rounded-lg w-full md:w-1/2 text-left pr-6 pl-2 items-center font-medium cursor-pointer select-none md:text-base text-sm lg:text-sm sm:text-sm gap-2`} onClick={() => onClick(getValue(4).stage)} style={{ 'height': '52px' }} > <div className="rounded-full h-2 w-2" style={{ 'backgroundColor': statusColor(getValue(4).stage) }}></div> {getValue(4).label}</div>
                            </div>
                    }
                </div>
                <div className={`flex w-full ${requestWithFourStatus.includes(location) ? 'lg:w-2/5' : 'lg:w-3/5'} mb-12 lg:pr-6 lg:mb-0 flex-col lg:flex-row gap-2`}>
                    <Inputlabel
                        type={typeInicio}
                        className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 p-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full`}
                        colClassname={`w-full`}
                        name={"dateInicial"}
                        placeholder={`Período Início Vigência`}
                        onFocus={() => setTypeInicio("date")}
                        value={dateActive?.dateInicial ?? ""}
                        onChange={onChangeDate}
                        inputTitle={`Data Solicitação Inicial`}
                        titleClassname={`text-sm mg:text-base whitespace-no-wrap overflow-hidden font-bold text-gray-system-mega-imoveis`}
                    />
                    <Inputlabel
                        type={typeFim}
                        className={`bg-gray-50 text-gray-900 text-sm border border-gray-300 shadow-sm px-4 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4`}
                        colClassname={`w-full`}
                        name={"dateFinal"}
                        placeholder={`Período Fim Vigência`}
                        onFocus={() => setTypeFim("date")}
                        value={dateActive?.dateFinal ?? ""}
                        onChange={onChangeDate}
                        inputTitle={`Data Solicitação Final`}
                        titleClassname={`text-sm mg:text-base whitespace-no-wrap overflow-hidden font-bold text-gray-system-mega-imoveis`}
                    />
                </div>
            </div>
        </Fragment>
    );
}
