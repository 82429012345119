import React, { useState } from 'react';
import { useControlContextUsuarios } from 'src/contexts/UsuariosContext';
import { axiosApi } from 'src/services/axiosInstance';
import Modal from '../ModalNoticia';
import './style.css'

type Props = {
    open: boolean;
    titulo: string | undefined;
    modalId: string | number | undefined;
    nameRouter?: string;
    isClose?: any;
    requestData?: any;
    onChangeStatus?: any;
    status?: any;
}

const ModalDelete: React.FC<Props> = ({
    isClose,
    open,
    modalId,
    titulo = "Tem Certeza que Deseja Excluir?",
    nameRouter = "",
    requestData,
    onChangeStatus = "",
    status = ""
}: Props): JSX.Element => {
    const {
        getUsuarios,
    } = useControlContextUsuarios();

    const [motivo, setMotivo] = useState<any>();

    const onChange = (e: any) => {
        const { value } = e.target;
        setMotivo(value);
    };

    const onSubmitReprovacao = async (e: any) => {
        try {
            e.preventDefault();
            if (modalId !== null) {
                if (nameRouter == 'inativar-usuario' || nameRouter == 'inativar-locatario')
                    await axiosApi.put(`${nameRouter}/${modalId}`);
                else if (nameRouter == 'inativar-proprietario')
                    await axiosApi.put(`${nameRouter}/${modalId}`);
                else if (nameRouter == 'cancelar-proprietario') {
                    var motivo_cancelamento = {
                        motivo: motivo
                    }
                    await axiosApi.put(`${nameRouter}/${modalId}`);
                }
                else if (nameRouter == 'inativar-cargo')
                    onChangeStatus(modalId);
                else if (nameRouter == 'cancelar-locatario' || nameRouter == 'cancelar-imovel') {
                    var motivo_cancelamento = {
                        motivo: motivo
                    }
                    await axiosApi.put(`${nameRouter}/${modalId}`, motivo_cancelamento);
                }
                else {
                    await axiosApi.delete(`${nameRouter}/${modalId}`);
                }
                isClose();
            }
        } catch (error) {
            console.log(error);
        } finally {
            if (nameRouter == 'inativar-usuario')
                getUsuarios();
            else if (nameRouter == 'inativar-cargo') {

            }
            // else if (nameRouter == 'inativar-locatario' || nameRouter == 'cancelar-locatario')
            //     document.location.reload();
            else
                requestData();
        }
    }

    return (
        <Modal
            isOpen={open}
            isClose={() => isClose()}
            headerStyle={''}
            closeOnOutsideClick={true}
            widthModal={`${nameRouter == 'cancelar-locatario' || nameRouter == 'cancelar-proprietario' || nameRouter == 'cancelar-imovel' ? '40%' : '35%'}`}
            modalStyle={`modal-style bg-white rounded-lg py-5 px-10 ${nameRouter == 'cancelar-locatario' || nameRouter == 'cancelar-proprietario' || nameRouter == 'cancelar-imovel' ? 'modal-cancelar' : ''}`}
        >
            <div>
                <form onSubmit={onSubmitReprovacao} className={`flex flex-col items-center h-32 justify-between`}>
                    {nameRouter == 'cancelar-locatario' || nameRouter == 'cancelar-proprietario' || nameRouter == 'cancelar-imovel' ?
                        <div>
                            <p className="font-extrabold text-center text-2xl font-mont-bold" style={{ color: "#081E52" }}>Deseja cancelar esse cadastro?</p>
                            <p className="font-extrabold text-center text-2xl mb-4 font-mont-bold" style={{ color: "#081E52" }}>Digite o motivo do cancelamento.</p>
                        </div>
                        : <div className="flex">
                            <p className="font-extrabold text-center text-2xl mb-4 font-mont-bold" style={{ color: "#081E52" }}>{titulo}</p>
                        </div>
                    }
                    {nameRouter == 'cancelar-locatario' || nameRouter == 'cancelar-proprietario' || nameRouter == 'cancelar-imovel' ?
                        <div className='text-modal-cancelar'>
                            <textarea
                                rows={4}
                                onChange={onChange}
                                required
                                name={`comentario_fiador`}
                                style={{ maxHeight: "6rem", minHeight: "3.5rem" }}
                                className="block p-4 w-full h-32 mt-2 text-sm border-2 border-opacity-100 text-gray-system-mega-imoveis border-gray-system-mega-imoveis rounded-lg focus:outline-none placeholder-gray-700 resize-none"
                                placeholder={`Dê detalhes do porquê o ${nameRouter == 'cancelar-locatario' ? 'processo de locação' : (nameRouter == 'cancelar-imovel' ? 'imóvel' : 'proprietário')} foi cancelado`}
                            />
                        </div>
                        : ''}
                    <div className="flex flex-row w-full justify-center row-buttons-modal-delete">

                        <button
                            type={`button`}
                            className={`flex h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs bg-white text-blue-mega-imoveis cursor-pointer rounded-lg justify-center items-center buttons-modal-delete`}
                            onClick={() => isClose()}
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className={`flex h-12 p-2 focus:outline-none mt-2 uppercase font-medium text-xs bg-blue-mega-imoveis text-white cursor-pointer rounded-lg justify-center items-center buttons-modal-delete`}
                        >
                            {nameRouter == 'inativar-cargo' ? (status == true ? 'Inativar' : 'Ativar') : nameRouter == 'cancelar-locatario' ? 'Confirmar' : (nameRouter == 'cancelar-proprietario' || nameRouter == 'cancelar-imovel' ? 'Confirmar' : 'Excluir')}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
}

export default ModalDelete;