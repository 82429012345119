import React, { Fragment } from 'react';
import './style.css';
import { mask } from 'src/helpers/functions';

type Props = {
    data?: any
}

const LinhaEndereco: React.FC<Props> = ({
    data
}: Props) => {

    return (
        <Fragment>
            <div className="flex">
                <FormaLabelInput
                    titulo="CEP"
                    nome_do_campo="cep"
                    valor_campo={mask(data?.endereco?.cep, "cep") ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo="Bairro"
                    nome_do_campo="bairro"
                    valor_campo={data?.endereco?.bairro ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
            </div>
            <div className="flex">
                <div className="flex linha-2">
                    <FormaLabelInput
                        titulo="Logradouro"
                        nome_do_campo="logradouro"
                        valor_campo={data?.endereco?.logradouro ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                    <FormaLabelInput
                        titulo="Número"
                        nome_do_campo="numero"
                        valor_campo={data?.endereco?.numero ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                        menor={"menor"}
                    />
                </div>
                <div className="flex linha-2">
                    <FormaLabelInput
                        titulo="Cidade"
                        nome_do_campo="cidade"
                        valor_campo={data?.endereco?.cidade ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                    />
                    <FormaLabelInput
                        titulo="Estado"
                        nome_do_campo="estado"
                        valor_campo={data?.endereco?.estado ?? ""}
                        tipo_campo="text"
                        marginRigth="1.5rem"
                        menor={"menor"}
                    />
                </div>
            </div>
            <div className="flex">
                <FormaLabelInput
                    titulo="Complemento"
                    nome_do_campo="complemento"
                    valor_campo={data?.endereco?.complemento ?? ""}
                    tipo_campo="text"
                    marginRigth="1.5rem"
                />
                <FormaLabelInput
                    titulo=""
                    nome_do_campo=""
                    valor_campo={""}
                    tipo_campo="hidden"
                    marginRigth="1.5rem"
                />
            </div>
        </Fragment>
    );
}

const FormaLabelInput: React.FC<CamposForm> = (props) => {
    return (
        <Fragment>
            <div className={`form-input-solicitacao ${props.menor}`} style={{ width: props.width }}>
                <label className="form-label-titulo my-3" style={{ display: props.displayBlock }}>{props.titulo}</label>
                <input
                    required={props.required ? true : false}
                    type={props.tipo_campo}
                    name={props.nome_do_campo}
                    onChange={props.funcao}
                    value={props.valor_campo ?? ""}
                    className="modal-input mb-3 p-1"
                    style={{ width: props.width2, height: props.height, marginLeft: props.marginLeft, marginRight: props.marginRigth }}
                />
            </div>
        </Fragment>
    );
};

interface CamposForm {
    titulo?: string,
    nome_do_campo?: string,
    valor_campo?: number | string,
    tipo_campo?: string,
    required?: boolean,
    funcao?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    width?: string,
    width2?: string,
    height?: string,
    marginLeft?: string,
    marginRigth?: string,
    displayBlock?: string,
    menor?: string;
}

export default LinhaEndereco;