import React, { createContext, useContext, useState } from 'react';
import { transformDataToOptionsSelectForm } from 'src/components/Basicos/funcoes';
import { axiosApi } from 'src/services/axiosInstance';


const ControllCadastroContext = createContext({});

interface IControllContextCadastroProps {
    children: React.ReactNode
}

type DateType = {
    dateInicial: String | null,
    dateFinal: String | null,
}

const ControllCadastroProvider: React.FC<IControllContextCadastroProps> = React.memo(({ children }) => {

    const [etapaActive, setEtapaActive] = useState(0);
    const [sedeActive, setSedeActive] = useState(0);
    const [statusEtapaActive, setStatusEtapaActive] = useState(0);
    const [tiposSedesCadastro, setTipoSedes] = useState<any>();
    const [tiposEtapas, setTiposEtapas] = useState<any>();
    const [tiposStatusEtapa, setTiposStatusEtapa] = useState<any>();
    const [statusActive, setStatusActive] = useState(0);
    const [checkCancelado, setCheckCancelado] = useState(false);
    const [paginaFiltro, setPaginaFiltro] = useState<any>();

    const [dateActive, setDateActive] = useState<DateType>({
        dateInicial: null,
        dateFinal: null
    });

    const loadEtapas = async () => {
        const { data } = await axiosApi.get('etapas-historico');
        var opcaoDefault = { id: 0, nome: "Selecione" };
        const opcaoDefaul2t = [opcaoDefault].concat(data.data);
        setTiposEtapas(transformDataToOptionsSelectForm(opcaoDefaul2t));
    }

    const loadStatusEtapa = async () => {
        const { data } = await axiosApi.get('status-solicitacao');
        var opcaoDefault = { id: 0, nome: "Selecione" };
        const opcaoDefaul2t = [opcaoDefault].concat(data.data);
        setTiposStatusEtapa(transformDataToOptionsSelectForm(opcaoDefaul2t));
    }

    const setSedesOptions = async () => {
        const { data } = await axiosApi.get(`sede-responsavel`);
        var opcaoDefault = { id: 0, nome: "Selecione" }
        const opcaoDefaul2t = [opcaoDefault].concat(data.data)
        setTipoSedes(transformDataToOptionsSelectForm(opcaoDefaul2t))
    }

    const setEtapa = (etapaId: Number): void => {
        if (etapaId === etapaActive) {
            setEtapaActive(0);
        } else {
            setEtapaActive(Number(etapaId));
        }
    }

    const setSede = (sedeId: Number): void => {
        if (sedeId === sedeActive) {
            setSedeActive(0);
        } else {
            setSedeActive(Number(sedeId));
        }
    }

    const setStatusEtapa = (statusEtapaId: Number): void => {
        if (statusEtapaId === statusEtapaActive) {
            setStatusEtapaActive(0);
        } else {
            setStatusEtapaActive(Number(statusEtapaId));
        }
    }

    const changeCancelado = () => {
        setCheckCancelado(!checkCancelado)
    }

    const setSedeCadastro = (sedeId: Number): void => {
        setPaginaFiltro(0)
        setSedeActive(Number(sedeId));
    }

    const setStatus = (statusId: Number): void => {
        if (statusId === statusActive) {
            setStatusActive(0);
        } else {
            setStatusActive(Number(statusId));
        }
    }

    const setDateFilter = (dateInicial: String, dateFinal: String): void => {
        setDateActive({
            ...dateActive,
            dateInicial: dateInicial,
            dateFinal: dateFinal
        });
    }

    return (
        <ControllCadastroContext.Provider value={{
            setEtapa,
            setStatus,
            setDateFilter,
            setSede,
            setSedeCadastro,
            setSedesOptions,
            changeCancelado,
            setPaginaFiltro,
            setStatusEtapa,
            loadEtapas,
            loadStatusEtapa,
            tiposStatusEtapa,
            tiposEtapas,
            statusEtapaActive,
            paginaFiltro,
            checkCancelado,
            tiposSedesCadastro,
            etapaActive,
            statusActive,
            dateActive,
            sedeActive
        }}>
            {children}
        </ControllCadastroContext.Provider>
    );
});

function useControlContextCadastro(): any {
    const context = useContext(ControllCadastroContext);
    if (!context) {
        throw new Error("useControll must a be used with ControllCadastroProvider");
    }
    return context;
}

export { ControllCadastroProvider, useControlContextCadastro };
