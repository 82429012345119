import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardTable from "src/components/Basicos/Cards/CardTable";
import { ajustUserAgent, converterDataBr, formatarValor } from "src/components/Basicos/funcoes";
import Loader from "src/components/Basicos/Loader";
import TableDetails from "src/components/Basicos/TableDetails";
import TimelineCadastroFiador from "src/components/Basicos/Timeline/TimelineCadastroFiador";
import TimelimeCadastroLocatario from "src/components/Basicos/Timeline/TimelineCadastroLocatario";
import { useControlContextAnaliseFinanceira } from "src/contexts/AnaliseFinanceiraContext";
import { mask } from "src/helpers/functions";
import { axiosApi } from "src/services/axiosInstance";
import './style.css'
import { useControlContextMobile } from "src/contexts/MobileContext";
import IndexMobile from "./indexMobile";
import TimelineCadastroFiadorMobile from "src/components/Basicos/Timeline/TimelineCadastroFiador/indexMobile";

type SedesResponsaveis = {
    id: number;
    nome: string | number;
};

const DetalhesCadastroLocatario: React.FunctionComponent = () => {
    const [locatarioData, setLocatarioData] = useState<any>();
    const [fiadores, setFiadores] = useState<any>([]);
    const [historico, setHistorico] = useState<any>([]);
    const [timelineLocatarioPrincipal, setTimelineLocatarioPrincipal] = useState<any>([]);
    const [timelineLocatarioSecundario, setTimelineLocatarioSecundario] = useState<any>([]);
    const [loader, setLoader] = useState(false);
    const [etapaAtual, setEtapaAtual] = useState(0);
    const [sedesResponsavel, setSedesResponsavel] = useState<any>();
    const { loadAnaliseFinanceira } = useControlContextAnaliseFinanceira();
    const { mobile, setMobile } = useControlContextMobile();

    let { locatarioId }: any = useParams();
    let sedeId = locatarioData?.sede_id;

    const onTop = () => {
        window.scrollTo(0, 0);
    }

    const getLocatario = async (locatarioId: number) => {
        try {
            setLoader(true);
            if (locatarioId !== null) {
                const { data } = await axiosApi.get(`/locatario/${locatarioId}`);
                setLocatarioData(data?.data);
                loadAnaliseFinanceira(data?.data);
                setHistorico(data?.data?.historico);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    const getLinhaTempo = async (locatarioId: number) => {
        try {
            setLoader(true)
            if (locatarioId !== null) {
                const { data } = await axiosApi.get(`/linha-tempo/adm/locatario/${locatarioId}`);
                setTimelineLocatarioPrincipal(data?.data.filter((item: any) => { return item?.id === Number(locatarioId) })[0].timeLine);
                setTimelineLocatarioSecundario(data?.data.filter((item: any) => { return item?.id !== Number(locatarioId) }));
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const getLinhaTempoFiador = async (locatarioId: number) => {
        try {
            setLoader(true)
            if (locatarioId !== undefined) {
                const { data } = await axiosApi.get(`/linha-tempo/adm/fiadores/${locatarioId}`);
                setFiadores(data?.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const statusTimeLine = async (status_id: number, locatarioIdStatus: number) => {
        try {
            setLoader(true)
            if (locatarioIdStatus !== null && etapaAtual !== 0) {
                const dataForm = {
                    locatario_id: locatarioIdStatus,
                    etapa_id: etapaAtual,
                    comentario: null
                }
                // console.log([dataForm, status_id]);
                const pathName = { '10': 'realizado', '11': 'nao-realizado' }
                await axiosApi.post(`/locatario/status/${pathName[status_id]}`, dataForm);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
            setEtapaAtual(0);
            getLinhaTempo(locatarioId);
            getLinhaTempoFiador(locatarioId);
            getLocatario(locatarioId);
        }
    }

    const statusTimeLineFiador = async (status_id: number, fiadorId: number) => {
        try {
            setLoader(true)
            if (fiadorId !== null && etapaAtual !== 0) {
                const dataForm = {
                    fiador_id: fiadorId,
                    etapa_id: etapaAtual,
                    comentario: null
                }
                const pathName = { '10': 'realizado', '11': 'nao-realizado' }
                await axiosApi.post(`/fiador/status/${pathName[status_id]}`, dataForm);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
            setEtapaAtual(0);
            getLinhaTempo(locatarioId);
            getLinhaTempoFiador(locatarioId);
            getLocatario(locatarioId);
        }
    }

    const getOptionSedesResponsavel = async () => {
        try {
            setLoader(true);
            const { data } = await axiosApi.get('/sede-responsavel');
            const sedeResponsavel = data.data.map((el: SedesResponsaveis) => ({
                label: el.nome,
                value: el.id,
            }));
            setSedesResponsavel(sedeResponsavel);
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    const saveSedeResponsavel = async (sedeId: number, locatarioId: number) => {
        try {
            setLoader(true);
            if (locatarioId !== null && sedeId !== null && sedeId !== undefined) {
                const dataForm = {
                    sede_id: sedeId
                }
                await axiosApi.put(`sede_responsavel/${locatarioId}`, dataForm);
            }

        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    const onChangeSelect = (item: any, action: any) => {
        setLocatarioData({
            ...locatarioData,
            [action.name]: item.value
        })
    }

    // console.log(fiador)

    const imovel = [
        { header: "Código do Imóvel", value: locatarioData?.imovel_pretendido?.imovel?.codigo_imovel ?? locatarioData?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.codigo_imovel },
        { header: "Valor do Aluguel", value: formatarValor(locatarioData?.imovel_pretendido?.imovel?.preco_locacao ?? locatarioData?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.preco_locacao, "dinheiro") },
        { header: "Endereço", value: locatarioData?.imovel_pretendido?.imovel?.endereco + ", " + locatarioData?.imovel_pretendido?.imovel?.numero + " - " + locatarioData?.imovel_pretendido?.imovel?.bairro ?? locatarioData?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.endereco + ", " + locatarioData?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.numero + " - " + locatarioData?.locatario_imovel?.locatario_principal?.imovel_pretendido?.imovel?.bairro },
        { header: "Garantia", value: locatarioData?.garantia?.descricao ?? locatarioData?.locatario_imovel?.locatario_principal?.garantia?.descricao },
        { header: "Sede", type: locatarioData?.principal ? 'button' : null, value: locatarioData?.sede_responsavel?.nome ?? locatarioData?.locatario_imovel?.locatario_principal?.sede_responsavel?.nome },
    ];

    const locatario = [
        { header: "Nome", value: locatarioData?.informacoes_basicas?.nome_cliente === null ? locatarioData?.informacoes_basicas?.razao_social : locatarioData?.informacoes_basicas?.nome_cliente },
        { header: "Email", value: locatarioData?.informacoes_basicas?.email },
        { header: "Telefone", value: mask(locatarioData?.informacoes_basicas?.telefone1, 'telefone') },
        { header: "Data Solicitação", value: converterDataBr(locatarioData?.created_at) },
        { header: "Tempo de Atendimento", value: `${locatarioData?.tempo_atendimento ?? 0} dias` }
    ];

    const origemCadastro = [
        { header: "Data e Hora", value: converterDataBr(locatarioData?.informacoes_dispositivo?.created_at) },
        { header: "Dispositivo", value: locatarioData?.informacoes_dispositivo?.device_type },
        { header: "Versão", value: ajustUserAgent(locatarioData?.informacoes_dispositivo?.user_agent) },
        { header: "Navegador", value: locatarioData?.informacoes_dispositivo?.browser_name },
        { header: "Versão do Navegador", value: locatarioData?.informacoes_dispositivo?.browser_version },
        { header: "Sistema Operacional", value: locatarioData?.informacoes_dispositivo?.os_name }
    ];

    const fiadorSection = (fiador: any) => {
        return (
            <div>
                {fiador !== undefined ?
                    mobile ?
                        <TimelineCadastroFiadorMobile
                            fiadorId={fiador?.id}
                            timeline={fiador?.timeline}
                            setStatusTimeLine={statusTimeLineFiador}
                            setEtapaAtual={setEtapaAtual}
                            etapaAtual={0}
                            itsButtons={false}
                            statusSectionTimeline={fiador.status === 1}
                            fiador={fiador}
                        /> : <TimelineCadastroFiador
                            fiadorId={fiador?.id}
                            timeline={fiador?.timeline}
                            setStatusTimeLine={statusTimeLineFiador}
                            setEtapaAtual={setEtapaAtual}
                            etapaAtual={0}
                            itsButtons={false}
                            statusSectionTimeline={fiador.status === 1}
                            fiador={fiador}
                        /> : ""}
            </div>
        );
    }

    useEffect(() => {
        onTop();
        getLocatario(locatarioId);
        getLinhaTempo(locatarioId);
        getLinhaTempoFiador(locatarioId);
    }, [locatarioId]);

    useEffect(() => {
        saveSedeResponsavel(sedeId, locatarioId);
    }, [sedeId, locatarioId]);

    useEffect(() => {
        onTop();
        getOptionSedesResponsavel();
        setMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }, []);

    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
                {mobile ? <IndexMobile
                    data={locatarioData}
                    timelineLocatarioPrincipal={timelineLocatarioPrincipal}
                    timelineLocatarioSecundario={timelineLocatarioSecundario}
                    fiadores={fiadores}
                    fiadorSection={fiadorSection}
                    statusTimeLine={statusTimeLine}
                    setEtapaAtual={setEtapaAtual}
                    etapaAtual={etapaAtual}
                /> :
                    <div className="p-6">
                        <div className={`${locatarioData?.status == 2 ? 'bg-cancelado' : 'bg-white'} p-10 flex flex-col space-y-20 rounded-lg border-gray-100 border h-full w-full`} style={{ "boxShadow": "0px 1px 10px rgba(0, 0, 0, 0.2);" }}>
                            {
                                locatarioData?.status == 2 ?
                                    <div className="mt-5">
                                        <p className="font-light text-lg">Motivo do Cancelamento</p>
                                        <textarea
                                            rows={2}
                                            readOnly
                                            name={`comentario_fiador`}
                                            style={{ maxHeight: "6rem", minHeight: "3.5rem" }}
                                            className="block p-4 w-full h-32 mt-2 text-sm border-2 border-opacity-100 text-gray-system-mega-imoveis border-gray-system-mega-imoveis rounded-lg focus:outline-none placeholder-gray-700 resize-none"
                                            // placeholder={'Adicione um comentário'}
                                            value={locatarioData?.motivo_cancelamento}
                                        />
                                    </div>
                                    : ''
                            }
                            <CardTable
                                data={locatario}
                                title={`Informações do Locatário`}
                                key={1}
                            />
                            <CardTable
                                data={origemCadastro}
                                title={`Origem do Cadastro`}
                                key={2}
                            />
                            <CardTable
                                data={imovel}
                                title={`Informações do Imóvel`}
                                key={3}
                                dataOptions={sedesResponsavel}
                                onChange={onChangeSelect}
                                dataForm={locatarioData}
                            />

                            <TimelimeCadastroLocatario
                                locatarioId={locatarioId}
                                timeline={timelineLocatarioPrincipal}
                                setStatusTimeLine={statusTimeLine}
                                setEtapaAtual={setEtapaAtual}
                                etapaAtual={etapaAtual}
                                isPrincipal={true}
                                locatario={locatarioData}
                                itsButtons={true}
                            />

                            {/* <div className="mt-5">
                            <p className="font-light text-lg text-gray-system-mega-imoveis">Comentário</p>
                            <textarea
                                rows={2}
                                name={`comentario_fiador`}
                                style={{ maxHeight: "6rem", minHeight: "3.5rem" }}
                                className="block p-4 w-full h-32 mt-2 text-sm border-2 border-opacity-100 text-gray-system-mega-imoveis border-gray-system-mega-imoveis rounded-lg focus:outline-none placeholder-gray-700 "
                                placeholder={'Adicione um comentário'}
                            />
                        </div> */}

                            <div className="flex flex-col">
                                {timelineLocatarioSecundario.map((item: any) => {
                                    return <TimelimeCadastroLocatario
                                        locatario={item}
                                        locatarioId={item.id}
                                        timeline={item?.timeLine}
                                        setStatusTimeLine={statusTimeLine}
                                        setEtapaAtual={setEtapaAtual}
                                        etapaAtual={etapaAtual}
                                        itsButtons={true}
                                        isSecundario={!item?.principal}
                                    />
                                })}
                                {fiadores.map((item: any) => {
                                    return (fiadorSection(item))
                                })}
                            </div>
                            <TableDetails
                                title="Histórico de Alterações"
                                historico={historico}
                            />
                        </div>
                    </div >
                }
            </Loader >
        </Fragment >
    );
}

export default DetalhesCadastroLocatario;