import React from 'react';
import Header from '../Universal/HeaderPDF';
import Footer from '../Universal/FooterPDF';
import Linha from '../Universal/Linha';
import { formatarValor } from 'src/components/Basicos/funcoes';
import { mask } from 'src/helpers/functions';
import ModeloProprietario1 from '../ModelosProprietario/Modelo1'
import ModeloProprietario2 from '../ModelosProprietario/Modelo2'
import ModeloProprietario3 from '../ModelosProprietario/Modelo3'
import ModeloProprietario4 from '../ModelosProprietario/Modelo4'

interface ModeloProps {
    imovel?: any
    proprietarios?: any
}

const Modelo1: React.FC<ModeloProps> = ({ imovel, proprietarios }) => {
    return (
        <>
            {/* Página 1 */}
            <div className='pagina-pdf'>
                <Header
                    tipo='Imóvel'
                    nome={imovel?.informacoes_basicas?.nome_cliente}
                    data_imovel={imovel?.created_at}
                />
                <div className='body-pdf'>
                    <h1 className='sub-title estilo-fonte-negrito'>
                        Dados do Imóvel
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <Linha campo10='Tipo*' valor10={imovel?.tipo_imovel?.descricao} campo20='CEP*' valor20={mask(imovel?.endereco?.cep, "cep")} />
                    <Linha campo10='Finalidade*' valor10={imovel?.finalidade?.descricao} isEndereco2={true} campo20='Logradouro*' valor20={imovel?.endereco?.logradouro} campo21='Número*' valor21={imovel?.endereco?.numero} />
                    <Linha campo10='Matrícula do Imóvel*' valor10={imovel?.matricula_imovel} campo20='Complemento*' valor20={imovel?.endereco?.complemento} />
                    <Linha campo10='Para Locação*' valor10={imovel?.locacao ? 'Sim' : 'Não'} campo11='Para Venda' valor11={imovel?.venda ? 'Sim' : 'Não'} campo20='Bairro*' valor20={imovel?.endereco?.bairro} />
                    <Linha campo10='Valor da Locação*' valor10={formatarValor(imovel?.valor_locacao)} campo11='Valor da Venda' valor11={formatarValor(imovel?.valor_venda)} campo20='Cidade' valor20={imovel?.endereco?.cidade} campo21='Estado' valor21={imovel?.endereco?.estado} />
                    <Linha campo10='Será Representado por Procurador?' valor10={imovel?.principal_por_procuracao ? 'Sim' : 'Não'} campo20='IPTU' valor20={formatarValor(imovel?.iptu)} />
                    <Linha campo10='Cópia da Matrícula, Escritura ou Contrato de Compra e Venda do Imóvel' valor10={imovel?.arquivo_matricula_escritura?.nome} />

                    <div className='borda-principal-pdf mt-4'></div>

                    <h1 className='sub-title estilo-fonte-negrito'>
                        Características do Imóvel
                    </h1>
                    <div className='borda-pdf mb-3 mt-3'></div>
                    <h1 className='text-xs'>Documentos do Imóvel</h1>
                    <Linha campo10='Água' valor10={imovel?.arquivo_agua?.nome} campo20='Gás*' valor20={imovel?.arquivo_gas?.nome} />
                    <Linha campo10='Condomínio' valor10={imovel?.arquivo_condominio?.nome} campo20='IPTU*' valor20={imovel?.arquivo_iptu?.nome} />
                    <Linha campo10='Energia Elétrica' valor10={imovel?.arquivo_arquivo_energia?.nome} campo20='Quantos proprietários o imóvel possui?' valor20={imovel?.proprietarios_count} />
                </div>
                <div className='footer-pdf'>
                    <Footer />
                </div>
            </div>
            {/* Proprietário Principal */}
            {
                proprietarios[0]?.conjuge != null && proprietarios[0]?.procurador != null ? <ModeloProprietario1 proprietario={proprietarios[0]} tipo_proprietario='Principal' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                    : proprietarios[0]?.conjuge == null && proprietarios[0]?.procurador != null ? <ModeloProprietario2 proprietario={proprietarios[0]} tipo_proprietario='Principal' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                        : proprietarios[0]?.conjuge != null && proprietarios[0]?.procurador == null ? <ModeloProprietario3 proprietario={proprietarios[0]} tipo_proprietario='Principal' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                            : <ModeloProprietario4 proprietario={proprietarios[0]} tipo_proprietario='Principal' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
            }

            {/* Proprietario Secundario 1 */}
            {
                proprietarios[1] != undefined ?
                    proprietarios[1]?.conjuge != null && proprietarios[1]?.procurador != null ? <ModeloProprietario1 proprietario={proprietarios[1]} tipo_proprietario='Secundário 1' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                        : proprietarios[1]?.conjuge == null && proprietarios[1]?.procurador != null ? <ModeloProprietario2 proprietario={proprietarios[1]} tipo_proprietario='Secundário 1' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                            : proprietarios[1]?.conjuge != null && proprietarios[1]?.procurador == null ? <ModeloProprietario3 proprietario={proprietarios[1]} tipo_proprietario='Secundário 1' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                                : <ModeloProprietario4 proprietario={proprietarios[1]} tipo_proprietario='Secundário 1' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} /> : ''
            }

            {/* Proprietario Secundario 2 */}
            {
                proprietarios[2] != undefined ?
                    proprietarios[2]?.conjuge != null && proprietarios[2]?.procurador != null ? <ModeloProprietario1 proprietario={proprietarios[2]} tipo_proprietario='Secundário 2' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                        : proprietarios[2]?.conjuge == null && proprietarios[2]?.procurador != null ? <ModeloProprietario2 proprietario={proprietarios[2]} tipo_proprietario='Secundário 2' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                            : proprietarios[2]?.conjuge != null && proprietarios[2]?.procurador == null ? <ModeloProprietario3 proprietario={proprietarios[2]} tipo_proprietario='Secundário 2' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                                : <ModeloProprietario4 proprietario={proprietarios[2]} tipo_proprietario='Secundário 2' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} /> : ''
            }

            {/* Proprietario Secundario 3 */}
            {
                proprietarios[3] != undefined ?
                    proprietarios[3]?.conjuge != null && proprietarios[3]?.procurador != null ? <ModeloProprietario1 proprietario={proprietarios[3]} tipo_proprietario='Secundário 3' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                        : proprietarios[3]?.conjuge == null && proprietarios[3]?.procurador != null ? <ModeloProprietario2 proprietario={proprietarios[3]} tipo_proprietario='Secundário 3' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                            : proprietarios[3]?.conjuge != null && proprietarios[3]?.procurador == null ? <ModeloProprietario3 proprietario={proprietarios[3]} tipo_proprietario='Secundário 3' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                                : <ModeloProprietario4 proprietario={proprietarios[3]} tipo_proprietario='Secundário 3' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} /> : ''
            }

            {/* Proprietario Secundario 4 */}
            {
                proprietarios[4] != undefined ?
                    proprietarios[4]?.conjuge != null && proprietarios[4]?.procurador != null ? <ModeloProprietario1 proprietario={proprietarios[4]} tipo_proprietario='Secundário 4' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                        : proprietarios[4]?.conjuge == null && proprietarios[4]?.procurador != null ? <ModeloProprietario2 proprietario={proprietarios[4]} tipo_proprietario='Secundário 4' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                            : proprietarios[4]?.conjuge != null && proprietarios[4]?.procurador == null ? <ModeloProprietario3 proprietario={proprietarios[4]} tipo_proprietario='Secundário 4' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} />
                                : <ModeloProprietario4 proprietario={proprietarios[4]} tipo_proprietario='Secundário 4' tipo_header='Imóvel' nome={imovel?.informacoes_basicas?.nome_cliente} data_imovel={imovel?.created_at} /> : ''
            }
        </>
    );
};

export default Modelo1;