import React from "react";

type Props = {
  className?: string;
  id?: string;
  htmlFor?: string;
  content: string;
};

export default function Title({
  id,
  className,
  htmlFor,
  content,
}: Props): JSX.Element {
  return (
    <label htmlFor={htmlFor} className={className} id={id}>
      {content}
    </label>
  );
}
