import React, { Fragment, useEffect, useState } from "react";
import { Title } from "src/components";
import { axiosApi } from "src/services/axiosInstance";
import Loader from "src/components/Basicos/Loader";
import PieChartMegaImoveisMobile from "src/components/Graficos/PieChart/indexMobile";
import BarChartHorizontalMobile from "src/components/Graficos/BarChart/BarChartHorizontal/indexMobile";

type Props = {
    dateInicial: any,
    dateFinal: any,
    sedeId: any
};

const DashboardDirectorMobile: React.FunctionComponent<Props> = ({
    dateInicial,
    dateFinal,
    sedeId
}) => {
    const [chartsData, setChartsData] = useState<any>();
    const [loader, setLoader] = useState<boolean>(true);

    const getChartsData = async (sedeId: number | null, dateInicial: string | null, dateFinal: string | null) => {
        try {
            setLoader(true)
            const dataForm = {
                sede_id: sedeId ?? null,
                date_inicial: dateInicial ?? null,
                date_final: dateFinal ?? null
            }
            const { data } = await axiosApi.post(`graficos-diretoria`, dataForm);
            setChartsData(data?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getChartsData(sedeId, dateInicial, dateFinal)
    }, [sedeId, dateInicial, dateFinal]);

    return (
        <Fragment>
            <Loader
                isActive={loader}
            >
                <div className="flex flex-col w-full pb-12 px-8 gap-5">
                    <div className="flex flex-col p-3 rounded-xl bg-white w-full" style={{ height: '30rem' }}>
                        <div className="flex w-full">
                            <Title
                                className={`text-2xl font-bold font-mont-bold text-gray-system-mega-imoveis`}
                                content={`Cadastros de Locação - Gerais`} />
                        </div>
                        <PieChartMegaImoveisMobile
                            legenda={true}
                            data={chartsData?.locatariosGeral}
                        />
                    </div>
                    <div className="flex flex-col p-3 rounded-xl bg-white w-full grafico-mobile" style={{ height: '30rem' }}>
                        <BarChartHorizontalMobile
                            title={'Cadastros de Locação por Sede'}
                            buttons={true}
                            data={chartsData?.locacaoPorSede}
                        />
                    </div>
                </div>
            </Loader>
        </Fragment>
    );
}

export default DashboardDirectorMobile;