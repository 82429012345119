import React, { useEffect, useState } from 'react';
import { formatarValor } from 'src/components/Basicos/funcoes';
import Modal from '../ModalNoticia';
import './style.css';
import ModalPJ from './ModalPJ';
import ModalPF from './ModalPF';
import ModalPFNovo from './ModalPFNovo';
import ModalPJNovo from './ModalPJNovo';

type Props = {
    open: boolean;
    isClose?: any;
    locatario?: any;
    fiador?: any;
    analiseFinanceira?: any;
    timeline?: any;
}

const ModalAnaliseFinanceira: React.FC<Props> = ({
    isClose,
    open,
    locatario,
    fiador,
    analiseFinanceira,
    timeline
}: Props): JSX.Element => {
    const [data, setData] = useState<any>();
    const [conjuge, setConjuge] = useState<any>();
    const [valorAluguel, setValorAluguel] = useState<any>();
    const [valorCondominio, setValorCondominio] = useState<any>();
    useEffect(() => {
        if (open == true && (locatario || fiador)) {
            setValorAluguel(locatario?.imovel_pretendido?.imovel?.preco_locacao ?? fiador?.locatario?.imovel_pretendido?.imovel?.preco_locacao ?? 0);
            setValorCondominio(locatario?.imovel_pretendido?.imovel?.condominio ?? fiador?.locatario?.imovel_pretendido?.imovel?.condominio ?? 0);
            setConjuge(locatario?.conjuge ?? fiador?.conjuge);
            setData(locatario ?? fiador);
        }
    }, [open])

    return (
        <Modal
            isOpen={open}
            isClose={() => isClose()}
            headerStyle={''}
            closeOnOutsideClick={true}
            widthModal={conjuge != null ? `85%` : `45%`}
            modalStyle={`modal-style bg-white rounded-lg py-5 px-10 modal-analise-financeira`}
        >
            <h1 className='text-2xl estilo-fonte-negrito'>Resultado Consulta Serasa</h1>
            <div className='flex header-box mt-4 mb-4'>
                {data?.tipo_locatario_id == 1 ?
                    <>
                        <div className={`box-header-analise-financeira estilo-1`}>
                            <h1>Valor do Aluguel</h1>
                            <h1>{formatarValor(valorAluguel, "dinheiro")}</h1>
                        </div>
                        <div className={`box-header-analise-financeira estilo-1`}>
                            <h1>Pacote de Locação</h1>
                            <h1>{formatarValor(Number(valorAluguel) + Number(valorCondominio), "dinheiro")}</h1>
                        </div>
                        <div className='box-header-analise-financeira estilo-1'>
                            <h1>Total da Renda</h1>
                            <h1>{formatarValor(Number(timeline?.renda_total), "dinheiro")}</h1>
                        </div>
                    </>
                    : <div className={`box-header-analise-financeira estilo-pj-1`}>
                        <h1>Valor do Aluguel</h1>
                        <h1>{formatarValor(valorAluguel, "dinheiro")}</h1>
                    </div>}
            </div>
            {
                data?.analise_financeira?.novo == true ?
                    data?.tipo_locatario_id == 2 ?
                        <ModalPJNovo infos={data} timeline={timeline} /> :
                        conjuge != null ?
                            <div className='flex modal-com-conjuge'>
                                <ModalPFNovo infos={data} conjuge={conjuge} timeline={timeline} />
                                <ModalPFNovo infos={conjuge} conjuge={conjuge} timeline={timeline} principal={data} />
                            </div>
                            :
                            <ModalPFNovo infos={data} timeline={timeline} />
                    :
                    data?.tipo_locatario_id == 2 ?
                        <ModalPJ infos={data} timeline={timeline} /> :
                        conjuge != null ?
                            <div className='flex modal-com-conjuge'>
                                <ModalPF infos={data} conjuge={conjuge} timeline={timeline} />
                                <ModalPF infos={conjuge} conjuge={conjuge} timeline={timeline} />
                            </div>
                            :
                            <ModalPF infos={data} timeline={timeline} />
            }
        </Modal>
    );
}

export default ModalAnaliseFinanceira;