import React, { createRef, useEffect, useState } from 'react';
import { ativarRefreshToken, axiosApi } from "src/services/axiosInstance";
import { faEye, faEyeSlash, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './style.css';
import { AuthState, Usuario } from "src/store/types";
import { setDadosAtualizados } from "src/store/actions/auth";
import { connect, ConnectedProps } from "react-redux";
import { warn } from 'src/components/Basicos/funcoes';
import IndexDesktop from './indexDesktop';
import IndexMobile from './indexMobile';
import { useControlContextMobile } from 'src/contexts/MobileContext';

const mapStateToProps = ({ auth }: { auth: AuthState }) => {
    return {
        access_token: {
            token: auth.access_token?.token,
            usuario: auth.access_token?.usuario,
        },
        refresh_token: auth.refresh_token
    };
};

const mapDispatchToProps = {
    setDadosAtualizados,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {};

type infoToken = {
    token: string;
    usuario: Usuario;
};
type LoginResponse = {
    access_token: infoToken;
    refresh_token: string;
    expires_in: number;
    token_type: string;
};
type LoginForm = {
    email: string;
    password: string;
};

function Login({ setDadosAtualizados }: Props): JSX.Element {
    const { mobile, setMobile } = useControlContextMobile();
    const [isRevealPassword, setIsRevealPassword] = useState<Boolean>(false);
    const iconRef = createRef<HTMLDivElement>();
    const [errorLogin, setErroLogin] = useState<boolean>();

    const togglePassword = (): void => {
        setIsRevealPassword(!isRevealPassword);
    }

    useEffect(() => {
        setMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }, []);

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        setErroLogin(false);
        event.preventDefault();
        try {
            const response = await axiosApi.post<LoginResponse>(`/auth/login-master`, form);
            const dataExpiracao = new Date(
                new Date().getTime() + response.data.expires_in
            );
            localStorage.setItem("token", response.data.access_token.token);
            localStorage.setItem("refresh_token", response.data.refresh_token);
            localStorage.setItem(
                "usuario",
                JSON.stringify(response.data.access_token.usuario)
            );
            localStorage.setItem(
                "expires_in",
                JSON.stringify(response.data.expires_in)
            );
            localStorage.setItem("data_expiracao", JSON.stringify(dataExpiracao));
            setDadosAtualizados(
                response.data.access_token.token,
                response.data.access_token.usuario,
                response.data.expires_in,
                response.data.refresh_token
            );

            ativarRefreshToken(setDadosAtualizados);
        } catch (error) {
            setErroLogin(true);
            warn('Usuário ou senha inválido!');
            let errorMessage = "Failed to do something exceptional";
            if (error instanceof Error) {
                errorMessage = error.message;
            }
            console.log(errorMessage)
        }
    };

    const [form, setForm] = useState<LoginForm>({ email: "", password: "" });

    const changeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        setForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        mobile ? <IndexMobile
            handleLogin={handleLogin}
            changeInput={changeInput}
            errorLogin={errorLogin}
            isRevealPassword={isRevealPassword}
            togglePassword={togglePassword}
            iconRef={iconRef}
            faEye={faEye}
            faEyeSlash={faEyeSlash}
            faArrowRight={faArrowRight}
        /> : <IndexDesktop
            handleLogin={handleLogin}
            changeInput={changeInput}
            errorLogin={errorLogin}
            isRevealPassword={isRevealPassword}
            togglePassword={togglePassword}
            iconRef={iconRef}
            faEye={faEye}
            faEyeSlash={faEyeSlash}
            faArrowRight={faArrowRight}
        />
    );
}

export default connector(Login);