import React, { Fragment } from "react";
import iconAnalise from "src/assets/icones/icon-analise.svg"
// import { alertMsg } from "src/helpers/functions";
import CardTimeLapse from "../../Cards/CardTimeLapse";
import { AccordionTimeLine } from "../../AccordionTimeLine";
import iconCrownFill from "src/assets/icones/icon-crown-fill.svg"
import iconCrown from "src/assets/icones/icon-crown.svg"

export type Props = {
    proprietarioId?: number;
    timeline?: any;
    proprietario?: any;
    setStatusTimeLine?: any,
    setEtapaAtual?: any,
    setStateLinkImovel?: any,
    etapaAtual?: number,
    comentario?: any,
    setValueOption?: any,
    isPrincipal?: boolean
}

export default function TimelimeCadastroProprietario({
    proprietarioId,
    proprietario,
    timeline,
    isPrincipal = false
}: Props): JSX.Element {
    // [1, 2, 3, 5]
    const statusWithButton = [1, 2, 3, 5, 9];
    
    // const principal = proprietario?.imovel_proprietario_auxiliar?.principal;
    return (
        <Fragment>
            <div className="flex flex-col w-full scale-150">
                <AccordionTimeLine
                    disable={isPrincipal}
                    data={proprietario}
                    type={'proprietario'}
                >
                    <div className={`flex flex-col p-4  ${isPrincipal ? 'bg-gray-ligth-system-mega-imoveis' : 'bg-white'} rounded-xl gap-2`}>
                        {isPrincipal ? <div className="flex flex-row justify-between font-mont-bold">
                            <div className={`flex ml-auto h-16 w-16 items-center justify-center p-1 focus:outline-none `} onClick={() => { }}>
                                <img src={!isPrincipal ? iconCrown : iconCrownFill} className={`inset-0 ${!isPrincipal ? 'h-8 w-8' : 'h-12 w-12'} object-center`} alt="icon_crown" />
                            </div>
                        </div> : ""}
                        <div className="flex flex-col justify-center items-center md:flex-row gap-4 w-full md:justify-between">
                            <CardTimeLapse
                                etapa={1}
                                image={iconAnalise}
                                itsButton={isPrincipal}
                                isIcon={timeline[1]?.status_id == 1 ? true : false}
                                cadastroId={proprietarioId}
                                titleCard={'Cadastro Recebido'}
                                nextPathName={'view'}
                                status={timeline[1]?.status_id}
                                subTitleCard={timeline[1]?.updated_at ?? null}
                                width={'w-1/2'}
                                active={timeline[1]?.status !== null}
                                buttonTitle={'visualizar cadastro'}
                                minHeight={'15rem'}
                            />
                            <CardTimeLapse
                                etapa={3}
                                etapaName={'2'}
                                color={'bg-orange-500'}
                                colorText={'text-orange-500'}
                                status={timeline[3]?.status_id}
                                nextPathName={'analise-cadastro'}
                                image={iconAnalise}
                                itsButton={isPrincipal ? statusWithButton.includes(timeline[3]?.status_id) : false}
                                itsButtonView={isPrincipal ? statusWithButton.includes(timeline[3]?.status_id) : false}
                                cadastroId={proprietarioId}
                                motivo={timeline[3]?.motivo ?? null}
                                buttonTitle={'Analisar cadastro'}
                                titleCard={'Análise do Cadastro'}
                                subTitleCard={timeline[3]?.updated_at ?? null}
                                width={'w-1/2'}
                                active={timeline[3]?.status !== null}
                                minHeight={'15rem'}
                            />
                        </div>
                    </div>
                </AccordionTimeLine>
            </div>
        </Fragment>
    );
}