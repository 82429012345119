export const UPDATE_TOKEN: string = "AUTH_UPDATE_TOKEN";
export const INITIAL_STATE: string = "AUTH_INITIAL_STATE";
export const UPDATE_PERFIL: string = "UPDATE_PERFIL";

export type AuthState = {
  access_token?: AccessToken;
  expires_in?: number;
  token_type?: string;
  refresh_token?: string;
};
export type AccessToken = {
  usuario?: Usuario | null;
  token?: string | null;
};

export type AuthAction = {
  type: string;
  payload: AuthState;
};

export type Usuario = {
  id: number;
  id_nivel_usuario: number;
  nome_razao_social: string;
  nr_cpf_cnpj: string;
  email: string;
  nr_telefone: number;
  nr_cep: string;
  ds_logradouro: string;
  numero: string;
  ds_complemento: string;
  ds_bairo: string;
  ds_cidade: string;
  ds_estado: string;
  ds_pais: string;
  image: Image;
  status?: number;
};

export type Notificacao = {
  id: number;
  descricao: string;
  usina_id: number;
}

type Image = {
  id: number;
  id_usuario: number;
  id_usina_beneficiaria: number;
  nome: string;
  ds_caminho: string;
  tipo_mime: string;
};

export type Fatura = {
  id: number;
  id_usuario: Usuario;
  nr_fatura: number;
  nr_cliente: number;
  data_referencia: string;
  nr_medidor: string;
  id_modalidade_tarifa: ModalidadeTarifa;
  id_tipo_ligacao: TipoLigacao;
  id_classificacao: Classificacao;
  data_vencimento: string;
  vr_total_a_pagar: number;
  data_emissao: string;
  ds_informacao_importante: string;
  ds_atencao: string;
  nr_codigo_barras: string;
  data_pagamento: string;
  id_status_fatura: StatusFatura;

};

type ModalidadeTarifa = {
  id: number;
  descricao: string;
};

type TipoLigacao = {
  id: number;
  descricao: string;
};

type Classificacao = {
  id: number;
  descricao: string;
};

type StatusFatura = {
  id: number;
  descricao: string;
};

export type Beneficiaria = {
  id: number;
  id_usuario: Usuario;
  id_usina: Usina;
  nr_cliente: string;
  id_contrato: Contrato;
  nr_cpf_cnpj: string;
  nome_beneficiaria: string;
  codigo_beneficiaria: string;
  cep: string;
  ds_logradouro: string;
  numero: string;
  ds_complemento: string;
  ds_bairro: string;
  ds_cidade: string;
  ds_estado: string;
  ds_pais: string;
  status: boolean;
  id_concessionaria: Concessionaria;
}

export type Usina = {
  id: number;
  id_tipo_central_eletrica: TipoCentralEletrica;
  nome_usina: string;
  nome_pessoa_contato: string;
  nr_cep: string;
  nr_latitude: string;
  nr_longitude: string;
  ds_logradouro: string;
  numero: string;
  ds_bairro: string;
  ds_cidade: string;
  ds_estado: string;
  ds_pais: string;
  qnt_painel: number;
  qnt_potencia_total: number;
  qnt_capacidade_instalada_kwp: number;
  data_inicio_operacao: string;
  vr_inestimento: number;
  vr_energia: number;
  st_ativo: number;
  numeracao_otimizador: number;
  nr_armazenamento_energia: number;
  nr_potencia_tempo_real_kw: number;
  qdt_horas_utilizadas_equivalente_kwh_kwp: number;
  nr_capacidade_geracao_dia_kwh: number;
  nr_geracao_acumulada: number;
  vr_eficiencia_sistema_pr: number;
  qnt_total_irradiacao_kwh_m2: number;
  id_status_central: StatusCentral;
}

export type Contrato = {
  id: number;
  id_usuario: Usuario;
  valor_contrato: number;
  qtd_energia_injetada: number;
  vr_demanda_usina_beneficiaria: number;
  status: number;
  numero_contrato: string;
};

type Concessionaria = {
  id: number;
  nome: string;
  status: boolean;
};

type TipoCentralEletrica = {
  id: number;
  descricao: string;
  status: boolean;
};

type StatusCentral = {
  id: number;
  descricao: string;
  status: boolean;
};

export type BeneficiariaFatura = {
  id: number;
  id_fatura: Fatura;
  id_beneficiaria: Beneficiaria;
  nr_cliente: number;
};

export type Medicao = {
  id: number;
  id_fatura: Fatura;
  id_posto_tarifario: PostoTarifario;
  data_leitura_atual: string;
  data_leitura_anterior: string;
  data_previsao_proxima_leitura: string;
  nr_leitura_atual_consumo_kwh: number;
  nr_leitura_anterior_consumo_kwh: number;
  nr_consumo_mes_kwh: number;
  nr_leitura_atual_injetada_kwh: number;
  nr_leitura_anterior_injetada_kwh: number;
  nr_consumo_mes_injetada_kwh: number;
  nr_consumo_liquido_kwh: number;
  nr_consumo_faturado_kwh: number;
  id_tarifa: Tarifa;
  valor_consumido: number;
  nr_credito_injetado_leitura_anterior_kwh: number;
  nr_credito_injetado_acumulado_kwh: number;
  nr_saldo_atualizado_kwh: number;
  id_usina_beneficiaria: number;
}


export type PostoTarifario = {
  id: number;
  descricao: string;
}


export type Tarifa = {
  id: number;
  descricao: string;
  vl_tarifa: number;
}